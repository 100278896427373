import axios from 'axios';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/state';
import config from '@/config';
import { MUTATION_NAME } from './mutations-types';
import { initialState, State } from './model';

export default {
  async GET_LABELLED_STATES(context: ActionContext<State, RootState>, payload) {
    /* Your action content here */
    try {
      const { status, data } = await axios.post(
        `${config.dataProxyUrls.graphql}`,
        {
          query: `query {
            getDeviceLabelledStates(
              deviceId: "${payload.deviceId}",
              fromTs: "${payload.fromTs}",
              toTs: "${payload.toTs}"
            ) {
              deviceId,
              fromTs,
              toTs,
              classification,
              tag {
                id,
                tagname,
                tagcolor,
                tagtype,
                tagplanned,
              },
              deviceState {
                id,
                tag {
                  id,
                  companyid,
                  tagname,
                  tagcolor,
                  tagtype,
                  tagplanned,
                  data,
                }
              }
            }
          }`,
        }
      );

      if (status === 200 && data) {
        return data.data.getDeviceLabelledStates;
      }

      throw new Error(`${status}`);
    } catch (error) {
      console.log('ERROR:', error);
      throw error;
    }
  },
  async UPDATE_LABELLED_STATES(
    context: ActionContext<State, RootState>,
    payload
  ) {
    try {
      const { status, statusText, data } = await axios.patch(
        config.dataProxyUrls.v2.deviceLabelledStates.url(),
        payload.data,
        {
          params: payload.searchParams,
        }
      );

      if (status === 200 && data.response) {
        return data.response;
      }
      throw new Error(`[UPDATE_LABELLED_STATES]: ${status} - ${statusText}`);
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
