import axios, { AxiosResponse, CancelTokenStatic } from 'axios';
import { ActionContext } from 'vuex';
import moment from 'moment';
import { RootState } from '@/store/state.ts';
import config from '@/config';

import { Post } from '@/store/modules/posts/model';

import {
  SET_FEEDS,
  MERGE_FEEDS,
  SET_LOADING,
  SET_LOADING_DONE,
  PREPEND_FEEDS,
  CLEAR_FEEDS,
  REMOVE_FEED,
  UPDATE_FEED,
} from './mutations-types';

import { State, BigTableModel, Feed, PostFeed } from './model';

type GetFeedsActionResponse = { response: { feeds: Array<BigTableModel> } };

const cancelToken: CancelTokenStatic = axios.CancelToken;
let cancelSource = cancelToken.source();

const refreshToken = () => {
  cancelSource = cancelToken.source();
};

const cancelAllRequests = () => {
  cancelSource.cancel('cancelled');
};

export default {
  async getFeeds(context: ActionContext<State, RootState>, payload) {
    // Cancel all previous calls
    cancelAllRequests();
    refreshToken();

    /* Your action content here */
    const { company: companyId } = context.rootState;
    const url = config.dataProxyUrls.v2.feeds.url({ companyId });

    // Return days to keep scrolling but prevent requests to the server
    if (payload.types.length === 1 && payload.types.includes('SUMMARY')) {
      context.commit(SET_LOADING);
      const days = moment(payload.to).diff(payload.from, 'days');
      const feeds = Array(days)
        .fill(0)
        .reduce((acc, e, i) => {
          const day = moment(payload.to).subtract(i, 'days').valueOf();
          acc[day] = [];
          return acc;
        }, {});
      context.commit(MERGE_FEEDS, feeds);
      return new Promise((res) => {
        setTimeout(() => {
          context.commit(SET_LOADING_DONE);
          res(feeds);
        }, 500);
      });
    }

    context.commit(SET_LOADING);
    try {
      const { data, ...rest } = await axios.get(url, {
        params: payload,
        cancelToken: cancelSource.token,
      });

      if (!data) {
        throw new Error(rest.message);
      }

      if (data && data.response) {
        context.commit(MERGE_FEEDS, data.response.feeds);
      }

      context.commit(SET_LOADING_DONE);
      return data.response.feeds;
    } catch (error) {
      if (error.message === 'cancelled') {
        context.commit(SET_LOADING_DONE);
        context.commit(SET_FEEDS, []);
        return [];
      }

      context.commit(SET_LOADING_DONE);
      return [];
    }
  },
  addPostFeed(context: ActionContext<State, RootState>, payload: Post) {
    const { id, companyId, username, timestamp } = payload;

    const feed: PostFeed = {
      companyId,
      content: payload,
      header: 'Post',
      type: 'POST',
      timestamp,
      sourceId: id,
      username,
    };

    context.commit(PREPEND_FEEDS, [feed]);
  },
  updatePostFeed(context: ActionContext<State, RootState>, payload: Post) {
    const { id, companyId, username, content, timestamp } = payload;

    const feed: PostFeed = {
      companyId,
      content: payload,
      header: 'Post',
      type: 'POST',
      timestamp,
      sourceId: id,
      username,
    };

    context.commit(UPDATE_FEED, feed);
  },
  removeFeed(context: ActionContext<State, RootState>, feed: Feed) {
    context.commit(REMOVE_FEED, feed);
  },
  clear(context: ActionContext<State, RootState>) {
    context.commit(CLEAR_FEEDS);
  },
};
