import Index from '@/views/Index.vue';

const AsyncRoutes = {
  uptime: async () => import('@/views/Uptime/index.vue'),
  monitor: async () => import('@/views/Monitor'),
  production: async () => import('@/views/Production'),
  trends: async () => import('@/views/Trends'),
  dashboard: async () => import('@/views/Dashboard'),
  alerts: async () => import('@/views/Alerts'),
  diagnostics: async () => import('@/views/Diagnostics'),
  feed: async () => import('@/views/Feed'),
  calculations: async () => import('@/views/Calculations.vue'),
  historical: async () => import('@/views/HistoricalBills.vue'),
  home: async () => import('@/views/Home'),
  historicalEdit: async () => import('@/views/HistoricalBillsEdit.vue'),
  settings: async () => import('@/views/Settings/NewSettings.vue'),
  login: async () => import('@/views/Login.vue'),
  forgot: async () => import('@/views/Forgot.vue'),
  reset: async () => import('@/views/Reset.vue'),
  exportData: async () => import('@/views/ExportData.vue'),
  email: () => import('@/views/Email'),
  issueMobile: async () => import('@/views/IssueMobile'),
  labs: async () => import('@/views/Labs'),
  maintenance: async () => import('@/views/Maintenance'),
  operatorTagging: async () => import('@/views/OperatorTagging'),
  mfa: async () => import('@/views/Mfa'),
  lineIssueMobile: async () => import('@/views/LineIssueMobile'),
};

export const preloadPages = () => {
  Object.values(AsyncRoutes).forEach(async (route) => {
    try {
      await route();
    } catch {
      // We are here because of one of the following reasons:
      //
      // Scenario 1: we have a network problem so we couldn't load the chunk, then
      // it will be lazy loaded when user clicks in the corresponding section.
      //
      // Scenario 2: chunk was removed due to a new version deployment, then, in
      // the next request to Safi API system will auto refresh the website.
      //
      // Conclusion: both scenarios will 'auto-fix' themselves, the idea here
      // is doing nothing and stop populating Sentry with a lot of "chunkLoadError"
      // entries that adds no extra value to us.
    }
  });
};

export default [
  {
    path: '/',
    meta: {
      requiresAuthentication: true,
    },
    component: Index,
  },
  {
    path: '/uptime',
    name: 'Uptime',
    component: AsyncRoutes.uptime,
    meta: {
      requiresAuthentication: true,
      feature: 'uptime-menubar',
      landingPageName: 'uptime',
    },
    navItem: {
      icon: 'uptime-icon',
      text: 'Uptime',
      to: '/uptime',
    },
  },
  {
    path: '/monitor',
    name: 'OEE Monitor',
    component: AsyncRoutes.monitor,
    meta: {
      requiresAuthentication: true,
      feature: 'factory-ops-mvp',
      landingPageName: 'monitor',
    },
    navItem: {
      icon: 'monitor-icon',
      text: 'Monitor',
      to: '/monitor',
    },
  },
  {
    path: '/trends',
    name: 'Trends',
    component: AsyncRoutes.trends,
    meta: {
      requiresAuthentication: true,
      feature: 'trends-menubar',
      landingPageName: 'trends',
    },
    navItem: {
      icon: 'trends-icon',
      text: 'Trends',
      to: '/trends',
    },
  },
  {
    path: '/dashboard',
    name: 'Scoreboard',
    component: AsyncRoutes.dashboard,
    meta: {
      requiresAuthentication: true,
      requiresAuthorizationLevel: 3,
      feature: 'scoreboard-menubar',
      landingPageName: 'scoreboard',
    },
    navItem: {
      icon: 'dashboard',
      text: 'Scoreboard',
      to: '/dashboard',
    },
  },
  {
    path: '/new-production',
    name: 'Production',
    component: AsyncRoutes.production,
    meta: {
      requiresAuthentication: true,
      feature: 'production-menubar',
      landingPageName: 'production',
    },
    navItem: {
      icon: 'production-icon',
      text: 'Production',
      to: '/new-production',
    },
  },
  {
    path: '/cycles',
    name: 'Cycles',
    component: AsyncRoutes.labs,
    meta: {
      requiresAuthentication: true,
      feature: 'cycles-menubar',
      landingPageName: 'cycles',
    },
    navItem: {
      icon: 'cycles',
      text: 'Cycles',
      to: '/cycles',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: AsyncRoutes.maintenance,
    meta: {
      requiresAuthentication: true,
      feature: 'maintenance-menubar',
    },
    navItem: {
      icon: 'maintenance-icon',
      text: 'Maintenance',
      to: '/maintenance',
    },
  },
  {
    path: '/alerts',
    name: 'Issues',
    component: AsyncRoutes.alerts,
    meta: {
      requiresAuthentication: true,
      feature: 'issues-menubar',
      landingPageName: 'issues',
    },
    navItem: {
      icon: 'issues-icon',
      text: 'Issues',
      to: '/alerts',
    },
  },
  {
    path: '/hmi',
    name: 'Sidekick',
    component: AsyncRoutes.operatorTagging,
    meta: {
      requiresAuthentication: true,
      feature: 'hmi-menubar',
      hideAppBar: true,
      landingPageName: 'sidekick',
    },
    navItem: {
      icon: 'hmi-icon',
      text: 'Sidekick',
      to: '/hmi',
    },
  },
  {
    path: '/diagnostics',
    name: 'Diagnostics',
    meta: {
      requiresAuthentication: true,
      feature: 'diagnostic-menubar',
    },
    component: AsyncRoutes.diagnostics,
    navItem: {
      icon: 'diagnostics-icon',
      text: 'Diagnostics',
      to: '/diagnostics',
    },
  },
  {
    path: '/feed',
    name: 'Feed',
    component: AsyncRoutes.feed,
    meta: {
      requiresAuthentication: true,
      feature: 'feed-menubar',
    },
    navItem: {
      icon: 'activity-icon',
      text: 'Feed',
      to: '/feed',
    },
  },
  {
    path: '/calculations',
    name: 'Calculations',
    meta: {
      requiresAuthentication: true,
      landingPageName: 'calculations',
      feature: 'calculations-menubar',
    },
    component: AsyncRoutes.calculations,
  },
  {
    path: '/historical',
    name: 'Historical',
    meta: {
      requiresAuthentication: true,
    },
    component: AsyncRoutes.historical,
  },
  {
    path: '/summary',
    name: 'Summary',
    meta: {
      requiresAuthentication: true,
      requiresAuthorizationLevel: 2,
    },
    component: AsyncRoutes.home,
  },
  {
    name: 'Energy',
    path: '/energymanagement',
    meta: {
      requiresAuthentication: true,
    },
    navItem: {
      icon: 'energy-mgmt-icon',
      text: 'Energy',
    },
    children: [
      {
        path: '/calculations',
        name: 'CalculationsChild',
        meta: {
          requiresAuthentication: true,
          feature: 'calculations-menubar',
        },
        navItem: {
          icon: 'insert_chart',
          text: 'Calculations',
          to: '/calculations',
        },
      },
      {
        path: '/historical',
        name: 'HistoricalBills',
        meta: {
          requiresAuthentication: true,
          feature: 'historical-menubar',
        },
        navItem: {
          icon: 'assessment',
          text: 'Historical',
          to: '/historical',
        },
      },
      {
        path: '/summary',
        name: 'summary',
        meta: {
          requiresAuthentication: true,
          requiresAuthorizationLevel: 2,
          feature: 'summary-menubar',
        },
        navItem: {
          icon: 'description',
          text: 'Summary',
          to: '/summary',
        },
      },
    ],
  },
  {
    path: '/historical/edition',
    name: 'HistoricalBillsEdit',
    component: AsyncRoutes.historicalEdit,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/new-settings',
    name: 'Settings',
    component: AsyncRoutes.settings,
    meta: {
      requiresAuthentication: true,
      feature: 'settings-menubar',
    },
    navItem: {
      icon: 'settings-icon',
      text: 'Settings',
      to: '/new-settings',
    },
  },
  {
    path: '/email',
    name: 'Email',
    component: AsyncRoutes.email,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/issue/:issueId',
    name: 'IssueMobile',
    component: AsyncRoutes.issueMobile,
    meta: {
      requiresNanoAuthentication: true,
    },
  },
  {
    path: '/line-issue/:issueId',
    name: 'LineIssueMobile',
    component: AsyncRoutes.lineIssueMobile,
    meta: {
      requiresNanoAuthentication: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: AsyncRoutes.login,
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: AsyncRoutes.forgot,
  },
  {
    path: '/reset/:token',
    name: 'Reset',
    component: AsyncRoutes.reset,
    props: true,
  },
  {
    path: '/exportdata',
    name: 'ExportData',
    component: AsyncRoutes.exportData,
    meta: {
      requiresAuthentication: true,
    },
  },
  {
    path: '/mfa',
    name: 'mfaverification',
    component: AsyncRoutes.mfa,
  },
  {
    path: '*',
    redirect: '/',
  },
];
