const hasFeature = (currentFeaturesFlags, featureName) =>
  !featureName || currentFeaturesFlags[featureName];

const validateRoutes = (routes, checkFeature) => {
  const landingPages = [];
  routes.forEach((route) => {
    if (route.meta?.landingPageName && checkFeature(route.meta?.feature)) {
      landingPages.push({
        path: route.path,
        text: route.name,
        value: route.meta?.landingPageName,
        feature: route.meta?.feature,
      });
    }
    if (route.children) {
      landingPages.push(...validateRoutes(route.children, checkFeature));
    }
  });

  return landingPages;
};

export const getLandingPages = (state, routes) => {
  const currentFeaturesFlags = state.getFeatureFlags;
  const checkFeature = hasFeature.bind(this, currentFeaturesFlags);
  return validateRoutes(routes, checkFeature);
};
