import * as locales from './i18n';

const vuetifyConfig = {
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#3890D3',
        cardBackGround: '#F2F4F5',
      },
    },
  },
  lang: {
    locales: { ...locales },
    current: 'en',
  },
};

export default vuetifyConfig;
