import { filter, find } from 'lodash';
import moment from 'moment';
import { RootState } from '@/store/state';
import { ProductionPeriod } from '../production/model';

export default {
  getScrapsByDeviceAndRange: (state) => (deviceId, from, to) => {
    const deviceScraps = filter(state.scraps, { deviceId });
    return filter(deviceScraps, (scrap) => {
      const { fromTs, toTs, deleted } = scrap;
      return (
        moment(Number(fromTs)).isSameOrAfter(from) &&
        moment(Number(toTs)).isSameOrBefore(to) &&
        !deleted
      );
    });
  },
  getScrap: (state) => (scrapId) => find(state.scraps, { id: Number(scrapId) }),
  getScrapsForProductionEntry:
    (state: RootState['scrap']) => (period: ProductionPeriod) => {
      const {
        device_id: deviceId,
        duration: {
          scheduled: { from: fromScheduled, to: toScheduled },
        },
      } = period;

      return state.scraps
        .filter(({ deleted }) => !deleted)
        .filter(
          ({ fromTs, toTs, deviceId: scrapDeviceId }) =>
            deviceId === scrapDeviceId &&
            // check that scrap event overlaps with production period
            fromTs <= toScheduled &&
            toTs >= fromScheduled
        );
    },
};
