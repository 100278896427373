import Vue from 'vue';

import { State } from './model';
import { ENABLE_URL_SHORTER, DISABLE_URL_SHORTER } from './mutations-types';

export default {
  [ENABLE_URL_SHORTER](state: State) {
    Vue.set(state, 'enabled', true);
  },
  [DISABLE_URL_SHORTER](state: State) {
    Vue.set(state, 'enabled', false);
  },
};
