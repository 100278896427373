import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';
import config from '@/config';
import {
  SET_STAFF,
  ADD_STAFF,
  EDIT_STAFF,
  DELETE_STAFF,
} from './mutation-types';

export default {
  async getStaff({ commit, state, rootState: { user, company } }) {
    if (state.staff.length) return Promise.resolve();
    const url = config.dataProxyUrls.v2.productionStaff.url({
      companyId: company,
    });
    return new Promise(async (res, rej) => {
      try {
        const resp = await axios.get(url, { ...getAxiosOpts(user.token) });
        commit(SET_STAFF, resp.data.response);
        res();
      } catch (err) {
        console.log('Something went wrong', err);
        rej();
      }
    });
  },
  async createStaff({ commit, rootState: { user, company } }, data) {
    const url = config.dataProxyUrls.v2.productionStaff.url({
      companyId: company,
    });
    const payload = { ...data, companyid: company };
    return new Promise((res, rej) => {
      axios
        .post(url, payload, getAxiosOpts(user.token))
        .then((response) => {
          commit(ADD_STAFF, response.data.response);
          res({ response: response.data, status: response.status });
        })
        .catch((err) => rej(err));
    });
  },
  async editStaff({ commit, rootState: { user, company } }, data) {
    const url = config.dataProxyUrls.v2.productionStaff.url({
      companyId: company,
    });
    const payload = { ...data, companyid: company };
    return new Promise((res, rej) => {
      axios
        .put(url, payload, getAxiosOpts(user.token))
        .then((response) => {
          if (response.status === 200) {
            commit(EDIT_STAFF, payload);
          }
          res({ response: response.data, status: response.status });
        })
        .catch((err) => rej(err));
    });
  },
  async deleteStaff({ commit, rootState: { user, company } }, data) {
    const url = config.dataProxyUrls.v2.productionStaff.url({
      companyId: company,
    });
    return new Promise((res, rej) => {
      axios
        .delete(`${url}&id=${data}`, {
          ...getAxiosOpts(user.token),
        })
        .then((response) => {
          if (response.status === 200) {
            commit(DELETE_STAFF, data);
          }
          res({ response: response.data, status: response.status });
        })
        .catch((err) => rej(err));
    });
  },
};
