import { Filter, FilterTypes } from '@/store/modules/filters';
import { applyFiltersToData } from '@/helpers/filters';
import { Alert } from './model';

export default {
  getAlerts: (state) => state.alerts,
  getDeviceAlert:
    ({ alerts }) =>
    (deviceId) =>
      alerts.filter(({ deviceid }) => deviceid === deviceId),
  getFilteredAlerts: (state, getters, rootState, rootGetters) => {
    let filteredData: Array<Alert> = state.alerts;

    const deviceFilter: Filter = rootGetters['filters/getFilters']('device');
    if (deviceFilter) {
      filteredData = applyFiltersToData<Alert>([deviceFilter], filteredData);
    }

    const localFilters: Array<Filter> = rootGetters['filters/getFiltersByType'](
      FilterTypes.LOCAL,
      'alert'
    );
    if (localFilters.length) {
      filteredData = applyFiltersToData<Alert>(localFilters, filteredData);
    }

    return filteredData;
  },
};
