import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';
import config from '@/config';
import { SET_SHIFTS } from './mutations-types';

export default {
  async loadShifts({ commit, state, rootState }) {
    if (Object.keys(state).length) {
      Promise.resolve();
      return Object.values(state);
    }
    const url = config.dataProxyUrls.v1.shifts.url({
      companyId: rootState.company,
    });
    return new Promise(async (res, rej) => {
      try {
        const { data } = await axios.get(url, {
          ...getAxiosOpts(rootState.user.token),
        });

        if (data.success) {
          commit(SET_SHIFTS, data.data);
          res(data.data);
        }
      } catch (e) {
        rej(e);
      }
    });
  },
};
