import Vue from 'vue';
import { isEmpty } from 'lodash';
import {
  availablePredefinedConditionsForDeviceIdAndRule,
  lineRuleConditionsText,
} from '@/components/alerts/helpers/alerts';
import { State } from './model';

const lineRuleWithMetadata = (
  allDevices,
  metricsGroupedByDevices,
  lineRule
) => {
  // Add metadata
  const {
    description,
    deviceIds = [],
    notifications = [],
    thresholds = {},
  } = lineRule;

  const devices = allDevices.filter((dev) => deviceIds.includes(dev.deviceid));
  const deviceNicknames = devices.map(({ nickname }) => nickname).join(', ');
  const ruleRecipients = !isEmpty(notifications)
    ? notifications.map(({ users = [] }) => users).flat()
    : [];
  const recipientsList =
    ruleRecipients.length > 0 ? ruleRecipients.join(', ') : 'None';
  const { alert = '--' } = thresholds;
  const thresholdDuration = `${alert} minutes`;
  const message = description;
  const availablePredefinedConditions =
    availablePredefinedConditionsForDeviceIdAndRule(
      null, // device id
      lineRule,
      true, // devices have anomaly
      null // store
    );
  const conditionsText = lineRuleConditionsText(
    lineRule,
    availablePredefinedConditions,
    allDevices,
    metricsGroupedByDevices,
    false
  );

  return {
    ...lineRule,
    conditionsText, // harmonize with device rules for table header conditions
    devices,
    deviceNicknames,
    message, // harmonize with device rules for table header message
    recipientsList,
    thresholdDuration,
  };
};

export default {
  SET_LINE_RULES: (state: State, payload) => {
    const {
      allDevices = [],
      metricsGroupedByDevices = {},
      lineRules = [],
    } = payload;

    const allLineRules = lineRules.map((mdr) =>
      lineRuleWithMetadata(allDevices, metricsGroupedByDevices, mdr)
    );

    Vue.set(state, 'lineRules', allLineRules);
  },
  SET_CREATE_LINE_RULE: (state: State, payload) => {
    const { allDevices = [], metricsGroupedByDevices = {}, lineRule } = payload;

    if (isEmpty(lineRule)) throw new Error('Line rule failed to create.');

    Vue.set(state, 'lineRules', [
      lineRuleWithMetadata(allDevices, metricsGroupedByDevices, lineRule),
      ...state.lineRules,
    ]);
  },
  SET_EDIT_LINE_RULE: (state: State, payload) => {
    const { allDevices = [], metricsGroupedByDevices = {}, lineRule } = payload;

    if (isEmpty(lineRule)) throw new Error('Line rule failed to update.');

    const index = state.lineRules.findIndex(
      (lRule) => lRule.id === lineRule.id
    );

    if (index >= 0) {
      Vue.set(
        state.lineRules,
        index,
        lineRuleWithMetadata(allDevices, metricsGroupedByDevices, lineRule)
      );
    }
  },
  SET_LINE_RULE_WITH_HISTORY: (state: State, payload) => {
    const { allDevices = [], metricsGroupedByDevices = {}, lineRule } = payload;

    if (isEmpty(lineRule)) throw new Error('Line rule failed to retrieve.');

    const index = state.lineRulesHistory.findIndex((lRule) => {
      const isMatchingId = lRule.id === lineRule.id;
      const isMatchingVersion = lRule.version === lineRule.version;

      return isMatchingId && isMatchingVersion;
    });

    const lRule = lineRuleWithMetadata(
      allDevices,
      metricsGroupedByDevices,
      lineRule
    );

    if (index >= 0) {
      Vue.set(state.lineRulesHistory, index, lRule);
    } else {
      Vue.set(state, 'lineRulesHistory', [lRule, ...state.lineRulesHistory]);
    }
  },
  SET_DELETE_LINE_RULE: (state: State, lineRule) => {
    if (isEmpty(lineRule)) throw new Error('Line rule failed to delete.');

    const index = state.lineRules.findIndex(
      (lRule) => lRule.id === lineRule.id
    );

    if (index >= 0) {
      state.lineRules.splice(index, 1);
    }
  },
};
