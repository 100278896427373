import moment from 'moment';

import { FormattedIssue as DeviceIssueDetails } from '@/store/modules/issues/model';
import { LineIssueDetail } from '@/store/modules/lineIssueDetails/model';

type IssueDetails = DeviceIssueDetails | LineIssueDetail;
export type RangeIssueDetails = Pick<IssueDetails, 'startedAt' | 'stoppedAt'>;

/**
 * Filter either Device or Line issues by time range, inclusive. Always includes
 * issues that started and/or stopped within the range. Include all ongoing
 * issues, regardless of when they started, unless `filterOngoing` is true.
 *
 * @param filterOngoing - Exclude ongoing issues that started outside of the
 *                        range. Ongoing issues that started within the range
 *                        (inclusive) are always included.
 */
export const filterByRange = <T extends RangeIssueDetails = RangeIssueDetails>(
  deviceIssues: T[],
  range: { from: number; to: number },
  filterOngoing: boolean = false
) => {
  return deviceIssues.filter((issue): issue is T => {
    const startedAt = Number(issue.startedAt);
    if (moment(startedAt).isBetween(range.from, range.to, undefined, '[]')) {
      return true;
    }

    if (issue.stoppedAt) {
      const stoppedAt = Number(issue.stoppedAt);
      if (
        moment(stoppedAt).isSameOrAfter(range.from) &&
        moment(stoppedAt).isSameOrBefore(range.to)
      ) {
        return true;
      }
      return false;
    } else if (filterOngoing) {
      return false;
    }

    return true;
  });
};
