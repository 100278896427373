import Vue from 'vue';
import { SHOW, HIDE } from './mutations-types';
import { initialState, State } from './model';

export default {
  [SHOW](state: State, payload) {
    Vue.set(state, 'show', true);
    Vue.set(state, 'component', payload.component);
    Vue.set(state, 'props', payload.props);
  },
  [HIDE](state: State) {
    Vue.set(state, 'show', false);
    Vue.set(state, 'component', null);
  },
};
