import Vue from 'vue';

export default {
  SET_NOTIFICATIONS: (state, notificationsResponse) => {
    const { notifications, totalCount } = notificationsResponse;
    const notificationsArray = [];
    if (notifications) {
      notifications.forEach((notification) => {
        notificationsArray.push(notification);
      });
    }
    Vue.set(state, 'totalFilteredNotifications', totalCount);
    Vue.set(state.notifications, 'notifications', notificationsArray);
  },
  SET_NEW_NOTIFICATIONS: (state, notificationsResponse) => {
    const notificationsArray = [];
    if (notificationsResponse) {
      notificationsResponse.forEach((notification) => {
        notificationsArray.push(notification);
      });
    }
    Vue.set(state, 'notifications', notificationsArray);
  },
  CLEAR_NOTIFICATIONS: (state) => {
    Vue.set(state, 'notifications', []);
  },
};
