import 'core-js/stable';
import Vue from 'vue';
import vuetifyPlugin from '@/plugins/vuetify';

import axios from 'axios';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import VueIntercom from 'vue-intercom';
import VueTheMask from 'vue-the-mask';
import Highcharts from 'highcharts';
import VueHighcharts from 'highcharts-vue';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import Annotations from 'highcharts/modules/annotations';
import drilldown from 'highcharts/modules/drilldown';
import Stock from 'highcharts/modules/stock';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import * as Sentry from '@sentry/vue';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import { get } from 'lodash';

import App from './App.vue';
import store from './store';
import router from './router';
import { axiosDefaultsBaseURL } from './config/urls';
import config from './config';
import vuetifyDefaultConfig from './config/vuetify';
import { initSentry } from './helpers/sentry';

import { safiTooltip, googleAnalytics } from './directives';

import './qa';

const isDev = !(config.production || config.staging || config.testenv);

if (isDev) {
  axios.defaults.baseURL = axiosDefaultsBaseURL;
}

// Prevent Sentry initialization during test runs.
// Note: `mode` is distinct from `environment`.
// See `@/config/index` for more details on these distinctions.
if (config.mode !== 'test') {
  const { sentry } = config ?? {};
  initSentry({ environment: config.environment, ...sentry });
}

function logout(reason = 'Log out user', ctx = {}) {
  Sentry.withScope((scope) => {
    scope.setContext('logout-context', ctx);
    Sentry.captureMessage(reason, 'debug');
  });
  store.dispatch('LOGOUT');
  router.push('/login');
  window.location.reload();
}

const getError =
  /**
   * The idea is to return something closest to Error object.
   * This function is made to avoid the Sentry "Non-Error exception log"
   * @param e
   */
  (e: any): Error => {
    if (e instanceof Error) {
      return e;
    }

    if (e.message) {
      return new Error(e.message);
    }

    return new Error(JSON.stringify(e));
  };

// proxy any sessionToken("st") in query string to API requests
// this nanoid-base token (tokens table) is different than a jwt
axios.interceptors.request.use(
  (axiosConfig) => {
    const { currentRoute } = router;
    if (
      currentRoute &&
      currentRoute.query &&
      currentRoute.query.st !== undefined
    ) {
      // NOTE: this doesn't seem to work as it thinks params is undefined
      // axiosConfig.params.sessionToken = currentRoute.query.sessionToken;
      axiosConfig.params = {
        sessionToken: currentRoute.query.st,
        ...axiosConfig.params,
      };
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

// add settings=true for any API requests coming from the settings page
// used by dataProxy to avoid devices list filtering of devices, groups, shifts, etc
// settings page is meant to show all possible values
axios.interceptors.request.use(
  (axiosConfig) => {
    const { currentRoute } = router;

    if (
      currentRoute &&
      currentRoute.name === 'Settings' &&
      (axiosConfig.params === undefined ||
        axiosConfig.params.settings === undefined)
    ) {
      axiosConfig.params = {
        settings: true,
        ...axiosConfig.params,
      };
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Change the upstream server if the request is coming from the email path
axios.interceptors.request.use(
  (axiosConfig) => {
    const { currentRoute } = router;

    if (
      ((currentRoute && currentRoute.name === 'Email') ||
        axiosConfig.headers['use-x-upstream-server']) &&
      !config.testenv
    ) {
      axiosConfig.headers['x-upstream-server'] = config.emailUpstreamServer;
      delete axiosConfig.headers['use-x-upstream-server'];
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => {
  const currentRoute = get(router, 'currentRoute', {});
  const name = get(currentRoute, 'name', '');
  const liveMode = Number(get(currentRoute, 'query.liveMode', 0));
  const isFullScreen = !!get(localStorage, 'isFullScreen', false);
  const isFullScreenLiveModeScoreboard =
    isFullScreen && name === 'Scoreboard' && liveMode === 1;
  const commitSha = get(response, 'data.commitSha', null);

  if (
    isFullScreenLiveModeScoreboard &&
    commitSha &&
    commitSha !== config.commitSha
  ) {
    window.location.reload();
    delete response.data.commitSha;
  }
  return response;
});

axios.interceptors.response.use(
  (response) => {
    if (response.data && response.data.version) {
      if (
        localStorage &&
        localStorage.version &&
        localStorage.version !== response.data.version
      ) {
        localStorage.version = response.data.version;
        window.location.reload();
      } else if (!localStorage.version || localStorage.version.length === 0) {
        localStorage.version = response.data.version;
      }
      delete response.data.version;
    }
    if (response.data && response.data.error && response.data.error === 401) {
      logout('Unauthorized access', response.data);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      logout('Unauthorized access', error.response);
    } else {
      // this is temporary, lets catch first 401 error (the most
      // frequent "request failed" reported in Sentry) and not
      // report it. Also let's analyse if there are other
      // errors reported by axios and why.
      const finalError = getError(error);
      // let's ignore when the error is "canceled" (we left this 'switch' structure
      // ready to include more errors to be ignored by sentry)
      switch (finalError.message) {
        case 'cancelled':
          break;
        default:
          Sentry.captureException(finalError);
      }
    }
    throw error;
  }
);

axios.interceptors.request.use(
  (axiosConfig) => {
    const token = window.localStorage.getItem('safigen-token');
    if (token) {
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

declare global {
  interface Window {
    moment: any;
    sentry: any;
  }
}

moment.defaultFormat = 'YYYY/MM/DD HH:mm';
window.moment = moment;
momentTimeZone();

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleApiKey,
    libraries: 'places',
  },
  installComponents: true,
});

Vue.config.productionTip = false;

if (config.intercomEnabled) {
  Vue.use(VueIntercom, config.intercom.INTERCOM_ID);
}

// Register directive mask globally
Vue.use(VueTheMask);

// Register Highcharts globally
Vue.use(VueHighcharts);
drilldown(Highcharts);
Stock(Highcharts);
NoDataToDisplay(Highcharts);
Annotations(Highcharts);

Vue.directive('safi-tooltip', safiTooltip);
Vue.directive('ga', googleAnalytics);
// Vuetify user specifig configuration

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

const vuetifyConfig = { ...vuetifyDefaultConfig };
// Some initial vuetify-based i18n configuration exists. The intent appears to
// be to manage locale dynamically and maintain state in the Vuex store, but
// this feature is not complete.
vuetifyConfig.lang.current = store.state.locale || 'en';

new Vue({
  router,
  store,
  vuetify: vuetifyPlugin(vuetifyConfig),
  render: (h) => h(App),
}).$mount('#app');
