'use strict';

import { getPropsValues, bindProps } from '../utils/bindProps.js';
import simulateArrowDown from '../utils/simulateArrowDown.js';

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

const props = {
  bounds: {
    type: Object,
  },
  defaultPlace: {
    type: String,
    default: '',
  },
  componentRestrictions: {
    type: Object,
    default: null,
  },
  types: {
    type: Array,
    default: function _default() {
      return [];
    },
  },
  placeholder: {
    required: false,
    type: String,
  },
  className: {
    required: false,
    type: String,
  },
  label: {
    required: false,
    type: String,
    default: null,
  },
  selectFirstOnEnter: {
    require: false,
    type: Boolean,
    default: false,
  },
};

const PlaceInputImpl = {
  mounted: function mounted() {
    const _this = this;
    const input = this.$refs.input;

    // Allow default place to be set
    input.value = this.defaultPlace;
    this.$watch('defaultPlace', function () {
      input.value = _this.defaultPlace;
    });

    this.$gmapApiPromiseLazy().then(function () {
      const options = getPropsValues(_this, props);
      if (_this.selectFirstOnEnter) {
        simulateArrowDown(_this.$refs.input);
      }

      if (typeof google.maps.places.Autocomplete !== 'function') {
        throw new Error(
          "google.maps.places.Autocomplete is undefined. Did you add 'places' to libraries when loading Google Maps?"
        );
      }

      _this.autoCompleter = new google.maps.places.Autocomplete(
        _this.$refs.input,
        options
      );

      const {
        placeholder,
        place,
        defaultPlace,
        className,
        label,
        selectFirstOnEnter,
        ...rest
      } = props;

      bindProps(_this, _this.autoCompleter, rest);

      _this.autoCompleter.addListener('place_changed', function () {
        _this.$emit('place_changed', _this.autoCompleter.getPlace());
      });
    });
  },
  created: function created() {
    console.warn(
      'The PlaceInput class is deprecated! Please consider using the Autocomplete input instead'
    ); // eslint-disable-line no-console
  },

  props: props,
};

export default PlaceInputImpl;
