import axios from 'axios';
import { ActionContext } from 'vuex';
import * as Sentry from '@sentry/vue';

import { RootState } from '@/store/state';
import config from '@/config';
import {
  LOAD_VIEWS,
  DELETE_VIEWS,
  ADD_VIEW,
  UPDATE_VIEW,
  LOADING_VIEW,
} from './mutations-types';
import { initialState, State, View, GetViewsPayload } from './model';

export default {
  async GET_VIEWS(
    { rootState, commit }: ActionContext<State, RootState>,
    payload: GetViewsPayload = { setLoading: false }
  ) {
    if (payload.setLoading) {
      commit(LOADING_VIEW, true);
    }

    const url = config.dataProxyUrls.v2.dashboardViews.url();
    try {
      const { status, data } = await axios.get(url);

      if (status !== 200)
        throw new Error(`[dashboardViews->getViews]: ${status}`);

      if (data) {
        const { response = [] } = data;
        commit(LOAD_VIEWS, response);

        if (payload.setLoading) {
          commit(LOADING_VIEW, false);
        }

        return response;
      }

      commit(LOAD_VIEWS, []);
      if (payload.setLoading) {
        commit(LOADING_VIEW, false);
      }
      return [];
    } catch (e) {
      if (payload.setLoading) {
        commit(LOADING_VIEW, false);
      }
      if (e instanceof Error) {
        console.error(e.message);
      } else {
        console.error(e);
      }
      return [];
    }
  },
  async CREATE_VIEW(context: ActionContext<State, RootState>, payload: View) {
    const url = config.dataProxyUrls.v2.dashboardViews.url();

    try {
      const { status, data } = await axios.post(url, payload);

      if (status !== 200 || !data || data.error) {
        throw new Error(`Error ${status} creating view`);
      }

      // TODO: Resolve devices or deviceslists before storing views
      // other option is to always store the id of the resources linked (not use graphql)
      context.commit(ADD_VIEW, data.response);

      context.dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'View created successfully.',
          alertType: 'success',
        },
        { root: true }
      );

      return data.response;
    } catch (e) {
      console.error(e);
      context.dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Sorry an error occurred. Please try again.',
          alertType: 'error',
        },
        { root: true }
      );
      throw e;
    }
  },
  async UPDATE_VIEW(context: ActionContext<State, RootState>, payload: View) {
    const url = config.dataProxyUrls.v2.dashboardViews.url();
    const { id, companyId, ...rest } = payload;

    try {
      const { status, data } = await axios.patch(`${url}/${id}`, rest);

      if (status !== 200 || !data || data.error) {
        // TODO: This error handling should be on the API side in future
        // And this error handling flow should be revisited
        const newError = new Error(
          `Error ${status} updating view: ${data.message}`
        );
        Sentry.withScope((scope) => {
          scope.setContext('view-context', { status, data, payload });
          Sentry.captureException(newError);
        });

        throw newError;
      }

      // TODO: Resolve devices or deviceslists before storing views
      // other option is to always store the id of the resources linked (not use graphql)
      context.commit(UPDATE_VIEW, data.response);

      context.dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'View updated successfully.',
          alertType: 'success',
        },
        { root: true }
      );

      return data.response;
    } catch (e) {
      // TODO: This error reporting should be on the API side in future
      console.error(e);
      Sentry.captureException(new Error('Error updating view', { cause: e }));

      // UI-level error handling is fine to keep here
      context.dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Sorry an error occurred. Please try again.',
          alertType: 'error',
        },
        { root: true }
      );
      throw e;
    }
  },
  async DELETE_VIEW(
    context: ActionContext<State, RootState>,
    payload: View['id'][]
  ) {
    const url = config.dataProxyUrls.v2.dashboardViews.url();

    try {
      const { status, data } = await axios.delete(url, {
        data: { ids: payload },
      });

      if (status !== 200) {
        throw new Error(`Error ${status} deleting view ${payload}`);
      }

      context.commit(DELETE_VIEWS, payload);

      return true;
    } catch (e) {
      context.dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Sorry an error occurred. Please try again.',
          alertType: 'error',
        },
        { root: true }
      );
      return false;
    }
  },
};
