import axios from 'axios';
import { get, orderBy } from 'lodash';
import { Action } from 'vuex';

import getAxiosOpts from '@/helpers/axios.js';
import { RootState } from '@/store/state';

import { State } from './model';
import { SET_ANOMALY_METRICS } from './mutations-types';

const getAnomalyMetrics: Action<State, RootState> = async (
  { commit, rootState },
  data
) => {
  data.device = encodeURIComponent(data.device);
  data.from = encodeURIComponent(data.from);
  data.to = encodeURIComponent(data.to);

  const response = await axios.get('/api/anomaly-metrics', {
    ...getAxiosOpts(rootState.user.token),
    params: {
      ...data,
    },
  });

  if (response.status === 401) {
    // Clear any cached data...
    localStorage.clear();

    commit('LOGOUT');
    return {
      data: null,
    };
  }

  const responseData = get(response, 'data.response') || [];
  const orderedData = orderBy(responseData, ['from_ts'], ['asc']);

  commit(SET_ANOMALY_METRICS, orderedData);
  return {
    data: orderedData,
  };
};

export default {
  getAnomalyMetrics,
};
