import { Shift, State } from './';

export default {
  getShifts: (state: State) => (shiftId: string) => {
    if (shiftId) {
      return [state[shiftId]];
    }
    return Object.values(state);
  },
};
