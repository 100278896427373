import axios from 'axios';
import config from '@/config';
import getAxiosOpts from '@/helpers/axios.js';
import { EmailReport } from '.';
import {
  CREATE_REPORT,
  DELETE_REPORT,
  SET_LOADED_WIDGET,
  SET_PERFORMANCE_WIDGET_DATA,
  SET_REPORTS,
  UPDATE_REPORT,
} from './mutations-types';

const url = config.dataProxyUrls.v2.emailReports.url();

export default {
  async getReports({ commit, rootState: { user, company } }, id) {
    return new Promise((res, rej) => {
      axios
        .get(url, {
          ...getAxiosOpts(user.token),
          params: {
            companyId: company,
            id,
          },
        })
        .then(({ status, data }) => {
          if (status === 200) {
            const { response } = data;
            commit(SET_REPORTS, response);
            res(response);
          }
        })
        .catch((e) => {
          rej(e);
        });
    });
  },
  async createReport(
    { commit, rootState: { user, company }, dispatch },
    payload
  ) {
    return new Promise((res, rej) => {
      axios
        .post(
          url,
          {
            companyId: company,
            report: payload,
          },
          getAxiosOpts(user.token)
        )
        .then(({ status, data }) => {
          if (status === 200) {
            dispatch(
              'SHOW_SNACKBAR',
              {
                alertMessage: 'Report created successfully',
              },
              { root: true }
            );
            commit(CREATE_REPORT, data.response[0]);
            res(data.response);
          }
        })
        .catch((e) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Sorry an error occurred. Please try again.',
              alert: 'error',
            },
            { root: true }
          );
          rej(e);
        });
    });
  },
  async updateReport(
    { commit, rootState: { user, company }, dispatch },
    payload
  ) {
    return new Promise((res, rej) => {
      axios
        .put(
          url,
          {
            companyId: company,
            report: payload,
          },
          getAxiosOpts(user.token)
        )
        .then(({ status, data }) => {
          if (status === 200) {
            dispatch(
              'SHOW_SNACKBAR',
              {
                alertMessage: 'Report updated successfully',
              },
              { root: true }
            );
            commit(UPDATE_REPORT, data.response[0]);
            res(data.response);
          }
        })
        .catch((e) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Sorry an error occurred. Please try again.',
              alert: 'error',
            },
            { root: true }
          );
          rej(e);
        });
    });
  },
  async deleteReport(
    { commit, rootState: { user, company }, dispatch },
    payload
  ) {
    const deleteUrl = config.dataProxyUrls.v2.emailReports.url({
      companyId: company,
      id: payload,
    });
    const options = getAxiosOpts(user.token);
    return new Promise((res, rej) => {
      axios
        .delete(deleteUrl, options)
        .then(({ status, data }) => {
          if (status === 200) {
            dispatch(
              'SHOW_SNACKBAR',
              {
                alertMessage: 'Report deleted successfully',
              },
              { root: true }
            );
            commit(DELETE_REPORT, payload);
            res(data.response);
          } else {
            throw new Error(data);
          }
        })
        .catch((e) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Sorry an error occurred. Please try again.',
              alert: 'error',
            },
            { root: true }
          );
          rej(e);
        });
    });
  },
  async resendReport(
    { commit, rootState: { user, company }, dispatch },
    payload
  ) {
    const resendUrl = config.dataProxyUrls.v2.emailReports.resend({
      companyId: company,
      id: payload,
    });
    const options = getAxiosOpts(user.token);
    return new Promise((res, rej) => {
      axios
        .post(resendUrl, options)
        .then(({ status, data }) => {
          if (status === 200) {
            res(data.response);
          } else {
            throw new Error(data);
          }
        })
        .catch((e) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Sorry an error occurred. Please try again.',
              alert: 'error',
            },
            { root: true }
          );
          rej(e);
        });
    });
  },
  async getPerformanceWidgetData(
    { commit, rootState, rootGetters },
    {
      fromDate,
      toDate,
      report,
    }: { fromDate: number; toDate: number; report: EmailReport }
  ) {
    try {
      const { companyid } = rootGetters.getCompanyData;
      const opts = getAxiosOpts(rootState.user.token);

      const result = await axios.post(
        `/api/v2/device/email-report-cycles-widget-data`,
        {
          fromDate,
          toDate,
          report,
          companyId: companyid,
        },
        {
          ...opts,
          timeout: 30 * 1000,
          headers: {
            ...(opts?.headers || {}),
            'use-x-upstream-server': 'true',
          },
        }
      );

      if (result?.status !== 200 || !result.data?.response) {
        throw new Error('Invalid response');
      }

      commit(SET_PERFORMANCE_WIDGET_DATA, result.data.response);
      commit(SET_LOADED_WIDGET, 'cycles');

      return result.data.response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  async clearPerformanceWidgetData({ commit }) {
    commit(SET_PERFORMANCE_WIDGET_DATA, null);
  },
  widgetLoaded({ commit }, payload) {
    commit(SET_LOADED_WIDGET, payload);
  },
};
