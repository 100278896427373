// Import the required dependency with ES module syntax
import mapElementFactory from './mapElementFactory';

// Define props and events
const props = {
  center: {
    type: Object,
    twoWay: true,
    required: true,
  },
  radius: {
    type: Number,
    twoWay: true,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    twoWay: false,
  },
};

const events = [
  'click',
  'dblclick',
  'drag',
  'dragend',
  'dragstart',
  'mousedown',
  'mousemove',
  'mouseout',
  'mouseover',
  'mouseup',
  'rightclick',
];

// Export the default object using mapElementFactory
export default mapElementFactory({
  mappedProps: props,
  name: 'circle',
  ctr() {
    return google.maps.Circle;
  },
  events: events,
});
