import axios from 'axios';
import config from '@/config';

export default {
  async getScraps({ commit, rootState: { company } }, payload) {
    const { deviceId, from, to } = payload;
    try {
      const {
        status,
        statusText,
        data: { response },
      } = await axios.get(
        config.dataProxyUrls.v2.scrap.url({ companyId: company }),
        { params: { deviceId, from, to } }
      );

      if (status === 200 && response) {
        commit('SET_SCRAPS', response);
        return response;
      }
      throw new Error(statusText);
    } catch (e) {
      throw e;
    }
  },

  async saveScrap({ commit, rootState: { company } }, payload) {
    try {
      const method = payload.id ? 'put' : 'post';
      const url = config.dataProxyUrls.v2.scrap.url({ companyId: company });
      const {
        status,
        statusText,
        data: { response },
      } = await axios[method](url, payload);

      if (status === 200 && response) {
        commit('SET_SCRAP', response);
        return response;
      }
      throw new Error(statusText);
    } catch (e) {
      throw e;
    }
  },
};
