import axios from 'axios';

import getAxiosOpts from '@/helpers/axios.js';

export default {
  GET_METRICS_GROUPED_BY_DEVICES: ({ commit, state }) =>
    new Promise((res, rej) => {
      axios
        .get('api/metrics/by_devices', getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_METRICS_GROUPED_BY_DEVICES', response.data.response);
          res(response.data.response);
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_AVAILABLE_METRICS: ({ commit, state }) =>
    new Promise((res, rej) => {
      axios
        .get('/api/metrics', getAxiosOpts(state.user.token))
        .then((response) => {
          const metrics = {};
          response.data.response.forEach((metric) => {
            metrics[metric.metric.toLowerCase()] = metric;
          });
          commit('SET_AVAILABLE_METRICS', metrics);
          res(response.data.response);
        })
        .catch((err) => {
          rej(err);
        });
    }),
  async getDeviceAvailableMetrics({ commit, state }, { deviceId, from, to }) {
    try {
      const { status, statusText, data } = await axios.get(
        `/api/device-metrics/${deviceId}`,
        {
          params: { from, to },
          ...getAxiosOpts(state.user.token),
        }
      );

      if (status === 200) {
        commit('SET_DEVICE_AVAILABLE_METRICS', { data, deviceId });
        return data;
      }
      throw new Error(statusText);
    } catch (e) {
      throw e;
    }
  },
};
