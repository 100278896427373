<template>
  <v-app>
    <v-snackbar
      v-if="loggedIn || alert.forceShow"
      v-model="alertVisible"
      :timeout="alert.actions.length || alert.forceKeep ? -1 : 10000"
      :top="true"
      :color="alert.alertType"
      :class="alert.class || ''"
    >
      <v-icon v-if="icons[alertType]">{{ icons[alertType] }}</v-icon>
      <!-- FIXME: `alert.alertMessage` is set in over 100 places in the app at
           time of writing. It appears that it is set as a simple string
           (without HTML markup) but needs vetting before this can be fixed.
      -->
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="alert.alertMessage" />
      <v-btn
        v-for="({ action, caption }, idx) in alert.actions"
        :key="`action-${idx}`"
        text
        @click="action"
      >
        {{ caption }}
      </v-btn>
    </v-snackbar>
    <navigation-layout
      v-if="showNavBar() && loggedIn && !routeWithoutNabvar"
      :routes="routes"
      defaultIssueType="Manual"
      class="nav-layout"
    >
      <router-view v-if="companyDataReady" />
    </navigation-layout>
    <router-view v-else />
    <loader
      v-if="loggedIn && !companyDataReady && !routeWithoutNabvar"
      message="Loading..."
      :height="'100vh'"
      :style="{ zIndex: 2 }"
    />
    <analytics v-if="analyticsEnabled" />
    <intercom v-if="intercomEnabled && !routeWithoutNabvar" />
  </v-app>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapGetters } from 'vuex';

import routes, { preloadPages } from '@/config/routes';
import config from '@/config';
import NavigationLayout from '@/components/NavigationLayout.vue';
import createLogger from '@/utils/logging';
import Loader from '@/components/loader/Loader';

const logger = createLogger('App Component');

const Analytics = () => import('@/components/Analytics');
const Intercom = () => import('@/components/Intercom');

export default {
  name: 'app',
  components: {
    NavigationLayout,
    Analytics,
    Intercom,
    Loader,
  },
  data() {
    return {
      routes,
      companyDataReady: false,
      icons: {
        success: 'check_circle',
        error: 'warning',
      },
    };
  },
  computed: {
    ...mapGetters({
      alert: 'alert',
      userLevel: 'userLevel',
    }),
    routeWithoutNabvar() {
      return (
        this.$route.path === '/email' || this.$route.name === 'IssueMobile'
      );
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    isLightTheme() {
      return this.$store.state.user.isLightTheme;
    },
    intercomEnabled() {
      // Be sure that all this is true before boot intercom
      return config.intercomEnabled && this.loggedIn && this.companyDataReady;
    },
    analyticsEnabled() {
      return (
        config.analyticsEnabled &&
        (this.loggedIn || this.$route.name === 'IssueMobile')
      );
    },
    alertVisible: {
      get() {
        return this.alert.alertVisible;
      },
      set() {
        this.closeSnackbar();
      },
    },
    alertType: {
      get() {
        return this.alert.alertType;
      },
    },
  },
  watch: {
    // User's company info when logged in
    async loggedIn(loggedIn) {
      if (loggedIn) {
        const { username, company } = this.$store.getters;
        try {
          await this.$store.dispatch(
            'GET_USER_COMPANY',
            this.$store.state.user.loggedInUserInfo.companyid
          );

          await this.$store.dispatch('GET_SETTINGS');

          // Line alerts need access to all devices first
          await this.$store.dispatch('GET_ALL_DEVICES', { settings: true });

          await Promise.allSettled([
            this.$store.dispatch('GET_ALL_DEVICE_GROUPS', { settings: true }),
            this.$store.dispatch('lineRules/findAll'),
            this.$store.dispatch('units/getUnits'), // TODO: review this request
            this.$store.dispatch('GET_METER_TYPES'),
            this.$store.dispatch('GET_DEVICE_TYPES'),
            this.$store.dispatch('GET_METRICS_GROUPED_BY_DEVICES'),
            this.$store.dispatch('GET_AVAILABLE_METRICS'),
            this.$store.dispatch('devicesLists/getDevicesLists', {}),
            this.$store.dispatch('productionPeriod/LOAD_SHIFTS', {}),
            this.$store.dispatch('GET_COMPANY_USERS'),
            this.$store.dispatch('tags/getTags'),
            this.$store.dispatch('GET_ALERTS'),
            this.$store.dispatch('productionPeriod/LOAD_SKUS', {
              includeAllItems: true,
              ignoreCache: false,
            }),
            this.$store.dispatch('production/getInProgressPeriods'),
          ]);

          this.companyDataReady = true;
          Sentry.getCurrentScope().setUser({ username });
        } catch (e) {
          Sentry.getCurrentScope().setUser();
          logger.error({
            user: username,
            company,
            message: `GET_USER_COMPANY: ${e.message}`,
          });
        }
      } else {
        this.companyDataReady = false;
        this.$router.replace('/login');
        Sentry.getCurrentScope().setUser();
      }
    },
  },
  mounted() {
    this.$store.state.config = config;
    preloadPages();
  },
  methods: {
    showNavBar() {
      return this.$route.path !== '/login';
    },
    closeSnackbar() {
      this.$store.dispatch('CLOSE_SNACKBAR');
    },
  },
};
</script>
