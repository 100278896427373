export interface LoadState {
  state: string;
  color: string;
  start: number;
  end: number;
}

export interface State {
  [key: string]: {
    [key: string]: {
      loading: boolean;
      data: LoadState;
      lastUpdate?: string;
    };
  };
}

export interface DeviceStops {
  lateness: number;
  machineStart: number;
  plannedDowntimeBeforeStart: number;
  machineEnd: number;
  finishedWhenExpected: boolean;
  plannedDowntimeBeforeEnd: number;
  stops: number;
  totalStopTime: number; // in milliseconds
  state: string;
}

export const initialState: State = {};

export default initialState;
