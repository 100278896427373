import Vue from 'vue';
import { findIndex } from 'lodash';
import {
  SET_MAINTENANCE_DATA,
  UPDATE_MAINTENANCE_DATA,
} from './mutations-types';
import { State } from './model';

export default {
  [SET_MAINTENANCE_DATA](state: State, payload) {
    Vue.set(state, 'data', payload);
  },
  [UPDATE_MAINTENANCE_DATA](state: State, payload) {
    const maintenanceCopy = [...state.data];
    payload.forEach((data) => {
      const { id: newMaintenanceId } = data;
      const idx = findIndex(maintenanceCopy, { id: newMaintenanceId });
      maintenanceCopy[idx] = data;
    });
    Vue.set(state, 'data', maintenanceCopy);
  },
};
