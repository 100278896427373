import axios from 'axios';
import config from '@/config';
import {
  SET_MAINTENANCE_DATA,
  UPDATE_MAINTENANCE_DATA,
} from './mutations-types';

export default {
  async getMaintenanceData({ commit, rootState: { company } }) {
    try {
      const {
        status,
        statusText,
        data: { response },
      } = await axios.get(
        config.dataProxyUrls.v2.maintenance.url({ companyId: company })
      );
      if (status === 200) {
        commit(SET_MAINTENANCE_DATA, response);
      } else {
        throw new Error(statusText);
      }
    } catch {
      // no-op
    }
  },

  async resetMaintenanceSession({ commit }, payload) {
    try {
      const {
        status,
        statusText,
        data: { response },
      } = await axios.patch(
        config.dataProxyUrls.v2.maintenance.resetUrl(),
        payload
      );
      if (status === 200) {
        commit(UPDATE_MAINTENANCE_DATA, response);
      } else {
        throw new Error(statusText);
      }
    } catch {
      // no-op
    }
  },
};
