import { CycleCountSetting } from '@/store/state';

export interface ResponseSettings {
  settings: CycleCountSetting;
  deviceSettings: CycleCountSetting;
  skuSettings: CycleCountSetting;
  companySettings: {
    nonRunningTime: boolean;
  };
}

export interface State {
  data: ResponseSettings;
  previewSettings: CycleCountSetting;
  loading: Boolean;
  query: { deviceId: String; skuId: Number } | {};
}

const defaultSettings = {
  height: [null, null],
  distance: null,
  width: [null, null],
  threshold: [null, null],
  prominence: [null, null],
  standardCycleTime: null,
  smoothingEnabled: false,
  unitsPerCycle: 1,
};

const defaultState = {
  data: {
    settings: defaultSettings,
    deviceSettings: defaultSettings,
    skuSettings: defaultSettings,
    companySettings: {
      nonRunningTime: false,
    },
  },
  previewSettings: defaultSettings,
  loading: false,
  query: {},
};

export const initialState: State = defaultState;

export default initialState;
