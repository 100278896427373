import devices from './devices';
import deviceGroups from './deviceGroups';
import user from './user';
import alerts from './alerts';
import energy from './energy';
import notification from './notifications';
import uptime from './uptime';
import companies from './companies';
import roles from './roles';
import metrics from './metrics';
import dm from './dm';
import views from './views';
import productionPeriods from './productionPeriods';
import previousBest from './previousBest';

export default {
  ...devices,
  ...deviceGroups,
  ...user,
  ...alerts,
  ...energy,
  ...notification,
  ...uptime,
  ...companies,
  ...roles,
  ...metrics,
  ...dm,
  ...views,
  ...productionPeriods,
  ...previousBest,
};
