import { State, Issue } from '@/store/modules/issues/model';
import { Filter, FilterTypes } from '@/store/modules/filters';
import { applyFiltersToData } from '@/helpers/filters';
import { filterByRange } from '@/helpers/filter-issues';

export const formatIssues = (issues, rootGetters) =>
  issues.map((i) => {
    i.alertScope = i.alertScope || 'deviceAlert';
    i.deviceId = i.deviceId || i.deviceid;
    i.issueType = i.issueType || i.issuetype;
    i.startedAt = Number(i.startedAt || i.started_at);
    i.stoppedAt =
      i.stoppedAt || i.stopped_at ? Number(i.stoppedAt || i.stopped_at) : null;
    return {
      ...i,
      deviceNicknames: [
        rootGetters.getDeviceById(i.deviceId || i.deviceid).nickname,
      ].join(', '),
    };
  });

export default {
  getIssueById:
    (state, getters, rootState, rootGetters) => (issueId: string) => {
      const issue = state.issues.find((i) => i.id === issueId);
      let lineIssue;
      if (rootGetters.isFeatureEnabled('line-alerts')) {
        const lineIssueDetails =
          rootState.lineIssueDetails?.lineIssueDetails || [];
        lineIssue = lineIssueDetails.find((i) => i.id === issueId);
      }
      return issue ?? lineIssue;
    },
  /**
   * The intent is that `getIssues` should return formatted issues. formatIssues
   * should be additive and not mutate existing field values, but to prevent
   * regressions, this getter is explicit.
   *
   * TODO: It should also be investigated whether vuex is re-computing on each
   * call because of running this through formatIssues (i.e. it returns a
   * different reference/new array each time).
   */
  getFormattedIssues: (state, getters, rootState, rootGetters) =>
    formatIssues(state.issues, rootGetters),
  getIssues: (state) => state.issues,
  getIssuesByDevice:
    (state: { issuesByDevice: State }, getters, rootState, rootGetters) =>
    (deviceId: string) =>
      formatIssues(state.issuesByDevice[deviceId] || [], rootGetters),
  getIssuesByDeviceAndRange:
    (state: { issuesByDevice: State }, getters, rootState, rootGetters) =>
    (deviceId: string, range: { from: number; to: number }) => {
      const deviceIssues = state.issuesByDevice[deviceId] || [];
      if (!range.from || !range.to) return [];
      return filterByRange(deviceIssues, range);
    },
  getSelectedIssueType: (state) => state.selectedIssueType,
  getFilteredIssues: (state, getters, rootState, rootGetters) => {
    let filteredData: Array<Issue> = state.issues;

    const localFilters: Array<Filter> = rootGetters['filters/getFiltersByType'](
      FilterTypes.LOCAL,
      'issue'
    );
    if (localFilters.length) {
      filteredData = applyFiltersToData<Issue>(localFilters, filteredData);
    }

    return formatIssues(filteredData, rootGetters);
  },
  getIssuesTypes: (state) => state.issuesTypes,
  getIssuesStatus: (state) => state.status,
  getMessageSnippets: (state) => state.messageSnippets.filter((msg) => msg),
  getIssuesWithRules: (state) => state.issuesWithRules,
  getAddingIssueGraph: (state) => state.addingIssueGraph,
  getAddingIssueNavigation: (state) => state.addingIssueNavigation,
  getTaggedOptions: () => [
    { id: 'HumanTagged', text: 'User' },
    { id: 'AlertTagged', text: 'System' },
  ],
  getLoadingState: (state) => state.loading,
  getUpdateDeviceState: (state) => (deviceId) =>
    state.updateDeviceState[deviceId],
};
