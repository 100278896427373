export interface State {
  anomalyData: AnomalyData[];
}

export type AnomalyData = {
  company_id: string;
  device_id: string;
  from_ts: string;
  to_ts: string;
  anomaly_status: -1 | 0 | 1;
  metrics_ml: MetricsMl;
  created_at: string;
  updated_at: null;
};

type MetricsMl = {
  parameters: Parameters;
  predictionResults: PredictionResults[] | null;
  predictionSummary: PredictionSummary;
};

type Parameters = {
  to: number;
  from: number;
  deviceId: string;
  companyId: string;
  deviceType: string;
  dataProvided: boolean;
  secondsOfDataProvided: number;
  savePredictionInBigQuery: boolean;
  analysisTimebaseInSeconds: number;
};

type PredictionSummary = {
  message: string;
  modelThresholds: ModelThresholds;
  deviceModelExists: boolean;
  pipelineStatusCode: number;
  predictionSuccessful: boolean;
  anomalyExistsInTimePeriod: boolean;
};

type ModelThresholds = {
  ae: number;
  gmm: number;
  dsvdd: number;
  avgagg: number;
  maxagg: number;
};

type PredictionResults = {
  stdPower: number;
  sumPower: number;
  anomalyFlag: number;
  medianPower: number;
  toTimestamp: number;
  averagePower: number;
  maximumPower: number;
  minimumPower: number;
  aeOutlierFlag: number;
  fromTimestamp: number;
  variancePower: number;
  aeOutlierScore: number;
  gmmOutlierFlag: number;
  gmmOutlierScore: number;
  dsvddOutlierFlag: number;
  avgAggOutlierFlag: number;
  dsvddOutlierScore: number;
  maxAggOutlierFlag: number;
  avgAggOutlierScore: number;
  firstQuartilePower: number;
  maxAggOutlierScore: number;
  thirdQuartilePower: number;
};

export const initialState: State = {
  anomalyData: [],
};

export default initialState;
