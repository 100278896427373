import _, { filter, orderBy, keyBy, uniq, find, reduce, get } from 'lodash';
import { isDeviceGroup } from '@/helpers/validations';

import issues from './modules/issues';
import alerts from './modules/alerts';

const ALL_DEVICES_LIST = -1;

const activeDevices = (devices) => devices.filter((d) => d.status === 'A');
const withoutGhostDevices = (devices) =>
  devices.filter((d) => d.type !== 'ghost');
const activeGroups = (groups) => groups.filter((g) => g.status === 'A');

export const sortFunction = (a = '', b = '') => {
  const aLower = a.toLowerCase();
  const bLower = b.toLowerCase();
  return aLower.localeCompare(bLower, undefined, { numeric: true });
};

// based on allDevices (retrieved for everyone because settings page = show everything)
// returns list of devices the user has permission against
// NOTE: device API call without settings=true will get a restricted list for non safi/admin level
export const viewableDevices = (
  state,
  getters,
  rootState,
  rootGetters,
  settings = false
) => {
  const { devicesLists } = rootGetters.permissions;
  let allowed = [];

  if (devicesLists) {
    const lists = rootGetters['devicesLists/devicesLists'];
    // create a lookup for all devicesLists by id
    const listsLookup = keyBy(lists, 'id');

    // specific devices list selected, apply restrictions to just that chosen list
    if (
      devicesLists.selectedListId &&
      devicesLists.selectedListId !== ALL_DEVICES_LIST
    ) {
      let selectedList = listsLookup[devicesLists.selectedListId];

      // The viewWrapper is defaulting to the 1 available listIds item even though the user didn't
      // "select" it nor put it in the selectedListId
      // we need to take care of the case when it's only 1 available list and it has forced
      // selection in DevicesListsSelector, see items for population and selectedList for selecting
      // "if only 1" need to mimic the behavior of the list change selection,
      // otherwise selectedList === undefined
      if (rootGetters.userLevel > 1 && !selectedList && lists.length === 1) {
        [selectedList] = lists;
      }

      if (selectedList) {
        allowed = selectedList.devices;
      }
    } else if (
      rootGetters.userLevel > 1 &&
      devicesLists.listsIds &&
      devicesLists.listsIds.length
    ) {
      // if the user isn't safi or admin AND has not applied a selected a specific devices list
      // see if there are any restrictions via listsIds, look through each one and gather a
      // unique list of device id restrictions
      let collectedDevices = [];

      devicesLists.listsIds.forEach((listsId) => {
        const listObj = listsLookup[listsId];
        if (listObj && listObj.devices && listObj.devices.length > 0) {
          collectedDevices = collectedDevices.concat([...listObj.devices]);
        }
      });

      // apply a unique list of collected device ids
      allowed = [...new Set(collectedDevices)];
    }
  }

  const availableDevices = settings
    ? getters.allDevices
    : activeDevices(getters.allDevices);

  return availableDevices.filter((device) => {
    if (!allowed.length) {
      return true;
    }
    // TODO: figure out this tslint
    // https://stackoverflow.com/questions/52423842/what-is-not-assignable-to-parameter-of-type-never-error-in-typescript
    // @ts-ignore
    return allowed.includes(device.deviceid);
  });
};

const filterGroupsByPermissionsOrSelection = (...rest) => {
  const groups = rest[0].deviceGroups || [];
  const availableDevices = viewableDevices(...rest).map((d) => d.deviceid);

  // Groups that contains only allowed devices
  const filteredGroups = groups.filter((group) =>
    group.devices.find((d) => availableDevices.includes(d.deviceid))
  );

  // Now also remove not allowed devices for each group
  return filteredGroups.reduce((acc, group) => {
    const updatedGroup = {
      ...group,
      devices: group.devices.filter(({ deviceid }) =>
        availableDevices.includes(deviceid)
      ),
    };
    acc.push(updatedGroup);
    return acc;
  }, []);
};

const allGetters = {
  company: (state) => state.company,
  GetEnergy: (state) => state.energy,
  GetAllDeviceEnergy: (state) => state.energyAllDevices,
  // all groups unfiltered (settings page)
  allDeviceGroups: (state) => state.allDeviceGroups,
  allActiveDevicesGroups: (state) => activeGroups(state.allDeviceGroups),
  // groups filtered by permissioned devices
  deviceGroups: (...ctx) => filterGroupsByPermissionsOrSelection(...ctx),
  // Only active ones
  availableDeviceGroups: (...ctx) =>
    activeGroups(filterGroupsByPermissionsOrSelection(...ctx)),
  userLevel: (state) =>
    state.user.loggedIn === true ? state.user.loggedInUserInfo.level : -1,
  permissions: (state, getters) =>
    getters.getLoggedInUser ? getters.getLoggedInUser.permissions : {},
  username: (state) =>
    state.user.loggedIn === true ? state.user.loggedInUserInfo.username : '',
  email: (state) =>
    state.user.loggedIn === true ? state.user.loggedInUserInfo.email : '',
  companyid: (state) => state.company,
  devices: (...ctx) => withoutGhostDevices(viewableDevices(...ctx)),
  allDevices: (state) => state.allDevices,
  allEnabledDevices: (state) =>
    state.allDevices ? activeDevices(state.allDevices) : [],
  allEnabledDevicesWithoutGhosts: (state) =>
    state.allDevices
      ? activeDevices(withoutGhostDevices(state.allDevices))
      : [],
  // values are set in mutations/devices due to async emptiness as derivatives
  activeDevices: (...ctx) => viewableDevices(...ctx),
  activeNoGhostDevices: (...ctx) =>
    withoutGhostDevices(viewableDevices(...ctx)),
  activeWithGhostDevices: (...ctx) => viewableDevices(...ctx),
  metricsGroupedByDevice: (state) => state.metricsGroupedByDevices || {},
  getAvailableMetrics: (state) => state.available_metrics,
  getDeviceById: (state) => (deviceId) =>
    [...state.allDevices, ...state.deviceGroups].find(
      (d) => d.deviceid === deviceId || d.groupid === deviceId
    ) || {},
  getAllDevicesById: (state) => state.allDevicesById || {},
  getDeviceThresholds: (state, getters) => (deviceId) => {
    const device = getters.getDeviceById(deviceId);

    if (!device || !device.thresholds) return null;

    const thresholdsKeys = Object.keys(device.thresholds[0]);

    return thresholdsKeys.reduce((acc, thresholdKey) => {
      acc[thresholdKey.split('_')[0]] = Number(
        device.thresholds[0][thresholdKey]
      );
      return acc;
    }, {});
  },
  getDeviceThreshold: (state, getters) => (deviceId, thresholdName) =>
    getters.getDeviceThresholds(deviceId)[thresholdName],
  getDeviceGroup:
    (...ctx) =>
    (groupId) =>
      filterGroupsByPermissionsOrSelection(...ctx).find(
        (group) => group.groupid === groupId
      ) || {},
  getDeviceGroupRawData:
    (state) =>
    ({ from, to, groupId }) => {
      const key = `${from}_${to}_${groupId}`;
      return state.rawDeviceGroupData[key] || [];
    },
  isGhostDevice: (state, getters) => (deviceId) =>
    getters.getDeviceById(deviceId).type === 'ghost',
  // getDeviceIssues: state => deviceId =>
  //  state.issues.filter(issue => issue.deviceid === deviceId),
  getRawDataByDevice:
    (state) =>
    ({ from, to, deviceId, metricId }) => {
      const key = `${from}_${to}_${deviceId}`;
      const metrics = state.rawDataByDevice[key] || {};
      return metrics[metricId] || [];
    },
  groupedLoadTypes: ({ loadTypes }) => {
    const grouped = [];
    let mains;
    let machine;
    let utility;

    loadTypes.forEach(({ type, status, name }) => {
      const typeObj = { type, status };

      if (type.indexOf('mains') > -1) {
        if (!mains) {
          mains = { name: 'Mains', types: [] };
          grouped.push(mains);
        }
        mains.types.push(typeObj);
      } else if (type.indexOf('machine') > -1) {
        if (!machine) {
          machine = { name: 'Machine', types: [] };
          grouped.push(machine);
        }
        machine.types.push(typeObj);
      } else if (type.indexOf('utility') > -1) {
        if (!utility) {
          utility = { name: 'Utility', types: [] };
          grouped.push(utility);
        }
        utility.types.push(typeObj);
      } else {
        grouped.push({ name, types: [type], status });
      }
    });

    return grouped;
  },
  GetCompanyName: (state) => state.companyData.name,
  getUsers: (state) => state.users.users,
  getRoles: (state) => state.roles.roles,
  getLoggedInUser: (state) => state.user.loggedInUserInfo,
  getMfaChannel: (state) =>
    state.user.loggedInUserInfo && state.user.loggedInUserInfo.mfa,
  getCompanyInfo: (state) => state.user.companyInfo,
  getFeatureFlags: (state) =>
    reduce(
      state?.user?.companyInfo?.featureFlags ?? [],
      (ob, feature) => {
        ob[feature.name] = feature.enabled;
        return ob;
      },
      {}
    ),
  getCompanyData: (state) => state.companyData,
  getProdUnits: (state) => state.prodUnits,
  getCompanySettings: (state) => state.companySettings || {},
  getCompanySettingPlannedDowntime: (state) =>
    state.companySettings.plannedDowntime || { compute: false, snooze: false },
  getCustomSorted: (state) => state.customSorted,
  getAllDevicesForDevicesGroups: (...ctx) =>
    allGetters.allEnabledDevicesWithoutGhosts(...ctx).map((d) => ({
      deviceid: d.deviceid,
      operator: d.operator === '-',
      willBeAdded: false,
      nickname: d.nickname,
      loadtype: d.loadtype,
      settings: d.settings,
    })),
  getDevicesForDevicesGroups: (...ctx) =>
    allGetters.devices(...ctx).map((d) => ({
      deviceid: d.deviceid,
      operator: d.operator === '-',
      willBeAdded: false,
      nickname: d.nickname,
      loadtype: d.loadtype,
    })),
  ...issues.getters,
  ...alerts.getters,
  getGroupsDevices:
    (...ctx) =>
    (status) => {
      const groups = filterGroupsByPermissionsOrSelection(...ctx);
      if (status === 'available')
        return groups.filter((dg) => dg.status === 'A');
      return groups;
    },
  getDevicesInAvailableDeviceGroups:
    (...ctx) =>
    (groupIDs) => {
      const groups = allGetters.availableDeviceGroups(...ctx);
      if (!groupIDs) {
        return _.flattenDeep(groups.map((adg) => adg.devices));
      }
      return _.flattenDeep(
        groups
          .filter((adg) => groupIDs.includes(adg.groupid))
          .map((adg) => adg.devices)
      );
    },
  alert: (state) => state.alert,
  getGroupDefaultDevice:
    (state, getters) =>
    (groupId, parameter = 'runtime_deviceid') => {
      const deviceGroup = getters.getDeviceGroup(groupId);
      if (!deviceGroup) return null;
      return (
        deviceGroup[parameter] ||
        deviceGroup.runtime_deviceid ||
        deviceGroup.devices?.[0]?.deviceid ||
        null
      );
    },
  getUsersForFilters: (state) => {
    const users = _.orderBy(state.users.users, ['username'], ['asc']);
    users.unshift({ username: 'none' });
    return users;
  },
  getCompanyTimezone: (state) => state.companyTimezone,
  getUserTimezone: (state) => state.userTimezone,
  getBrowserTimezone: (state) => state.browserTimezone,
  getIsCustomTimezone: (state) =>
    state.browserTimezone !== state.companyTimezone,
  getDeviceData:
    (state) =>
    (range, deviceId = null) => {
      const rangeData = state.devicesData[range] || {};

      if (deviceId) {
        return rangeData[deviceId];
      }
      return rangeData;
    },
  getAllDevicesAsObject: (...ctx) => {
    const devicesObject = {};
    viewableDevices(...ctx).forEach((device) => {
      devicesObject[device.deviceid] = device;
    });
    return devicesObject;
  },
  getUsersAsRecipients: (state) =>
    orderBy(
      state.users.users.map((user) => ({
        username: user.username,
        phonenumber: user.phone,
        email: user.email,
      })),
      'username'
    ),
  getRawData: (state) => (metric) => state.raw[metric],
  getMultipleMetricsData: (state) => state.multipleMetricsData,
  getMetricsStats: (state) => state.metricStats || [],
  getMetricsStatsData: (state) => state.metricStatsData || {},
  getCompanyUsers: (state) =>
    orderBy(
      filter(state.users.users, { companyid: state.company }),
      'username'
    ),
  getActiveSpeedLines: (state) => (deviceId) =>
    state.speedLines[deviceId] || [],
  getAlertRule: (state) => (ruleId) =>
    state.alerts.find((a) => a.id === ruleId),
  getUserByPhone: (state) => (phone) =>
    state.users.users.filter((u) => u.phone === phone) || [],
  getDeviceCycleCountSettings: (state) => (deviceid) => {
    const device = state.allDevices.find((d) => d.deviceid === deviceid);

    // the cyclesCounting key returns with default and current from the request /devices but when
    // we store it we merge both at the cyclesCounting level.
    return device.settings.cyclesCounting;
  },
  getSkusDevices: (state, getters) =>
    state.skusDevices.filter((sd) =>
      getters.allEnabledDevices.find(
        (d) => d.deviceid === sd.device_id || sd.device_id === null
      )
    ),
  getSkuDevicesBySettingsType: (state) => (type) =>
    state.skusDevices.filter((sd) => sd.settings && sd.settings.type === type),
  getSkusDevicesByDeviceId: (state, getters) => (deviceId) => {
    const relations = orderBy(
      getters
        .getSkuDevicesBySettingsType('cyclesCounting')
        .filter(
          (sd) =>
            sd.status === 'A' &&
            (sd.device_id === deviceId || sd.device_id === null)
        ),
      'device_id',
      'asc'
    );

    const deviceRelationsLenght = relations.filter((sd) => sd.device_id).length;

    const skusIds = uniq(relations.map((sd) => sd.sku_id));
    const skus = skusIds.map(
      (skuId) => getters['productionPeriod/getSKUById'](skuId) || {}
    );

    let deviceRelations = [];
    let otherProducts = [];

    if (deviceRelationsLenght) {
      deviceRelations = skus
        .slice(0, deviceRelationsLenght)
        .sort(({ skuCode: a }, { skuCode: b }) => sortFunction(a, b));

      deviceRelations = [
        { id: 'subtitle-1', subtitle: true, text: 'Device-specific Products' },
        ...deviceRelations,
      ];

      if (deviceRelationsLenght !== skus.length) {
        otherProducts = skus
          .slice(deviceRelationsLenght)
          .sort(({ skuCode: a }, { skuCode: b }) => sortFunction(a, b));

        otherProducts = [
          { id: 'subtitle-2', subtitle: true, text: 'All Other Products' },
          ...otherProducts,
        ];
      }

      const allSkus = [...deviceRelations, ...otherProducts];

      return allSkus;
    }

    return skus.sort(({ skuCode: a }, { skuCode: b }) => sortFunction(a, b));
  },
  isFeatureEnabled: (state, getters) => (feature) => {
    const featureFlags = getters.getFeatureFlags || {};
    return featureFlags?.[feature];
  },
  getDeviceOrDefaultDevice: (state, getters) => (deviceOrGroupId) => {
    const deviceId = isDeviceGroup(deviceOrGroupId)
      ? getters.getGroupDefaultDevice(deviceOrGroupId, 'cycletime_deviceid')
      : deviceOrGroupId;
    return getters.getDeviceById(deviceId);
  },
  getAdditionalSensorMetricData: (state, getters) => (deviceOrGroupId) => {
    const machine = getters.getDeviceOrDefaultDevice(deviceOrGroupId);
    const usesAdditionalSensorSetting = machine?.details?.usesAdditionalSensor;
    const additionalSensorMetric =
      usesAdditionalSensorSetting && machine?.details?.additionalSensorMetric;

    let additionalSensorMetricTarget = Number(
      machine?.settings?.metricsTargets?.[additionalSensorMetric]
    );
    if (isNaN(additionalSensorMetricTarget)) {
      additionalSensorMetricTarget = null;
    }
    const metric =
      state.available_metrics[additionalSensorMetric?.toLowerCase?.()];

    const split = metric?.unit?.split?.('/');
    const ratelessUnit = split?.[0];
    const frequency = metric?.options?.config?.frequency || 'hour';

    let targetNormalizedToHr = additionalSensorMetricTarget;

    if (additionalSensorMetricTarget) {
      if (frequency === 'minute') {
        targetNormalizedToHr = additionalSensorMetricTarget * 60;
      } else if (frequency === 'second') {
        targetNormalizedToHr = additionalSensorMetricTarget * 3600;
      }
    }

    const usesAdditionalSensor =
      !!(usesAdditionalSensorSetting && additionalSensorMetric) &&
      additionalSensorMetricTarget !== null;

    return {
      usesAdditionalSensor,
      additionalSensorMetric,
      additionalSensorMetricTarget,
      metric,
      ratelessUnit,
      frequency,
      targetNormalizedToHr,
    };
  },
  getRelationSettings: (state, getters) => (deviceOrGroupId, skuId) => {
    const { settings: deviceSettings = {}, deviceid: deviceId } =
      getters.getDeviceOrDefaultDevice(deviceOrGroupId);

    const { settings: skuSettings = {} } =
      find(state.skusDevices, {
        status: 'A',
        sku_id: skuId,
        device_id: null,
      }) || {};

    const { settings: skuDeviceSettings = {} } =
      find(state.skusDevices, {
        status: 'A',
        sku_id: skuId,
        device_id: deviceId,
      }) || {};

    const settingsMappedToSourceName = (settings, name) =>
      Object.entries(settings || {}).reduce((acc, [key]) => {
        acc[key] = name;
        return acc;
      }, {});

    const settingSources = {
      ...settingsMappedToSourceName(deviceSettings, 'DEVICE'),
      ...settingsMappedToSourceName(skuSettings, 'SKU'),
      ...settingsMappedToSourceName(skuDeviceSettings, 'SKU_DEVICE'),
    };

    return {
      type: 'speed',
      ...deviceSettings,
      ...skuSettings,
      ...skuDeviceSettings,
      settingSources,
      deviceSettings,
      skuSettings,
      skuDeviceSettings,
    };
  },
  getMfaUser: (state) => state.usermfa,
  totalDevices: (state) => state.allDevices.length,
  getStat:
    (state, getters) =>
    (deviceId, issue = null) => {
      const device = getters.getDeviceById(deviceId, issue);
      const { thresholdsEx = {} } = device;

      const { Psum } = thresholdsEx || {};
      let parameter =
        (Psum &&
          ((Psum.offline && Psum.offline.parameter) ||
            (Psum.idle && Psum.idle.parameter) ||
            (Psum.ratedload && Psum.ratedload.parameter))) ||
        'avgvalue';
      if (!issue) return parameter;

      const { issuetype, settings } = issue;
      if (issuetype !== 'alert' || !settings) return parameter;

      const { deviceThresholdAlert } = settings;
      if (deviceThresholdAlert) return parameter;

      const { thresholds: issueThresholds } = issue;
      const { AND, OR } = issueThresholds;
      [...AND, ...OR].forEach((condition) => {
        if (condition.stat !== 'avgvalue') {
          parameter = condition.stat;
        }
      });
      return parameter;
    },
  getReadableNumber:
    (state) =>
    (number, locale = state.locale) =>
      (number && Number(number).toLocaleString(locale)) || number,
  getDeviceOrDeviceListName: (state, getters) => (item) => {
    if (item.is === 'DEVICES') {
      return getters.getDeviceById(item.id)?.nickname;
    }
    const deviceList = find(getters['devicesLists/devicesLists'], {
      id: item.id,
    });
    const deviceListName = get(deviceList, 'name', 'noName');
    return deviceListName;
  },
};

export default allGetters;
