import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import { capitalize, uppercaseFirstLetter } from '../../helpers/validations';
import mappers from '../../helpers/mappers';

const saveUptime = (
  state,
  { uptimeArr, uptimeThresholds, peakHighArr, peakLowArr, avgValArr, lfArr },
  rangeLabel
) => {
  if (rangeLabel) {
    Vue.set(state, `uptimeStats${uppercaseFirstLetter(rangeLabel)}`, {
      uptimeData: uptimeArr,
      uptimeThresholds,
      peakHigh: peakHighArr,
      peakLow: peakLowArr,
      avgVal: avgValArr,
      loadFactor: lfArr,
    });
  } else {
    Vue.set(state, 'uptimeData', uptimeArr);
    Vue.set(state, 'uptimeThresholds', uptimeThresholds);
    Vue.set(state, 'peakHigh', peakHighArr);
    Vue.set(state, 'peakLow', peakLowArr);
    Vue.set(state, 'avgVal', avgValArr);
    Vue.set(state, 'loadFactor', lfArr);
  }
};

const clearUptime = (state) => {
  Vue.set(state, 'uptimeData', []);
  Vue.set(state, 'uptimeThresholds', []);
  Vue.set(state, 'peakHigh', []);
  Vue.set(state, 'peakLow', []);
  Vue.set(state, 'avgVal', []);
  Vue.set(state, 'loadFactor', []);

  // clean rangeLabeled stats too
  Vue.set(state, 'uptimeStatsWeek', {});
  Vue.set(state, 'uptimeStatsDay', {});
};

/**
 * To Do:
 * 1) @Ken Refactor the following three methods
 * to be only one, avoiding duplicated code
 * 2) Add basic support to throw an error
 *  in case of invalid input
 * (like empty array or falsy values)
 */

/**
 * convenientely processes the stats to be able
 * to show them in grids/charts
 * @param {Array} uptimeData
 */
const parseUptimeDataAll = (uptimeData) => {
  const { fromDate, toDate, metric } =
    uptimeData[uptimeData.length - 1][0].extras;
  uptimeData.pop();
  const uptimeArr = [];
  const uptimeThresholds = [];
  let uptimeObj = {};
  const peakHighArr = [];
  const peakLowArr = [];
  let peakHighObj = {};
  let peakLowObj = {};
  const avgValArr = [];
  let avgValObj = {};
  const lfArr = [];
  let lfObj = {};
  let renamedThresh = null;
  let fromM = moment(fromDate);
  const toM = moment(toDate);

  uptimeData.forEach((uptime) => {
    // Get each available threshold and create array/object for each
    const thresholds = Object.keys(uptime[0].uptimeData.uptimedata).filter(
      (upd) => upd.split('_')[0] === 'total'
    );
    const metricId = mappers.metricMap[metric].id;

    for (const thresh of thresholds) {
      if (thresh.split('_')[1] === 'others') {
        if (thresholds.includes(`total_loaded_${metricId}`)) {
          renamedThresh = `total_overloaded_${metricId}`;
        }
      }
      // Loop through time range to fill gaps (only highcharts does this for us)
      while (fromM.valueOf() <= toM.valueOf()) {
        const upItem = uptime.find(
          (up) => Number(up.datefrom) === fromM.valueOf()
        );
        if (upItem) {
          const date = moment(Number(upItem.datefrom)).format('YYYY-MM-DD');
          uptimeObj.device = upItem.nickname;
          uptimeObj[date] = upItem.uptimeData.uptimedata[thresh];

          // Capture peak data for the metric

          for (const stat of Object.keys(upItem.uptimeData.statsdata)) {
            // Create stat object for metric (if it does not exist)
            const metricName = Object.keys(mappers.metricMap).find(
              (mm) => mappers.metricMap[mm].id === stat
            );
            const metricUnit = mappers.metricMap[metricName].unit;
            if (!Object.hasOwn(peakHighObj, metricUnit)) {
              peakHighObj[metricUnit] = {};
              peakLowObj[metricUnit] = {};
              avgValObj[metricUnit] = {};
            }
            const formattedPeakHigh = Object.assign(
              {},
              upItem.uptimeData.statsdata[stat].peakHigh
            );
            const formattedPeakLow = Object.assign(
              {},
              upItem.uptimeData.statsdata[stat].peakLow
            );
            formattedPeakHigh.time = formattedPeakHigh.time
              ? moment(Number(formattedPeakHigh.time)).format('HH:mm:ss')
              : 'N/A';
            formattedPeakLow.time = formattedPeakLow.time
              ? moment(Number(formattedPeakLow.time)).format('HH:mm:ss')
              : 'N/A';
            peakHighObj[metricUnit].device = upItem.nickname;
            peakHighObj[metricUnit][date] = formattedPeakHigh;
            peakLowObj[metricUnit].device = upItem.nickname;
            peakLowObj[metricUnit][date] = formattedPeakLow;

            avgValObj[metricUnit].device = upItem.nickname;
            avgValObj[metricUnit][date] =
              upItem.uptimeData.statsdata[stat].avgVal;

            // If metric is kVA then capture loadFactor
            if (metricName === 'Apparent Power') {
              if (!Object.hasOwn(lfObj, metricUnit)) {
                lfObj[metricUnit] = {};
              }
              lfObj[metricUnit].device = upItem.nickname;
              lfObj[metricUnit][date] =
                upItem.uptimeData.statsdata[stat].loadFactor;
            }
          }
        } else {
          const date = fromM.format('YYYY-MM-DD');
          uptimeObj.device = uptime[0].nickname;
          uptimeObj[date] = 'N/A';

          for (const metricName of Object.keys(mappers.metricMap)) {
            const metricUnit = mappers.metricMap[metricName].unit;
            if (!Object.hasOwn(peakHighObj, metricUnit)) {
              peakHighObj[metricUnit] = {};
              peakLowObj[metricUnit] = {};
              avgValObj[metricUnit] = {};
            }
            peakHighObj[metricUnit].device = uptime[0].nickname;
            peakHighObj[metricUnit][date] = 'N/A';
            peakLowObj[metricUnit].device = uptime[0].nickname;
            peakLowObj[metricUnit][date] = 'N/A';

            avgValObj[metricUnit].device = uptime[0].nickname;
            avgValObj[metricUnit][date] = 'N/A';

            // If metric is kVA then capture loadFactor
            if (metricName === 'Apparent Power') {
              if (!Object.hasOwn(lfObj, metricUnit)) {
                lfObj[metricUnit] = {};
              }
              lfObj[metricUnit].device = uptime[0].nickname;
              lfObj[metricUnit][date] = 'N/A';
            }
          }
        }
        fromM.add(1, 'day');
      }
      const orderedUpObj = _(uptimeObj).toPairs().sortBy(0).fromPairs().value(); // Order object by it's keys, ascending
      uptimeArr.push({
        threshold: renamedThresh || thresh,
        data: orderedUpObj,
      });

      // Also add thresholds to state/store
      if (renamedThresh) {
        if (!uptimeThresholds.includes(capitalize(renamedThresh))) {
          uptimeThresholds.push(capitalize(renamedThresh));
        }
      } else if (!uptimeThresholds.includes(capitalize(thresh))) {
        uptimeThresholds.push(capitalize(thresh));
      }
      uptimeObj = {};
      renamedThresh = null;
      fromM = moment(fromDate);
    }
    // Now add peaks as well...
    const orderedPeakHighObj = _(peakHighObj)
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();
    peakHighArr.push(orderedPeakHighObj);
    const orderedPeakLowObj = _(peakLowObj)
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();
    peakLowArr.push(orderedPeakLowObj);
    peakHighObj = {};
    peakLowObj = {};

    avgValArr.push(avgValObj);
    avgValObj = {};

    lfArr.push(lfObj);
    lfObj = {};
  });
  const result = {
    uptimeArr,
    uptimeThresholds,
    peakHighArr,
    peakLowArr,
    avgValArr,
    lfArr,
  };
  return result;
};

const parseUptimeData = (uptimeData) => {
  const { fromDate, toDate, metric } =
    uptimeData[uptimeData.length - 1][0].extras;
  uptimeData.pop();
  const uptimeArr = [];
  const uptimeThresholds = [];
  let uptimeObj = {};
  const peakHighArr = [];
  const peakLowArr = [];
  const peakHighObj = {};
  const peakLowObj = {};
  const avgValArr = [];
  const avgValObj = {};
  const lfArr = [];
  const lfObj = {};
  let renamedThresh = null;
  let fromM = moment(fromDate);
  const toM = moment(toDate);
  // Get each available threshold and create array/object for each
  const thresholds = Object.keys(
    uptimeData[uptimeData.length - 1].uptimeData.uptimedata
  ).filter((upd) => upd.split('_')[0] === 'total');
  const metricId = mappers.metricMap[metric].id;

  for (const thresh of thresholds) {
    if (thresh.split('_')[1] === 'others') {
      if (thresholds.includes(`total_loaded_${metricId}`)) {
        renamedThresh = `total_overloaded_${metricId}`;
      }
    }
    // Loop through time range to fill gaps (only highcharts does this for us)
    while (fromM.valueOf() <= toM.valueOf()) {
      const upItem = uptimeData.find(
        (up) => Number(up.datefrom) === fromM.valueOf()
      );
      if (upItem) {
        const date = moment(Number(upItem.datefrom)).format('YYYY-MM-DD');
        uptimeObj.device = upItem.nickname;
        uptimeObj[date] = upItem.uptimeData.uptimedata[thresh];

        // Capture peak data for the metric

        for (const stat of Object.keys(upItem.uptimeData.statsdata)) {
          // Create stat object for metric (if it does not exist)
          const metricName = Object.keys(mappers.metricMap).find(
            (mm) => mappers.metricMap[mm].id === stat
          );
          const metricUnit = mappers.metricMap[metricName].unit;
          if (!Object.hasOwn(peakHighObj, metricUnit)) {
            peakHighObj[metricUnit] = {};
            peakLowObj[metricUnit] = {};
            avgValObj[metricUnit] = {};
          }
          const formattedPeakHigh = Object.assign(
            {},
            upItem.uptimeData.statsdata[stat].peakHigh
          );
          const formattedPeakLow = Object.assign(
            {},
            upItem.uptimeData.statsdata[stat].peakLow
          );
          formattedPeakHigh.time =
            formattedPeakHigh.time && typeof formattedPeakHigh.time === 'number'
              ? moment(Number(formattedPeakHigh.time)).format('HH:mm:ss')
              : 'N/A';
          formattedPeakLow.time =
            formattedPeakLow.time && typeof formattedPeakLow.time === 'number'
              ? moment(Number(formattedPeakLow.time)).format('HH:mm:ss')
              : 'N/A';
          peakHighObj[metricUnit].device = upItem.nickname;
          peakHighObj[metricUnit][date] = formattedPeakHigh;
          peakLowObj[metricUnit].device = upItem.nickname;
          peakLowObj[metricUnit][date] = formattedPeakLow;

          avgValObj[metricUnit].device = upItem.nickname;
          avgValObj[metricUnit][date] =
            upItem.uptimeData.statsdata[stat].avgVal;

          // If metric is kVA then capture loadFactor
          if (metricName === 'Apparent Power') {
            if (!Object.hasOwn(lfObj, metricUnit)) {
              lfObj[metricUnit] = {};
            }
            lfObj[metricUnit].device = upItem.nickname;
            lfObj[metricUnit][date] =
              upItem.uptimeData.statsdata[stat].loadFactor;
          }
        }
      } else {
        const date = fromM.format('YYYY-MM-DD');
        uptimeObj.device = uptimeData[0].nickname;
        uptimeObj[date] = 'N/A';

        for (const metricName of Object.keys(mappers.metricMap)) {
          const metricUnit = mappers.metricMap[metricName].unit;
          if (!Object.hasOwn(peakHighObj, metricUnit)) {
            peakHighObj[metricUnit] = {};
            peakLowObj[metricUnit] = {};
            avgValObj[metricUnit] = {};
          }
          peakHighObj[metricUnit].device = uptimeData[0].nickname;
          peakHighObj[metricUnit][date] = 'N/A';
          peakLowObj[metricUnit].device = uptimeData[0].nickname;
          peakLowObj[metricUnit][date] = 'N/A';

          avgValObj[metricUnit].device = uptimeData[0].nickname;
          avgValObj[metricUnit][date] = 'N/A';

          // If metric is kVA then capture loadFactor
          if (metricName === 'Apparent Power') {
            if (!Object.hasOwn(lfObj, metricUnit)) {
              lfObj[metricUnit] = {};
            }
            lfObj[metricUnit].device = uptimeData[0].nickname;
            lfObj[metricUnit][date] = 'N/A';
          }
        }
      }
      fromM.add(1, 'day');
    }
    const orderedObj = _(uptimeObj).toPairs().sortBy(0).fromPairs().value(); // Order object by it's keys, ascending
    uptimeArr.push({
      threshold: renamedThresh || thresh,
      data: orderedObj,
    });

    // Also add threshold to state/store
    if (renamedThresh) {
      if (
        uptimeThresholds.find(
          (uth) => uth.name === capitalize(renamedThresh)
        ) === undefined
      ) {
        uptimeThresholds.push({
          name: capitalize(renamedThresh),
          value: uptimeData[0].uptimeData[renamedThresh.replace('total_', '')],
        });
      }
    } else if (
      uptimeThresholds.find((uth) => uth.name === capitalize(thresh)) ===
      undefined
    ) {
      uptimeThresholds.push({
        name: capitalize(thresh),
        value: uptimeData[0].uptimeData[thresh.replace('total_', '')],
      });
    }
    uptimeObj = {};
    renamedThresh = null;
    fromM = moment(fromDate);
  }
  // Now add peaks as well...

  for (const peakH in peakHighObj) {
    const orderedPeakHighObj = _(peakHighObj[peakH])
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();
    peakHighArr.push({ [peakH]: orderedPeakHighObj });
  }

  for (const peakL in peakLowObj) {
    const orderedPeakLowObj = _(peakLowObj[peakL])
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();
    peakLowArr.push({ [peakL]: orderedPeakLowObj });
  }

  for (const avg in avgValObj) {
    avgValArr.push({ [avg]: avgValObj[avg] });
  }

  for (const lf in lfObj) {
    lfArr.push({ [lf]: lfObj[lf] });
  }
  const result = {
    uptimeArr,
    uptimeThresholds,
    peakHighArr,
    peakLowArr,
    avgValArr,
    lfArr,
  };
  return result;
};

const parseUptimeDataShift = (uptimeData) => {
  const { fromDate, toDate, shiftStart, shiftDuration, metric } =
    uptimeData[uptimeData.length - 1][0].extras;
  uptimeData.pop();
  const uptimeArr = [];
  const uptimeThresholds = [];
  let uptimeObj = {};
  const peakHighArr = [];
  const peakLowArr = [];
  let peakHighObj = {};
  let peakLowObj = {};
  const avgValArr = [];
  let avgValObj = {};
  const lfArr = [];
  let lfObj = {};
  let renamedThresh = null;
  let fromM = moment(fromDate);
  const toM = moment(toDate);

  uptimeData.forEach((uptime) => {
    // Get each available threshold and create array/object for each
    const thresholds = Object.keys(
      uptime[uptime.length - 1].uptimeData.uptimedata
    ).filter((upd) => upd.split('_')[0] === 'total');
    const metricId = mappers.metricMap[metric].id;

    for (const thresh of thresholds) {
      if (thresh.split('_')[1] === 'others') {
        if (thresholds.includes(`total_loaded_${metricId}`)) {
          renamedThresh = `total_overloaded_${metricId}`;
        }
      }
      // Loop through time range to fill gaps (only highcharts does this for us)
      while (fromM.valueOf() <= toM.valueOf()) {
        const startTime =
          Number(shiftStart) > 0
            ? Number(fromM.valueOf()) + Number(shiftStart) * 60 * 1000
            : fromM.valueOf();

        const upItem = uptime.find(
          (up) => Number(up.datefrom) === Number(startTime)
        );
        if (upItem) {
          const date = moment(Number(upItem.datefrom)).format('YYYY-MM-DD');
          uptimeObj.device = upItem.nickname;
          uptimeObj[date] = upItem.uptimeData.uptimedata[thresh];

          // Capture peak data for the metric

          for (const stat of Object.keys(upItem.uptimeData.statsdata)) {
            // Create stat object for metric (if it does not exist)
            const metricName = Object.keys(mappers.metricMap).find(
              (mm) => mappers.metricMap[mm].id === stat
            );
            const metricUnit = mappers.metricMap[metricName].unit;
            if (!Object.hasOwn(peakHighObj, metricUnit)) {
              peakHighObj[metricUnit] = {};
              peakLowObj[metricUnit] = {};
              avgValObj[metricUnit] = {};
            }
            const formattedPeakHigh = Object.assign(
              {},
              upItem.uptimeData.statsdata[stat].peakHigh
            );
            const formattedPeakLow = Object.assign(
              {},
              upItem.uptimeData.statsdata[stat].peakLow
            );
            formattedPeakHigh.time =
              formattedPeakHigh.time &&
              typeof formattedPeakHigh.time === 'number'
                ? moment(Number(formattedPeakHigh.time)).format('HH:mm:ss')
                : 'N/A';
            formattedPeakLow.time =
              formattedPeakLow.time && typeof formattedPeakLow.time === 'number'
                ? moment(Number(formattedPeakLow.time)).format('HH:mm:ss')
                : 'N/A';
            peakHighObj[metricUnit].device = upItem.nickname;
            peakHighObj[metricUnit][date] = formattedPeakHigh;
            peakLowObj[metricUnit].device = upItem.nickname;
            peakLowObj[metricUnit][date] = formattedPeakLow;

            avgValObj[metricUnit].device = upItem.nickname;
            avgValObj[metricUnit][date] =
              upItem.uptimeData.statsdata[stat].avgVal;

            // If metric is kVA then capture loadFactor
            if (metricName === 'Apparent Power') {
              if (!Object.hasOwn(lfObj, metricUnit)) {
                lfObj[metricUnit] = {};
              }
              lfObj[metricUnit].device = upItem.nickname;
              lfObj[metricUnit][date] =
                upItem.uptimeData.statsdata[stat].loadFactor;
            }
          }
        } else {
          const date = fromM.format('YYYY-MM-DD');
          uptimeObj.device = uptime[0].nickname;
          uptimeObj[date] = 'N/A';

          for (const metricName of Object.keys(mappers.metricMap)) {
            const metricUnit = mappers.metricMap[metricName].unit;
            if (!Object.hasOwn(peakHighObj, metricUnit)) {
              peakHighObj[metricUnit] = {};
              peakLowObj[metricUnit] = {};
              avgValObj[metricUnit] = {};
            }
            peakHighObj[metricUnit].device = uptime[0].nickname;
            peakHighObj[metricUnit][date] = 'N/A';
            peakLowObj[metricUnit].device = uptime[0].nickname;
            peakLowObj[metricUnit][date] = 'N/A';

            avgValObj[metricUnit].device = uptime[0].nickname;
            avgValObj[metricUnit][date] = 'N/A';

            // If metric is kVA then capture loadFactor
            if (metricName === 'Apparent Power') {
              if (!Object.hasOwn(lfObj, metricUnit)) {
                lfObj[metricUnit] = {};
              }
              lfObj[metricUnit].device = uptime[0].nickname;
              lfObj[metricUnit][date] = 'N/A';
            }
          }
        }
        // Check if shift greater than a day
        if (Number(shiftDuration) >= 1440) {
          fromM.add(Number(shiftDuration), 'minutes');
        } else {
          fromM.add(1, 'day');
        }
      }
      const orderedObj = _(uptimeObj).toPairs().sortBy(0).fromPairs().value(); // Order object by it's keys, ascending
      uptimeArr.push({
        threshold: renamedThresh || thresh,
        data: orderedObj,
      });

      // Also add threshold to state/store
      if (renamedThresh) {
        if (
          uptimeThresholds.find(
            (uth) => uth.name === capitalize(renamedThresh)
          ) === undefined
        ) {
          uptimeThresholds.push({
            name: capitalize(renamedThresh),
            value: uptime[0].uptimeData[renamedThresh.replace('total_', '')],
          });
        }
      } else if (
        uptimeThresholds.find((uth) => uth.name === capitalize(thresh)) ===
        undefined
      ) {
        uptimeThresholds.push({
          name: capitalize(thresh),
          value: uptime[0].uptimeData[thresh.replace('total_', '')],
        });
      }
      uptimeObj = {};
      renamedThresh = null;
      fromM = moment(fromDate);
    }
    // Now add peaks as well...
    const orderedPeakHighObj = _(peakHighObj)
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();
    peakHighArr.push(orderedPeakHighObj);
    const orderedPeakLowObj = _(peakLowObj)
      .toPairs()
      .sortBy(0)
      .fromPairs()
      .value();
    peakLowArr.push(orderedPeakLowObj);
    peakHighObj = {};
    peakLowObj = {};

    avgValArr.push(avgValObj);
    avgValObj = {};

    lfArr.push(lfObj);
    lfObj = {};
  });

  const result = {
    uptimeArr,
    uptimeThresholds,
    peakHighArr,
    peakLowArr,
    avgValArr,
    lfArr,
  };
  return result;
};

export default {
  CLEAR_UPTIME_DATA: (state) => {
    clearUptime(state);
  },
  SET_UPTIME_DATA_ALL: (state, { uptimeData, rangeLabel }) => {
    // All devices
    const parsed = parseUptimeDataAll(uptimeData);
    saveUptime(state, parsed, rangeLabel);
  },
  SET_UPTIME_DATA: (state, { uptimeData, rangeLabel }) => {
    // One device
    const parsed = parseUptimeData(uptimeData);
    saveUptime(state, parsed, rangeLabel);
  },
  SET_UPTIME_SHIFT_DATA: (state, uptimeData) => {
    // For one or all devices by shift
    const parsed = parseUptimeDataShift(uptimeData);
    saveUptime(state, parsed);
  },
  SET_UPTIME_PREV_AVG: (state, { payload, range }) => {
    Vue.set(state.uptimeStatsPrevAvg, range, payload);
  },
  /**
   * Export this methods to be used in
   * unit tests coverage
   */
  parseUptimeDataAll,
  parseUptimeData,
  parseUptimeDataShift,
};
