import _ from 'lodash';
import moment from 'moment-timezone';

const DEFAULT_PRODGRAPH_STEP = 86400; // 24 hrs in seconds

export const getUnit = (units, unitId) => {
  if (!unitId) {
    return '';
  }

  let desc = '';
  try {
    desc = units.find((item) => item.id === unitId).unitName;
  } catch (error) {
    desc = '';
  }
  return desc;
};

// Used to create the drilldown data
export const setDrilldown = (
  orderedData,
  deviceList,
  deviceGroups,
  optionsProduction,
  units
) => {
  const drilldownData = {
    id: 'Others',
    name: 'Others (Unsorted)',
    type: 'column',
    data: [],
  };
  let drillcount = 0;
  orderedData.forEach((od) => {
    const starttime = moment.unix(od.datefromunix);
    const endtime = moment.unix(od.datetounix);
    const duration = endtime.diff(starttime, 'hours');
    drilldownData.data.push({
      name: `Shift ${(drillcount += 1)} ${moment.unix(od.datefromunix).format('YYYY-MM-DD')}`,
      y: Number(Number(od.efficiency).toFixed(4)) || 0,
      device:
        deviceList
          .filter((dev) => dev.deviceid === od.deviceId)
          .map((d) => d.nickname)
          .toString() ||
        deviceGroups
          .filter((dev) => dev.groupid === od.deviceId)
          .map((d) => d.nickname)
          .toString() ||
        od.deviceId,
      production:
        Number(od.details ? od.details[0].quantity : null).toFixed(4) || 0,
      waste: Number(od.details ? od.details[0].waste : null).toFixed(4) || 0,
      energy: Number(od.energy).toFixed(4) || 0,
      from: moment.unix(od.datefromunix).format('YYYY-MM-DD HH:mm'),
      to: moment.unix(od.datetounix).format('YYYY-MM-DD HH:mm'),
      unit: getUnit(units, od.details ? od.details[0].unitId : null),
      duration,
    });
  });

  optionsProduction.drilldown = {
    name: 'Unsorted shifts',
    series: [drilldownData],
    drillUpButton: {
      position: {
        x: 0,
        y: 25,
      },
    },
  };
};

// Used to aggregate similar production entries if any found
export const manageDuplicates = (prodMachines, unitName, units) => {
  // If more than one recording for the same maching found, reduce to the summation
  const summedProduction = _(prodMachines)
    .groupBy('deviceId')
    .map((objs, key) => ({
      deviceId: key,
      energy: _.sumBy(objs, (o) => Number(Number(o.energy).toFixed(4))),
      production: _.sumBy(objs, (o) =>
        Number(Number(o.details ? o.details[0].quantity : null).toFixed(4))
      ),

      unit:
        unitName === 'All'
          ? getUnit(
              units,
              _.map(objs, (o) => {
                if (o.details) {
                  return o.details[0].unitId;
                }
                return null;
              })[0]
            )
          : unitName,
      from: Math.min(..._.map(objs, (o) => o.datefromunix)),
      to: Math.max(..._.map(objs, (o) => o.datetounix)),
      waste: _.sumBy(objs, (o) =>
        Number(Number(o.details ? o.details[0].waste : null).toFixed(4))
      ),
    }))
    .value();
  // Calculate intensity using total production and energy obtained
  const summedEnergy = _.map(summedProduction, (o) =>
    _.extend(
      {
        intensity:
          Number(o.energy / o.production) && o.energy > 0
            ? Number((o.energy / o.production).toFixed(4))
            : 0,
      },
      o
    )
  );
  return summedEnergy;
};

// Used to create a drilldown parent option for any remaining unsorted entries
export const manageRemainingEntries = (orderedData, units) => ({
  name: 'Others (Unsorted)',
  production:
    Number(
      _.sumBy(orderedData, (o) =>
        Number(o.details ? o.details[0].quantity : null)
      )
    ).toFixed(0) || 0,
  energy: Number(_.sumBy(orderedData, (o) => Number(o.energy))).toFixed(4) || 0,
  from: moment
    .unix(Math.min(..._.map(orderedData, (od) => od.datefromunix)))
    .format('YYYY-MM-DD HH:mm'),
  to: moment
    .unix(Math.max(..._.map(orderedData, (od) => od.datetounix)))
    .format('YYYY-MM-DD HH:mm'),
  unit: getUnit(units, orderedData[0].details[0].unitId),
  drilldown: 'Others',
  waste:
    Number(
      _.sumBy(orderedData, (o) => Number(o.details ? o.details[0].waste : null))
    ).toFixed(4) || 0,
});

const handleWithShifts = (
  units,
  selectedUnitID,
  shifts,
  selectedShiftID,
  devices,
  deviceGroups,
  unsortedData,
  orderedData,
  shiftCategories,
  seriesData,
  optionsProduction,
  isWidget
) => {
  const selectedUnitName = units.find((unit) => unit.id === selectedUnitID)
    ? units.find((unit) => unit.id === selectedUnitID).unitName
    : 'N/A';
  for (let x = 0; x < orderedData.length; x += 1) {
    const dataDuration =
      (orderedData[x].datetounix - orderedData[x].datefromunix) / 60;
    let dataStartDate = moment
      .unix(orderedData[x].datefromunix)
      .format('YYYY-MM-DD');
    const dataStartTime =
      (orderedData[x].datefromunix -
        moment(dataStartDate, 'YYYY-MM-DD').unix()) /
      60;
    if (isWidget) {
      dataStartDate = moment.unix(orderedData[x].datefromunix).format('DD MMM');
    }
    const dataStartDateTime = orderedData[x].datefromunix;
    const dataEndDateTime = orderedData[x].datetounix;
    const shiftFound =
      selectedShiftID === -1
        ? shifts.find(
            (ss) =>
              Number(ss.durationmin) === dataDuration &&
              Number(ss.minfrommidnight) === dataStartTime
          )
        : shifts.find(
            (ss) =>
              Number(ss.durationmin) === dataDuration &&
              Number(ss.minfrommidnight) === dataStartTime &&
              ss.id === selectedShiftID
          );
    if (shiftFound) {
      // Check for all devices that had a shift in this period
      const prodMachines = unsortedData.filter((od) => {
        const odDuration = (od.datetounix - od.datefromunix) / 60;
        const odStartDate = moment.unix(od.datefromunix).format('YYYY-MM-DD');
        const odStartTime =
          (od.datefromunix - moment(odStartDate, 'YYYY-MM-DD').unix()) / 60; // Obtains min from midnight
        if (selectedUnitID !== -1) {
          return (
            odDuration === Number(shiftFound.durationmin) &&
            odStartTime === Number(shiftFound.minfrommidnight) &&
            getUnit(units, od.details ? od.details[0].unitId : null) ===
              selectedUnitName &&
            od.datefromunix === dataStartDateTime &&
            od.datetounix === dataEndDateTime
          );
        }
        return (
          odDuration === Number(shiftFound.durationmin) &&
          odStartTime === Number(shiftFound.minfrommidnight) &&
          od.datefromunix === dataStartDateTime &&
          od.datetounix === dataEndDateTime
        );
      });

      // Group entries found by device id
      if (prodMachines.length > 0) {
        // Create shift category
        const shiftCatName = `${shiftFound.shiftname} ${dataStartDate}`;
        if (shiftCategories.indexOf(shiftCatName) === -1) {
          shiftCategories.push(`${shiftFound.shiftname} ${dataStartDate}`);
        }
        const summedEnergy = manageDuplicates(
          prodMachines,
          selectedUnitName,
          units
        );
        // Finally add data to series

        seriesData.forEach((seriesItem) => {
          const sItem = seriesItem;
          const dataMatch = _.find(summedEnergy, { deviceId: sItem.name });
          if (dataMatch) {
            sItem.data.push({
              name: `${shiftFound.shiftname} ${dataStartDate}`,
              y: Number(Number(dataMatch.intensity).toFixed(4)),
              production: Number(Number(dataMatch.production).toFixed(4)),
              waste: Number(Number(dataMatch.waste).toFixed(4)),
              energy: Number(Number(dataMatch.energy).toFixed(4)),
              unit: dataMatch.unit,
              from: moment.unix(dataMatch.from).format('YYYY-MM-DD HH:mm'),
              to: moment.unix(dataMatch.to).format('YYYY-MM-DD HH:mm'),
              device:
                devices
                  .filter((dev) => dev.deviceid === sItem.name)
                  .map((d) => d.nickname)
                  .toString() ||
                deviceGroups
                  .filter((dev) => dev.groupid === sItem.name)
                  .map((d) => d.nickname)
                  .toString() ||
                sItem.name,
            });
            // Remove entries found in unsortedData array
            _.remove(
              unsortedData,
              (r) =>
                r.datefromunix === dataMatch.from &&
                r.datetounix === dataMatch.to &&
                r.deviceId === dataMatch.deviceId
            );
          } else {
            sItem.data.push(0);
          }
        });
      }
    }
  }

  // If unsortedData still has entries, create one special shift for them.
  if (unsortedData.length > 0 && selectedShiftID === -1) {
    let dataRemaining = [];
    if (selectedUnitID !== -1) {
      dataRemaining = unsortedData.filter(
        (ud) =>
          getUnit(units, ud.details ? ud.details[0].unitId : null) ===
          selectedUnitName
      );
    } else {
      dataRemaining = unsortedData;
    }
    const summedShifts = manageRemainingEntries(dataRemaining, units);
    summedShifts.y = Number(
      (summedShifts.energy / summedShifts.production).toFixed(4)
    );
    // Add data point to series
    if (summedShifts.y > 0) {
      shiftCategories.push('Others');
      // Create drilldown data
      setDrilldown(
        dataRemaining,
        devices,
        deviceGroups,
        optionsProduction,
        units
      );
      seriesData.forEach((seriesItem) => {
        const sItem = seriesItem;
        if (sItem.name === 'All devices (unsorted shift)') {
          sItem.data.push(summedShifts);
        } else {
          sItem.data.push(0);
        }
      });
    } else {
      _.remove(seriesData, (s) => s.name === 'All devices (unsorted shift)');
    }
  } else {
    _.remove(seriesData, (s) => s.name === 'All devices (unsorted shift)');
  }

  return seriesData;
};

const handleWithoutShifts = (
  units,
  selectedUnitID,
  devices,
  deviceGroups,
  unsortedData,
  orderedData,
  shiftCategories,
  seriesData,
  optionsProduction,
  isWidget
) => {
  let shiftCount = 0;
  const filteredUnits = units.find((unit) => unit.id === selectedUnitID);
  const selectedUnitName = filteredUnits ? filteredUnits.unitName : '';

  if (orderedData && orderedData.length > 0 && orderedData[0].datefromunix) {
    for (
      let x = orderedData[0].datefromunix;
      x < orderedData[orderedData.length - 1].datetounix;
      x += DEFAULT_PRODGRAPH_STEP
    ) {
      const endtime = x + DEFAULT_PRODGRAPH_STEP;
      // Get all machines that have recorded production in this range for the given unit
      const prodMachines = _.filter(unsortedData, (obj) => {
        if (selectedUnitID !== -1) {
          return (
            obj.datefromunix >= x &&
            obj.datetounix <= endtime &&
            getUnit(units, obj.details ? obj.details[0].unitId : null) ===
              selectedUnitName
          );
        }
        return obj.datefromunix >= x && obj.datetounix <= endtime;
      });

      // Creating shifts because none found
      if (!isWidget) {
        shiftCategories.push(
          `Shift ${(shiftCount += 1)} ${moment.unix(x).format('YYYY-MM-DD')}`
        );
      } else {
        shiftCategories.push(
          `Shift ${(shiftCount += 1)} ${moment.unix(x).format('DD MMM')}`
        );
      }

      if (prodMachines.length > 0) {
        const summedEnergy = manageDuplicates(
          prodMachines,
          selectedUnitName,
          units
        );
        // Finally add data to series

        seriesData.forEach((seriesItem) => {
          const sItem = seriesItem;
          const dataMatch = _.find(summedEnergy, { deviceId: sItem.name });
          if (dataMatch) {
            sItem.data.push({
              y: Number(Number(dataMatch.intensity).toFixed(4)),
              production: Number(Number(dataMatch.production).toFixed(4)),
              waste: Number(Number(dataMatch.waste).toFixed(4)),
              energy: Number(Number(dataMatch.energy).toFixed(4)),
              unit: dataMatch.unit,
              from: moment.unix(dataMatch.from).format('YYYY-MM-DD HH:mm'),
              to: moment.unix(dataMatch.to).format('YYYY-MM-DD HH:mm'),
              device:
                devices
                  .filter((dev) => dev.deviceid === sItem.name)
                  .map((d) => d.nickname)
                  .toString() ||
                deviceGroups
                  .filter((dev) => dev.groupid === sItem.name)
                  .map((d) => d.nickname)
                  .toString() ||
                sItem.name,
            });
          } else {
            sItem.data.push(0);
          }
        });
        // Remove entries found from array

        prodMachines.forEach((prodItem) => {
          _.remove(unsortedData, (r) => r === prodItem);
        });
      } else {
        // Add zero value to series data
        seriesData.forEach((seriesItem) => {
          const sItem = seriesItem;
          sItem.data.push(0);
        });
      }
    }
  }
  // If unsortedData still has entries, create one special shift for them.
  if (unsortedData.length > 0) {
    let dataRemaining = [];
    if (selectedUnitID !== -1) {
      dataRemaining = unsortedData.filter(
        (ud) =>
          getUnit(units, ud.details ? ud.details[0].unitId : null) ===
          selectedUnitName
      );
    } else {
      dataRemaining = unsortedData;
    }
    const summedShifts = manageRemainingEntries(dataRemaining, units);
    summedShifts.y = Number(
      (summedShifts.energy / summedShifts.production).toFixed(4)
    );
    // Add data point to series
    if (summedShifts.y > 0) {
      shiftCategories.push('Others');
      // Create drilldown data
      setDrilldown(
        dataRemaining,
        devices,
        deviceGroups,
        optionsProduction,
        units
      );
      seriesData.forEach((seriesItem) => {
        const sItem = seriesItem;
        if (sItem.name === 'All devices (unsorted shift)') {
          sItem.data.push(summedShifts);
        } else {
          sItem.data.push(0);
        }
      });
    } else {
      _.remove(seriesData, (s) => s.name === 'All devices (unsorted shift)');
    }
  } else {
    _.remove(seriesData, (s) => s.name === 'All devices (unsorted shift)');
  }

  return seriesData;
};

export const handleProductionWidgetWithShifts = (
  units,
  selectedUnitID,
  shifts,
  selectedShiftID,
  devices,
  deviceGroups,
  unsortedData,
  orderedData,
  shiftCategories,
  seriesData,
  optionsProduction
) =>
  handleWithShifts(
    units,
    selectedUnitID,
    shifts,
    selectedShiftID,
    devices,
    deviceGroups,
    unsortedData,
    orderedData,
    shiftCategories,
    seriesData,
    optionsProduction,
    true
  );

export const handleProductionWidgetWithoutShifts = (
  units,
  selectedUnitID,
  devices,
  deviceGroups,
  unsortedData,
  orderedData,
  shiftCategories,
  seriesData,
  optionsProduction
) =>
  handleWithoutShifts(
    units,
    selectedUnitID,
    devices,
    deviceGroups,
    unsortedData,
    orderedData,
    shiftCategories,
    seriesData,
    optionsProduction,
    true
  );

export const handleProductionWithShifts = (
  units,
  selectedUnitID,
  shifts,
  selectedShiftID,
  devices,
  deviceGroups,
  unsortedData,
  orderedData,
  shiftCategories,
  seriesData,
  optionsProduction
) =>
  handleWithShifts(
    units,
    selectedUnitID,
    shifts,
    selectedShiftID,
    devices,
    deviceGroups,
    unsortedData,
    orderedData,
    shiftCategories,
    seriesData,
    optionsProduction,
    false
  );

export const handleProductionWithoutShifts = (
  units,
  selectedUnitID,
  devices,
  deviceGroups,
  unsortedData,
  orderedData,
  shiftCategories,
  seriesData,
  optionsProduction
) =>
  handleWithoutShifts(
    units,
    selectedUnitID,
    devices,
    deviceGroups,
    unsortedData,
    orderedData,
    shiftCategories,
    seriesData,
    optionsProduction,
    false
  );

export const optionsProduction = {
  colors: [
    '#2b908f',
    '#607848',
    '#2196F3',
    '#789048',
    '#7798BF',
    '#FFEB3B',
    '#EB7B59',
    '#C0D860',
    '#F0F0D8',
    '#604848',
    '#EB7B59',
    '#CF4647',
    '#aaeeee',
    '#55BF3B',
    '#DF5353',
    '#9061C2',
    '#2263A0',
    '#FFAD2B',
    '#7599F4',
    '#3AAD98',
    '#1F7BA5',
    '#ff0066',
  ],
  chart: {
    // type: 'column',
    zoomType: 'x',
    resetZoomButton: {
      position: {
        x: 0,
        y: 25,
      },
    },
    marginTop: 50,
  },
  title: {
    text: '',
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: false,
      },
    },
    series: {
      turboThreshold: 0,
      cropThreshold: 500,
    },
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        symbol:
          "url(data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 3.75h12v3H6v-3zm13.5 3h2.333c.369 0 .667.298.667.667v8.416a.667.667 0 01-.667.667H19.5v4.583a.667.667 0 01-.667.667H5.167a.667.667 0 01-.667-.667V16.5H2.167a.667.667 0 01-.667-.667V7.417c0-.369.298-.667.667-.667H4.5V2.917c0-.369.298-.667.667-.667h13.666c.369 0 .667.298.667.667V6.75zM21 15h-1.5v-1.5h-15V15H3V8.25h18V15zM6 15h12v5.25H6V15zm0-4.5a.75.75 0 10-1.5 0 .75.75 0 001.5 0z' fill='%23706E6B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h24v24H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E)",
      },
    },
  },
  xAxis: {
    title: {
      text: null,
    },
    crosshair: true,
    type: 'category',
    min: 0,
  },
  yAxis: [
    {
      min: 0,
      title: {
        text: 'Energy intensity',
      },
      labels: {
        overflow: 'justify',
      },
    },
    {
      min: 0,
      title: {
        text: 'Production',
      },
      opposite: true,
    },
  ],
};

export const toHHmm = (str = '21:00', toAdd = 3) => {
  // defaults to Kenya tz
  const [hourStr, minStr] = str.split(':');
  let newHour = Number(hourStr) + toAdd;
  if (newHour >= 24) {
    newHour -= 24;
  }
  const prefix = newHour <= 9 ? '0' : '';
  return `${prefix}${newHour}${minStr}`;
};

export const findShift = (shifts, potentialShift) => {
  const { duration, startTime } = potentialShift;
  return shifts.find(
    (s) =>
      Number(s.durationmin) === duration &&
      Number(s.minfrommidnight) === startTime
  );
};

export const findShiftOfProductionPeriod = (period, shifts, timezone) => {
  const {
    duration: {
      production: { from, to },
    },
  } = period;
  const periodFrom = moment(Number(from), 'x').tz(timezone);
  const periodTo = moment(Number(to), 'x').tz(timezone);
  const duration = moment.duration(periodTo.diff(periodFrom)).asMinutes();
  const startTimeMinutesFromMidnight = moment
    .duration(periodFrom.diff(periodFrom.clone().startOf('day')))
    .asMinutes();
  return shifts.find(
    (s) =>
      Number(s.durationmin) === duration &&
      Number(s.minfrommidnight) === startTimeMinutesFromMidnight
  );
};

export const getShiftTimes = (shiftDetails, d, t) => {
  const { durationmin } = shiftDetails;
  const endtime =
    moment(`${d} ${t}`, 'YYYY-MM-DD HH:mm').unix() + durationmin * 60;
  const shiftend = moment.unix(endtime).format('YYYY-MM-DD HH:mm');
  const shifttime = durationmin / 60; // Converting to hrs
  return { shiftend, shifttime, endtime };
};

export const getProductionPeriodProgress = (pp) => {
  const { from, to } = pp.duration.production;
  const now = moment().valueOf();
  const total = moment(to).diff(from, 'minutes');
  const current = moment(now).diff(from, 'minutes');
  return ((100 * current) / total).toFixed(2);
};
