import Vue from 'vue';

export { default as googleAnalytics } from './analytics';

export const safiTooltip = function (el, binding) {
  Vue.nextTick(() => {
    if (el) {
      const { children } = el;
      for (let i = 0; i < children.length; i += 1) {
        const child = children[i];
        if (child.className.includes('safi-tooltip')) {
          el.removeChild(child);
        }
      }
      if (
        binding &&
        binding.value &&
        el.offsetWidth > 0 &&
        el.offsetHeight > 0
      ) {
        const dim = document.createElement('div');
        // dim.style.width = `${el.offsetWidth}px`;
        dim.style.height = `${el.offsetHeight}px`;
        dim.style.width = '100%';
        dim.style.backgroundColor = 'rgba(0,0,0,0.5)';
        dim.style.position = 'absolute';
        dim.style.top = `${el.offsetTop}px`;
        dim.className = 'safi-tooltip text-xs-center';
        dim.style.display = 'table';

        const text = document.createElement('span');
        text.innerHTML = binding.arg;
        text.style.display = 'table-cell';
        text.style.verticalAlign = 'middle';
        dim.appendChild(text);
        el.appendChild(dim);
      }
    }
  });
};
