import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';
import config from '@/config';

import { SET_UNITS } from './mutations-types';

export default {
  async getUnits({ commit, rootState: { user } }) {
    const url = config.dataProxyUrls.v1.units.url();

    try {
      const { data } = await axios.get(url, { ...getAxiosOpts(user.token) });

      if (data && data.success) {
        commit(SET_UNITS, data.units);
      } else {
        commit(SET_UNITS, []);
      }
    } catch (e) {
      commit(SET_UNITS, []);
    }
  },
};
