import axios from 'axios';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/state';
import config from '@/config';
import { MUTATION_NAME } from './mutations-types';
import { initialState, State } from './model';

export default {
  async GET_DEVICE_SCHEDULE(context: ActionContext<State, RootState>, payload) {
    /* Your action content here */
    try {
      const {
        status,
        data: { response },
      } = await axios.get(config.dataProxyUrls.v2.deviceSchedule.url(), {
        params: {
          deviceId: payload.deviceId,
          fromTs: payload.fromTs,
          toTs: payload.toTs,
        },
      });
    } catch {
      // no-op
    }
  },
};
