const getAgent = () => {
  try {
    return navigator.userAgent.toLowerCase();
  } catch (error) {
    console.error('Failed to look for user agent information.');
  }
  return '';
};

const isMobileOrTable = () => {
  const userAgent = getAgent();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  return isTablet;
};

export { isMobileOrTable };
export default {};
