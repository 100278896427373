export default {
  getStaff: (state) => state.staff,
  getSupervisors: (state) =>
    state.staff.filter((f) => f.role === 'supervisor') || [],
  getOperators: (state) =>
    state.staff.filter((f) => f.role === 'operator') || [],
  getManagers: (state) => state.staff.filter((f) => f.role === 'manager') || [],
  getStaffMemberById: (state) => (id) => state.staff.find((s) => s.id === id),
  getStaffMembersNamesList: (state) => (idList) => {
    let ids = idList;
    if (typeof ids === 'object') ids = Object.values(ids);
    const members = state.staff.filter((s) => ids.includes(s.id));
    const names = members.reduce((list, member, idx) => {
      list += `${member.name}${idx < members.length - 1 ? ',' : ''}`;
      return list;
    }, '');

    return {
      names,
    };
  },
};
