import Vue from 'vue';
import { keyBy, last, sortBy, isEqual } from 'lodash';

const removeData = (stateDm, newDm) => {
  if (stateDm && stateDm.length) {
    const lastIndex = stateDm.length - 1;
    const { timestamp } = stateDm[lastIndex];
    const dataToPush = newDm.filter((data) => data.timestamp > timestamp);
    return dataToPush;
  }
  return newDm;
};

export default {
  SET_SPEED_LINES: (state, { deviceId, speedline }) => {
    const { selected } = speedline;
    const { speedLines } = state;
    const deviceSpeedLines = speedLines[deviceId] || [];
    if (selected) {
      deviceSpeedLines.push(speedline);
      speedLines[deviceId] = deviceSpeedLines;
    } else {
      const idx = deviceSpeedLines.findIndex((sp) => sp.id === speedline.id);
      deviceSpeedLines.splice(idx, 1);
    }
    Vue.set(state, 'speedLines', { ...speedLines });
  },
  CLEAN_SPEED_LINES: (state, deviceId) => {
    const { speedLines } = state;
    speedLines[deviceId] = [];
    Vue.set(state, 'speedLines', speedLines);
  },
  SET_DEVICE_DATA: (
    state,
    { data, duration, deviceId, loadStates = false }
  ) => {
    const stateCopy = Object.assign({}, state.devicesData);
    if (!stateCopy[duration]) {
      stateCopy[duration] = {};
    }
    Vue.set(
      state.devicesData[duration],
      `${deviceId}${loadStates ? '' : '.dmData'}`,
      data[deviceId]
    );
  },
  CLEAN_DEVICE_DATA: (state, { duration, deviceId, loadStates = false }) => {
    if (state.devicesData[duration]) {
      Vue.set(state.devicesData[duration], `${deviceId}`, {
        dmData: [],
        loadStates: loadStates
          ? []
          : (state.devicesData[duration][deviceId] &&
              state.devicesData[duration][deviceId].loadStates) ||
            [],
        loading: true,
      });
    } else {
      Vue.set(state.devicesData, `${duration}`, {});
    }
  },
  SET_LIVE_DEVICE_DATA: (state, { data, duration, range }) => {
    const stateCopy = { ...state.devicesData[duration] };
    const deviceid = Object.keys(data)[0];
    const { dmData, loadStates } = data[deviceid];
    const orderedLoadStates = sortBy(loadStates, 'start');
    if (dmData) {
      const newDmData = removeData(stateCopy[deviceid].dmData, dmData);
      stateCopy[deviceid].dmData.push(...newDmData);
    }

    // Only leave the states for the current range
    // remove possible repeated states
    const currentStates = range
      ? stateCopy[deviceid].loadStates.filter(
          ({ start, end }) =>
            (start > range.from || end > range.from) &&
            !orderedLoadStates.find(
              (newState) => newState.start === start && newState.end === end
            )
        )
      : stateCopy[deviceid].loadStates.filter(
          ({ start, end }) =>
            !orderedLoadStates.find(
              (newState) => newState.start === start && newState.end === end
            )
        );

    // check if the last state received is older than the last state in the array
    // if it's, remove it
    let lastState = last(currentStates) || { start: 0 };
    if (orderedLoadStates[0].start <= lastState.start) {
      currentStates.pop();
      lastState = last(currentStates);
    }

    // then check if the new state received is the same status from the last stored
    // and update the state range
    if (lastState && lastState.state === orderedLoadStates[0].state) {
      // remove the lastState and update the new state with the start
      currentStates.pop();
      if (orderedLoadStates[0].end > lastState.start) {
        orderedLoadStates[0].start = lastState.start;
      }
    }

    currentStates.push(...orderedLoadStates);
    if (range && currentStates[0].start < range.from) {
      if (currentStates[0].end > range.from) {
        currentStates[0].start = range.from;
      }
    }

    let lastIndexFiltered = -1;
    stateCopy[deviceid].loadStates = currentStates.filter((item, index) => {
      const preview =
        lastIndexFiltered !== -1
          ? currentStates[lastIndexFiltered - 1]
          : currentStates[index - 1];
      const next = currentStates[index + 1];

      if (preview && preview.end !== item.start) {
        // skip this item in the next check since it was filtered
        if (lastIndexFiltered === -1) {
          lastIndexFiltered = index;
        }
        return false;
      }

      if (next && (next.start !== item.end || item.start === next?.start)) {
        // skip this item in the next check since it was filtered
        if (lastIndexFiltered === -1) {
          lastIndexFiltered = index;
        }
        return false;
      }

      lastIndexFiltered = -1;
      return true;
    });

    // stateCopy[deviceid].loadStates = currentStates;

    Vue.set(state.devicesData, duration, stateCopy);
  },
  SET_AVAILABLE_DEVICES: (state, { devices, customSorted }) => {
    const devicesArray = [];
    if (devices) {
      devices.forEach((device) => {
        devicesArray.push(device);
      });

      Vue.set(state, 'devices', devicesArray);
      if (customSorted) {
        Vue.set(state, 'customSorted', true);
      } else {
        Vue.set(state, 'customSorted', false);
      }
      // Cache devices to reduce requests...
      localStorage.globaldevices = JSON.stringify(devicesArray);
    }
  },
  SET_ALL_DEVICES: (state, { devices, customSorted }) => {
    const devicesArray = [];
    if (devices) {
      devices.forEach((device) => {
        // Add change cycle settings response
        // default settings object for all devices
        if (!device.settings) {
          device.settings = {};
        }

        // default cyclesCounting object if does not exists
        if (!device.settings.cyclesCounting) {
          device.settings.cyclesCounting = {};
        }

        device.settings.cyclesCounting = {
          // Current settings from response or {}
          ...device.settings.cyclesCounting,
          // Move current or default one level up
          ...(device.settings.cyclesCounting.current ||
            device.settings.cyclesCounting.default),
        };

        // Old saved settings doesn't have smoothing enabled key
        // if that is the case we initialize it so the components can react
        // to this property change
        if (
          typeof device.settings.cyclesCounting.smoothingEnabled === 'undefined'
        ) {
          device.settings.cyclesCounting.smoothingEnabled = false;
        }

        // delete current and default properies as we move it one level up
        delete device.settings.cyclesCounting.default;
        delete device.settings.cyclesCounting.current;
        // Not valid but some devices has it because of the old approach
        delete device.settings.cyclesCounting.custom;

        devicesArray.push(device);
      });

      Vue.set(state, 'allDevices', devicesArray);
      Vue.set(state, 'allDevicesById', keyBy(devicesArray, 'deviceid'));
      if (customSorted) {
        Vue.set(state, 'customSorted', true);
      } else {
        Vue.set(state, 'customSorted', false);
      }
    }
  },
  SET_ORDER_DEVICES: (state, devices) => {
    Vue.set(state, 'allDevices', devices);
    Vue.set(state, 'customSorted', true);
  },
  SET_DEFAULT_ORDER: (state, devices) => {
    Vue.set(state, 'allDevices', devices);
    Vue.set(state, 'customSorted', false);
  },
  SET_NEW_DEVICE: (state, item) => {
    Vue.set(state, 'devices', [...state.devices, { ...item, status: 'A' }]);
    Vue.set(state, 'allDevices', [
      ...state.allDevices,
      { ...item, status: 'A' },
    ]);
  },
  UPDATE_DEVICE: (state, data) => {
    // TODO: alei 2021jan21 - we should find the index based on data.newDevice.deviceid and
    // not just the passed in index for proper concurrency support
    // this prevents useto update state.devices because it has a different array length
    // ideally store/getters.js devices should be a derivative of allDevices
    // so allDevices is the only one which truly needs a state update
    if (data.index >= 0) {
      const allDevicesCopy = [...state.allDevices];
      Object.assign(allDevicesCopy[data.index], data.newDevice);
      Vue.set(state, 'allDevices', allDevicesCopy);
    }
  },
  UPDATE_DEVICE_CYCLE_SETTINGS: (state, { deviceId, newSettings }) => {
    const deviceIndex = state.allDevices.findIndex(
      (d) => d.deviceid === deviceId
    );
    if (!deviceIndex) return;

    if (!state.allDevices[deviceIndex].settings) {
      state.allDevices[deviceIndex].settings = {};
    }

    Vue.set(
      state.allDevices[deviceIndex].settings,
      'cyclesCounting',
      newSettings
    );

    // state.allDevices[deviceIndex].settings.cyclesCounting = { ...newSettings };
  },
  DEVICE_REMOVED: (state, deviceId) => {
    const devicesCopy = state.devices.slice();
    const indexOf = devicesCopy.findIndex((a) => a.deviceid === deviceId);
    if (indexOf !== -1) {
      devicesCopy.splice(indexOf, 1);
      Vue.set(state, 'devices', devicesCopy);
    }
  },
  SET_DEVICE_TYPES: (state, deviceTypes) => {
    const deviceTypesArray = [];
    if (deviceTypes) {
      deviceTypes.forEach((type) => {
        deviceTypesArray.push(type);
      });
    }
    Vue.set(state, 'deviceTypes', deviceTypesArray);
    // Cache device types as well to reduce requests...
    localStorage.globaldevicetypes = JSON.stringify(deviceTypesArray);
  },
  SET_METER_TYPES: (state, loadTypes) => {
    const meterTypesArray = [];
    if (loadTypes) {
      loadTypes.forEach((type) => {
        meterTypesArray.push(type);
      });
    }
    Vue.set(state, 'loadTypes', meterTypesArray);
  },
  SET_SELECTED_DEVICE: (state, selectedDevice) => {
    const newState = state;
    newState.selectedDevice = selectedDevice;
  },
  UPDATE_SELECTED_DEVICES: (state, selectedDevices) => {
    Vue.set(state, 'selectedDevices', selectedDevices);
  },
  UPDATE_SELECTED_METRICS: (state, selectedMetrics) => {
    Vue.set(state, 'selectedMetrics', selectedMetrics);
  },
  EMPTY_SELECTED_DEVICES: (state) => {
    Vue.set(state, 'selectedDevices', []);
  },
  UPDATE_SELECTED_FILTERS: (state, filters) => {
    Vue.set(state, 'selectedFilters', filters);
  },
  CLEAN_ALL_DEVICE_DATA: (state) => {
    Vue.set(state, 'devicesData', {
      hour: {},
      day: {},
      week: {},
      month: {},
    });
  },
  SET_MAKE_OPTIONS: (state, makeOptions) => {
    Vue.set(state.deviceDetailsOptions, 'make', makeOptions);
  },
  SET_MODEL_OPTIONS: (state, modelOptions) => {
    Vue.set(state.deviceDetailsOptions, 'model', modelOptions);
  },
  SET_PROCESS_OPTIONS: (state, processOptions) => {
    Vue.set(state.deviceDetailsOptions, 'process', processOptions);
  },
  SET_MATERIAL_OPTIONS: (state, materialOptions) => {
    Vue.set(state.deviceDetailsOptions, 'material', materialOptions);
  },
  SET_SENSOR_TYPE_OPTIONS: (state, sensorOptions) => {
    Vue.set(state.deviceDetailsOptions, 'sensor', sensorOptions);
  },
};
