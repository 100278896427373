import Vue from 'vue';
import { State, Unit } from '.';
import { SET_UNITS, SET_UNIT } from './mutations-types';

export default {
  [SET_UNITS](state: State, payload: Array<Unit>) {
    payload.forEach((unit) => {
      Vue.set(state, unit.id, unit);
    });
  },
  [SET_UNIT](state: State, unit: Unit) {
    Vue.set(state, unit.id, unit);
  },
};
