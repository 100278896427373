import axios from 'axios';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/state.ts';
import config from '@/config';
import { SET_DASHBOARD } from './mutations-types';
import { State, GetPayload } from './model';

export default {
  async getDashboard(
    context: ActionContext<State, RootState>,
    payload: GetPayload
  ) {
    const {
      excludedDays = [],
      excludedDevicesListIds = [],
      targetShiftPosition,
      from,
      to,
      viewId,
    } = payload;

    /* Your action content here */
    try {
      const {
        status,
        statusText,
        data: { response },
      } = await axios.get(
        config.dataProxyUrls.v2.dashboard.get({
          companyId: context.rootState.company,
          averages: [7, 30],
          excludedDays,
          excludedDevicesListIds,
          targetShiftPosition,
          viewId,
          from,
          to,
        })
      );

      if (status === 200) {
        context.commit(SET_DASHBOARD, response);
      } else {
        throw new Error(statusText);
      }
    } catch (e) {
      // no-op
    }
  },
  async getDashboardSegments(
    context: ActionContext<State, RootState>,
    payload
  ) {
    const { from, to, segments } = payload;
    const url = config.dataProxyUrls.v2.dashboard.post({
      from,
      to,
      companyId: context.rootState.company,
    });

    try {
      const {
        status,
        statusText,
        data: { response },
      } = await axios.post(url, segments);
      if (status !== 200) {
        throw new Error(statusText);
      }
      return response;
    } catch (e) {
      throw e;
    }
  },
};
