<template>
  <div>Index</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getLandingPages } from '@/helpers/pages';

export default {
  name: 'index',
  beforeMount() {
    const { landing_page: landingPage = 'uptime' } = this.loggedInUser || {};
    const page = this.availableLandingPages.find(
      ({ value }) => value === landingPage
    );

    if (page) {
      return this.$router.replace(page.path);
    }
    return this.$router.replace('/uptime');
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'getLoggedInUser',
    }),

    availableLandingPages() {
      return getLandingPages(this.$store.getters, this.$router.options.routes);
    },
  },
};
</script>
