export default {
  CHANGE_VIEW: ({ commit }, selectedView) => {
    commit('SET_SELECTED_VIEW', selectedView);
  },
  SHOW_SNACKBAR: ({ commit }, data) => {
    commit('UPDATE_ALERT', {
      alertMessage: data.alertMessage || '',
      alertType: data.alertType || 'success',
      alertVisible: true,
      actions: data.actions || [],
      class: data.class || '',
      forceShow: data.forceShow || false, // force to show even if the user is not logged in
      forceKeep: data.forceKeep,
    });
  },
  CLOSE_SNACKBAR: ({ commit }) => {
    commit('CLOSE_SNACKBAR');
  },
};
