import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';

export default {
  LOAD_PREVIOUS_BEST: ({ commit, state }, params) =>
    new Promise((res, rej) => {
      const url = `/api/v2/previousbest/${encodeURIComponent(params.deviceId)}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data && response.data.response) {
            commit('SET_PREVIOUS_BEST', response.data.response);
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
};
