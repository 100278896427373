// Import necessary modules
import bindEvents from '../utils/bindEvents.js';
import { getPropsValues, bindProps } from '../utils/bindProps.js';
import mapElementMixin from './mapElementMixin';

// Main export function, restructured to ES module format
export default function (options) {
  const {
    mappedProps,
    name,
    ctr,
    ctrArgs,
    events,
    beforeCreate,
    afterCreate,
    props,
    ...rest
  } = options;

  const promiseName = `$${name}Promise`;
  const instanceName = `$${name}Object`;

  assert(
    !(rest.props instanceof Array),
    '`props` should be an object, not Array'
  );

  return {
    ...(typeof GENERATE_DOC !== 'undefined' ? { $vgmOptions: options } : {}),
    mixins: [mapElementMixin],
    props: { ...props, ...mappedPropsToVueProps(mappedProps) },
    render() {
      return '';
    },
    provide() {
      const promise = this.$mapPromise
        .then((map) => {
          this.$map = map;

          const options = {
            ...this.options,
            map: map,
            ...getPropsValues(this, mappedProps),
          };
          delete options.options;

          if (beforeCreate) {
            const result = beforeCreate.bind(this)(options);
            if (result instanceof Promise) {
              return result.then(() => ({ options }));
            }
          }
          return { options };
        })
        .then(({ options }) => {
          const ConstructorObject = ctr();
          this[instanceName] = ctrArgs
            ? new ConstructorObject(
                ...ctrArgs(options, getPropsValues(this, props || {}))
              )
            : new ConstructorObject(options);

          bindProps(this, this[instanceName], mappedProps);
          bindEvents(this, this[instanceName], events);

          if (afterCreate) {
            afterCreate.bind(this)(this[instanceName]);
          }
          return this[instanceName];
        });

      this[promiseName] = promise;
      return { [promiseName]: promise };
    },
    destroyed() {
      if (this[instanceName] && this[instanceName].setMap) {
        this[instanceName].setMap(null);
      }
    },
    ...rest,
  };
}

// Helper function `mappedPropsToVueProps`, also exported
export function mappedPropsToVueProps(mappedProps) {
  return Object.entries(mappedProps)
    .map(([key, prop]) => {
      const value = {};
      if ('type' in prop) value.type = prop.type;
      if ('default' in prop) value.default = prop.default;
      if ('required' in prop) value.required = prop.required;
      return [key, value];
    })
    .reduce((acc, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {});
}

// Internal helper `assert` function
function assert(v, message) {
  if (!v) throw new Error(message);
}
