import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';

export default {
  GET_NOTIFICATIONS: ({ commit, state }, dateRange) =>
    new Promise((res, rej) => {
      const getUrl = `/api/notifications?from=${dateRange.from}&to=${dateRange.to}`;
      const { company } = state;
      const objectQuery = {};
      objectQuery.company = company;
      const urlEncodedQuery = objectQuery
        ? `?${Object.entries(objectQuery)
            .map(([key, val]) => `${key}=${val}`)
            .join('&')}`
        : '';
      axios
        .get(`${getUrl}${urlEncodedQuery}`, getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_NOTIFICATIONS', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  NEW_GET_NOTIFICATIONS: ({ commit, state }, dateRange) =>
    new Promise((res, rej) => {
      const getUrl = `/api/notifications/company/${state.company.toLowerCase()}?from=${dateRange.from}&to=${dateRange.to}`;
      axios
        .get(`${getUrl}`, getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_NEW_NOTIFICATIONS', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  SEND_NOTIFICATION: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const postUrl = '/api/notifications/send';
      axios
        .post(postUrl, data, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.status === 200) {
            res('SMS sent successfully!');
          } else {
            rej(new Error('Error sending SMS. Please try again.'));
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  CLEAR_NOTIFICATIONS: ({ commit }) => commit('CLEAR_NOTIFICATIONS'),
};
