import Vue from 'vue';
import { State, Setting, SettingPayload } from './model';
import { SET_SETTINGS, SET_SETTING } from './mutations-types';

export default {
  [SET_SETTINGS](state: State, payload: Array<SettingPayload>) {
    payload.forEach((setting) => {
      this[SET_SETTING](state, setting);
    });
  },
  [SET_SETTING](state: State, payload: SettingPayload) {
    Vue.set(state, payload.name, { ...state[payload.name], ...payload.value });
  },
};
