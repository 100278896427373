import axios, { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { CycleCountSetting, RootState } from '@/store/state';
import config from '@/config';
import {
  SET_CYCLES_SETTINGS_DATA,
  SET_CYCLES_SETTINGS,
  SET_CYCLES_PREVIEW_SETTINGS,
  SET_LOADING_STATE,
  SET_CYCLES_QUERY,
} from './mutations-types';
import { initialState, ResponseSettings, State } from './model';

export default {
  async getCyclesSettings(context: ActionContext<State, RootState>, payload) {
    /* Your action content here */
    try {
      context.commit(SET_LOADING_STATE, true);

      const { data } = await axios.get<{ response: ResponseSettings }>(
        config.dataProxyUrls.v2.cyclesStatistics.settings(),
        { params: payload }
      );

      context.commit(SET_CYCLES_SETTINGS_DATA, data.response);
      context.commit(SET_CYCLES_PREVIEW_SETTINGS, data.response.settings);
      context.commit(SET_CYCLES_QUERY, {
        deviceId: payload.deviceId,
        skuId: payload.skuId,
      });

      return data.response;
    } catch {
      return {};
    } finally {
      context.commit(SET_LOADING_STATE, false);
    }
  },
  updateCyclesSettings(
    context: ActionContext<State, RootState>,
    { query, settings }
  ) {
    context.commit(SET_CYCLES_QUERY, query);

    context.commit(SET_CYCLES_SETTINGS, settings);
    // Keep sync with the saved settings.
    context.commit(SET_CYCLES_PREVIEW_SETTINGS, settings);
  },
  updatePreviewSettings(
    context: ActionContext<State, RootState>,
    settings: CycleCountSetting
  ) {
    context.commit(SET_CYCLES_PREVIEW_SETTINGS, settings);
  },
};
