import axios from 'axios';

import getAxiosOpts from '@/helpers/axios.js';

export default {
  async GET_ROLES({ commit, state }) {
    if (state.roles.roles.length) return;
    try {
      const { data } = await axios.get(
        '/api/roles',
        getAxiosOpts(state.user.token)
      );
      if (data) {
        commit('SET_ROLES', data.response);
      } else {
        commit('SET_ROLES', []);
      }
    } catch (e) {
      commit('SET_ROLES', []);
    }
  },
};
