'use strict';

import mapElementFactory from './mapElementFactory.js';

const props = {
  bounds: {
    type: Object,
    twoWay: true,
  },
  draggable: {
    type: Boolean,
    default: false,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    twoWay: false,
  },
};

const events = [
  'click',
  'dblclick',
  'drag',
  'dragend',
  'dragstart',
  'mousedown',
  'mousemove',
  'mouseout',
  'mouseover',
  'mouseup',
  'rightclick',
];

export default mapElementFactory({
  mappedProps: props,
  name: 'rectangle',
  ctr() {
    return google.maps.Rectangle;
  },
  events: events,
});
