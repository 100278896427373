import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { initialState } from './model';

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
