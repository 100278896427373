import Vue from 'vue';
import {
  SET_LISTS,
  ADD_LIST,
  REMOVE_LIST,
  UPDATE_LIST,
  CLEAR_LIST,
} from './mutations-types';
import { initialState, State } from './model';

export default {
  [CLEAR_LIST](state: State, payload) {
    state = initialState;
  },
  [SET_LISTS](state: State, payload) {
    Vue.set(state, 'data', payload);
  },
  [ADD_LIST](state: State, payload) {
    const newState = [...state.data, payload];
    Vue.set(state, 'data', newState);
  },
  [REMOVE_LIST](state: State, payload) {
    const idx = state.data.findIndex((i) => i.id === payload);
    if (idx > -1) state.data.splice(idx, 1);
  },
  [UPDATE_LIST](state: State, payload) {
    const idx = state.data.findIndex((i) => i.id === payload.id);
    if (idx > -1) state.data.splice(idx, 1, payload);
  },
};
