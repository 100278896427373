import axios, { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import moment from 'moment';

import { RootState } from '@/store/state.ts';
import config from '@/config';

import {} from /* Your mutations names here */ './mutations-types';
import { State, Post } from './model';

export default {
  async getPosts(context: ActionContext<State, RootState>, payload) {
    const { company: companyId } = context.rootState;
    const url = config.dataProxyUrls.v2.posts.url({ companyId });
    const response = await axios.get(url);
  },
  async createPost(
    context: ActionContext<State, RootState>,
    payload: {
      content: Post['content'];
    }
  ) {
    const { company: companyId } = context.rootState;
    const url = config.dataProxyUrls.v2.posts.url({ companyId });

    try {
      // In order to create a new post we only need the content and a timestamp
      // the companyId and username are part of the user token and are added in dataProxy
      // automatically
      const response: AxiosResponse<{ response: Post }> = await axios.post(
        url,
        {
          timestamp: moment().valueOf(),
          content: payload.content,
        }
      );

      if (!response.data) {
        throw new Error(response.statusText);
      }

      // commit new post to feeds
      return Promise.resolve(response.data.response);
    } catch (e) {
      // commit error message
      return Promise.reject(e.message);
    }
  },
  async deletePost(context: ActionContext<State, RootState>, id: Post['id']) {
    const { company: companyId } = context.rootState;
    const url = config.dataProxyUrls.v2.posts.url({ id, companyId });

    try {
      const response = await axios({
        method: 'delete',
        url,
      });

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data.response;
    } catch (e) {
      return 'Cannot delete entry';
    }
  },
  async updatePost(context: ActionContext<State, RootState>, post: Post) {
    const { company: companyId } = context.rootState;
    const url = config.dataProxyUrls.v2.posts.url({ id: post.id, companyId });

    try {
      const response = await axios.put(url, {
        ...post,
        timestamp: moment().valueOf(),
      });

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data.response;
    } catch (e) {
      return 'Cannot update entry';
    }
  },
};
