export const SET_COMMENTS = 'SET_COMMENTS';
export const SET_COMMENT = 'SET_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export default {
  SET_COMMENTS,
  SET_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
};
