import log from 'loglevel';
import store from '../store';

type LogLevel = 'error' | 'warn' | 'info' | 'debug';

class LogWrapper {
  logger: log.Logger;
  logLabel: string;

  constructor(logger: log.Logger, logLabel?: string) {
    this.logger = logger;
    this.logLabel = logLabel || 'Log';
  }

  error(logObject) {
    this.log('error', logObject);
  }

  warn(logObject) {
    this.log('warn', logObject);
  }

  info(logObject) {
    this.log('info', logObject);
  }

  debug(logObject) {
    this.log('debug', logObject);
  }

  log(type, logObject) {
    // Explanation: need to keep this for clarity due to deep optional chaining

    const level = store?.state?.user?.loggedInUserInfo?.level;

    if (level !== null && level === 0) {
      // Since `loglevel` doesn't accept objects directly as messages, convert them if necessary
      const message =
        typeof logObject === 'object' ? JSON.stringify(logObject) : logObject;
      this.logger[type](`${this.logLabel}: ${message}`);
    }
  }
}

const createLogger = (logLabel: string, logLevel?: LogLevel) => {
  const logger = log.getLogger(logLabel);

  logger.setLevel((logLevel || 'debug') as log.LogLevelDesc);

  return new LogWrapper(logger, logLabel);
};

export default createLogger;
