import moment from 'moment';

export default class {
  constructor(
    timestamp,
    metricId,
    deviceId,
    receivedValue,
    calculatedValue,
    exceedsThreshold,
    exceedsTheoreticalLimit,
    deviation
  ) {
    this.timestamp = timestamp;
    this.strTimestamp = moment(Number(timestamp)).utc().format('hh:mm A');
    this.metricId = metricId;
    this.deviceId = deviceId;
    this.recValue = !Number.isNaN(parseFloat(receivedValue))
      ? parseFloat(Number(receivedValue)).toFixed(2)
      : null;
    this.calcValue = !Number.isNaN(parseFloat(calculatedValue))
      ? parseFloat(Number(calculatedValue)).toFixed(2)
      : null;
    this.excThreshold = exceedsThreshold;
    this.excThLimit = exceedsTheoreticalLimit;
    this.deviation = deviation;
  }
}
