export const SET_PRODUCTION_PERIODS = 'SET_PRODUCTION_PERIODS';
export const CLEAR_PRODUCTION_PERIODS = 'CLEAR_PRODUCTION_PERIODS';
export const SET_UPTIME = 'SET_UPTIME';
export const SET_PARSED_CSV = 'SET_PARSED_CSV';
export const ADD_PRODUCTION_PERIODS = 'ADD_PRODUCTION_PERIODS';
export const SET_CSV_SAVING_STATUS = 'SET_CSV_SAVING_STATUS';
export const SET_CSV_SAVED = 'SET_CSV_SAVED';
export const SET_CSV_SAVE_MESSAGE = 'SET_CSV_SAVE_MESSAGE';
export const SET_CSV_PARSE_STATUS = 'SET_CSV_PARSE_STATUS';
export const UPDATING_OEES = 'UPDATING_OEES';
export const UPDATING_OEES_DONE = 'UPDATING_OEES_DONE';
export const SET_UNSAVED_ENTRIES_STATUS = 'SET_UNSAVED_ENTRIES_STATUS';
export const SET_IN_PROGRESS_PRODUCTION_PERIODS =
  'SET_IN_PROGRESS_PRODUCTION_PERIODS';
export const LOADING_RESOURCE = 'LOADING_RESOURCE';
export const LOADING_RESOURCE_DONE = 'LOADING_RESOURCE_DONE';
export const UPDATE_PRODUCTION_ENTRY = 'UPDATE_PRODUCTION_ENTRY';
export const SET_CSV_MAPPING = 'SET_CSV_MAPPING';
export const SAVE_THROUGHPUT_MODEL = 'SAVE_THROUGHPUT_MODEL';
export const CLEAN_PRODUCTION_THROUGHPUT = 'CLEAN_PRODUCTION_THROUGHPUT';
export const UPDATE_EDIT_SCRAP = 'UPDATE_EDIT_SCRAP';
export const UPDATE_PRODUCTION_PERIOD_CREATE_UPDATE_MODAL =
  'UPDATE_PRODUCTION_PERIOD_CREATE_UPDATE_MODAL';
