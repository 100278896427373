import Vue from 'vue';
import { MUTATION_NAME } from './mutations-types';
import { initialState, State } from './model';

export default {
  [MUTATION_NAME](state: State, payload) {
    /* Your mutation code here */
    /* Don't forget to use Vue.set to update the state */
  },
};
