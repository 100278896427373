import {
  State,
  DEFAULT_SAFI_CSV_MAPPING,
  DEFAULT_SUMMARY_CONFIG,
  DEFAULT_DASHBOARD_CONFIG,
} from './model';

export default {
  getSettings:
    (state: State) =>
    (settingName: string = '') => {
      if (settingName) {
        return state[settingName];
      }
      return state;
    },
  getCompanySettings: (state: State) => state.company,
  getCsvMapping: (state: State) =>
    state.company ? state.company.csvMapping : DEFAULT_SAFI_CSV_MAPPING,
  getSummarySettings: (state: State) =>
    state.company ? state.company.summary : DEFAULT_SUMMARY_CONFIG,
  getDashboardSettings: (state: State) =>
    state.company ? state.company.dashboard : DEFAULT_DASHBOARD_CONFIG,
};
