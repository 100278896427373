import moment from 'moment';
import { detailedDiff } from 'deep-object-diff';

class Changelog {
  protected data: Array<any>;
  protected user: { username: String; companyid: String };
  protected action: ['I', 'U', 'D'];
  protected timestamp: Number;

  constructor({ user, data = [], action, timestamp }) {
    this.user = user;
    this.data = data;
    this.action = action;
    this.timestamp = timestamp || moment().valueOf();
  }

  addData(data) {
    const copy = { ...data };
    if (data.changelog) {
      // Clean data if changelog is already there, we want to store only real data
      delete copy.changelog;
    }
    this.data.push(copy);
  }

  dataDiff = (oldValue = {}, newValue = {}) => detailedDiff(oldValue, newValue);

  appendChangelogToEntry = (entry) => {
    if (!entry.changelog) {
      entry.changelog = [];
    }

    entry.changelog.push(this);
    return entry;
  };
}

export class ChangelogInsert extends Changelog {
  constructor(attrs) {
    super({ ...attrs, action: 'I' });
  }
}

export class ChangelogUpdate extends Changelog {
  constructor(attrs) {
    super({ ...attrs, action: 'U' });
  }

  /**
   * Call this to print out the changelog in a readable way
   */
  printChanges() {
    return `${this.data[0]}`;
  }
}

export class ChangelogDelete extends Changelog {
  constructor(attrs) {
    super({ ...attrs, action: 'D' });
  }
}

export class Changelogs {
  private changelogs: Array<Changelog>;
  constructor(changelogs = []) {
    this.changelogs = [];
    changelogs.forEach((changelog) => {
      const cl = new Changelog(changelog);
      this.changelogs.push(cl);
    });
  }

  getChangelogs() {
    return this.changelogs;
  }

  getLastIndex() {
    return this.changelogs.length - 1;
  }

  addDataToChangelog(index, data) {
    this.changelogs[index].addData(data);
  }
}
