import _ from 'lodash';
import moment from 'moment-timezone';
import { Filter, FilterTypes } from '@/store/modules/filters';
import { State, ProductionPeriod } from '@/store/modules/production/model';
import { applyFiltersToData } from '@/helpers/filters';
import { findShiftOfProductionPeriod } from '@/helpers/productionPeriods';
import { getPeriodStatus } from './helpers';

export default {
  getFilteredProductionPeriods(state: State, getters, rootState, rootGetters) {
    // Filter production entries with local filters
    // Probably the best place to do the mapping is in the backend
    // but for now we keep it here

    let filteredData: Array<ProductionPeriod> = state.periods.all;

    const localFilters: Array<Filter> = rootGetters['filters/getFiltersByType'](
      FilterTypes.LOCAL
    );

    if (localFilters.length) {
      filteredData = applyFiltersToData<ProductionPeriod>(
        localFilters,
        filteredData
      );
    }

    return filteredData;
  },
  getProduction(state: State, getters) {
    return getters.getFilteredProductionPeriods.map(
      getters.getPeriodWithShiftInfo
    );
  },
  getAllProduction(state: State) {
    return state.periods.all;
  },
  getProductionById: (state: State, getters) => (id) =>
    getters.getFilteredProductionPeriods.find((pp) => pp.id === id),
  getProductionPeriodThreshold:
    (state: State, getters, rootState, rootGetters) =>
    (parameter: string, value) => {
      const { productionPeriodsThresholds } =
        rootGetters['settings/getCompanySettings'];

      if (!productionPeriodsThresholds[parameter]) {
        return null;
      }

      const threshold = productionPeriodsThresholds[parameter].find((th) =>
        th.validate(value)
      );

      return threshold || productionPeriodsThresholds[parameter][0];
    },
  getPeriodDeviceInfo:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) =>
      rootGetters.getDeviceById(period.device_id),
  getPeriodWithDeviceInfo:
    (state: State, getters) => (period: ProductionPeriod) => {
      const device = getters.getPeriodDeviceInfo(period);
      return { ...period, device };
    },
  getPeriodWithShiftInfo:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      const shifts = rootGetters['shifts/getShifts']();
      const shift = findShiftOfProductionPeriod(
        period,
        shifts,
        rootGetters.getCompanyTimezone
      );
      return { ...period, shift };
    },
  getPeriodsGroupedByDeviceId: (state: State, getters) =>
    getters.getProduction.reduce((grouped, period: ProductionPeriod) => {
      const { device_id } = period;
      const group = grouped[device_id] || [];
      group.push(
        getters.getPeriodWithShiftInfo(getters.getPeriodWithDeviceInfo(period))
      );

      return {
        ...grouped,
        [device_id]: group,
      };
    }, {}),
  getPeriodsGroupedByShiftId: (state: State, getters) => {
    const periodsWithExtraInfo = getters.getProduction.map((period) =>
      getters.getPeriodWithShiftInfo(getters.getPeriodWithDeviceInfo(period))
    );

    return _.groupBy(periodsWithExtraInfo, 'shift.id');
  },
  getComments: (state: State, getters, rootState, rootGetters) => (id) =>
    rootGetters['comments/getComments']('production_entries', id),
  getPeriodsGroupedByDay: (state: State, getters, rootState, rootGetters) =>
    _.groupBy(getters.getProduction, (period) =>
      moment(period.duration.production.from)
        .tz(rootGetters.getCompanyTimezone)
        .startOf('day')
        .valueOf()
    ),
  getParsedCsvData: (state) => state.parsedCsv,
  getParseCsvStatus: (state) => state.csvParsed,
  getSavingParsedCsvStatus: (state) => state.savingCsvData,
  parsedCsvSaved: (state) => state.parsedCsvDataSaved,
  csvSaveMessage: (state) => state.csvSaveMessage,
  getUpdatingOEEsLoadingState: (state: State) => state.updatingOEEs,
  getUnSavedEntriesState: (state: State) => state.unSavedEntries,
  // This getter is basically the same than getPeriods but works with the new state
  // structure that is grouping the periods by status so first we get the periods from
  // the state and then we apply the local filters
  getProductionByStatus:
    (state: State, getters, rootState, rootGetters) =>
    (status): Array<ProductionPeriod> => {
      if (!status) return [];

      // Apply local filters if defined
      const localFilters: Array<Filter> = rootGetters[
        'filters/getFiltersByType'
      ](FilterTypes.LOCAL);

      if (localFilters.length) {
        if (status === 'inProgress') {
          return applyFiltersToData<ProductionPeriod>(
            localFilters,
            state.periods.inProgress.data
          );
        }

        return applyFiltersToData<ProductionPeriod>(
          localFilters,
          state.periods[status]
        );
      }

      if (status === 'inProgress') {
        return state.periods[status].data;
      }

      return state.periods[status];
    },
  getPeriodStatus: (state: State) => (period) => getPeriodStatus(period),
  getDefaultProductionUnitId:
    (state: State, getters, rootState, rootGetters) => (order) => {
      const companySettings = rootGetters['settings/getCompanySettings'];
      return companySettings.production.production[order].unit;
    },
  getInProgressLoadingStatus: (state: State) =>
    state.periods.inProgress.loading,
  /**
   * A production entry performance is based on cycles if it meet a few conditions
   * 1. the cycles feature is enabled for the company
   * 2. the performanceBasedOnCycles setting is enabled
   * 3. the device or device/sku combination type is cycle
   */
  isPerformanceBasedOnCycles:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      return (
        getters.getUseCyclesEstimations(period) &&
        rootGetters['settings/getCompanySettings'].production
          .performanceBasedOnCycles
      );
    },
  getProductionTargetBasedOnCycles:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      const rawData = getters.getProductionPeriodThroughputModel(period.id);
      const { seriesData: { targetSerie = [] } = {} } = rawData || {};
      return targetSerie?.[targetSerie?.length - 1]?.y || null;
    },
  getProductionTarget:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      if (getters.isPerformanceBasedOnCycles(period)) {
        return getters.getProductionTargetBasedOnCycles(period);
      }

      return period.production.target;
    },
  getProductionPeriodThroughputModel: (state: State) => (id) =>
    state.throughputModels[id],
  getAllInProgressProduction: (state: State) => state.periods.inProgress.data,
  getInProgressProductionByDeviceId: (state: State) => (deviceId) =>
    _.find(state.periods.inProgress.data, { device_id: deviceId }),
  getBatchFilters: (state: State, getters, rootState, rootGetters) => {
    const productionPeriods = getters.getAllProduction;
    return productionPeriods
      .map((pp) => pp.details?.batch)
      ?.filter(Boolean)
      ?.sort((a, b) => a.localeCompare(b))
      ?.map((batch) => ({
        id: batch,
        name: batch,
      }));
  },
  getUsesAdditionalSensor:
    (state: State, getters, rootState, rootGetters) => (deviceId: string) => {
      const additionalSensorMetricData =
        rootGetters['getAdditionalSensorMetricData'](deviceId);
      return additionalSensorMetricData?.usesAdditionalSensor;
    },
  getIsCycleDevice:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      const { device_id: deviceId, sku } = period;

      const usesAdditionalSensor = getters.getUsesAdditionalSensor(deviceId);
      return (
        !usesAdditionalSensor &&
        rootGetters.getRelationSettings(deviceId, sku).type === 'cyclesCounting'
      );
    },
  getUseCyclesEstimations:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) =>
      getters.getIsCycleDevice(period) &&
      rootGetters.isFeatureEnabled('cycles-menubar'),
  /**
   * As the production qty could be either entered by the user or estimated in different ways
   * we need this computed property to present the right value to the user.
   */
  getDerivedProductionQty:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      const { cycles_info: cyclesInfo, production } = period;

      const { produced } = production;

      let estimatedValue;

      // if it is a cycle device and we computed the qty based on cycles then we use that value
      if (getters.getUseCyclesEstimations(period)) {
        estimatedValue = {
          value: _.get(cyclesInfo, 'summary.totalUnits', null),
          isEstimated: true,
          source: 'CYCLES_ESTIMATE',
        };
      } else {
        // production qty could also be calculated using the throughput prediction
        // this returns either a value or null that we parse later using the `formatAmount` function
        estimatedValue = {
          value: period.calculated_qty,
          isEstimated: true,
          source: 'SPEED_ESTIMATE',
        };
      }

      // If the user entered a production qty this should be shown no matter what.
      if (!_.isNil(produced)) {
        return {
          value: produced,
          isEstimated: false,
          source: 'MANUAL_ENTRY',
          estimatedValue,
        };
      }

      return estimatedValue;
    },
  getIsEstimatedWasteEnabled:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      const settings = rootGetters['settings/getCompanySettings'];
      const usesAdditionalSensor = getters.getUsesAdditionalSensor(
        period.device_id
      );
      return (
        (getters.getUseCyclesEstimations(period) || usesAdditionalSensor) &&
        settings.estimatedWaste
      );
    },
  getDerivedWaste:
    (state: State, getters, rootState, rootGetters) =>
    (period: ProductionPeriod) => {
      const isEstimatedWasteEnabled =
        getters.getIsEstimatedWasteEnabled(period);
      const scraps = rootGetters['scrap/getScrapsForProductionEntry'](period);
      const wasteFromScraps =
        scraps.reduce((acc, scrap) => acc + +scrap.amount, 0) || null;
      const manualEntry = {
        value: wasteFromScraps,
        isEstimated: false,
        source: 'MANUAL_ENTRY',
      };
      if (!isEstimatedWasteEnabled) {
        return manualEntry;
      }

      const derivedProductionQty = getters.getDerivedProductionQty(
        period,
        true
      );
      if (!derivedProductionQty.estimatedValue) {
        return manualEntry;
      }

      const estimatedValueQty =
        derivedProductionQty.estimatedValue || derivedProductionQty;

      const estimate = Number(estimatedValueQty?.value) || 0;
      const actual = Number(derivedProductionQty?.value) || 0;
      /*
       * If the estimated value is greater than the actual value, the waste is set to the
       *    difference between the two.
       * If the estimated value is less than the actual value, the waste is considered null.
       */
      const estimatedWaste =
        estimate < actual ? null : Math.max(estimate - actual, 0) || 0;

      const estimatedEntry = {
        value: estimatedWaste,
        isEstimated: true,
        source: 'ESTIMATED',
      };

      const waste =
        _.isNil(period?.waste?.wasted) && isEstimatedWasteEnabled
          ? estimatedEntry
          : manualEntry;

      return {
        ...waste,
        estimatedValue: estimatedEntry,
      };
    },
};
