import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';
import { LineRule, LineRuleParams } from './model';

export default {
  find: (
    {
      commit,
      getters,
      rootState: { allDevices = [], company, metricsGroupedByDevices, user },
    },
    params: LineRuleParams
  ) => {
    const lineRule = getters.getLineRule(params);

    if (lineRule) return lineRule;

    const { lineRuleId, lineRuleVersion } = params;

    return new Promise((res, rej) => {
      const qs = `companyId=${company}&version=${lineRuleVersion}`;
      const url = `/api/line-rules/${lineRuleId}?${qs}`;

      axios
        .get(url, getAxiosOpts(user.token))
        .then((response) => {
          const resLineRule = response?.data?.response;

          commit('SET_LINE_RULE_WITH_HISTORY', {
            allDevices,
            metricsGroupedByDevices,
            lineRule: resLineRule,
          });

          res(resLineRule);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  findAll: ({
    commit,
    rootState: {
      allDevices = [],
      company,
      lineRules = [],
      metricsGroupedByDevices,
      user,
    },
  }) => {
    if (lineRules.length) return Promise.resolve();

    return new Promise((res, rej) => {
      const url = `/api/line-rules?companyId=${company}`;

      axios
        .get(url, getAxiosOpts(user.token))
        .then((response) => {
          const resLineRules = response?.data?.response;

          commit('SET_LINE_RULES', {
            allDevices,
            metricsGroupedByDevices,
            lineRules: resLineRules,
          });

          res(resLineRules);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  create: (
    {
      commit,
      dispatch,
      rootState: { allDevices = [], metricsGroupedByDevices, user },
    },
    lineRule: LineRule
  ) =>
    new Promise((res, rej) => {
      const url = '/api/line-rules';

      axios
        .post(url, lineRule, getAxiosOpts(user.token))
        .then((response) => {
          const resLineRule = response?.data?.response;

          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Alert created successfully.',
            },
            { root: true }
          );

          commit('SET_CREATE_LINE_RULE', {
            allDevices,
            metricsGroupedByDevices,
            lineRule: resLineRule,
          });

          res(resLineRule);
        })
        .catch((err) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Error creating alert.',
              alertType: 'error',
            },
            { root: true }
          );
          rej(err);
        });
    }),
  update: (
    {
      commit,
      dispatch,
      rootState: { allDevices = [], metricsGroupedByDevices, user },
    },
    lineRule: LineRule
  ) =>
    new Promise((res, rej) => {
      const url = `/api/line-rules/${lineRule.id}`;

      axios
        .put(url, lineRule, getAxiosOpts(user.token))
        .then((response) => {
          const resLineRule = response?.data?.response;

          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Alert updated successfully.',
            },
            { root: true }
          );

          commit('SET_EDIT_LINE_RULE', {
            allDevices,
            metricsGroupedByDevices,
            lineRule: resLineRule,
          });

          res(resLineRule);
        })
        .catch((err) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Error updating alert.',
              alertType: 'error',
            },
            { root: true }
          );
          rej(err);
        });
    }),
  delete: (
    { commit, dispatch, rootState: { company, user } },
    lineRule: LineRule
  ) =>
    new Promise((res, rej) => {
      const url = `/api/line-rules/${lineRule.id}?companyId=${company}`;

      axios
        .delete(url, getAxiosOpts(user.token))
        .then((response) => {
          const resLineRule = response?.data?.response;

          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Alert deleted successfully.',
            },
            { root: true }
          );

          commit('SET_DELETE_LINE_RULE', resLineRule);

          res(resLineRule);
        })
        .catch((err) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Error deleting alert.',
              alertType: 'error',
            },
            { root: true }
          );
        });
    }),
};
