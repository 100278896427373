import Vue from 'vue';

export default {
  SET_ROLES: (state, roles) => {
    const rolesArray = [];
    if (roles) {
      roles.forEach((role) => {
        rolesArray.push(role);
      });
    }
    Vue.set(state.roles, 'roles', rolesArray);
  },
};
