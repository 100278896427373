import { get } from 'lodash';
import UserLevels from '@/constants/UserLevels';
import { State } from './model';

const sortNames = ({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB, undefined, { numeric: true });
};

export default {
  devicesLists(state: State, getters, rootState, rootGetters) {
    return (state.data || []).sort(sortNames);
  },
  currentDeviceList(state: State, getters, rootState, rootGetters) {
    if (rootGetters.getLoggedInUser) {
      const { permissions } = rootGetters.getLoggedInUser;
      if (permissions && permissions.devicesLists) {
        const { selectedListId } = permissions.devicesLists;
        if (
          selectedListId &&
          rootState.devicesLists &&
          rootState.devicesLists.data
        ) {
          const { data } = rootState.devicesLists;
          const deviceList = data.find((list) => list.id === selectedListId);
          return deviceList;
        }
      }
    }
    return null;
  },
  allowedListsIds(state: State, getters, rootState, rootGetters) {
    const { loggedInUserInfo } = rootState.user;

    if (loggedInUserInfo) {
      const { permissions, level } = loggedInUserInfo;
      const allDeviceListIds = getters.devicesLists.map(({ id }) => id);

      // managers, engineers, and recipients can be restricted to some device lists
      if (level > UserLevels.ADMIN) {
        const userDeviceListIdsRestrictedTo = get(
          permissions,
          'devicesLists.listsIds',
          []
        );

        // user has device list restrictions
        if (userDeviceListIdsRestrictedTo.length > 0)
          return userDeviceListIdsRestrictedTo;
      }

      // safi, admin, and users without device list restrictions can see all device lists
      return allDeviceListIds;
    }

    return [];
  },
  allowedLists(state: State, getters, rootState, rootGetters) {
    return getters.devicesLists.filter(({ id }) =>
      getters.allowedListsIds.includes(id)
    );
  },
};
