export const LOAD_VIEWS = 'LOAD_VIEWS';
export const DELETE_VIEWS = 'DELETE_VIEWS';
export const ADD_VIEW = 'ADD_VIEW';
export const UPDATE_VIEW = 'UPDATE_VIEW';
export const LOADING_VIEW = 'LOADING_VIEW';

export default {
  LOAD_VIEWS,
  DELETE_VIEWS,
  ADD_VIEW,
  UPDATE_VIEW,
  LOADING_VIEW,
};
