import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export interface Tag {
  id: string;
  companyid: string;
  tagname: string;
  tagtype: string;
  tagplanned: number;
  tagcolor?: string;
  data?: object | null;
}

export interface State {
  [key: string]: Array<Tag>;
}

const initialState = {
  ml: [],
  production_entries: [],
  issue_details: [],
  default: [],
  // Support other tag types here
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
