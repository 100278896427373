'use strict';

// This piece of code was originally written by sindresorhus and can be seen here
// https://github.com/sindresorhus/lazy-value/blob/master/index.js

export default function (fn) {
  let called = false;
  let ret;

  return function () {
    if (!called) {
      called = true;
      ret = fn();
    }
    return ret;
  };
}
