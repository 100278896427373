import { State, Filter, FilterTypes } from './';

export default {
  getFilters:
    (state: State) =>
    (filterName: string = '') => {
      if (filterName) {
        return state.items[filterName];
      }
      return Object.values(state.items);
    },
  getFiltersByType: (state: State) => (filterType: FilterTypes, resource) =>
    Object.values<Filter>(state.items).filter((filter) => {
      if (filter.version === 2) {
        return (
          filter.type === filterType &&
          (filter.resources?.includes?.(resource) ||
            filter.resources?.length === 0)
        );
      }
      return filter.type === filterType;
    }),
  getFiltersByNotType: (state: State) => (filterType: FilterTypes, resource) =>
    Object.values<Filter>(state.items).filter((filter) => {
      if (filter.version === 2) {
        return (
          filter.type !== filterType &&
          (filter.resources?.includes?.(resource) ||
            filter.resources?.length === 0)
        );
      }
      return filter.type !== filterType;
    }),
  getChangedFilter: (state: State) =>
    state.filterChanged ? state.items[state.filterChanged] : null,
  getChangedFilterType: (state: State) => state.filterChangedType || null,
  getChangedFilterName: (state: State) => state.filterChanged,
  getFilterList: (state: State) => (filterType: string) => state[filterType],
  getFilterState:
    (state: State) =>
    (filterName: string = '') =>
      state.states[filterName] || {},
  updateFilter: (state: State) => state.updateFilter,
};
