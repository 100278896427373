import bills from './bills';
import exportData from './exportData';
import productionPeriod from './productionPeriod';
import production from './production';
import filters from './filters';
import shifts from './shifts';
import settings from './settings';
import units from './units';
import comments from './comments';
import staff from './staff';
import tags from './tags';
import notifprofiles from './notifprofiles';
import devicesLoadStates from './devicesLoadStates';
import issues from './issues';
import feeds from './feeds';
import posts from './posts';
import notificationBar from './notificationBar';
import devicesLists from './devicesLists';
import emailReports from './emailReports';
import dashboard from './dashboard';
import deviceStates from './deviceStates';
import deviceLabelledStates from './deviceLabelledStates';
import deviceSchedule from './deviceSchedule';
import cyclesSettings from './cyclesSettings';
import dashboardViews from './dashboardViews';
import maintenance from './maintenance';
import urlShorter from './urlShorter';
import anomaly from './anomaly';
import scrap from './scrap';
import lineRules from './lineRules';
import lineIssueDetails from './lineIssueDetails';

export default {
  bills,
  exportData,
  productionPeriod,
  production,
  filters,
  shifts,
  settings,
  units,
  comments,
  staff,
  tags,
  notifprofiles,
  devicesLoadStates,
  issues,
  feeds,
  posts,
  notificationBar,
  devicesLists,
  emailReports,
  dashboard,
  deviceStates,
  deviceSchedule,
  deviceLabelledStates,
  cyclesSettings,
  dashboardViews,
  maintenance,
  urlShorter,
  anomaly,
  scrap,
  lineRules,
  lineIssueDetails,
};
