import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const initialState = { staff: [] };

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
