import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';

export default {
  GET_ALERTS: ({ commit, state }) => {
    if (state.alerts.length) return Promise.resolve();
    return new Promise((res, rej) => {
      const url = `/api/rules?companyid=${state.company}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_ALERTS', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  NEW_CREATE_ALERT: ({ commit, state, dispatch }, alert) =>
    new Promise((res, rej) => {
      const url = '/api/rules';
      axios
        .post(url, alert, getAxiosOpts(state.user.token))
        .then((response) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Alert(s) created successfully.',
            },
            { root: true }
          );
          commit('SET_NEW_CREATE_ALERT', response.data.response);
          res();
        })
        .catch((err) => {
          dispatch('SHOW_SNACKBAR', {
            alertMessage: 'Sorry an error occurred. Please try again.',
            alertType: 'error',
          });
          rej(err);
        });
    }),
  NEW_EDIT_ALERT: ({ commit, state, dispatch }, { alert, deleted }) =>
    new Promise((res, rej) => {
      const url = '/api/rules';
      axios
        .put(url, alert, getAxiosOpts(state.user.token))
        .then((response) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: `Alert ${deleted ? 'deleted' : 'updated'} successfully.`,
            },
            { root: true }
          );
          const { status, thresholds } = response.data.response;
          commit('SET_NEW_EDIT_ALERT', { status, ...alert, thresholds });
          res();
        })
        .catch((err) => {
          dispatch('SHOW_SNACKBAR', {
            alertMessage: 'Sorry an error occurred. Please try again.',
            alertType: 'error',
          });
          rej(err);
        });
    }),
  NEW_DELETE_ALERT: ({ commit, state }, alert) =>
    new Promise((res, rej) => {
      const url = `/api/rules/${alert.id}`;
      axios
        .delete(url, getAxiosOpts(state.user.token))
        .then(() => {
          commit('SET_NEW_DELETE_ALERT', alert);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  NEW_CLEAR_ALERT: ({ commit }, alert) => {
    commit('SET_NEW_CLEAR_ALERT', alert);
  },
  GET_CURRENT_ALERTS: (context, deviceId) =>
    new Promise((res, rej) => {
      let getAlertsUrl;
      if (context.state.user.loggedInUserInfo.level === 0) {
        getAlertsUrl = `/api/alerts/device/${deviceId}`;
      } else {
        getAlertsUrl = `/api/alerts/device/${deviceId}?companyId=${context.state.company}`;
      }
      axios
        .get(getAlertsUrl, getAxiosOpts(context.state.user.token))
        .then((response) => {
          context.commit('SET_CURRENT_ALERTS', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  CREATE_ALERT: (context, alert) => {
    const alertToSend = Object.assign({}, alert, {
      companyid: context.state.company,
    });
    return new Promise((res, rej) => {
      axios
        .post(
          '/api/alerts',
          { alert: alertToSend },
          getAxiosOpts(context.state.user.token)
        )
        .then((result) => {
          context.commit(
            'SET_NEW_ALERT',
            Object.assign({}, alertToSend, { id: result.data.id })
          );
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  EDIT_ALERT: (context, data) => {
    const alertToSend = Object.assign({}, data.alert, {
      companyid: context.state.company,
    });
    return new Promise((res, rej) => {
      axios
        .put(
          '/api/alerts',
          { alert: alertToSend },
          getAxiosOpts(context.state.user.token)
        )
        .then(() => {
          context.commit('UPDATE_ALERT_VALUE', {
            alert: alertToSend,
            index: data.index,
          });
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  DEACTIVATE_ALERT: (context, data) => {
    const alertToSend = Object.assign({}, data.alert, {
      companyid: context.state.company,
    });
    return new Promise((res, rej) => {
      axios
        .put(
          `/api/alerts/${alertToSend.id}`,
          {},
          getAxiosOpts(context.state.user.token)
        )
        .then(() => {
          context.commit('REMOVE_ALERT', data.index);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  SEND_TEST_SMS: ({ commit }, message) =>
    new Promise((res, rej) => {
      axios
        .post('/api/alerts/test/testMessage', {
          message,
        })
        .then(() => {
          commit('TEST_MESSAGE_SENT', true);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_ALERTS_WIDGET_INFO: ({ commit, state }, { companyid, from, to, range }) =>
    new Promise((res, rej) => {
      commit('SET_ALERTS_WIDGET_LOADING', true);
      commit('SET_ALERTS_WIDGET_INFO', {
        payload: {
          alertsCount: 0,
          change: {},
          topAlerts: [],
        },
        range,
      });
      const url = state.isFetchedFromCache
        ? `/api/home/${companyid}?range=${range.toLocaleLowerCase()}&widget=${'alerts'}`
        : `/api/alerts/home/${companyid}/${from}/${to}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_ALERTS_WIDGET_INFO', {
            payload: state.isFetchedFromCache
              ? response.data
              : response.data.response,
            range,
          });
          commit('SET_ALERTS_WIDGET_LOADING', false);
          res();
        })
        .catch((err) => {
          commit('SET_ALERTS_WIDGET_LOADING', false);
          rej(err);
        });
    }),
  BULK_EDIT: async ({ commit, dispatch, rootState: { user } }, payload) => {
    const url = '/api/rules/bulk-edit';
    try {
      const {
        data: { response },
      } = await axios.post(url, payload, getAxiosOpts(user.token));
      commit('BULK_EDIT', response);
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Alerts updated successfully.',
        },
        { root: true }
      );
      return response;
    } catch {
      dispatch('SHOW_SNACKBAR', {
        alertMessage: 'Sorry an error occurred. Please try again.',
        alertType: 'error',
      });
      return Promise.resolve();
    }
  },
};
