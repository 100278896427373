import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export interface NotificationProfile {
  id: string;
  resource_name: string;
  profile_type: string;
  status: number;
  primary_channel: string;
  backup_channel: string;
  created_by: string;
  active: boolean;
  display_name: string;
  description: string;
  createdat: string;
  updatedat: string;
}

export interface State {
  notificationProfile: NotificationProfile;
}

const initialState = {};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
