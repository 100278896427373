import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import io from 'socket.io-client';

import config from '@/config';
import getAxiosOpts from '@/helpers/axios.js';
import { company } from '../state';

export default {
  CLEAR_UPTIME_DATA: ({ commit }) => {
    commit('CLEAR_UPTIME_DATA');
  },
  GET_UPTIME_SHIFT_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      if (data.device) {
        axios
          .get(
            `/api/devices/${company}/${data.device || null}/uptimeshifts?from=${data.fromDate}&to=${data.toDate}&shiftstarttime=${data.starttime}&shiftduration=${data.duration}&showbyduration=${data.isDuration}&metric=${data.metric}`,
            getAxiosOpts(state.user.token)
          )
          .then((response) => {
            if (
              response.data &&
              response.data.response &&
              response.data.response.length > 0
            ) {
              response.data.response.push([
                {
                  extras: {
                    fromDate: data.fromDate,
                    toDate: data.toDate,
                    shiftStart: data.starttime,
                    shiftDuration: data.duration,
                    metric: data.metric,
                  },
                },
              ]);
              commit('SET_UPTIME_SHIFT_DATA', response.data.response);
            }
            res();
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        // Do the main connection with socket.io
        let allUptimeArr = [];
        let progressCount = 0;
        const vueState = state;
        const progressTotal =
          state.devices.length *
          moment
            .duration(moment(data.toDate).diff(moment(data.fromDate)))
            .asDays();
        const socket = io(`${config.dataProxyUrl}/sockets/uptimeallshifts`, {
          query: `token=${state.user.token}`,
          transports: ['websocket', 'polling'],
          reconnectionAttempts: 5,
        });
        socket.emit('getuptimeshiftdata', {
          data: { ...data, user: state.user.loggedInUserInfo },
        });
        socket.on('uptimedata', (updata) => {
          if (updata) {
            allUptimeArr.push(updata);
            progressCount += 1;
            vueState.progressUptime = (progressCount / progressTotal) * 100;
          }
        });
        // Commit once we receive all data
        socket.on('updone', () => {
          if (allUptimeArr.length > 0) {
            allUptimeArr = _(allUptimeArr)
              .groupBy('deviceid')
              .map((up) => [..._.orderBy(up, ['datefrom'], ['asc'])])
              .value();
            allUptimeArr.push([
              {
                extras: {
                  fromDate: data.fromDate,
                  toDate: data.toDate,
                  shiftStart: data.starttime,
                  shiftDuration: data.duration,
                  metric: data.metric,
                },
              },
            ]);
            commit('SET_UPTIME_SHIFT_DATA', allUptimeArr);
          }
          socket.close();
          vueState.progressUptime = 0;
          res();
        });
        // Capture any errors
        socket.on('error', (err) => {
          if (
            err.type === 'UnauthorizedError' ||
            err.code === 'invalid_token'
          ) {
            socket.close();
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          } else {
            socket.close();
            vueState.progressUptime = 0;
            rej(new Error(err));
          }
        });
        // Capture invalid token
        socket.on('unauthorized', (error) => {
          if (
            error.data.type === 'UnauthorizedError' ||
            error.data.code === 'invalid_token'
          ) {
            socket.close();
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          }
        });
      }
    }),
  GET_CACHED_UPTIME: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const authOptions = getAxiosOpts(state.user.token);
      const { rangeLabel, fromDate, toDate, metric } = data;
      const url = `api/home/${state.company}`;
      const params = {
        range: rangeLabel.toLocaleLowerCase(),
        widget: 'uptime',
      };
      axios
        .get(url, {
          params,
          ...authOptions,
        })
        .then((response) => {
          const { chart, stats } = response.data;
          const { response: uptimeData } = chart;
          uptimeData.push([
            {
              extras: {
                fromDate,
                toDate,
                metric,
              },
            },
          ]);
          commit('SET_UPTIME_DATA_ALL', {
            uptimeData,
            rangeLabel: rangeLabel.toLocaleLowerCase(),
          });
          const { idlePercentage, uptimePercentage } = stats;
          commit('SET_UPTIME_PREV_AVG', {
            payload: {
              idlePercentage,
              uptimePercentage,
            },
            range: rangeLabel.toLocaleLowerCase(),
          });

          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_UPTIME_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const {
        device,
        fromDate,
        toDate,
        isDuration,
        metric,
        isShift,
        isgroup,
        rangeLabel,
        devices,
      } = data;
      axios
        .get(
          `/api/devices/${company}/${device || null}/uptime?from=${fromDate}&to=${toDate}&showbyduration=${isDuration}&isshift=${isShift}&metric=${metric}&isgroup=${isgroup}`,
          {
            params: { devices },
            ...getAxiosOpts(state.user.token),
          }
        )
        .then((response) => {
          if (
            response.data &&
            response.data.response &&
            response.data.response.length > 0
          ) {
            const uptimeData = response.data.response;
            uptimeData.push([
              {
                extras: {
                  fromDate,
                  toDate,
                  metric,
                },
              },
            ]);
            const commitTag = device
              ? 'SET_UPTIME_DATA'
              : 'SET_UPTIME_DATA_ALL';
            commit(commitTag, {
              uptimeData,
              rangeLabel,
            });
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_UPTIME_PREV_AVG: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const { from, to, rangeLabel: range, devices } = data;

      axios
        .get(`/api/uptime-stats/${state.company}/${from}/${to}`, {
          params: {
            devices,
          },
          ...getAxiosOpts(state.user.token),
        })
        .then((response) => {
          if (response.data && response.data.response) {
            const { idlePercentage, uptimePercentage } = response.data;
            commit('SET_UPTIME_PREV_AVG', {
              payload: {
                idlePercentage,
                uptimePercentage,
              },
              range,
            });
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
};
