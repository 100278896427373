export interface Dashboard {
  // if we want to support dynamic days for averages, use something like Group1...GroupN?
  average2DayFollowing: number;
  average7DayFollowing: number;
  average30DayFollowing: number;
  // current value of the last completed shift
  runtime: number;
  listId: String;
  listName: String;
  includedShifts: String;
  startTS: String;
  // tz: String;
}

export interface State {
  loading: boolean;
  data: Array<Dashboard>;
  lastUpdate: number;
}

export interface GetPayload {
  /** @default [7, 30] */
  // averages: number[];
  targetShiftPosition: number;
  excludedDevicesListIds?: number[];
  excludedDays?: number[];
  viewId: string;
  from?: number;
  to?: number;
}

export const initialState: State = {
  loading: false,
  data: [],
  lastUpdate: 0,
};

export default initialState;
