export default class Thresholds {
  public name: string;
  public color: string;
  public minValue: any;
  public maxValue: any;
  public condition: (value: any) => boolean;

  constructor(name, color, minValue, maxValue, condition = null) {
    this.name = name;
    this.color = color;
    this.minValue = minValue;
    this.maxValue = maxValue;

    const defaultCondition = (value) =>
      !value ||
      (value >= this.minValue &&
        (this.maxValue ? value < this.maxValue : true));

    this.condition = condition || defaultCondition;
  }

  validate = (value: any) => this.condition(value);
}
