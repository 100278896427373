import Vue from 'vue';
import Vuex from 'vuex';

import state, { RootState } from './state';
import getters from './getters';
import actions from './actions';
import modules from './modules';
import mutations from './mutations';

Vue.use(Vuex);

export type VueStoreState = RootState;

export default new Vuex.Store<VueStoreState>({
  state,
  getters,
  actions,
  modules,
  mutations,
});
