import { Post } from '@/store/modules/posts/model';

export type Type =
  | 'POST'
  | 'PRODUCTION'
  | 'ALERTS-ISSUE'
  | 'DIAGNOSTICS-ISSUE'
  | 'MANUAL-ISSUE'
  | 'PRODUCTION-ISSUE'
  | 'SUMMARY';

interface Icon {
  icon: string;
  color: string;
}

export const ICONS_BY_TYPE: { [key in Type]?: Icon } = {
  POST: {
    icon: 'mdi-face',
    color: 'blue',
  },
  PRODUCTION: {
    icon: 'input',
    color: 'blue',
  },
  'ALERTS-ISSUE': {
    icon: 'mdi-exclamation',
    color: 'orange darken-3',
  },
  'DIAGNOSTICS-ISSUE': {
    icon: 'dvr',
    color: 'orange ',
  },
  'PRODUCTION-ISSUE': {
    icon: 'input',
    color: 'orange',
  },
  'MANUAL-ISSUE': {
    icon: 'mdi-gesture-tap',
    color: 'orange',
  },
  SUMMARY: {
    icon: 'description',
    color: 'green',
  },
};

export interface Feed {
  companyId: string;
  type: Type;
  timestamp: number;
  sourceId: string;
  header: string;
  content: any;
  comments?: number;
  username?: string;
  icon?: Icon;
}

export interface PostFeed extends Feed {
  type: 'POST';
  content: Post;
  header: Post['username'];
}

export interface BigTableModel {
  key: string;
  data: Feed;
}

export interface State {
  loading: boolean;
  data: {
    [key: string]: Array<Feed>;
  };
  lastUpdate: number;
}

export const initialState: State = {
  loading: false,
  data: {},
  lastUpdate: 0,
};

export default initialState;
