import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export interface Shift {
  companyId: string;
  durationmin: number;
  id: number;
  minfrommidnight: number;
  shiftname: string;
  status: ['A' | 'B'];
}

export interface State {
  [key: number]: Shift;
}

export const initialState: State = {};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
