import '@mdi/font/css/materialdesignicons.css';
import '@/assets/sass/main.scss';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default (config) =>
  new Vuetify({
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
    ...config,
  });
