export interface DeviceMaintenance {
  id: string;
  deviceId: string;
  sessionStart: number;
  sessionEnd: number;
  lastUpdate: number;
  runtimeHours: number;
  totalRuntimeHours: number;
}

export interface State {
  data: Array<DeviceMaintenance>;
}

export const initialState: State = {
  data: [],
};

export default initialState;
