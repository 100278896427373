import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import config from '@/config';
import { FilterTypes, Filter } from '@/store/modules/filters';
import { prepareQuery } from '@/helpers/filters';
import getAxiosOpts from '@/helpers/axios.js';
import { formatIssues } from './getters';

/**
 * Normalize `devices` to an array of device IDs.
 */
function toDeviceIdsArray(devices: string | string[] | undefined) {
  // devices might be:
  // -  a string representing a single device ID (string), or
  // -  a stringified JSON Array of device IDs (string), or
  // -  an Array of device IDs (string[])
  let deviceIds = devices || [];

  // Normalize to `string[]` if needed.
  if (typeof deviceIds === 'string') {
    try {
      const parsed = JSON.parse(deviceIds);
      if (Array.isArray(parsed)) {
        deviceIds = parsed;
      }
    } catch {
      // Ignore parse errors and leave deviceIds as is
    }

    if (deviceIds && !Array.isArray(deviceIds)) {
      deviceIds = [deviceIds];
    }
  }

  return deviceIds as string[];
}

export default {
  async getIssues(
    { commit, rootState: { user, company }, rootGetters },
    payload
  ) {
    const url = '/api/issue_details/';
    let remoteFilters: { [key: string]: Filter } = rootGetters[
      'filters/getFiltersByType'
    ](FilterTypes.REMOTE);
    if (payload) {
      if (!payload.replaceFromTo) {
        remoteFilters = {
          from: {
            id: 'from',
            label: 'From',
            type: FilterTypes.REMOTE,
            ready: true,
            value: payload.from,
            condition: () => true,
            objectKey: '',
          },
          to: {
            id: 'to',
            label: 'To',
            type: FilterTypes.REMOTE,
            ready: true,
            value: payload.to,
            condition: () => true,
            objectKey: '',
          },
        };

        if (payload.devices) {
          remoteFilters.devices = {
            id: 'device',
            label: 'Devices',
            type: FilterTypes.REMOTE,
            ready: true,
            value: payload.devices,
            condition: () => true,
            objectKey: '',
          };
        }
      } else {
        // replace the from and to on filter
        Object.keys(remoteFilters).forEach((i) => {
          if (remoteFilters[i].id === 'dateTimeRangePicker') {
            delete remoteFilters[i];
            remoteFilters = {
              ...remoteFilters,
              ...{
                from: {
                  id: 'from',
                  label: 'From',
                  type: FilterTypes.REMOTE,
                  ready: true,
                  value: payload.from,
                  condition: () => true,
                  objectKey: '',
                },
                to: {
                  id: 'to',
                  label: 'To',
                  type: FilterTypes.REMOTE,
                  ready: true,
                  value: payload.to,
                  condition: () => true,
                  objectKey: '',
                },
              },
            };
          }
        });
      }
    }
    try {
      const { data } = await axios.get(url, {
        params: {
          ...prepareQuery(remoteFilters),
          type: 'all',
          companyId: company,
          fields:
            'id, entry_id, started_at, stopped_at, deviceid, tags, comments, assigned, notified, issuetype, status, changelog, updated_at, alert_type',
        },
        ...getAxiosOpts(user.token),
      });

      const { useStore = true } = payload || {};
      if (data && data.response) {
        if (useStore) {
          commit('SET_ISSUES', data.response);
        }

        return data.response;
      }
      return [];
    } catch {
      return [];
    }
  },
  CREATE_ISSUE_DETAIL: ({ commit, rootState: { user, company } }, issue) =>
    new Promise((res, rej) => {
      const url = '/api/issue_details';
      axios
        .post(
          url,
          {
            ...issue,
            companyId: company,
          },
          getAxiosOpts(user.token)
        )
        .then((response) => {
          if (response.status === 200 && !response.data.error) {
            commit('SET_ISSUE_DETAILS', response.data.response);
            res(response.data.response);
          } else {
            rej(response.data.error);
          }
        });
    }),
  UPDATE_ISSUE_DETAILS: async (
    {
      dispatch,
      commit,
      state: { issues },
      rootGetters,
      rootState: { user, company },
    },
    { issue, updateCb = (_issue) => {} }
  ) => {
    let params;
    const { id: issueId } = issue;

    try {
      const url = `/api/issue_details/${issueId}`;
      params = {
        // with the payload change this change and dpps is waiting deviceid
        deviceid: issue.deviceId,
        ...issue,
        companyId: company,
      };

      const response = await axios.put(url, params, getAxiosOpts(user.token));

      if (response.status !== 200) {
        throw new Error(response.data.error);
      }

      if (response.data.error) {
        const messageError = response.data.error.data.errors.error[0];
        throw Error(messageError.trim());
      }

      const { sendSMS, ...rest } = response.data.response;

      commit('SET_ISSUE_DETAILS', {
        ...rest,
        // the backend is returning `updated_at`
        updatedAt: moment(rest.updated_at).valueOf(),
        reloadIssue: true,
      });

      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Issue updated successfully.',
        },
        { root: true }
      );

      return response.data.response;
    } catch (e) {
      if (e instanceof Error) {
        switch (e.message) {
          case 'ISSUE_VERSION_ERROR': {
            // Get stale issue
            const staleIssue = formatIssues(
              [issues.find((i) => i.id === issueId)],
              rootGetters
            )?.[0];

            // Get old issue
            const oldIssue = await dispatch('getIssue', issueId);

            commit('SET_ISSUE_DETAILS', {
              ...oldIssue,
              // the backend is returning `updated_at`
              updatedAt: moment(oldIssue.updated_at).valueOf(),
              reloadIssue: false,
            });

            // Merge old and new issue
            const concatValues = (objValue, srcValue, key) => {
              if (_.isArray(objValue)) {
                return objValue.concat(srcValue);
              }

              // Use old issue property since it changed value and new issue property didn't change
              if (
                _.isEqual(staleIssue[key], objValue) &&
                !_.isEqual(staleIssue[key], srcValue)
              ) {
                return srcValue;
              }

              return objValue;
            };
            const mergedParams = _.mergeWith(params, oldIssue, concatValues);
            const now = moment().valueOf();

            // remove dupes in changelog, tags
            const uniqueParams = {
              ...mergedParams,
              changelog: _.uniqWith(mergedParams.changelog, _.isEqual),
              tags: _.uniqBy(mergedParams.tags, 'id'),
              assigned: _.uniqWith(mergedParams.assigned, _.isEqual),
              updatedAt: now,
              updated_at: now,
            };

            updateCb(uniqueParams);

            dispatch(
              'SHOW_SNACKBAR',
              {
                alertMessage:
                  'Issue recently updated. Changes merged. Please review again.',
                alertType: 'error',
              },
              { root: true }
            );
            break;
          }
          default:
            dispatch(
              'SHOW_SNACKBAR',
              {
                alertMessage: 'Sorry an error occurred. Please try again.',
                alertType: 'error',
              },
              { root: true }
            );
        }

        throw e;
      }
    }
  },
  MULTIPLE_UPDATE_ISSUE_DETAILS: async (
    { dispatch, commit, rootState: { user, company } },
    { issues, column }
  ) => {
    try {
      // Check if issues were updated
      const latestIssuesResponse = await axios.get('/api/issue_details', {
        params: {
          issueId: issues.map((i) => i.id),
        },
      });

      if (latestIssuesResponse.status !== 200) {
        throw new Error(latestIssuesResponse.data.error);
      }

      latestIssuesResponse.data.response.forEach((latestIssue) => {
        const currentIssue = issues.find((ci) => ci.id === latestIssue.id);
        if (!currentIssue) throw new Error('Issue does not exists');
      });

      const url = '/api/issues_details/bulkAction';
      const { data, status } = await axios.put(
        url,
        {
          issues: issues.map((i) => ({
            id: i.id,
            tags: i.tags,
            status: i.status,
            changelog: i.changelog,
            startedAt: i.startedAt,
            stoppedAt: i.stoppedAt,
            entryId: i.entryId,
            deviceid: i.deviceId,
            assigned: i.assigned,
            deleted: i.deleted,
            issueType: i.issueType,
            companyId: company,
            updatedAt: i.updatedAt,
          })),
          column,
        },
        getAxiosOpts(user.token)
      );

      if (status !== 200) {
        throw new Error(data.error);
      }

      if (data.error) {
        const messageError = data.error.data.errors.error[0];
        throw Error(messageError.trim());
      }

      commit('MULTIPLE_UPDATE_ISSUES_DETAILS', {
        issues: data.response,
        column,
      });
      const action = column === 'deleted' ? 'deleted' : 'updated';
      const items = issues.length > 1 ? 'Issues' : 'Issue';
      const alertMessage = `${items} ${action} successfully`;

      // All went OK
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage,
        },
        { root: true }
      );

      return status;
    } catch (e) {
      if (e instanceof Error) {
        switch (e.message) {
          case 'ISSUE_VERSION_ERROR':
            dispatch(
              'SHOW_SNACKBAR',
              {
                alertMessage:
                  'An issue in your selection was recently updated, please refresh the page',
                alertType: 'error',
                actions: [
                  {
                    caption: 'Refresh page',
                    action: () => window.location.reload(),
                  },
                ],
              },
              { root: true }
            );
            break;
          default:
            dispatch(
              'SHOW_SNACKBAR',
              {
                alertMessage: 'Sorry an error occurred. Please try again.',
                alertType: 'error',
              },
              { root: true }
            );
        }
        throw e;
      }
    }
  },
  SET_ISSUE_TYPE: ({ commit }, type) => commit('SET_ISSUE_TYPE', type),
  GET_DEVICE_ISSUES: ({ commit, rootState: { user } }, params) =>
    new Promise((res, rej) => {
      const deviceId = encodeURIComponent(params.deviceId);
      const url = `/api/issue_details/device/${deviceId}?from=${params.startTime}&to=${params.endTime}`;
      axios
        .get(url, getAxiosOpts(user.token))
        .then((response) => {
          if (response.data) {
            commit('SET_ISSUES', response.data.response);
          }
          res(true);
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_MULTIPLE_DEVICES_ISSUES: async (
    { commit, rootState: { user } },
    { from, to, devices }
  ) => {
    const url = config.dataProxyUrls.v1.issues.url({ from, to });
    try {
      const { status, data } = await axios.get(url, {
        params: { deviceid: `in:${encodeURIComponent(devices.join(','))}` },
        ...getAxiosOpts(user.token),
      });
      if (data.response) {
        data.response.forEach((issue) => {
          commit('SET_ISSUE_DETAILS', issue);
        });
        return Promise.resolve(data.response);
      }
      return Promise.reject(status);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // tag can be {tagname, color} or "typed tag" string
  ADD_TAG: (
    { dispatch, commit, rootState: { user, company } },
    { issueId, tag }
  ) =>
    new Promise((res, rej) => {
      const url = `/api/issue_details/${issueId}`;
      axios
        .patch(
          url,
          {
            operation: 'addTag',
            value: tag,
          },
          getAxiosOpts(user.token)
        )
        .then((response) => {
          if (response.status === 200) {
            res(response.data.response);
          } else {
            rej(response.data.error);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  REMOVE_TAG: (
    { dispatch, commit, rootState: { user, company } },
    { issueId, tag }
  ) =>
    new Promise((res, rej) => {
      const url = `/api/issue_details/${issueId}`;
      axios
        .patch(
          url,
          {
            operation: 'removeTag',
            value: tag,
          },
          getAxiosOpts(user.token)
        )
        .then((response) => {
          if (response.status === 200) {
            res(response.data.response);
          } else {
            rej(response.data.error);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  UPDATE_MESSAGE_SNIPPETS: ({ commit }) => commit('SET_MESSAGE_SNIPPETS'),
  getIssuesStatus: ({ commit }) => commit('SET_ISSUES_STATUS'),
  getIssuesTypes: ({ commit }) => commit('SET_ISSUES_TYPES'),
  async getIssuesMessages({ commit, rootState: { user, company } }, payload) {
    const url = config.dataProxyUrls.v2.issuesMessages.url({
      companyId: company,
    });

    const { from, to } = payload;
    try {
      const { data } = await axios.get(url, {
        params: { from, to },
        ...getAxiosOpts(user.token),
      });
      if (data && data.response) {
        commit('SET_MESSAGE_SNIPPETS', data.response);
      }
    } catch {
      // no-op
    }
  },
  async getMergedIssuesAndRules({ dispatch, rootGetters }, payload) {
    dispatch('getIssuesAndRules', payload);
    const deviceIds = toDeviceIdsArray(payload.devices);
    const { from, to } = payload;

    if (rootGetters.isFeatureEnabled('line-alerts')) {
      dispatch(
        'lineIssueDetails/findAll',
        { from, to, deviceIds },
        { root: true }
      );
    }
  },
  async getIssuesAndRules(
    { commit, rootState: { user, company }, rootGetters, dispatch },
    payload = { companyId: true, useStore: true }
  ) {
    const { companyId = company, useStore = true, ...filters } = payload;
    const url = config.dataProxyUrls.v2.issuesAndRules.url({
      companyId: companyId ? company : '',
    });

    const remoteFilters = rootGetters['filters/getFiltersByType'](
      FilterTypes.REMOTE
    );

    try {
      commit('SET_LOADING', true);
      const { data } = await axios.get(url, {
        params: Object.keys(filters).length
          ? filters
          : prepareQuery(remoteFilters),
        ...getAxiosOpts(user.token),
      });

      if (data && data.error) throw new Error(data.message);

      if (data && data.response) {
        if (useStore) commit('SET_ISSUES', data.response);
        return data.response;
      }

      if (useStore) commit('SET_ISSUES', []);
      return [];
    } catch (e) {
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Error getting issues, please try again.',
          alertType: 'error',
        },
        { root: true }
      );

      if (useStore) commit('SET_ISSUES', []);
      return [];
    } finally {
      commit('SET_LOADING', false);
    }
  },
  addIssueFromGraph: ({ commit }) => commit('ADD_ISSUE_FROM_GRAPH'),
  addIssueFromNavigation: ({ commit }) => commit('ADD_ISSUE_FROM_NAVIGATION'),
  cleanIssues: ({ commit }) => commit('CLEAN_ISSUES'),
  async getIssue({ commit, rootState: { user }, rootGetters }, issueId) {
    const url = '/api/issue_details/';
    try {
      const {
        config: axiosConfig,
        status,
        data,
      } = await axios.get(url, {
        params: { issueId },
        ...getAxiosOpts(user.token),
      });

      // status is always 200 regardless of actual api 401 or 422
      // statusText = OK
      if (status === 200 && !data.error) {
        commit('SET_ISSUE_DETAILS', data.response);
        return formatIssues([data.response], rootGetters)?.[0];
      }

      throw new Error(data.error);
    } catch {
      return null;
    }
  },
  async getIssueNotification({ rootState: { user } }, issueId) {
    const url = '/api/issue_details/notifications';
    try {
      const { status, data } = await axios.get(url, {
        params: { issueId },
        ...getAxiosOpts(user.token),
      });

      if (status === 200 && !data.error) {
        return data.response;
      }

      throw new Error(data.error);
    } catch (e) {
      if (e instanceof Error) {
        throw new Error(e.message);
      } else {
        throw new Error(String(e));
      }
    }
  },
};
