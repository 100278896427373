import Vue from 'vue';
import { State, EmailReport } from './index';
import type { WidgetData } from '@/views/Email/components/PerformanceEmailWidget/types';

import {
  SET_REPORTS,
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  SET_LOADED_WIDGET,
  SET_PERFORMANCE_WIDGET_DATA,
} from './mutations-types';

const convertOldWidgetFormat = (widget: any) => {
  if (widget.id === 'cycles-widget' && widget.cyclesCounting) {
    const { cyclesCounting, ...rest } = widget;
    return {
      ...rest,
      metrics: ['cycles'],
      perfSummary: 'totalUnits',
    };
  }
  return widget;
};

const convertReportWidgets = (report: EmailReport) => {
  return {
    ...report,
    widgets: report.widgets.map(convertOldWidgetFormat),
  };
};

export default {
  [SET_REPORTS]: (state: State, payload: Array<EmailReport>) => {
    Vue.set(state, 'items', payload.map(convertReportWidgets));
  },
  [CREATE_REPORT]: (state: State, payload: EmailReport) => {
    Vue.set(state, 'items', [...state.items, convertReportWidgets(payload)]);
  },
  [UPDATE_REPORT]: (state: State, payload: EmailReport) => {
    const copy = [...state.items];
    const idx = copy.findIndex((report) => report.id === payload.id);
    if (idx > -1) copy.splice(idx, 1, convertReportWidgets(payload));
    Vue.set(state, 'items', copy);
  },
  [DELETE_REPORT]: (state: State, payload) => {
    const copy = [...state.items];
    const idx = copy.findIndex((report) => report.id === payload);
    if (idx > -1) copy.splice(idx, 1);
    Vue.set(state, 'items', copy);
  },
  [SET_LOADED_WIDGET]: (state: State, payload) => {
    Vue.set(state, 'loadedWidgets', [...state.loadedWidgets, payload]);
  },
  [SET_PERFORMANCE_WIDGET_DATA]: (state: State, payload: WidgetData) => {
    Vue.set(state, 'performanceWidgetData', payload);
  },
};
