'use strict';

import { getPropsValues, bindProps } from '../utils/bindProps.js';
import simulateArrowDown from '../utils/simulateArrowDown.js';
import { mappedPropsToVueProps } from './mapElementFactory.js';

const mappedProps = {
  bounds: {
    type: Object,
  },
  componentRestrictions: {
    type: Object,
    noBind: true, // Do not bind -- must check for undefined in the property
  },
  types: {
    type: Array,
    default() {
      return [];
    },
  },
};

const props = {
  selectFirstOnEnter: {
    required: false,
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
  },
};

export default {
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      if (this.selectFirstOnEnter) {
        simulateArrowDown(this.$refs.input);
      }

      if (typeof google.maps.places.Autocomplete !== 'function') {
        throw new Error(
          "google.maps.places.Autocomplete is undefined. Did you add 'places' to libraries when loading Google Maps?"
        );
      }

      const finalOptions = {
        ...getPropsValues(this, mappedProps),
        ...this.options,
      };
      this.$autocomplete = new google.maps.places.Autocomplete(
        this.$refs.input,
        finalOptions
      );

      bindProps(this, this.$autocomplete, mappedProps);

      this.$watch('componentRestrictions', (v) => {
        if (v !== undefined) {
          this.$autocomplete.setComponentRestrictions(v);
        }
      });

      this.$autocomplete.addListener('place_changed', () => {
        this.$emit('place_changed', this.$autocomplete.getPlace());
      });
    });
  },

  props: { ...mappedPropsToVueProps(mappedProps), ...props },
};
