export interface Post {
  id: string;
  companyId: string;
  username: string;
  timestamp: number;
  content: string;
}

export interface State {
  loading: boolean;
  data: Array<Post>;
  lastUpdate: number;
}

export const initialState: State = {
  loading: false,
  data: [],
  lastUpdate: 0,
};

export default initialState;
