<script lang="jsx">
import Vue from 'vue';

import store from '@/store';

const closeStyle = {
  position: 'absolute',
  right: 0,
  top: 0,
  'z-index': 1,
  fontWeight: 'bold',
  padding: '0.25rem',
  cursor: 'pointer',
};

export const NotificationBar = Vue.extend({
  name: 'notifiation-bar',
  methods: {
    dismissNotification() {
      store.dispatch('notificationBar/hide');
    },
  },
  render() {
    const { show, component: Slots } = store.state.notificationBar;

    if (!show || !Slots) return null;

    return (
      <div>
        <div>{Slots.default}</div>
        <div style={closeStyle}>
          {Slots.close ? (
            <span>{Slots.close}</span>
          ) : (
            <span onClick={this.dismissNotification}>X</span>
          )}
        </div>
      </div>
    );
  },
});

const NotificationWrapper = Vue.extend({
  name: 'notification-bar-wrapper',
  mounted() {
    this.$store.dispatch('notificationBar/show', {
      component: this.$slots,
    });
  },
  destroyed() {
    this.$store.dispatch('notificationBar/hide');
  },
  render() {
    return null;
  },
});

export default NotificationWrapper;
</script>
