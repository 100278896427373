import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from 'lz-string';

import store from '../store';

const QUERY_LENGTH_THRESHOLD = 500;

export const isUrlShorterEnabled = (): boolean =>
  store.getters['urlShorter/enabled'];

export const getCompressedQueryString = (plainTextQuery: string): string =>
  compressToEncodedURIComponent(plainTextQuery);

export const getDecompressedQueryString = (compressedQuery: string) =>
  decompressFromEncodedURIComponent(compressedQuery);

export const isQueryStringAboveCompressionThreshold = (
  plainTextQuery: string
): boolean => plainTextQuery.length > QUERY_LENGTH_THRESHOLD;
