import { Filter, FilterTypes } from '@/store/modules/filters';
import { applyFiltersToData } from '@/helpers/filters';
import { filterByRange } from '@/helpers/filter-issues';
import { LineIssueDetail, State } from './model';

export default {
  getLineIssueDetail: (state: State) => (id: string) => {
    const lineIssueDetail = state.lineIssueDetails.find((mid) => mid.id === id);

    return lineIssueDetail;
  },
  getLineIssueDetails: (state: State) => state.lineIssueDetails,
  getFilteredLineIssueDetails: (
    state: State,
    getters,
    rootState,
    rootGetters
  ) => {
    let filteredData: Array<LineIssueDetail> = state.lineIssueDetails || [];

    const deviceFilter: Filter = rootGetters['filters/getFilters']('device');

    if (deviceFilter) {
      filteredData = applyFiltersToData<LineIssueDetail>(
        [deviceFilter],
        filteredData
      );
    }

    const localFilters: Array<Filter> = rootGetters['filters/getFiltersByType'](
      FilterTypes.LOCAL,
      'issue'
    );

    if (localFilters && localFilters.length) {
      filteredData = applyFiltersToData<LineIssueDetail>(
        localFilters,
        filteredData
      );
    }

    return filteredData;
  },
  getIssuesByDevice: (state: State) => (deviceId: string) => {
    // If an entry for this deviceId exists in lineIssueDetailsByDevice,
    // preferentially return it. lineIssueDetailsByDevice entries are only
    // set if a findAll fetch filters by deviceIds; lineIssueDetails are reset
    // on every findall fetch.
    return (
      state.lineIssueDetailsByDevice[deviceId] ||
      state.lineIssueDetails?.filter((issue) =>
        issue.deviceIds.includes(deviceId)
      )
    );
  },
  getIssuesByDeviceAndRange:
    (state: State, getters) =>
    (deviceId: string, range: { from: number; to: number }) => {
      const deviceIssues = getters.getIssuesByDevice(deviceId);
      if (!range.from || !range.to) return [];
      return filterByRange(deviceIssues, range);
    },
};
