import devices from './devices';
import deviceGroups from './deviceGroups';
import users from './users';
import alerts from './alerts';
import energy from './energy';
import notifications from './notifications';
import dm from './dm';
import metrics from './metrics';
import companies from './companies';
import roles from './roles';
import uptime from './uptime';
import views from './views';
import productionPeriods from './productionPeriods';
import previousBest from './previousBest';

export default {
  ...devices,
  ...deviceGroups,
  ...users,
  ...alerts,
  ...energy,
  ...notifications,
  ...dm,
  ...metrics,
  ...companies,
  ...roles,
  ...uptime,
  ...views,
  ...productionPeriods,
  ...previousBest,
};
