import Vue from 'vue';

export default {
  SET_SELECTED_VIEW: (state, selectedView) => {
    const newState = state;
    newState.selectedView = selectedView;
  },
  UPDATE_ALERT: (state, alert) => {
    Vue.set(state, 'alert', alert);
  },
  CLOSE_SNACKBAR: (state) => {
    Vue.set(state.alert, 'alertVisible', false);
  },
};
