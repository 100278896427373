import { Component, Vue } from 'vue-property-decorator';
import * as Sentry from '@sentry/vue';
import store from '../store';

/**
 *
 * @param name the name of the event
 * @param event not used
 * @param label optional: the label for the event
 * @param value optional: the value for the event
 * @param category optional: if no value then this.$route.name will be assigned as category
 * @returns
 */

const track = (
  gtag: any,
  route: any,
  intercom: any,
  name: string,
  event: any,
  label?: string,
  value?: number,
  category?: string
) => {
  try {
    const deviceList = store.getters['devicesLists/currentDeviceList'];
    const data = {
      event_category: route.name,
      event_label: label,
      value,
      device_list: deviceList ? deviceList.name : '',
    };

    if (category) {
      data.event_category = category;
    }

    // wrap in try/catch so platform can continue to execute even if there is a GA problem
    // https://github.com/MatteoGabriele/vue-gtag/issues/104#issuecomment-615196767
    if (gtag) {
      try {
        gtag.event(name, data);
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setLevel('info');
          Sentry.captureException(
            new Error(
              `[GA Track] failed - name: ${name}, event: ${event}, label: ${label}`,
              { cause: err }
            )
          );
        });
      }
    }

    if (intercom) {
      try {
        intercom.trackEvent(name, data);
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setLevel('info');
          Sentry.captureException(
            new Error(
              `[Intercom Track] failed - name: ${name}, event: ${event}, label: ${label}`,
              { cause: err }
            )
          );
        });
      }
    }
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setLevel('info');
      Sentry.captureException(
        new Error(
          `[Analytics Mixin] failed - name: ${name}, event: ${event}, label: ${label}`,
          { cause: err }
        )
      );
    });
  }
};

@Component
export default class AnalyticsMixin extends Vue {
  track(
    name: string,
    event: any,
    label?: string,
    value?: number,
    category?: string
  ) {
    const { $gtag, $route, $intercom } = this as any;
    track($gtag, $route, $intercom, name, event, label, value, category);
  }
}

export { track };
