import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';
import {
  BulkUpdateParams,
  GetLineIssueDetailsPayload,
  LineIssueDetail,
} from './model';

export default {
  bulkUpdate: (
    { commit, dispatch, rootState: { allDevices = [], company, user } },
    params: BulkUpdateParams
  ) =>
    new Promise((res, rej) => {
      const url = `/api/line-issue-details/bulk-actions?companyId=${company}`;

      axios
        .put(url, params, getAxiosOpts(user.token))
        .then((response) => {
          const resLineIssueDetails = response?.data?.response;

          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Issues updated successfully.',
            },
            { root: true }
          );

          if (resLineIssueDetails && resLineIssueDetails.length) {
            resLineIssueDetails.forEach((lineIssueDetail) => {
              commit('SET_LINE_ISSUE_DETAIL', {
                allDevices,
                lineIssueDetail,
              });
            });
          }

          res(true);
        })
        .catch((err) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Error updating issues.',
              alertType: 'error',
            },
            { root: true }
          );

          rej(err);
        });
    }),
  update: (
    { commit, dispatch, getters, rootState: { allDevices = [], user } },
    lineIssueDetail: LineIssueDetail
  ) =>
    new Promise((res, rej) => {
      const url = `/api/line-issue-details/${lineIssueDetail.id}`;

      axios
        .put(url, lineIssueDetail, getAxiosOpts(user.token))
        .then((response) => {
          const resLineIssueDetail = response?.data?.response;

          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Issue updated successfully.',
            },
            { root: true }
          );

          commit('SET_LINE_ISSUE_DETAIL', {
            allDevices,
            lineIssueDetail: resLineIssueDetail,
          });

          const mutatedLineIssueDetail = getters.getLineIssueDetail(
            resLineIssueDetail.id
          );

          res(mutatedLineIssueDetail);
        })
        .catch((err) => {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Error updating issue.',
              alertType: 'error',
            },
            { root: true }
          );

          rej(err);
        });
    }),
  find: (
    { commit, getters, rootState: { allDevices = [], company, user } },
    id: string
  ) =>
    new Promise((res, rej) => {
      const url = `/api/line-issue-details/${id}`;

      axios
        .get(url, {
          params: {
            companyId: company,
          },
          ...getAxiosOpts(user.token),
        })
        .then((response) => {
          const lineIssueDetail = response?.data?.response;

          commit('SET_LINE_ISSUE_DETAIL', {
            allDevices,
            lineIssueDetail,
          });

          const mutatedLineIssueDetail = getters.getLineIssueDetail(
            lineIssueDetail.id
          );

          res(mutatedLineIssueDetail);
        })
        .catch((err) => {
          rej(err);
        });
    }),
  findAll: (
    { commit, getters, rootState: { allDevices = [], company, user } },
    payload: GetLineIssueDetailsPayload
  ) => {
    const { from, to, deviceIds } = payload || {};
    return new Promise((res, rej) => {
      const url = '/api/line-issue-details';

      axios
        .get(url, {
          params: {
            companyId: company,
            from,
            to,
            deviceIds,
          },
          ...getAxiosOpts(user.token),
        })
        .then((response) => {
          const resLineIssueDetails = response?.data?.response || [];

          commit('SET_LINE_ISSUE_DETAILS', {
            allDevices,
            deviceIds,
            lineIssueDetails: resLineIssueDetails,
          });

          const lineIssueDetails = resLineIssueDetails.map((mid) =>
            getters.getLineIssueDetail(mid.id)
          );

          res(lineIssueDetails);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
};
