import axios from 'axios';
import io from 'socket.io-client';
import _ from 'lodash';

import getAxiosOpts from '@/helpers/axios.js';
import config from '@/config';
import { socketBaseURL } from '../../config/urls';
import mappers from '../../helpers/mappers';

let socket;

export default {
  CLEAR_DATA: ({ commit }) => {
    commit('CLEAR_DATA');
  },
  ACQUIRE_RAW_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const vueState = state;
      vueState.progressShowPercentage = false;
      const unencodedDeviceId = data.device;
      data.device = encodeURIComponent(data.device);

      axios
        .get('/api/diagnostics/singlemetric', {
          ...getAxiosOpts(state.user.token),
          params: { ...data },
        })
        .then((response) => {
          if (response.status === 401) {
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          } else {
            const dataArr = _.get(response, 'data.response.data') || [];
            const table = _.get(response, 'data.response.table');
            if (table) {
              commit('UPDATE_METRICS_TABLE', table);
            }
            // Check here
            const formattedDataArr = _(dataArr)
              .groupBy('metricId')
              .map((group) => _.orderBy(group, ['timestamp'], ['asc']))
              .value();

            const ordered = _.orderBy(dataArr, ['timestamp'], ['asc']);

            const key = `${data.from}_${data.to}_${unencodedDeviceId}`;

            commit('RAW_DATA_ACQUIRED', formattedDataArr);
            commit('RAW_DATA_DEVICE_ACQUIRED', {
              key,
              data: _.groupBy(ordered, 'metricId'),
            });
            if (formattedDataArr.length < 1) {
              commit('NO_GRAPH_DATA');
            } else {
              commit('GRAPH_DATA_AVAILABLE');
            }
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_RAW_DATA_DEVICE_GROUP: ({ commit, state, getters }, data) =>
    new Promise((res, rej) => {
      const key = `${data.from}_${data.to}_${data.groupId}`;

      if (state.rawDeviceGroupData[key]) {
        res();
        return;
      }

      axios
        .get('/api/diagnostics/multipledevices', {
          ...getAxiosOpts(state.user.token),
          params: { ...data },
        })
        .then((response) => {
          if (response.status === 401) {
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          } else {
            const dataArr = _.get(response, 'data.response.data') || [];
            const table = _.get(response, 'data.response.table');
            if (table) {
              commit('UPDATE_METRICS_TABLE', table);
            }

            const parsedData = [];
            const formattedDataArr = _(dataArr)
              .groupBy((x) => x.deviceId)
              .map((group) => _.orderBy(group, ['timestamp'], ['asc']))
              .value();

            formattedDataArr.forEach((option) => {
              parsedData.push({
                phase: {
                  opName: getters.devices.find(
                    (x) => x.deviceid === option[0].deviceId
                  ).nickname,
                  deviceId: getters.devices.find(
                    (x) => x.deviceid === option[0].deviceId
                  ).deviceid,
                },
                serie: option.map((x) => [x.timestamp, x.recValue]),
                serieAsObject: option.map((val) => ({
                  x: val.timestamp,
                  y: val.calcValue,
                })),
              });
            });

            commit('RAW_DATA_DEVICE_GROUP_ACQUIRED', { key, data: parsedData });
            if (formattedDataArr.length < 1) {
              commit('NO_GRAPH_DATA');
            } else {
              commit('GRAPH_DATA_AVAILABLE');
            }
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_RAW_LIVE_DATA: ({ commit, state }, channel) => {
    // commit('CLEAR_DATA');
    // Do the main connection with socket.io
    const { token } = state.user;
    socket = io(`${socketBaseURL}/rNS`);
    socket.emit('join', {
      channel,
      token,
    });
    // When data received commit to RAW_LIVE_DATA_ACQUIRED with da data.
    socket.on('data', (data) => {
      commit('RAW_LIVE_DATA_ACQUIRED', data);
    });
  },
  ACQUIRE_RAW_DATA_MULTIPLE_DEVICES: ({ commit, state, getters }, data) =>
    new Promise((res, rej) => {
      const vueState = state;
      vueState.progressShowPercentage = false;
      data.metric = encodeURIComponent(data.metric);
      data.devices = [].concat(...data.devices);
      axios
        .get('/api/diagnostics/multipledevices', {
          ...getAxiosOpts(state.user.token),
          params: { ...data },
        })
        .then((response) => {
          if (response.status === 401) {
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          } else {
            const dataArr = _.get(response, 'data.response.data') || [];
            const table = _.get(response, 'data.response.table');
            if (table) {
              commit('UPDATE_METRICS_TABLE', table);
            }

            const listPhases = [];
            const formattedDataArr = _(dataArr)
              .groupBy((x) => x.deviceId)
              .map((group) => _.orderBy(group, ['timestamp'], ['asc']))
              .value();
            formattedDataArr.forEach((option) => {
              listPhases.push({
                phase: {
                  opName: getters.devices.find(
                    (x) => x.deviceid === option[0].deviceId
                  ).nickname,
                },
                serie: option.map((x) => [x.timestamp, x.recValue]),
              });
            });
            if (listPhases.length < 1) {
              commit('NO_GRAPH_DATA');
            } else {
              commit('GRAPH_DATA_AVAILABLE');
            }
            commit('UPDATE_COMPARE_DEVICES', listPhases);
            commit('HIDE_MAXMIN');
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_ANOMALY_METRICS: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const vueState = state;
      vueState.progressShowPercentage = false;
      data.device = encodeURIComponent(data.device);
      data.from = encodeURIComponent(data.from);
      data.to = encodeURIComponent(data.to);
      Promise.all([
        axios.get('/api/anomaly-metrics', {
          ...getAxiosOpts(state.user.token),
          params: {
            ...data,
          },
        }),
        axios.get('/api/diagnostics/singlemetric', {
          ...getAxiosOpts(state.user.token),
          params: {
            ...data,
            metric: 'Psum',
          },
        }),
      ])
        .then(([anomalyMetricsResponse, singleMetricResponse]) => {
          if (
            anomalyMetricsResponse.status === 401 ||
            singleMetricResponse.status === 401
          ) {
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          } else {
            const anomalyMetricsData =
              _.get(anomalyMetricsResponse, 'data.response') || [];
            const activePowerData =
              _.get(singleMetricResponse, 'data.response.data') || [];
            const activePowerPsumData = activePowerData.filter(
              (x) => x.metricId === 'Psum'
            );

            const listPhases = [
              {
                phase: {
                  metric: 'psum',
                  opName: 'Active Power',
                  unit: 'kW',
                },
                serie: _.orderBy(activePowerPsumData, ['timestamp'], ['asc']),
              },
              {
                phase: {
                  metric: 'anomaly',
                  opName: 'Anomaly',
                  unit: '',
                  minChartValue: 0,
                  maxChartValue: 1,
                  allowDecimals: false,
                  alignTicks: false,
                },
                serie: _.orderBy(anomalyMetricsData, ['from_ts'], ['asc']).map(
                  (x) => [
                    parseInt(x.from_ts, 10),
                    x.anomaly_status !== -1 ? x.anomaly_status : null,
                  ]
                ),
                rawData: _.orderBy(anomalyMetricsData, ['from_ts'], ['asc']),
              },
            ];

            commit('GRAPH_DATA_AVAILABLE');
            commit('UPDATE_ANOMALY_METRICS', listPhases);
            commit('HIDE_MAXMIN');
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_RAW_DATA_MULTIPLE_METRICS: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const vueState = state;
      vueState.progressShowPercentage = false;
      data.metrics = JSON.stringify(data.metrics);
      data.device = encodeURIComponent(data.device);
      axios
        .get('/api/diagnostics/multiplemetrics', {
          ...getAxiosOpts(state.user.token),
          params: { ...data },
        })
        .then((response) => {
          if (response.status === 401) {
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          } else {
            const dataArr = _.get(response, 'data.response.data') || [];
            const table = _.get(response, 'data.response.table');
            if (table) {
              commit('UPDATE_METRICS_TABLE', table);
            }

            const listPhases = [];
            const formattedDataArr = _(dataArr)
              .groupBy((x) => x.metricId)
              .map((group) => _.orderBy(group, ['timestamp'], ['asc']))
              .value();
            formattedDataArr.forEach((option) => {
              listPhases.push({
                phase: {
                  opName:
                    state.available_metrics[option[0].metricId.toLowerCase()]
                      .name,
                  metric:
                    state.available_metrics[
                      option[0].metricId.toLowerCase()
                    ].metric.toLowerCase(),
                },
                serie: option.map((x) => [x.timestamp, x.recValue]),
              });
            });
            if (listPhases.length < 1) {
              commit('NO_GRAPH_DATA');
            } else {
              commit('GRAPH_DATA_AVAILABLE');
            }

            commit('UPDATE_COMPARE_METRICS', listPhases);
            commit('HIDE_MAXMIN');
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_RAW_METRICS: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const vueState = state;
      vueState.progressShowPercentage = false;
      const params = {
        devices: data.devices.flat(),
        metrics: data.metrics.flat(),
        from: encodeURIComponent(data.from),
        to: encodeURIComponent(data.to),
      };
      axios
        .get('/api/diagnostics/metrics', {
          ...getAxiosOpts(state.user.token),
          params,
        })
        .then((response) => {
          if (response.status === 401) {
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
            res();
          } else {
            const dataArr = _.get(response, 'data.response.data') || [];
            res({
              data: dataArr,
            });
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  TURN_OFF_ADVANCED_FILTER: ({ commit }) => commit('TURN_OFF_ADVANCED_FILTER'),
  TURN_OFF_METRICS_FILTER: ({ commit }) => commit('TURN_OFF_METRICS_FILTER'),
  TURN_OFF_DEVICES_FILTER: ({ commit }) => commit('TURN_OFF_DEVICES_FILTER'),
  UPDATE_COMPARE_DEVICES: ({ commit }, devices) =>
    commit('UPDATE_COMPARE_DEVICES', devices),
  CLOSE_CHANNEL: ({ commit }, channel) => {
    if (socket) {
      socket.emit('leave', channel);
      socket.close();
      commit('CHANNEL_CLOSED');
    }
  },
  FETCH_TOTAL_POWER: (
    { commit, state },
    { from, to, deviceGroupId, notDayTable = null, useStore = true }
  ) =>
    new Promise((res, rej) => {
      const oneDay = 86400000;
      const rangeLabel = to - from <= oneDay * 3 ? 'Day' : 'Week';
      if (useStore) {
        commit('SET_TOTAL_POWER', {
          tpData: { success: true },
          rangeLabel,
        });
      }
      const url = state.isFetchedFromCache
        ? `/api/home/${state.company}?range=${rangeLabel.toLocaleLowerCase()}&widget=${'totalpower'}`
        : `/api/dm/mains/${state.company}`;
      axios
        .get(url, {
          params: {
            deviceGroupId,
            from,
            to,
            notDayTable,
          },
          ...getAxiosOpts(state.user.token),
        })
        .then((response) => {
          if (useStore) {
            commit('SET_TOTAL_POWER', {
              tpData: response.data,
              rangeLabel,
            });
          }
          res(response);
        })
        .catch((err) => {
          rej(err);
        });
    }),
  SHOW_PHASES: ({ commit }) => commit('SHOW_PHASES'),
  HIDE_PHASES: ({ commit }) => commit('HIDE_PHASES'),
  SHOW_MAXMIN: ({ commit }) => commit('SHOW_MAXMIN'),
  HIDE_MAXMIN: ({ commit }) => commit('HIDE_MAXMIN'),
  SET_METRIC_TABLE: ({ commit }, payload) =>
    commit('UPDATE_METRICS_TABLE', payload),
  async ACQUIRE_STATS_DATA(
    { commit, rootState: { user, company }, rootGetters },
    params
  ) {
    const { from, to, shiftId, devices } = params;
    const metrics = rootGetters.getMetricsStats.map((m) => m.metric);
    const url = config.dataProxyUrls.v2.metricStats.url({
      companyId: company,
      from,
      to,
    });
    try {
      const { data } = await axios.get(url, {
        params: {
          metrics,
          shiftId,
          devices,
        },
        ...getAxiosOpts(user.token),
      });

      /** The response is grouped by device (includes groups)
       * data.response = {
          dev_cosmos_machines_150ch:Object
          dev_cosmos_machines_210c:Object
          dev_cosmos_machines_210d:Object
          dev_cosmos_machines_210s:Object
          dev_cosmos_machines_230xt:Object
          dev_cosmos_machines_cartonator:Object
          dev_cosmos_machines_clinipak:Object
          dev_cosmos_machines_pgsupper:Object
          dev_cosmos_machines_swrapping:Object
          dev_cosmos_mains_generator:Object
          dev_cosmos_mains_liquids:Object
          dev_cosmos_mains_mains:Object
          dev_cosmos_mains_solar:Object
          dev_cosmos_mains_tablets:Object
          dev_cosmos_meter6_27d2stn:Object
          dev_cosmos_meter6_55stn:Object
          dev_cosmos_meter6_coating39:Object
          dev_cosmos_meter6_coating50:Object
          dev_cosmos_meter6_coating60:Object
          dev_cosmos_meter7_35cstn:Object
          group_cosmos_397156077350709: Object
       * }
       */

      /**
       * orderedDevices = [{ deviceid: 'device1', ... }, { deviceid: 'device2' }]
       */
      const orderedDevices = rootGetters.allDevices;
      const deviceGroups = rootGetters.allActiveDevicesGroups;

      let orderedStats = data.response;

      if (orderedStats && Object.keys(orderedStats).length) {
        // Order stats based on the devices order
        orderedStats = [...orderedDevices, ...deviceGroups].reduce(
          (acc, device) => {
            const id = device.groupid || device.deviceid;
            if (orderedStats[id]) {
              acc[id] = orderedStats[id];
            }

            return acc;
          },
          {}
        );
      }

      commit('SET_METRIC_STATS_DATA', orderedStats);
    } catch (e) {
      console.log(e);
    }
  },
  async getRawDataMultiple({ commit, rootState: { user } }, data) {
    const { metrics, device, from, to } = data;

    try {
      const { status, data: { response: { data: metricsData } = {} } = {} } =
        await axios.get('/api/diagnostics/multiplemetrics', {
          ...getAxiosOpts(user.token),
          params: {
            metrics: JSON.stringify(metrics),
            device: encodeURIComponent(device),
            from,
            to,
          },
        });
      if (status === 200) {
        return _.groupBy(metricsData, (d) => d.metricId.toLowerCase());
      }
    } catch (err) {
      console.log(err);
    }
    return {};
  },
};
