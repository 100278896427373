import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { CYCLE_BARS_CODES } from '@/components/charts/features/CycleCountFeature/CycleCountChartFeature';
import { Device } from '@/store/state';
import { Rounding } from '@/helpers/cumulative-metrics';

export type PerformanceTimeSeriesData = {
  /**
   * Can be hourly or by day
   */
  timestamp: number;
  performance: number | null;
  averageMetric: number | null;
  standardMetric: number | null;
  performanceThresholdCode: CYCLE_BARS_CODES;
};

export type PerformanceSummaryData = {
  performanceData: PerformanceTimeSeriesData[];
  /**
   * Percentage
   */
  averagePerformance: number | null;
  averageMetric: number | null;
  standardMetric: number | null;
  performanceThresholdCode: CYCLE_BARS_CODES;
};

export type PerformanceDeviceData = {
  device: Device;
  averageMetric: number | null;
  standardMetric: number;
  performanceData: PerformanceTimeSeriesData[];
  type: 'cycles' | 'productionRate';
  extraMessage?: {
    label: string;
    value: string;
  };
  /**
   * "seconds/cycle" for cycles data, the metric string for production rate data
   */
  subtitle: string;
  performanceThresholdCode: CYCLE_BARS_CODES;
  totalUnits: number;
  performancePercentage: number;
  perfSummary: 'totalUnits' | 'performancePercentage';
  rounding: Rounding;
};

export type PerformanceThresholds = {
  // percentages
  highCycleTimeDiff: number;
  lowCycleTimeDiff: number;
};

export type PerformanceWidgetData = {
  companyTimeZone: string;
  thresholds: PerformanceThresholds;
  cyclesData: {
    summaryData: PerformanceSummaryData;
    devicesData: PerformanceDeviceData[];
  };
  productionRateData: {
    summaryData: PerformanceSummaryData;
    devicesData: PerformanceDeviceData[];
  };
};

export interface PerformanceWidget {
  /**
   * Old name of the widget, used to identify the widget in the report
   */
  id: 'cycles-widget';
  defaultNoDevices: boolean;
  lowCycleTimeDiff: number;
  highCycleTimeDiff: number;
  perfSummary: string;
  metrics: string[];
  devices: string[];
  index: number;
}

export interface EmailReport {
  id: number;
  name: string;
  /**
   * Currently only performance widget is typed, add more types as other widgets
   * are worked on/added.
   */
  widgets: PerformanceWidget[];
  contentRange: 'daily' | 'weekly';
  shiftId: number | null;
  recipients: Array<string>;
  daysToSend: Array<string>;
  timeToSend: string;
}

export interface State {
  items: Array<EmailReport>;
  loadedWidgets: Array<String>;
  performanceWidgetData: PerformanceWidgetData | null;
}

export const initialState: State = {
  /**
   * The list of email reports, found in the email reports setting component.
   * Also used on individual email report pages.
   */
  items: [],
  /**
   * The list of widgets that have been loaded on the email report page.
   * Used to determine if the email report is ready to be screenshotted by
   * puppeteer and sent as an email.
   */
  loadedWidgets: [],
  performanceWidgetData: null,
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
