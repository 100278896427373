import { ActionContext } from 'vuex';
import { RootState } from '@/store/state.ts';

import { SET_SETTINGS, SET_SETTING } from './mutations-types';
import { SettingPayload, State } from './model';

export default {
  setSettings({ commit }, payload: SettingPayload) {
    commit(SET_SETTINGS, payload);
  },
  setSetting({ commit }, payload: SettingPayload) {
    commit(SET_SETTING, payload);
  },
  saveCompanySetting(
    context: ActionContext<State, RootState>,
    payload: SettingPayload
  ) {
    const newSettings = {
      ...context.rootState.companySettings,
      [payload.name]: payload.value,
    };
    return context.dispatch('SAVE_SETTINGS', newSettings, { root: true });
  },
};
