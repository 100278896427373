import axios from 'axios';
import moment from 'moment';
import * as Sentry from '@sentry/vue';

import { omit, isEmpty } from 'lodash';
import getAxiosOpts from '@/helpers/axios.js';
import config from '@/config';
import { updateFilterForEachPage } from '@/store/modules/filters/helpers';
import { isDeviceGroup } from '@/helpers/validations';

import { company } from '../state';
import helper from '../../../src/helpers/custom-sort';

const DATA_CACHE_TIME = 100; // 5 min

// unique list of cancel tokens keyed by device id so future calls with the same device id are
// cancelled (e.g. 7 day request vs current 24h outstanding request
// or when leaving the page
// remember, it only cancels the UI call, but not the server call once server starts processing
let deviceCancelTokenSources = {};

const cancelRequest = (tokens, key) => {
  // see if we have an existing device token to cancel
  if (tokens[key]) {
    tokens[key].cancel('cancelled');
  }
};

// support axios cancel against the deviceId
// there are case where we don't want to cancel against the same deviceId
// (e.g. multiple summary components in the feed of the same deviceIds for different days)
async function getDeviceData(
  { commit, rootState: { issues } },
  payload,
  enableCancel = false
) {
  const { deviceId, duration, notUseStore } = payload;
  if (!notUseStore)
    commit('CLEAN_DEVICE_DATA', {
      duration,
      deviceId,
      loadStates: payload.loadStates,
    });
  const url = '/api/device/devicedata';

  const updatedLoadStates = issues.updateDeviceState[deviceId];

  // We have a cache in the API at the request/params level. adding a new parameter in case we are
  // in the future prevents us from bringing cache data
  // (this also fixes the shift view on other pages)
  const { to } = payload;
  const avoidApiCache =
    to > moment().add(5, 'minutes').valueOf()
      ? moment().startOf('minute').valueOf()
      : undefined;
  try {
    const axiosConfig = {
      params: {
        ...payload,
        companyId: company,
        updated: updatedLoadStates,
        avoidApiCache,
      },
    };

    if (enableCancel) {
      // see if we have an existing device token to cancel
      if (deviceCancelTokenSources[deviceId]) {
        deviceCancelTokenSources[deviceId].cancel('cancelled');
      }
      // generate a new source for the current request
      const cancelTokenSource = axios.CancelToken.source();
      deviceCancelTokenSources[deviceId] = cancelTokenSource;
      axiosConfig.cancelToken = cancelTokenSource.token;
    }

    const { data, status } = await axios.get(url, axiosConfig);
    if (status < 200 || status >= 300) {
      console.error(
        `GET ${url} for device data came back with status ${status}`
      );
    }
    if (data && data.response) {
      if (!notUseStore) {
        commit('SET_DEVICE_DATA', {
          data: data.response,
          duration,
          deviceId,
          loadStates: payload.loadStates,
        });
      }

      return {
        deviceStateCount: data.response[deviceId].deviceStateCount,
        loadStates: data.response[deviceId].loadStates,
        dmData: data.response[deviceId].dmData,
        computedLoadStates: data.response[deviceId].computedLoadStates,
      };
    }
    return [];
  } catch (e) {
    if (e.message !== 'cancelled') {
      console.error(e);
    }
    return [];
  }
}

/**
 * Checks and reports cycles statistics data consistency
 */
const checkDataConsistency = (data, filters) => {
  let message = '';

  // Malformed Response
  if (!data.data || !data.query) {
    message = 'Malformed Response';

    Sentry.withScope((scope) => {
      scope.setContext('cycles-context', { data, filters });
      scope.captureMessage(`GET_CYCLES_STATISTICS: ${message}`, 'error');
    });

    return;
  }

  const {
    data: { peaks, cyclesStats },
    query,
  } = data;

  // Stats data but not peaks data
  if (cyclesStats.avgTime && !peaks.length) {
    message = 'Stats data but peaks array is empty';
  }

  // Requested filters does not match the query object returned by the backend
  if (
    query.from !== filters.from ||
    query.to !== filters.to ||
    query.deviceId !== filters.deviceId
  ) {
    message = 'Payload and response query does not match';
  }

  if (!message) return;

  Sentry.withScope((scope) => {
    scope.setContext('cycles-context', { data, filters });
    Sentry.captureMessage(`GET_CYCLES_STATISTICS: ${message}`, 'error');
  });
};

/**
 * Common code to fetch device details options
 */
const fetchDeviceDetailsOptions =
  (type, commitKey) =>
  async ({ commit, rootState: { user } }) => {
    const url = `/api/devices/${type}-options`;

    try {
      const { data, status } = await axios.get(url, getAxiosOpts(user.token));

      if (status === 200) {
        commit(commitKey, data);
        return data;
      }
      return [];
    } catch (e) {
      console.error(`Error fetching ${type} options`, e);
      return [];
    }
  };

export default {
  async getMultipleDeviceData({ rootState: { user } }, payload) {
    const { deviceId, metricsIds, from, to } = payload;
    const url = '/api/diagnostics/multiplemetrics';

    try {
      const axiosConfig = {
        params: {
          device: deviceId,
          metrics: JSON.stringify(metricsIds),
          from,
          to,
        },
        ...getAxiosOpts(user.token),
      };

      const { data, status } = await axios.get(url, axiosConfig);
      if (status === 200) {
        return data.response.data;
      }

      return [];
    } catch {
      return [];
    }
  },
  handleSpeedLine({ commit }, payload) {
    commit('SET_SPEED_LINES', payload);
  },
  cleanSpeedLines({ commit }, deviceId) {
    commit('CLEAN_SPEED_LINES', deviceId);
  },
  async getDeviceDataWithCancel(context, payload) {
    return getDeviceData(context, payload, true);
  },
  getDeviceData,
  getDeviceById: async ({ state }, deviceId) => {
    const response = await axios.get(
      `/api/device/${deviceId}`,
      getAxiosOpts(state.user.token)
    );

    return response.data.response;
  },
  async getLastMinuteDataNoStore({ rootState: { user } }, payload) {
    const url = '/api/device/devicedata';

    try {
      const { data } = await axios.get(url, {
        params: {
          ...omit(payload, ['range']),
          companyId: company,
          avoidApiCache: moment().startOf('minute').valueOf(),
        },
        ...getAxiosOpts(user.token),
      });

      if (data && data.response) {
        const deviceId = Object.keys(data.response)[0];
        const { loadStates } = data.response[deviceId];
        if (loadStates[0] && loadStates[0].start < payload.from) {
          const errorMsg = 'State outside of range';

          Sentry.withScope((scope) => {
            scope.setContext('last-minute-data', {
              data: data.response,
              payload: omit(payload, ['range']),
            });
            Sentry.captureMessage(errorMsg, 'error');
          });
        }

        return data.response;
      }
      return {};
    } catch {
      return {};
    }
  },
  async getLastMinuteData({ commit, dispatch }, payload) {
    const response = await dispatch('getLastMinuteDataNoStore', payload);

    if (!isEmpty(response)) {
      commit('SET_LIVE_DEVICE_DATA', {
        data: response,
        duration: payload.duration || 'hour',
        range: payload.range,
      });
    }

    return response;
  },
  GET_AVAILABLE_DEVICES: ({ commit, state }) =>
    new Promise((res, rej) => {
      if (
        localStorage.devices &&
        localStorage.deviceslasttime &&
        moment().utc().valueOf() - Number(localStorage.deviceslasttime) <
          DATA_CACHE_TIME &&
        JSON.parse(localStorage.devices).length > 0 &&
        localStorage.devicescompany === company
      ) {
        commit('SET_AVAILABLE_DEVICES', {
          devices: JSON.parse(localStorage.devices),
          customSorted: localStorage.customSorted,
        });
        res();
      } else {
        axios
          .get(
            `/api/devices?companyid=${company}&username=${state.user.loggedInUserInfo.username}`,
            getAxiosOpts(state.user.token)
          )
          .then((response) => {
            let devicesArray = response.data.response
              ? response.data.response.slice()
              : [];
            // Check if user has sorting config
            const { customSorting } = response.data;
            if (customSorting && customSorting.sorting) {
              const sortedDevicesId = JSON.parse(customSorting.sorting);
              const devicesArrayHashMap = {};
              devicesArray.forEach((device) => {
                devicesArrayHashMap[device.deviceid] = device;
              });
              const temporaryArray = [];
              // If the user has sorting configs, then sort devices with that criterias
              sortedDevicesId.forEach((deviceid) => {
                if (devicesArrayHashMap[deviceid]) {
                  temporaryArray.push(
                    Object.assign({}, devicesArrayHashMap[deviceid])
                  );
                  delete devicesArrayHashMap[deviceid];
                }
              });

              // If not all the devices are contained within the user
              // sorting config then add them to the end
              // or the array following the default criteria.
              const unorderedRemainingDevices = [];
              Object.keys(devicesArrayHashMap).forEach((key) => {
                unorderedRemainingDevices.push(devicesArrayHashMap[key]);
              });

              devicesArray = temporaryArray;
              devicesArray.push(
                ...helper.sortArrayWithLoadTypeCriteria(
                  unorderedRemainingDevices
                )
              );
            } else {
              // If the user doesn't has sorting configs implement default sorting
              devicesArray = helper.sortArrayWithLoadTypeCriteria(devicesArray);
            }

            commit('SET_AVAILABLE_DEVICES', {
              devices: devicesArray,
              customSorted: customSorting != null,
            });
            localStorage.devices = '[]';
            const cachedDevicesData = JSON.parse(localStorage.devices);
            devicesArray.forEach((device) => {
              cachedDevicesData.push(device);
            });
            localStorage.devicescompany = company;
            if (customSorting) {
              localStorage.customSorted = true;
            } else {
              delete localStorage.customSorted;
            }
            localStorage.deviceslasttime = moment().utc().valueOf();
            localStorage.devices = JSON.stringify(cachedDevicesData);
            res();
          })
          .catch((err) => {
            rej(err);
          });
      }
    }),
  GET_ALL_DEVICES: ({ commit, state }, data = {}) =>
    new Promise((res, rej) => {
      const params = new URLSearchParams({ companyid: company });
      const username = state.user?.loggedInUserInfo?.username;
      if (username) params.append('username', username);
      if (data.settings) params.append('settings', 'true');
      axios
        .get(`/api/devices?${params}`, getAxiosOpts(state.user.token))
        .then((response) => {
          let devicesArray = response.data.response
            ? response.data.response.slice()
            : [];
          // Check if user has sorting config
          const { customSorting } = response.data;
          if (customSorting && customSorting.sorting) {
            const sortedDevicesId = JSON.parse(customSorting.sorting);
            const devicesArrayHashMap = {};
            devicesArray.forEach((device) => {
              devicesArrayHashMap[device.deviceid] = device;
            });
            const temporaryArray = [];
            // If the user has sorting configs, then sort devices with that criterias
            sortedDevicesId.forEach((deviceid) => {
              if (devicesArrayHashMap[deviceid]) {
                temporaryArray.push(
                  Object.assign({}, devicesArrayHashMap[deviceid])
                );
                delete devicesArrayHashMap[deviceid];
              }
            });

            // If not all the devices are contained within the user
            // sorting config then add them to the end
            // or the array following the default criteria.
            const unorderedRemainingDevices = [];
            Object.keys(devicesArrayHashMap).forEach((key) => {
              unorderedRemainingDevices.push(devicesArrayHashMap[key]);
            });

            devicesArray = temporaryArray;
            devicesArray.push(
              ...helper.sortArrayWithLoadTypeCriteria(unorderedRemainingDevices)
            );
          } else {
            // If the user doesn't has sorting configs implement default sorting
            devicesArray = helper.sortArrayWithLoadTypeCriteria(devicesArray);
          }
          commit('SET_ALL_DEVICES', {
            devices: devicesArray,
            customSorted: customSorting != null,
          });
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  CREATE_DEVICE: ({ dispatch, commit, state }, device) =>
    new Promise((res, rej) => {
      axios
        .post('/api/devices', { device }, getAxiosOpts(state.user.token))
        .then((response) => {
          if (!response.data.toLowerCase().includes('error')) {
            localStorage.devices = '[]';
            commit('SET_NEW_DEVICE', device);
            dispatch('SHOW_SNACKBAR', {
              alertMessage: 'Device created successfully.',
              alertType: 'success',
            });
            // return the new device in case the caller needs access to it
            res(device);
          } else {
            dispatch('SHOW_SNACKBAR', {
              alertMessage: response.data,
              alertType: 'error',
            });
            res(response.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  EDIT_DEVICE: ({ dispatch, commit, state }, data) =>
    new Promise((res, rej) => {
      axios
        .put(
          '/api/devices',
          {
            formerDeviceId: data.formerDeviceId,
            newDevice: data.newDevice,
          },
          getAxiosOpts(state.user.token)
        )
        .then((response) => {
          if (!JSON.stringify(response.data).toLowerCase().includes('error')) {
            localStorage.devices = '[]';
            commit('UPDATE_DEVICE', {
              newDevice: data.newDevice,
              index: data.index,
            });
            commit('SET_ALERTS', []);
            dispatch('SHOW_SNACKBAR', {
              alertMessage: 'Device updated successfully.',
              alertType: 'success',
            });
            updateFilterForEachPage('device', data.newDevice);
            // return the updated device in case the caller needs access to it
            res(data.newDevice);
          } else {
            dispatch('SHOW_SNACKBAR', {
              alertMessage: response.data,
              alertType: 'error',
            });
            res(response.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  REMOVE_DEVICE: ({ commit, state }, deviceId) =>
    new Promise((res, rej) => {
      axios
        .delete(`/api/devices/${deviceId}`, getAxiosOpts(state.user.token))
        .then((response) => {
          if (!response.data.toLowerCase().includes('error')) {
            localStorage.devices = '[]';
            commit('DEVICE_REMOVED', deviceId);
            res();
          } else {
            rej();
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_DEVICE_TYPES({ commit, state }) {
    if (state.deviceTypes.length) return;

    new Promise((res, rej) => {
      axios
        .get('/api/devices/types', getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_DEVICE_TYPES', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  GET_METER_TYPES({ commit, state }) {
    if (state.loadTypes.length) return;

    new Promise((res, rej) => {
      axios
        .get('/api/devices/loadtypes', getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_METER_TYPES', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  REORDER_DEVICES: ({ commit, state }, devices) => {
    const devicesKeys = [];
    devices.forEach((device) => {
      devicesKeys.push(device.deviceid);
    });
    axios
      .post(
        '/api/devices/reorder',
        {
          username: state.user.loggedInUserInfo.username,
          companyid: state.company,
          sorting: JSON.stringify(devicesKeys),
        },
        getAxiosOpts(state.user.token)
      )
      .then(() => {
        localStorage.devices = '[]';
        commit('SET_ORDER_DEVICES', devices);
      })
      .catch(() => {});
  },
  REORDER_DEFAULTS: ({ commit, state }) =>
    new Promise((res, rej) => {
      axios
        .post(
          '/api/devices/defaultorder',
          {
            username: state.user.loggedInUserInfo.username,
            companyid: state.company,
          },
          getAxiosOpts(state.user.token)
        )
        .then(() => {
          const devicesToOrder = state.allDevices
            ? state.allDevices.slice()
            : [];
          const orderedDevices =
            helper.sortArrayWithLoadTypeCriteria(devicesToOrder);
          commit('SET_DEFAULT_ORDER', orderedDevices);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  CHANGE_DEVICE: ({ commit }, selectedDevice) => {
    commit('SET_SELECTED_DEVICE', selectedDevice);
  },
  UPDATE_SELECTED_DEVICES: ({ commit }, selectedDevices) => {
    commit('UPDATE_SELECTED_DEVICES', selectedDevices);
  },
  UPDATE_SELECTED_METRICS: ({ commit }, selectedMetrics) => {
    commit('UPDATE_SELECTED_METRICS', selectedMetrics);
  },
  EMPTY_SELECTED_DEVICES: ({ commit }) => {
    commit('EMPTY_SELECTED_DEVICES');
  },
  UPDATE_SELECTED_FILTERS: ({ commit }, filters) => {
    commit('UPDATE_SELECTED_FILTERS', filters);
  },
  cleanAllDeviceData: ({ commit }) => commit('CLEAN_ALL_DEVICE_DATA'),
  cancelAllDeviceRequests: () => {
    if (deviceCancelTokenSources) {
      const keys = Object.keys(deviceCancelTokenSources);
      keys.forEach((key) => {
        if (deviceCancelTokenSources[key]) {
          deviceCancelTokenSources[key].cancel('cancelled');
        }
      });
    }
    // reset to empty object
    deviceCancelTokenSources = {};
  },
  cancelDeviceRequest: (ctx, deviceId) => {
    if (deviceCancelTokenSources[deviceId]) {
      deviceCancelTokenSources[deviceId].cancel('cancelled');
      delete deviceCancelTokenSources[deviceId];
    }
  },
  async GET_SECOND_LEVEL_DATA(
    { rootState: { user } },
    payload,
    enableCancel = true
  ) {
    const { deviceId, from, to, smoothingEnabled, smoothingWindow } = payload;

    const url = config.dataProxyUrls.v2.device.secondLevelData({ deviceId });

    const axiosConfig = {
      ...getAxiosOpts(user.token),
    };

    if (enableCancel) {
      const key = `second_level_data-${deviceId}`;
      // see if we have an existing device token to cancel
      if (deviceCancelTokenSources[key]) {
        deviceCancelTokenSources[key].cancel('cancelled');
      }
      // generate a new source for the current request
      const cancelTokenSource = axios.CancelToken.source();
      deviceCancelTokenSources[key] = cancelTokenSource;
      axiosConfig.cancelToken = cancelTokenSource.token;
    }

    const { data } = await axios.get(url, {
      params: {
        from,
        to,
        smooth_data: smoothingEnabled,
        smoothing_window: smoothingWindow,
      },
      ...axiosConfig,
    });

    if (data && data.response && data.response.data) {
      return data.response.data;
    }
    return {};
  },
  async GET_MULTI_DEVICE_SECOND_LEVEL_DATA(
    { dispatch },
    payload,
    enableCancel = true
  ) {
    const { deviceIds, ...restPayload } = payload;

    const responses = deviceIds.map(async (deviceId) => {
      const response = await dispatch(
        'GET_SECOND_LEVEL_DATA',
        {
          deviceId,
          ...restPayload,
        },
        enableCancel
      );

      return response;
    });

    return Promise.all(responses);
  },
  async GET_CYCLES_STATISTICS(_context, payload, enableCancel = true) {
    const { deviceId, ...rest } = payload;

    // Force to get the value from the backend if auto-cycle is disabled
    // Not the base place to put this but as there is no place on the UI to change this value
    // we can keep it here, we could also set it always to null

    const url = config.dataProxyUrls.v2.device.cyclesStatistics({ deviceId });

    const axiosConfig = {};

    try {
      // something is not working on cancel query check later
      // anyway, the inputs disabled until the previuos query finish
      if (enableCancel) {
        const key = `cycles_statistics-${deviceId}`;
        // see if we have an existing device token to cancel
        if (deviceCancelTokenSources[key]) {
          deviceCancelTokenSources[key].cancel('cancelled');
        }
        // generate a new source for the current request
        const cancelTokenSource = axios.CancelToken.source();
        deviceCancelTokenSources[key] = cancelTokenSource;
        axiosConfig.cancelToken = cancelTokenSource.token;
      }

      const response = await axios.post(
        url,
        {
          ...rest,
        },
        axiosConfig
      );

      const { data, status, statusText } = response ?? {};

      if (data?.response) {
        checkDataConsistency(data.response, payload);
        return data.response;
      }

      let errorDetails;
      try {
        errorDetails = JSON.stringify({
          data: data ?? '<missing>',
          status: status ?? '<unknown>',
          statusText: statusText ?? '<unknown>',
        });
      } catch {
        errorDetails = '<unable to serialize>';
      }

      throw new Error(`No cycles statistics data: ${errorDetails}`);
    } catch (e) {
      if (e instanceof Error && e.message !== 'cancelled') {
        this.loading = false;
        Sentry.withScope((scope) => {
          scope.setContext('cycles-context', { payload });
          scope.setTag('capture-source', 'vuex-action GET_CYCLES_STATISTICS');
          Sentry.captureException(e);
        });
        return {};
      } else {
        throw e;
      }
    }
  },
  async GET_CYCLES_SUMMARY({ rootState: { user } }, payload) {
    const { deviceId, ...rest } = payload;

    const url = config.dataProxyUrls.v2.device.cyclesSummary({ deviceId });

    const axiosConfig = {
      ...getAxiosOpts(user.token),
    };

    const key = `cycles_summary-${deviceId}`;
    // see if we have an existing device token to cancel
    if (deviceCancelTokenSources[key]) {
      deviceCancelTokenSources[key].cancel('cancelled');
    }
    // generate a new source for the current request
    const cancelTokenSource = axios.CancelToken.source();
    deviceCancelTokenSources[key] = cancelTokenSource;
    axiosConfig.cancelToken = cancelTokenSource.token;

    try {
      const { data } = await axios.get(url, {
        params: { ...rest },
        ...axiosConfig,
      });

      if (data?.response) {
        return data.response;
      }
      return {};
    } catch (e) {
      if (e.message !== 'cancelled') {
        console.error(e);
      }
      throw e;
    }
  },
  CANCEL_CYCLES_SUMMARY(_, { deviceId }) {
    cancelRequest(deviceCancelTokenSources, `cycles_summary-${deviceId}`);
  },
  async UPDATE_DEVICE_CYCLE_SETTINGS({ commit, rootGetters }, payload) {
    const { deviceId, settings } = payload;
    const device = rootGetters.getDeviceById(deviceId);
    // device not found
    if (!device.deviceid) return;

    if (!device.settings) {
      device.settings = {};
    }

    const newCyclesCountingSettings = {
      ...device.settings.cyclesCounting,
      ...settings,
    };

    device.settings.cyclesCounting = newCyclesCountingSettings;

    // Update localstorage if needed
    updateFilterForEachPage('device', device);

    // Update allDevice stored in vuex
    commit('UPDATE_DEVICE_CYCLE_SETTINGS', {
      deviceId,
      newSettings: newCyclesCountingSettings,
    });
  },
  async CANCEL_CYCLES_COUNTING(_, payload) {
    const { deviceId } = payload;

    const keyStatistics = `cycles_statistics-${deviceId}`;
    // see if we have an existing device token to cancel
    if (deviceCancelTokenSources[keyStatistics]) {
      deviceCancelTokenSources[keyStatistics].cancel('cancelled');
    }

    const keySecond = `second_level_data-${deviceId}`;
    // see if we have an existing device token to cancel
    if (deviceCancelTokenSources[keySecond]) {
      deviceCancelTokenSources[keySecond].cancel('cancelled');
    }
  },
  getOEE: async (_context, payload) => {
    const url = config.dataProxyUrls.v2.devices.getOEE();

    try {
      const { data, status, statusText } = await axios.get(url, {
        params: payload,
      });

      if (data && data.response) {
        return data.response;
      }
      throw new Error(
        `No oee data: ${JSON.stringify({ data, status, statusText })}`
      );
    } catch (e) {
      console.error('Error getting production entry', e);
      throw e;
    }
  },
  getDeviceSKU: async ({ rootState: { user }, rootGetters }, payload) => {
    const { deviceId, ...params } = payload;

    const dId = isDeviceGroup(deviceId)
      ? rootGetters.getGroupDefaultDevice(deviceId, 'cycletime_deviceid')
      : deviceId;

    const url = config.dataProxyUrls.v2.devices.getSKU({
      companyId: user.loggedInUserInfo.loggedInCompany,
      deviceId: dId,
    });

    // Limit the query to 10 hours max.
    // TODO: Remove this restriction once we implement a new way to get the predicted sku
    const diff = moment(params.to).diff(params.from, 'hours');

    if (diff > 10) {
      params.to = moment(params.from).add(10, 'hours').valueOf();
    }

    try {
      const { data, status } = await axios.get(url, {
        params,
      });

      if (status === 200) return data.response;
      return null;
    } catch (e) {
      console.error('Error getting device SKU');
      throw e;
    }
  },
  FETCH_MAKE_OPTIONS: fetchDeviceDetailsOptions('make', 'SET_MAKE_OPTIONS'),
  FETCH_MODEL_OPTIONS: fetchDeviceDetailsOptions('model', 'SET_MODEL_OPTIONS'),
  FETCH_PROCESS_OPTIONS: fetchDeviceDetailsOptions(
    'process',
    'SET_PROCESS_OPTIONS'
  ),
  FETCH_MATERIAL_OPTIONS: fetchDeviceDetailsOptions(
    'material',
    'SET_MATERIAL_OPTIONS'
  ),
  FETCH_SENSOR_TYPE_OPTIONS: fetchDeviceDetailsOptions(
    'sensor',
    'SET_SENSOR_TYPE_OPTIONS'
  ),
};
