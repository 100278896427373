import Vue from 'vue';

export default {
  SET_SCRAPS: (state, scraps) => {
    const newScraps = [...state.scraps];
    scraps.forEach((scrap) => {
      const idx = newScraps.findIndex(({ id }) => id === scrap.id);
      if (idx === -1) {
        newScraps.push(scrap);
      } else {
        newScraps[idx] = scrap;
      }
    });
    Vue.set(state, 'scraps', newScraps);
  },
  SET_SCRAP: (state, scrap) => {
    const scraps = [...state.scraps];
    let idx = scraps.findIndex(({ id }) => id === scrap.id);
    if (idx === -1) idx = scraps.length;
    scraps[idx] = scrap;
    Vue.set(state, 'scraps', scraps);
  },
};
