import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import { orderBy, merge, uniqBy, keyBy, compact, uniq } from 'lodash';
import { ChangelogInsert, ChangelogUpdate } from '@/models/Changelogs';
import getAxiosOpts from '@/helpers/axios';
import { RequestLoadSettings } from '@/models/store';
import { sortFunction } from '@/store/getters.js';
import { getUnit, findShift } from '../../helpers/productionPeriods';
import { company } from '../state';

const ALL_DEVICES_LIST = -1;

/**
 * This function is a helper fo sorting SKUS avoiding doing this in the
 * muttation SET_SKUS due to optimization issues and because
 * SET_SKUS is being called from several different places with
 * different datasources.
 *
 * @param {*} context : action context
 * @param {*} data : sku list
 */
const setSkus = (context, data) => {
  context.commit(
    'SET_SKUS',
    orderBy(data, (p) => p.skuCode.toLowerCase(), 'asc')
  );
};

const activeShifts = (shifts) => shifts.filter((s) => s.status === 'A');

// based on allShifts (already sorted by shiftname)
// returns list of shifts the user has permission against
// when (non Safi/Admin) user is selecting "All devices and shifts"
// they will have an aggregate list of shifts they are restricted to
const viewableShifts = (
  state?,
  getters?,
  rootState?,
  rootGetters?,
  settings = false
) => {
  const { devicesLists } = rootGetters.permissions;
  let allowed: number[] = [];

  if (devicesLists) {
    const lists = rootGetters['devicesLists/devicesLists'];
    // create a lookup for all devicesLists by id
    const listsLookup = keyBy(lists, 'id');

    // specific devices list selected, apply restrictions to just that chosen list
    if (
      devicesLists.selectedListId &&
      devicesLists.selectedListId !== ALL_DEVICES_LIST
    ) {
      let selectedList = listsLookup[devicesLists.selectedListId];
      // The viewWrapper is defaulting to the 1 available listIds item even though the user didn't
      // "select" it nor put it in the selectedListId
      // we need to take care of the case when it's only 1 available list and it has forced
      // selection in DevicesListsSelector, see items for population and selectedList for selecting
      // "if only 1" need to mimic the behavior of the list change selection,
      // otherwise selectedList === undefined
      if (rootGetters.userLevel > 1 && !selectedList && lists.length === 1) {
        [selectedList] = lists;
      }
      // shiftsFilter can be null from the db in rare cases
      if (selectedList && selectedList.shiftsFilter) {
        allowed = selectedList.shiftsFilter;
      }
    } else if (
      rootGetters.userLevel > 1 &&
      devicesLists.listsIds &&
      devicesLists.listsIds.length > 1
    ) {
      // if the user isn't safi or admin AND has not applied a selected a specific devices list
      // see if there are any restrictions via listsIds, look through each one and gather a
      // unique list of shiftId restrictions
      let collectedShifts: number[] = [];

      // using the LEAST restrictive model here when user selects 'All devices' list
      // if we determine that ANY devices list DOES NOT have a shift restriction
      // then we will show ALL shifts for 'All devices' selection
      let hasAllShiftsEnabled: boolean = false;
      devicesLists.listsIds.forEach((listsId) => {
        const listObj = listsLookup[listsId];
        if (
          listObj &&
          listObj.shiftsFilter &&
          listObj.shiftsFilter.length > 0
        ) {
          collectedShifts = collectedShifts.concat([...listObj.shiftsFilter]);
        } else {
          // TODO: convert to regular for loop to allow breaking out
          hasAllShiftsEnabled = true;
        }
      });

      if (!hasAllShiftsEnabled) {
        // apply a unique list of collected shifts
        allowed = [...new Set(collectedShifts)];
      }
    }
  }

  const availableShifts: any[] = settings
    ? getters.allShifts
    : activeShifts(getters.allShifts);

  return availableShifts.filter((shift: any) => {
    // no restrictions defined, show everything
    if (!allowed.length) {
      return true;
    }
    // TODO: figure out this tslint
    // https://stackoverflow.com/questions/52423842/what-is-not-assignable-to-parameter-of-type-never-error-in-typescript
    // @ts-ignore
    return allowed.includes(shift.id);
  });
};

export default {
  namespaced: true,
  state: {
    units: [],
    skus: [],
    allShifts: [],
    shifts: [],
    deviceArr: [],
    prodData: [],
    prodWidget: {
      deviceArr: { day: [], week: [] },
      prodData: { day: [], week: [] },
      units: { day: [], week: [] },
      loaded: {},
    },
  },
  getters: {
    units: (state) => state.units,
    skus: (state) => state.skus.filter((s) => !s.deleted_at),
    enabledSkus: (state) =>
      state.skus
        ? state.skus.filter((sku) => sku.status === 'A' && !sku.deleted_at)
        : [],
    // initial order set in SET_ALL_SHIFTS, sometimes state.allShifts was accessed instead of this
    // getter
    allShifts: (state) => state.allShifts,
    allEnabledShifts: (state) =>
      state.allShifts ? activeShifts(state.allShifts) : [],
    shifts: (...ctx) => viewableShifts(...ctx),
    enabledShifts: (...ctx) => viewableShifts(...ctx),
    orderEnabledShifts: (...ctx) => viewableShifts(...ctx),
    prodData: (state) => state.prodData,
    deviceArr: (state) => state.deviceArr,
    prodWidget: (state) => state.prodWidget,
    getSKUById: (state) => (id) =>
      state.skus.find((sku) => sku.id === Number(id)) || {},
    getProductionById: (state) => (id) =>
      state.prodData.find((prod) => prod.id === id),
    getProductionSkus: (state, getters, rootState, rootGetters) => {
      const productionPeriods = rootGetters['production/getAllProduction'];
      const productionSkusIds = uniq(
        compact(productionPeriods.map((pp) => pp.sku))
      );
      const skus = productionSkusIds.map((skuId) => getters.getSKUById(skuId));
      return skus.sort(({ skuCode: a }, { skuCode: b }) => sortFunction(a, b));
    },
    getAllSkuById: (state) => (skuId) => state.skusById[skuId],
    lastShifts: () => [
      { text: 'Most recent shift', id: -1 },
      { text: 'Second most recent shift', id: -2 },
    ],
    currentShift: () => [{ text: 'Current shift ', id: 'CurrentShift' }],
  },
  mutations: {
    ADD_SHIFT(state, payloads) {
      const shifts = [...state.shifts];
      shifts.push({
        ...payloads,
        status: 'A',
      });
      Vue.set(state, 'shifts', shifts);
      Vue.set(state, 'allShifts', orderBy(shifts, ['shiftname'], ['asc']));
    },
    UPDATE_SHIFT(state, payloads) {
      const shifts = [...state.shifts];
      const Index = state.shifts.findIndex((shift) => shift.id === payloads.id);
      merge(shifts[Index], payloads);
      Vue.set(state, 'shifts', shifts);
      Vue.set(state, 'allShifts', orderBy(shifts, ['shiftname'], ['asc']));
    },
    SET_UNITS(ctx, payloads) {
      ctx.units = payloads;
    },
    SET_SKUS(ctx, payloads) {
      ctx.skus = payloads;
      ctx.skusById = keyBy(payloads, 'id');
    },
    ADD_SKU(state, payloads) {
      const skus = [...state.skus];
      skus.push(payloads);
      Vue.set(state, 'skus', skus);
      Vue.set(state, 'skusById', keyBy(skus, 'id'));
    },
    UPDATE_SKU(state, payloads) {
      const skus = [...state.skus];
      const index = skus.findIndex((sku) => sku.id === payloads.id);
      merge(skus[index], payloads);
      Vue.set(state, 'skus', skus);
      Vue.set(state, 'skusById', keyBy(skus, 'id'));
    },
    SET_SHIFTS(ctx, payloads) {
      ctx.shifts = payloads;
    },
    SET_ALL_SHIFTS(ctx, payloads) {
      // order here before setting it to the state so all future references will be sorted
      ctx.allShifts = orderBy(payloads, ['shiftname'], ['asc']);
    },
    SET_PROD_PERIOD_DATA(ctx, { payload, range }) {
      if (range) {
        ctx.prodWidget.deviceArr[range] = [];
        ctx.prodWidget.prodData[range] = [];
        ctx.prodWidget.units[range] = [];
      } else {
        ctx.deviceArr = [];
      }
      const prodData = payload;
      prodData.forEach((prodItem) => {
        const pItem = prodItem;
        const duration =
          (moment(prodItem.dateTimeTo, 'YYYYMMDDHHmm').unix() -
            moment(prodItem.dateTimeFrom, 'YYYYMMDDHHmm').unix()) /
          60;
        const startTime =
          (moment(prodItem.dateTimeFrom, 'YYYYMMDDHHmm').unix() -
            moment(prodItem.dateTimeFrom, 'YYYYMMDD').unix()) /
          60;
        const shiftFound = findShift(ctx.shifts, {
          duration,
          startTime,
        });
        if (shiftFound) {
          pItem.shiftname = shiftFound.shiftname;
        } else {
          pItem.shiftname = `N/A | Ended ${moment(
            pItem.dateTimeTo,
            'YYYYMMDDHHmm'
          ).format('YYYY-MM-DD HH:mm')}`;
        }
        pItem.datefromunix = moment(pItem.dateTimeFrom, 'YYYYMMDDHHmm').unix();
        pItem.datetounix = moment(pItem.dateTimeTo, 'YYYYMMDDHHmm').unix();
        // Capture only devices that have recorded prod. shifts
        if (!range && ctx.deviceArr.indexOf(pItem.deviceId) === -1) {
          ctx.deviceArr.push(pItem.deviceId);
        } else if (
          range &&
          ctx.prodWidget.deviceArr[range].indexOf(pItem.deviceId) === -1
        ) {
          ctx.prodWidget.deviceArr[range].push(pItem.deviceId);
        }
      });

      if (!range) {
        ctx.prodData = prodData;
        if (ctx.deviceArr.indexOf('All devices (unsorted shift)') === -1) {
          ctx.deviceArr.push('All devices (unsorted shift)');
        }
      } else {
        ctx.prodWidget.prodData[range] = prodData;
        if (
          ctx.prodWidget.deviceArr[range].indexOf(
            'All devices (unsorted shift)'
          ) === -1
        ) {
          ctx.prodWidget.deviceArr[range].push('All devices (unsorted shift)');
        }
      }

      if (range && ctx.units.length > 0) {
        const unitsFound: Array<{ id: number; unitName: string }> = [];
        ctx.prodWidget.prodData[range].forEach((pd) => {
          if (pd.details) {
            unitsFound.push({
              id: pd.details[0].unitId,
              unitName: getUnit(ctx.units, pd.details[0].unitId),
            });
          }
        });
        ctx.prodWidget.units[range] = uniqBy(unitsFound, 'unitName');
        ctx.prodWidget.units[range].splice(0, 0, {
          id: -1,
          unitName: 'All',
        });
      }

      if (range) {
        ctx.prodWidget.loaded[range] = true;
      }
    },
    ADD_EDIT_SKU(state, payload) {
      const { id } = payload;
      const idx = state.skus.findIndex((s) => s.id === id);
      const newState = [...state.skus];
      if (idx > -1) {
        newState.splice(idx, 1, payload);
      } else {
        newState.push(payload);
      }
      Vue.set(state, 'skus', newState);
      Vue.set(state, 'skusById', keyBy(newState, 'id'));
    },
  },
  actions: {
    async LOAD_ITEMS(context) {
      const options = getAxiosOpts(context.rootState.user.token);
      const url = `/api/production/items/all/${company}`;
      try {
        const { data } = await axios.get(url, options);
        context.commit('SET_UNITS', data.data.units);
        setSkus(context, data.data.skus);
        context.commit('SET_SHIFTS', data.data.shifts);
        return Promise.resolve(true);
      } catch (error) {
        console.log(
          'vuex module productionPeriods > [LOAD_ITEMS] error: ',
          error.message
        );
        return Promise.reject(error.message);
      }
    },
    async GET_PROD_PERIOD_DATA(context, { from, to, device, isWidget, range }) {
      context.prodData = [];
      let url = `/api/production/periods/${company}/${from}/${to}?iswidget=${isWidget ? 'true' : 'false'}`;
      if (device) {
        url = `${url}&deviceid=${encodeURIComponent(device)}`;
      }
      try {
        const options = getAxiosOpts(context.rootState.user.token);
        const { data } = await axios.get(url, options);
        context.commit('SET_PROD_PERIOD_DATA', { payload: data.data, range });
        return Promise.resolve(true);
      } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
      }
    },
    async LOAD_SKUS(
      context,
      settings: RequestLoadSettings = {
        ignoreCache: false,
        includeAllItems: false,
      }
    ): Promise<any> {
      const {
        state: { skus },
      } = context;
      if (!settings.ignoreCache && skus.length) return;
      const options = getAxiosOpts(context.rootState.user.token);
      const url = `/api/production/skus/${company}${settings.includeAllItems ? '?settings=true' : ''}`;
      try {
        const { data } = await axios.get(url, options);
        setSkus(context, data.data);
        Promise.resolve(true);
      } catch (error) {
        console.log(
          'vuex module productionPeriods > [LOAD_SKUS] error: ',
          error.message
        );
        Promise.reject(error.message);
      }
    },
    async LOAD_SHIFTS(context, settings) {
      const {
        state: { shifts = [] },
      } = context;
      if (shifts.length) return;
      const options = getAxiosOpts(context.rootState.user.token);
      const url = `/api/production/shifts/${company}${settings ? '?settings=true' : ''}`;
      try {
        const { data } = await axios.get(url, options);
        context.commit('SET_SHIFTS', data.data);
        context.commit('SET_ALL_SHIFTS', data.data);
      } catch (error) {
        console.log(
          'vuex module productionPeriods > [LOAD_SHIFTS] error: ',
          error.message
        );
        throw error;
      }
    },
    async SAVE({ rootState, getters }, payload) {
      const method = payload.id === -1 ? 'post' : 'put';
      const options = getAxiosOpts(rootState.user.token);
      const url = `/api/production/periods/${company}/`;

      if (payload.id === -1) {
        // Changelog insert
        // Create a new changelog value for this entry
        payload.changelog = [];
        const changelog = new ChangelogInsert({
          user: {
            username: rootState.user.loggedInUserInfo.username,
            companyid: rootState.user.loggedInUserInfo.companyid,
          },
          data: ['Entry created'],
        });

        // Add that new changelog to the array of changelogs in the entry
        changelog.appendChangelogToEntry(payload);
      } else {
        // Changelog update
        // Add original changelog to the entry
        const originalEntry = getters.getProductionById(payload.id) || {};
        payload.changelog = originalEntry.changelog;
        // Create a new changelog value for this update
        const changelog = new ChangelogUpdate({
          user: {
            username: rootState.user.loggedInUserInfo.username,
            companyid: rootState.user.loggedInUserInfo.companyid,
          },
          data: ['Entry updated'],
        });

        // Add old data to the changelog created
        changelog.addData({ ...originalEntry });
        // Add that new changelog to the array of changelogs in the entry
        changelog.appendChangelogToEntry(payload);
      }

      try {
        const { data } = await axios[method](url, payload, options);
        await axios.get(
          `/api/cachehome?companyid=${rootState.company.toLowerCase()}&widget=prodperiods&withurls=true`,
          options
        );
        return Promise.resolve(data);
      } catch (error) {
        console.log(
          'vuex module productionPeriods > [SAVE] error: ',
          error.message
        );
        return Promise.reject(error.message);
      }
    },
    async DELETE(context, payload) {
      const options = getAxiosOpts(context.rootState.user.token);
      const url = '/api/production/period/remove/';
      try {
        const params = { id: payload };
        const { data } = await axios.post(url, params, options);
        await axios.get(
          `/api/cachehome?companyid=${context.rootState.company.toLowerCase()}&widget=prodperiods&withurls=true`,
          options
        );
        return Promise.resolve(data);
      } catch (error) {
        console.log(
          'vuex module productionPeriods > [DELETE] error: ',
          error.message
        );
        return Promise.reject(error.message);
      }
    },
    async SAVE_SKU({ rootState, commit, dispatch }, payload) {
      const options = getAxiosOpts(rootState.user.token);
      const url = `/api/production/skus/${company}/`;
      try {
        const { data } = await axios.post(
          url,
          {
            id: payload.id || -1,
            skucode: payload.skuCode,
            status: payload.status,
          },
          options
        );
        if (!data.success) {
          const error = { message: data.error };
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: `Sorry an error occurred ${data.error}. Please try again.`,
              alertType: 'error',
            },
            { root: true }
          );
          throw error;
        }
        let action;
        if (payload.id === -1) {
          const { id } = data;
          action = 'created';
          commit('ADD_SKU', { ...payload, id });
        } else {
          action = 'updated';
          commit('UPDATE_SKU', payload);
        }
        dispatch(
          'SHOW_SNACKBAR',
          {
            alertMessage: `Product ${action} successfully.`,
          },
          { root: true }
        );
        return Promise.resolve(data.id);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
    async SAVE_SHIFT({ commit, dispatch, rootState }, payload) {
      const options = getAxiosOpts(rootState.user.token);
      const url = `/api/production/shifts/${company}/`;
      try {
        const { data } = await axios.post(url, payload, options);
        if (!data.success) {
          const error = {
            message: data.error,
          };
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: `${data.error}. Please try again.`,
              alertType: 'error',
            },
            { root: true }
          );
          throw error;
        }
        let action;
        if (payload.id === -1) {
          action = 'created';
          const { id } = data;
          commit('ADD_SHIFT', { ...payload, id, status: 'A' });
        } else {
          action = 'updated';
          commit('UPDATE_SHIFT', payload);
        }
        dispatch(
          'SHOW_SNACKBAR',
          {
            alertMessage: `Shift ${action} successfully.`,
          },
          { root: true }
        );
        // // else modificar shift
        // url = `/api/production/shifts/${company}?settings=true`;
        // const result = await axios.get(url, options);
        // //
        // context.commit('SET_SHIFTS', result.data.data);
        return Promise.resolve(data.id);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
    async DELETE_SKU(context, payload) {
      const options = getAxiosOpts(context.rootState.user.token);
      const delUrl = `/api/production/skus/remove/${company}/${payload}`;
      const getUrl = `/api/production/skus/${company}`;
      try {
        const { data } = await axios.delete(delUrl, options);
        const result = await axios.get(getUrl, options);
        //
        setSkus(context, result.data.data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
    async DELETE_SHIFT(context, payload) {
      const options = getAxiosOpts(context.rootState.user.token);
      const delUrl = `/api/production/shifts/remove/${company}/${payload}`;
      const getUrl = `/api/production/shifts/${company}`;
      try {
        const { data } = await axios.delete(delUrl, options);
        const result = await axios.get(getUrl, options);
        //
        context.commit('SET_SHIFTS', result.data.data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
  },
};
