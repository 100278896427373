function sortArrayWithLoadTypeCriteria(arrayToSort) {
  const loadtypePriority = {
    mains_total: 10,
    mains_part: 9,
    mains_machine: 8,
    mains_solar: 7,
    mains_generator: 6,
    ignore_mains: 5,
    ignore_machine: 4,
    ignore_utility: 3,
    utility: 2,
    machine: 1,
  };
  arrayToSort.sort((a, b) => {
    if (loadtypePriority[a.loadtype] && loadtypePriority[b.loadtype]) {
      if (loadtypePriority[b.loadtype] < loadtypePriority[a.loadtype]) {
        return -1;
      }

      if (loadtypePriority[b.loadtype] > loadtypePriority[a.loadtype]) {
        return 1;
      }

      const aNumberStr = a.nickname.match(/\d+/);
      const bNumberStr = b.nickname.match(/\d+/);
      let aNumber = 0;
      let bNumber = 0;
      if (aNumberStr && bNumberStr) {
        aNumber = Number(aNumberStr[0]);
        bNumber = Number(bNumberStr[0]);
        const indexOfNumberA = a.nickname.indexOf(aNumberStr[0]);
        const indexOfNumberB = b.nickname.indexOf(bNumberStr[0]);
        const aSubstring = a.nickname
          .substring(0, indexOfNumberA)
          .trim()
          .toLowerCase();
        const bSubstring = b.nickname
          .substring(0, indexOfNumberB)
          .trim()
          .toLowerCase();

        if (aSubstring > bSubstring) {
          return 1;
        }

        if (aSubstring < bSubstring) {
          return -1;
        }

        if (aNumber > bNumber) {
          return 1;
        }

        if (aNumber < bNumber) {
          return -1;
        }

        return 1;
      }

      if (a.nickname > b.nickname) {
        return 1;
      }

      if (a.nickname < b.nickname) {
        return -1;
      }
    }
    return 1;
  });

  return arrayToSort;
}

export default { sortArrayWithLoadTypeCriteria };
