<template>
  <div>
    <div ref="flyaway">
      <!-- so named because it will fly away to another component -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import InfoWindowImpl from './infoWindowImpl.js';

export default InfoWindowImpl;
</script>
