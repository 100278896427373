import axios from 'axios';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/state.ts';
import config from '@/config';
import {
  ADD_LIST,
  UPDATE_LIST,
  SET_LISTS,
  REMOVE_LIST,
} from './mutations-types';
import { initialState, State } from './model';

const actionTypes = {
  CHANGE_DEVICES_LIST: 'CHANGE_DEVICES_LIST',
};

export { actionTypes };

export default {
  async getDevicesLists(context: ActionContext<State, RootState>, payload) {
    /* Your action content here */
    try {
      const {
        status,
        statusText,
        data: { response },
      } = await axios.get(
        config.dataProxyUrls.v2.devicesLists.url({
          companyId: context.rootState.company,
        })
      );

      if (status === 200) {
        context.commit(SET_LISTS, response);
        return response;
      }
      throw new Error(statusText);
    } catch (e) {
      console.log('Action getDevicesLists: ', e);
      throw e;
    }
  },
  async saveDeviceList(context: ActionContext<State, RootState>, payload) {
    const { id, companyId, ...rest } = payload;

    const item = id === -1 ? payload : { ...rest };

    try {
      const response = await axios[id === -1 ? 'post' : 'put'](
        config.dataProxyUrls.v2.devicesLists.url({
          companyId: context.rootState.company,
          id: id === -1 ? null : id,
        }),
        item
      );

      if (response.status === 200) {
        // new item
        if (id === -1) {
          context.commit(ADD_LIST, response.data.response[0]);
        } else {
          // updating item
          context.commit(UPDATE_LIST, response.data.response[0]);
        }
      } else {
        throw new Error(response.statusText);
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async deleteDeviceList(context: ActionContext<State, RootState>, payload) {
    try {
      const response = await axios.delete(
        config.dataProxyUrls.v2.devicesLists.url({
          companyId: context.rootState.company,
          id: payload,
        })
      );

      if (response.status === 200) {
        // delete item from the list
        context.commit(REMOVE_LIST, payload);
      } else {
        throw new Error(response.statusText);
      }
    } catch (err) {
      throw err;
    }
  },
  [actionTypes.CHANGE_DEVICES_LIST]: ({ dispatch }, data = {}) => {
    // Uptime page
    dispatch('cancelAllDeviceRequests', null, { root: true });

    // Trends
    // module namespaced devicesLoadStates
    dispatch('devicesLoadStates/cancelLoadStatesSingleRequests', null, {
      root: true,
    });
  },
};
