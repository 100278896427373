import en from 'vuetify/es5/locale/en';
import constants from '@/config/constants';

export default {
  ...en,
  FROM: 'from',
  TO: 'to',
  USERNAME_MISSPELLED: `Username spelling:
  Please make sure that there are no extra spaces and that spelling is correct.`,
  TIME_TOOLTIP: 'Scheduled time of production',
  RUN_TIME_TOOLTIP: `Run-time is the % of time the machine is in a "running" state,
  i.e. the load is above the "idle" threshold. Up-time is the % of time the
  machine is available, i.e. the load is above the "offline" threshold.`,
  PROD_RATE_TOOLTIP:
    'Number of units produced over time, calculated using speed sensor data.',
  PRODUCTION_TOOLTIP: `Total good production. The target is either a fixed target or a
  "normal speed", the ideal rate of production produced per hour. Does not
  include waste.`,
  PRODUCTION_ONLY_ESTIMATED_VALUE_TOOLTIP:
    'Our system estimates that the machine produced',
  WASTE_ONLY_ESTIMATED_VALUE_TOOLTIP:
    'Our system estimates that the machine wasted',
  TARGET_ONLY_ESTIMATED_VALUE_TOOLTIP: 'Our system estimates a target of',
  PRODUCTION_ESTIMATED_VALUE_TOOLTIP: 'The system estimates production of',
  TARGET_ESTIMATED_VALUE_TOOLTIP: 'with an estimated target of',
  PRODUCTION_NULL_VALUE_TOOLTIP:
    'Not enough data to compute the production quantity',
  WASTE_ESTIMATE_LESS_THAN_PRODUCTION_TOOLTIP:
    'No waste estimation is available because estimated production is less than actual production.',
  WASTE_TOOLTIP: `Total waste. The target is either a fixed target or an ideal rate
  of minimal waste produced per hour.`,
  OEE_TOOLTIP: 'Overall Equipment Effectiveness = A x P x Q.',
  OEE_TOOLTIP_PERFORMANCE_EXPLANATION: `* Performance can only be greater than 100%
  if the machine produces more than the target or standard
  (we suggest updating the target or standard in this case). OEE cannot be more than 100%.`,
  OEE_NULL_VALUES_TOOLTIP: `When there is no data available for computation (-), the Performance
    or Quality scores will be removed from the equation.`,
  EI_TOOLTIP: 'Energy Intensity = Energy consumed / Total Production.',
  CYCLES_TOOLTIP: `Average Cycle Time for the duration of the scheduled time.<br/>
  Note: if the "ignore extended delay" setting is enabled,
  the average cycle time will ignore those long cycles in the average calculation.`,
  ENERGY: 'Energy',
  ENERGY_CONSUMED: 'Energy consumed',
  PRODUCTION: 'Production',
  TARGET: 'Target',
  WASTE: 'Waste',
  EDIT: 'Edit',
  DELETE: 'Delete',
  WRITE_A_COMMENT: 'Write a comment...',
  COMMENT_TOO_LONG: `Too long, max is ${constants.COMMENTS_MAX_LENGTH} characters`,
  NOTE_CHANGE_SETTINGS: `These changes will only affect analysis going forward from now. If you would
  like data to be calculated for historical data, please contact your Safi
  representative.`,
  SCHEDULED: 'Scheduled',
  DEVICE: 'Device',
  SCHEDULED_DOWNTIME: 'Scheduled Downtime',
  PERCENTAGE_SYMBOL: '%',
  SPEED: 'Speed',
  BATCH: 'Batch',
  COMMENT: 'Comment',
  TAGS: 'Tags',
  TAG: 'Tag',
  SECONDARY: 'Secondary',
  PEOPLE: 'People',
  OPERATOR: 'Operator',
  MANAGER: 'Manager',
  SUPERVISOR: 'Supervisor',
  QTY: 'Qty.',
  UNIT: 'Unit',
  PRODUCT: 'Product',
  POST: 'Post',
  ALERTS_PES_IN_PROGRESS:
    'To Enable / Disable an alert you have to press save.',
  ALERTS_PES_IN_PROGRESS_BELL: 'Blue if alert is configured and enabled.',
  ISSUE: 'issue',
  SUMMARY: 'Summary',
  BULKACTION_ASSIGN: 'Assign to',
  BULKACTION_TAGS: 'Change tags',
  BULKACTION_CLOSEDISSUE: 'Close issues',
  BULKACTION_ACKNISSUE: 'Acknowledged',
  BULKACTION_OPENISSUE: 'Open issues',
  BULKACTION_DELETEISSUE: 'Delete issues',
  'SYSTEM-ALERT': 'System Alert',
  'ALERTS-ISSUE': 'System Alert',
  'DIAGNOSTICS-ISSUE': 'diagnostics issue',
  'PRODUCTION-ISSUE': 'production issue',
  'MANUAL-ISSUE': 'manual issue',
  'PRODUCTION-readyForReview': 'Ready for Review Production Entry',
  'PRODUCTION-completed': 'Completed Production Entry',
  'PRODUCTION-inProgress': 'In-progress Production Entry',
  'PRODUCTION-others': 'Later today',
  'ISSUE:plural': 'issues',
  'SYSTEM-ALERT:plural': 'System Alerts',
  'ALERTS-ISSUE:plural': 'System Alerts',
  'DIAGNOSTICS-ISSUE:plural': 'diagnostics issues',
  'PRODUCTION-ISSUE:plural': 'production issues',
  'MANUAL-ISSUE:plural': 'manual issues',
  'PRODUCTION-readyForReview:plural': 'Ready for Review Production Entries',
  'PRODUCTION-completed:plural': 'Completed Production Entries',
  'PRODUCTION-inProgress:plural': 'In-progress Production Entries',
  'PRODUCTION-others:plural': 'Later today',
  REQUIRED_FIELD: 'Required field',
};
