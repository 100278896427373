<template>
  <div v-if="userInfo">
    <v-navigation-drawer
      class="justify-start sideBar pt-1"
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      :permanent="!mobile"
      hide-overlay
      :temporary="mobile"
      :width="mobile ? '100%' : '150'"
    >
      <div class="d-flex flex-column align-center">
        <v-btn
          v-if="!mobile"
          text
          @click.stop="mini = !mini"
          class="sidebar-edge-toggle"
        >
          <span hidden>Toggle sidebar</span>
        </v-btn>
        <v-list dense style="width: 100%">
          <v-btn v-if="mobile" icon @click.stop="collapse" style="float: right">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-list-item
            link
            to="/"
            color="primary"
            active-class="selected-item"
            class="d-md-flex d-none"
            @click.stop=""
          >
            <v-list-item-title>
              <svg class="SA-logo logo-full" height="40" width="118">
                <use href="#logo"></use>
              </svg>
              <svg class="SA-logo logo-symbol" height="40" width="118">
                <use href="#logo-symbol"></use>
              </svg>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="d-md-none menu-label">
            <v-list-item-title> Menu </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-capitalize text-left company-name">
              {{ companyname }}
            </v-list-item-title>
          </v-list-item>
          <template v-for="item in items">
            <!-- Item with children -->
            <v-tooltip
              top
              v-if="item.children"
              :key="`tooltip-${item.navItem.text}`"
            >
              <template v-slot:activator="{ on }">
                <v-list-group
                  v-on="mini ? on : null"
                  v-model="item.active"
                  :key="`vlg-${item.navItem.text}`"
                  link
                  class="menuButton"
                >
                  <template #prependIcon>
                    <svg class="sidebar-icon" aria-hidden="true">
                      <use :href="`#${item.navItem.icon}`"></use>
                    </svg>
                  </template>
                  <template v-slot:activator>
                    <v-list-item-title>
                      {{ item.navItem.text }}
                    </v-list-item-title>
                  </template>
                  <v-list-item
                    v-for="(child, i) in item.children"
                    :key="`c-${i}`"
                    :to="child.navItem.to"
                    link
                    class="sidebar-child-link"
                  >
                    <v-list-item-title class="listGroupItem">
                      {{ child.navItem.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-group>
              </template>
              <span>{{ item.navItem.text }}</span>
            </v-tooltip>
            <!-- End Item with children -->
            <!-- Item without children -->
            <v-tooltip top v-else :key="`tooltip-${item.navItem.text}`">
              <template v-slot:activator="{ on }">
                <v-list-item
                  v-on="mini ? on : null"
                  :key="`vlt-${item.navItem.text}`"
                  :to="item.navItem.to"
                  color="primary"
                  active-class="selected-item"
                  link
                  @click.native="trackClick(item.navItem.text)"
                  class="menuButton"
                >
                  <v-list-item-icon class="my-0 mr-2">
                    <svg class="sidebar-icon" aria-hidden="true">
                      <use :href="`#${item.navItem.icon}`"></use>
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.navItem.text }}</v-list-item-title>
                  <v-list-item-action v-if="item.newFeature">
                    <v-badge left color="#502DD5">
                      <span slot="badge" style="padding-top: 3px">*</span>
                    </v-badge>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <span>{{ item.navItem.text }}</span>
            </v-tooltip>
            <!-- End Item without children -->
          </template>
        </v-list>
      </div>
      <template v-slot:append>
        <v-list dense>
          <v-tooltip top v-if="!mobile">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="mini ? on : null" @click.stop="collapse">
                <v-list-item-icon class="mr-2">
                  <svg class="sidebar-icon" aria-hidden="true">
                    <use
                      :href="
                        mini ? '#sidebar-expand-icon' : '#sidebar-collapse-icon'
                      "
                    ></use>
                  </svg>
                </v-list-item-icon>
                <v-list-item-title>Collapse</v-list-item-title>
              </v-list-item>
            </template>
            <span>Expand</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-list-item
                v-on="on"
                target="_blank"
                href="https://support.guidewheel.app"
              >
                <v-list-item-icon class="mr-2">
                  <svg class="sidebar-icon" aria-hidden="true">
                    <use href="#help"></use>
                  </svg>
                </v-list-item-icon>
                <v-list-item-title>Help center</v-list-item-title>
              </v-list-item>
            </template>
            <span>Go to help center</span>
          </v-tooltip>
          <v-menu
            top
            open-on-hover
            offset-x
            :nudge-left="mini ? '10' : '30'"
            nudge-top="8"
            min-width="0"
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-icon class="mr-2">
                  <svg class="sidebar-icon" aria-hidden="true">
                    <use href="#user-icon"></use>
                  </svg>
                </v-list-item-icon>
                <v-list-item-title class="d-flex align-center">
                  <span class="username" :title="userInfo.username">
                    {{ userInfo.username }}
                  </span>
                  <v-icon
                    v-if="!mobile"
                    class="ml-auto"
                    style="float: right"
                    small
                  >
                    mdi-dots-vertical
                  </v-icon>
                  <v-btn
                    class="ml-4"
                    text
                    v-if="mobile"
                    small
                    color="primary"
                    @click="logout"
                  >
                    <svg class="user-menu-icon mr-1" aria-hidden="true">
                      <use href="#logout"></use>
                    </svg>
                    Logout
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </template>
            <div class="user-menu">
              <v-btn v-if="!mobile" small text color="primary" @click="logout">
                <svg class="user-menu-icon mr-1" aria-hidden="true">
                  <use href="#logout"></use>
                </svg>
                Logout
              </v-btn>
            </div>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      fixed
      clipped-left
      dense
      v-if="mobile && showAppBar"
      class="mobile-menu"
    >
      <v-app-bar-nav-icon @click.stop="collapse"></v-app-bar-nav-icon>
      <svg class="SA-logo" height="36" width="140">
        <use href="#logo"></use>
      </svg>
      <v-spacer />
    </v-app-bar>
    <v-main>
      <div v-if="showCollapseIcon" class="collapseIcon">
        <div @click.stop="collapse">
          <v-icon>mdi-menu</v-icon>
        </div>
      </div>
      <NotificationBar />
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import createLogger from '@/utils/logging';
import analytics from '@/mixins/analytics';
import { isMobileOrTable } from '@/helpers/display';
import { NotificationBar } from '@/components/NotificationBar';
import { CATEGORIES, EVENTS } from '@/constants/analytics';

const logger = createLogger('AsynMenu Instance');
export default {
  name: 'navigation-layout',
  components: {
    NotificationBar,
  },
  mixins: [analytics],
  props: {
    routes: {
      required: true,
    },
  },
  data() {
    return {
      theme: false,
      drawer: null,
      userInfo: '',
      companyInfo: null,
      items: [],
      mini: false,
    };
  },
  // update the page title on route change, beforeRouteUpdate doesn't work
  // https://stackoverflow.com/a/56325120
  updated() {
    if (this.$route && this.$route.name && document) {
      document.title = this.$route.name;
    }
  },
  async beforeMount() {
    this.userInfo = this.$store.getters.getLoggedInUser;
    this.companyInfo = this.$store.getters.getCompanyInfo;
    this.company = this.$store.state.company;
    await this.$store.dispatch('GET_COMPANY', this.$store.getters.companyid);
    // Select only routes that has an item in the sidebar
    // or if they are meant to be shown to the company
    this.routes.forEach((route) => {
      if (!this.routeCheck(route)) return;
      if (!route.children) {
        if (
          !route.meta.requiresAuthorizationLevel ||
          route.meta.requiresAuthorizationLevel >= this.userInfo.level
        ) {
          this.items.push(route);
          return;
        }
      }
      const children = route.children.filter((childroute) => {
        if (
          childroute.meta.feature &&
          !this.featureCheck(childroute.meta.feature)
        )
          return false;
        if (childroute.meta.requiresAuthorizationLevel === undefined)
          return true;
        return (
          childroute.meta.requiresAuthorizationLevel >= this.userInfo.level
        );
      });
      if (children.length !== 0) {
        this.items.push({
          ...route,
          children,
        });
      }
    });
    await this.getAutoCompletionMessages();
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown || isMobileOrTable();
    },
    ...mapGetters({
      exportDataUnread: 'exportData/unread',
      getLoggedInUser: 'getLoggedInUser',
      devices: 'devices',
      isFeatureEnabled: 'isFeatureEnabled',
    }),
    selectedView() {
      return this.$store.state.selectedView;
    },
    selectedDevice() {
      return this.$store.state.selectedDevice || 0;
    },
    isTheme() {
      return this.$store.state.user.isLightTheme;
    },
    companyname() {
      return this.$store.getters.GetCompanyName;
    },
    creditsClass() {
      return this.mini ? 'd-none' : 'credits';
    },
    showAppBar() {
      return !this.$route.meta?.hideAppBar;
    },
    showCollapseIcon() {
      return this.mobile && !this.showAppBar;
    },
  },
  watch: {
    mobile(newValue) {
      this.mini = !newValue;
    },
  },
  methods: {
    collapse() {
      if (this.mobile) {
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini;
      }
    },
    closeChannel(channel) {
      this.$store.dispatch('CLOSE_CHANNEL', channel);
    },
    logout() {
      this.$store.dispatch('LOGOUT');
      // this.$router.replace('/login');
    },
    setTheme(newVal) {
      this.$store.commit('SET_THEME', newVal);
    },
    // this only checks for the companyid to show nav item
    // does not check for AND requiresAuthorizationLevel
    routeCheck(route) {
      if (route.navItem) {
        if (route.meta.requiresAuthorizationLevel < this.userInfo.level)
          return null;
        if (route.meta.feature && !this.featureCheck(route.meta.feature))
          return null;
        if (this.whitelistCheck(route)) {
          return null;
        }
        return route;
      }
      return null;
    },
    featureCheck(feature) {
      return this.isFeatureEnabled(feature);
    },
    whitelistCheck(route) {
      return (
        route.meta &&
        route.meta.pageWhitelist &&
        !route.meta.pageWhitelist.includes(this.company)
      );
    },
    async getAutoCompletionMessages() {
      const params = {
        from: moment(Date.now()).subtract(1, 'week').valueOf(),
        to: moment().valueOf(),
      };
      try {
        await this.$store.dispatch('issues/getIssuesMessages', params);
      } catch (err) {
        logger.error({
          user: this.$store.state.user.loggedInUserInfo.username,
          company: this.$store.state.company,
          message: `GET_ISSUES_MESSAGES: ${err.message}`,
        });
      }
    },
    trackClick(item) {
      this.track(
        EVENTS.navMenuClick,
        undefined,
        item,
        undefined,
        CATEGORIES.navigation
      );
    },
  },
};
</script>

<style>
.deviceSelector {
  left: 37%;
}
/* .deviceSelector div button {
  background-color: #303030 !important;
} */
@media (max-width: 600px) {
  .deviceSelector {
    left: 0%;
  }
}
.selected-item::before {
  background-color: inherit;
}
.selected-item:hover::before {
  opacity: 0.08 !important;
  background-color: #ffffff;
}
.company-name {
  text-transform: capitalize;
  text-overflow: initial;
  white-space: normal;
}
.v-navigation-drawer .SA-logo__text {
  display: initial;
}
.v-navigation-drawer--mini-variant .SA-logo__text {
  display: none;
}

.logo-symbol,
.v-navigation-drawer--mini-variant .logo-full {
  display: none;
}

.v-navigation-drawer--mini-variant .logo-symbol {
  display: block;
}

.v-navigation-drawer--mini-variant .SA-logo {
  width: 120px;
}

.mobile-menu {
  background-color: white !important;
  box-shadow: 1px 0 0 1px #e0e0e0 !important;
}

.mobile-menu > .v-toolbar__content {
  padding: 4px 1rem;
}

@media (min-width: 600px) {
  .mobile-menu > .v-toolbar__content {
    padding: 4px 2rem;
  }
}

.user-menu {
  padding: 0.75rem 0.5rem 0.5rem;
  border: 1px solid rgb(221, 221, 221);
  align-items: center;
  display: flex;
  background-color: white;
  border-radius: 4px;
}

.user-menu-icon {
  height: 1rem;
  width: 1rem;
}

@media (max-height: 600px) {
  .sideBar .v-navigation-drawer__append {
    border-top: 1px solid #e0e0e0;
  }
}

.menuButton.v-list-item {
  min-height: 36px;
}
.menuButton .v-list-item__icon {
  align-self: center;
}
.collapseIcon {
  z-index: 1;
  position: absolute;
  top: 42px;
  left: 8px;
  cursor: pointer;
}

@media (max-width: 940px) {
  .sideBar.v-navigation-drawer--is-mobile .v-list-item {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .sideBar.v-navigation-drawer--is-mobile .v-list-item__title {
    font-size: 1rem !important;
    line-height: 1.3;
  }

  .sideBar.v-navigation-drawer--is-mobile .sidebar-icon {
    height: 1.5rem;
    width: 1.5rem;
    max-width: 1.5rem;
  }
  .sideBar.v-navigation-drawer {
    overflow: auto !important;
    overflow-x: hidden;
  }
}

.sideBar.v-navigation-drawer--is-mobile .v-navigation-drawer__content {
  overflow: visible;
}

.menu-label {
  min-height: 0 !important;
}

@media (max-width: 940px) and (max-height: 610px) {
  .sideBar.v-navigation-drawer--is-mobile .v-navigation-drawer__content {
    height: auto;
  }
}
</style>
