
<label>
  <span v-text="label"></span>
  <input
    type="text"
    :placeholder="placeholder"
    :class="className"
    ref="input"
  />
</label>
