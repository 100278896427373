import { ActionContext } from 'vuex';
import { RootState } from '@/store/state.ts';
import { SHOW, HIDE } from './mutations-types';
import { initialState, State } from './model';

export default {
  show(context: ActionContext<State, RootState>, payload) {
    context.commit(SHOW, payload);
  },
  hide(context: ActionContext<State, RootState>, payload) {
    context.commit(HIDE);
  },
};
