import Vue from 'vue';
import { CycleCountSetting } from '@/store/state';
import {
  SET_CYCLES_SETTINGS_DATA,
  SET_CYCLES_SETTINGS,
  SET_CYCLES_PREVIEW_SETTINGS,
  SET_LOADING_STATE,
  SET_CYCLES_QUERY,
} from './mutations-types';
import { initialState, ResponseSettings, State } from './model';

export default {
  [SET_LOADING_STATE](state: State, payload: Boolean) {
    Vue.set(state, 'loading', payload);
  },
  [SET_CYCLES_SETTINGS_DATA](state: State, payload: ResponseSettings) {
    Vue.set(state, 'data', payload);
  },
  [SET_CYCLES_SETTINGS](state: State, payload: CycleCountSetting) {
    Vue.set(state.data, 'settings', {
      ...state.data.settings,
      ...payload,
    });
  },
  [SET_CYCLES_PREVIEW_SETTINGS](state: State, payload: CycleCountSetting) {
    Vue.set(state, 'previewSettings', {
      ...state.previewSettings,
      ...payload,
    });
  },
  [SET_CYCLES_QUERY](state: State, payload) {
    Vue.set(state, 'query', {
      ...state.query,
      ...payload,
    });
  },
};
