const createEvent = (data, el, vnode) => {
  const {
    event,
    category = `${vnode.componentInstance.$route.name} page`,
    value = 1,
    label,
  } = data;

  return {
    event,
    event_category: category,
    event_label: label || el.innerText,
    value,
  };
};

const actions = {
  click: (el, binding, vnode) => {
    const gtag = vnode.componentInstance.$gtag;
    const { event, ...rest } = createEvent(binding.value, el, vnode);
    gtag.event(event || 'click', rest);
  },
};

const bind = {
  hover: (el, binding, vnode) => {},
  click: (el, binding, vnode) => {
    el.onclick = () => actions.click(el, binding, vnode);
  },
};

const options = {
  bind,
  trigger: actions,
};

export default {
  bind: (el, binding, vnode) => {
    const { arg } = binding;
    const [when, action] = arg.split('-');

    return options[when][action](el, binding, vnode);
  },
  inserted: () => null,
  update: () => null,
  componentUpdated: () => null,
  unbind: () => null,
};
