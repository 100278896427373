import Vue from 'vue';

import DeviceMetric from '../../models/DeviceMetric';

const dataAcquired = (state, sample) => {
  sample.metrics.forEach((metric) => {
    // eslint-disable-next-line
    if (state.raw.hasOwnProperty(metric.metricId)) {
      if (!state.raw[metric.metricId].values) {
        const obj = state.raw[metric.metricId];
        obj.values = [];
        Vue.set(state.raw, metric.metricId, obj);
      } else if (
        state.raw[metric.metricId].values.filter(
          (item) => item.timestamp === sample.timestamp
        ).length === 0
      ) {
        const array = state.raw[metric.metricId].values;
        const dm = new DeviceMetric(
          sample.timestamp,
          metric.metricId,
          sample.deviceId,
          metric.recValue,
          metric.calcValue,
          metric.excTh,
          metric.excThLimit,
          metric.deviation
        );
        dm.max = metric.max || undefined;
        dm.min = metric.min || undefined;
        array.push(dm);
      }
    } else {
      const obj = { values: [] };
      const dm = new DeviceMetric(
        sample.timestamp,
        metric.metricId,
        sample.deviceId,
        metric.recValue,
        metric.calcValue,
        metric.excTh,
        metric.excThLimit,
        metric.deviation
      );
      dm.max = metric.max || undefined;
      dm.min = metric.min || undefined;
      obj.values.push(dm);
      Vue.set(state.raw, metric.metricId, obj);
    }
  });
};

export default {
  CHANNEL_CLOSED: (state) => {
    state.rawArray.splice(0, state.rawArray.length);
  },
  CLEAR_DATA: (state) => {
    Vue.set(state, 'raw', {});
  },
  RAW_DATA_ACQUIRED: (state, metricdata) => {
    Vue.set(state, 'raw', {});
    metricdata.forEach((samples) => {
      Vue.set(state.raw, samples[0].metricId, { values: samples });
    });
  },
  RAW_DATA_DEVICE_ACQUIRED: (state, { key, data }) => {
    Vue.set(state.rawDataByDevice, key, data);
  },
  RAW_DATA_DEVICE_GROUP_ACQUIRED: (state, { key, data }) => {
    Vue.set(state.rawDeviceGroupData, key, data);
  },
  RAW_LIVE_DATA_ACQUIRED: (state, sample) => {
    dataAcquired(state, sample);
  },
  SET_TOTAL_POWER: (state, totalPower) => {
    const { rangeLabel, tpData } = totalPower;
    const { success, message, chartValues, tpStats, maxTotalPerHour } = tpData;
    Vue.set(state, `totalPower${rangeLabel}`, {
      errorMsg: success ? '' : message,
      chartValues,
      tpStats,
      maxTotalPerHour,
    });
  },
  UPDATE_COMPARE_DEVICES: (state, data) => {
    Vue.set(state, 'compareDevices', data);
    Vue.set(state, 'advancedFilter', true);
    Vue.set(state, 'devicesFilter', true);
  },
  TURN_OFF_ADVANCED_FILTER: (state) => {
    Vue.set(state, 'advancedFilter', false);
    Vue.set(state, 'devicesFilter', false);
    Vue.set(state, 'metricsFilter', false);
  },
  UPDATE_COMPARE_METRICS: (state, data) => {
    Vue.set(state, 'multipleMetricsData', data);
    Vue.set(state, 'advancedFilter', true);
    Vue.set(state, 'metricsFilter', true);
  },
  UPDATE_ANOMALY_METRICS: (state, data) => {
    Vue.set(state, 'multipleMetricsData', data);
    Vue.set(state, 'advancedFilter', false);
    Vue.set(state, 'metricsFilter', false);
  },
  UPDATE_METRICS: (state, data) => {
    Vue.set(state, 'metricsData', data);
    Vue.set(state, 'advancedFilter', false);
    Vue.set(state, 'metricsFilter', false);
  },
  TURN_OFF_METRICS_FILTER: (state) => {
    Vue.set(state, 'metricsFilter', false);
  },
  TURN_OFF_DEVICES_FILTER: (state) => {
    Vue.set(state, 'devicesFilter', false);
  },
  SHOW_PHASES: (state) => {
    Vue.set(state, 'phasesVisible', true);
  },
  HIDE_PHASES: (state) => {
    Vue.set(state, 'phasesVisible', false);
  },
  NO_GRAPH_DATA: (state) => {
    Vue.set(state, 'noGraphData', true);
  },
  GRAPH_DATA_AVAILABLE: (state) => {
    Vue.set(state, 'noGraphData', false);
  },
  SHOW_MAXMIN: (state) => {
    Vue.set(state, 'maxMinVisible', true);
  },
  HIDE_MAXMIN: (state) => {
    Vue.set(state, 'maxMinVisible', false);
  },
  UPDATE_METRICS_TABLE: (state, table) => {
    Vue.set(state, 'metricsTable', table);
  },
  SET_METRIC_STATS_DATA: (state, data) => {
    Vue.set(state, 'metricStatsData', data);
  },
};
