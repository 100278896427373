import Vue from 'vue';
import { keyBy } from 'lodash';
import moment from 'moment';
import { State, Filter, FilterComponent, initialState, FilterState } from '.';
import {
  SET_FILTERS,
  SET_FILTER,
  SET_INITIAL_FILTERS,
  ADD_FILTER,
  DEL_FILTER,
  CLEAR_FILTER,
  CLEAR_FILTERS,
  SET_STATE_FILTER,
  UPDATE_FILTER,
} from './mutations-types';

export default {
  [SET_STATE_FILTER](state: State, payload: FilterState) {
    Vue.set(state, 'states', { ...state.states, [payload.id]: payload });
  },
  [SET_FILTERS](state: State, payload: Array<Filter>) {
    payload.forEach((filter) => {
      Vue.set(state, 'filterChanged', filter.id);
      Vue.set(state.items, filter.id, { ...state.items[filter.id], ...filter });
    });
  },
  [SET_FILTER](state: State, filter: Filter) {
    Vue.set(state, 'filterChanged', filter.id);
    Vue.set(state, 'filterChangedType', filter.type);
    Vue.set(state.items, filter.id, { ...state.items[filter.id], ...filter });
  },
  [ADD_FILTER](state: State, payload: FilterComponent) {
    const actualFilters = [...state[payload.filterType]];
    const newFilterId = payload.data.id;
    const existinfFilterIndex = actualFilters.findIndex(
      (f) => f.id === newFilterId
    );
    if (existinfFilterIndex === -1) {
      actualFilters.push(payload.data);
    } else {
      actualFilters[existinfFilterIndex] = payload.data;
    }
    Vue.set(state, payload.filterType, [...actualFilters]);
  },
  [DEL_FILTER](state: State, payload) {
    const { filterType, index, id } = payload;
    const stateCopy = [...state[filterType]];
    const itemsCopy = { ...state.items };
    delete itemsCopy[id];
    stateCopy.splice(index, 1);
    Vue.set(state, filterType, stateCopy);
    Vue.set(state, 'items', itemsCopy);
  },
  [SET_INITIAL_FILTERS](state: State, payload) {
    Vue.set(state, 'appliedFilterList', payload);
  },
  [CLEAR_FILTER](state: State, payload) {
    const { id } = payload;
    const itemsCopy = { ...state.items };
    const deletedFilter = { ...itemsCopy[id] };
    delete itemsCopy[id];
    Vue.set(state, 'items', itemsCopy);
    Vue.set(state, 'filterChangedType', deletedFilter.type);
  },
  [CLEAR_FILTERS](state: State, payload = { force: true }) {
    const items = !payload.force
      ? Object.values<Filter>(state.items).filter(
          (filter) => filter.version && !filter.canBeCleared
        )
      : {};
    // if force = true, means that the user click on Clear filters
    if (!payload.force) {
      Vue.set(state, 'clearFilters', moment().valueOf());
    }
    Vue.set(state, 'items', keyBy(items, 'id'));
    Vue.set(state, 'appliedFilterList', []);
  },
  [UPDATE_FILTER](state: State, payload) {
    // state.updateFilter[payload.name] = payload.value;
    Vue.set(state, 'updateFilter', {
      ...state.updateFilter,
      [payload.name]: payload.value,
    });
  },
};
