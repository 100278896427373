import axios from 'axios';

import getAxiosOpts from '@/helpers/axios.js';

export default {
  GET_COMPANY({ commit, state }, companyid) {
    if (Object.keys(state.companyData).length) return;

    new Promise((res, rej) => {
      axios
        .get(`/api/companies/${companyid}/`, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data.success) {
            commit('SET_COMPANY', response.data.company);
            res();
          } else {
            rej(response.data.error);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  GET_USER_COMPANY: ({ commit, state }, companyid) =>
    new Promise((res, rej) => {
      axios
        .get(`/api/companies/${companyid}/`, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data.success) {
            commit('SET_USER_COMPANY', response.data.company);
            res();
          } else {
            rej(response.data.error);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_PROD_UNITS({ commit, state }) {
    if (state.prodUnits.length) return;

    new Promise((res, rej) => {
      axios
        .get(
          `/api/companies/${state.company.toLowerCase()}/produnits`,
          getAxiosOpts(state.user.token)
        )
        .then((response) => {
          if (response.data && response.data.units) {
            commit('SET_PROD_UNITS', response.data.units);
            res();
          } else {
            rej();
          }
        })
        .catch((error) => {
          rej(error);
        });
    });
  },
  GET_SETTINGS({ commit, state, dispatch }) {
    if (Object.keys(state.companySettings).length) return Promise.resolve();

    return new Promise((res, rej) => {
      axios
        .get(
          `/api/companies/${state.company.toLowerCase()}/settings`,
          getAxiosOpts(state.user.token)
        )
        .then((response) => {
          if (response.data && response.data.success) {
            commit('SET_SETTINGS', response.data.settings);
            dispatch('settings/setSetting', {
              name: 'company',
              value: response.data.settings,
            });
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  SAVE_SETTINGS: async ({ commit, state, dispatch }, settings) => {
    try {
      const response = await axios.post(
        `/api/companies/${state.company.toLowerCase()}/settings`,
        settings,
        getAxiosOpts(state.user.token)
      );
      if (response.data && response.data.success) {
        commit('SET_SETTINGS', settings);
        dispatch('settings/setSetting', { name: 'company', value: settings });
        dispatch(
          'SHOW_SNACKBAR',
          {
            alertMessage: 'Settings updated successfully.',
          },
          { root: true }
        );
      } else {
        dispatch(
          'SHOW_SNACKBAR',
          {
            alertMessage: 'Error saving settings. Please try again.',
            alertType: 'error',
          },
          { root: true }
        );
        throw new Error('Server error: wrong settings response structure');
      }
    } catch (err) {
      throw err;
    }
  },
  RESEND_EMAIL_REPORT: ({ state }, data) =>
    new Promise((res, rej) => {
      axios
        .post(
          `/api/companies/emails/${data.emailType}`,
          data,
          getAxiosOpts(state.user.token)
        )
        .then((response) => {
          if (response.status === 200) {
            res('Email resent successfully!');
          } else {
            rej(response.data.error);
          }
        });
    }),
  CLEAR_REFRESH_HOME: ({ commit }, { widgetId } = { widgetId: null }) => {
    commit('SET_REFRESH_HOME', { widgetId, refresh: false });
  },
  SET_REFRESH_HOME: ({ commit }, { widgetId } = { widgetId: null }) => {
    commit('SET_REFRESH_HOME', { widgetId, refresh: true });
  },
  async LOAD_SKUS_DEVICES(context, reload = false) {
    const options = getAxiosOpts(context.rootState.user.token);
    const {
      state: { skusDevices, company },
    } = context;
    const url = `api/skus/${company}`;
    try {
      const {
        data: { response, error },
        status,
        statusText,
      } = await axios.get(url, options);
      if (status === 200 && !error) {
        context.commit('SET_SKUS_DEVICES', response);
      } else {
        throw new Error(statusText);
      }
    } catch (e) {
      throw e;
    }
  },
  async SAVE_SKU_DEVICE(context, payload) {
    const options = getAxiosOpts(context.rootState.user.token);
    const { id, edit, ...rest } = payload;
    const url = 'api/skus_devices';

    try {
      const {
        data: { error, response },
        status,
        statusText,
      } = await axios.post(url, { id, ...rest }, options);

      if (status === 200 && !error) {
        const {
          id: relationId,
          sku,
          settings,
          device_id: deviceId,
          status: skuDeviceStatus,
        } = response;
        const {
          sku: { skuCode },
        } = rest;
        if (id || edit) {
          context.commit('EDIT_SKU_DEVICE', { ...payload, skucode: skuCode });
        } else {
          context.commit('ADD_SKU_DEVICE', {
            id: relationId,
            settings,
            sku_id: sku.id,
            device_id: deviceId,
            status: skuDeviceStatus,
            skucode: skuCode,
          });
        }
        context.commit('productionPeriod/ADD_EDIT_SKU', sku);
        context.dispatch('SHOW_SNACKBAR', {
          alertMessage: `Product/Relation ${id || edit ? 'Edited' : 'Added'} successfully.`,
        });
      } else {
        context.dispatch('SHOW_SNACKBAR', {
          alertMessage: `Sorry an error occurred ${error}. Please try again.`,
          alertType: 'error',
        });
        throw new Error({ statusText, error });
      }
    } catch (e) {
      throw e;
    }
  },
  async DELETE_SKU_DEVICE(context, payload) {
    const options = getAxiosOpts(context.rootState.user.token);
    const { item, deleteProduct } = payload;
    const { id, sku_id: skuId } = item;
    const url = `api/skus_devices/${id}`;
    const deleteProductUrl = `api/skus/${skuId}`;

    try {
      const { status, statusText } = await axios.delete(url, options);
      if (deleteProduct) {
        const { status: productStatus, statusText: productStatusText } =
          await axios.delete(deleteProductUrl, options);
        if (productStatus !== 200) {
          throw new Error(productStatusText);
        }
      }
      if (status === 200) {
        context.dispatch('LOAD_SKUS_DEVICES', context, true);
        if (deleteProduct) {
          context.dispatch('productionPeriod/LOAD_SKUS', {
            includeAllItems: true,
            ignoreCache: true,
          });
        }
        context.dispatch('SHOW_SNACKBAR', {
          alertMessage: 'Product/Relation deleted successfully.',
        });
      } else {
        context.dispatch('SHOW_SNACKBAR', {
          alertMessage: `Sorry an error occurred ${statusText}. Please try again.`,
          alertType: 'error',
        });
        throw new Error(statusText);
      }
    } catch (err) {
      context.dispatch('SHOW_SNACKBAR', {
        alertMessage: `Sorry an error occurred ${err}. Please try again.`,
        alertType: 'error',
      });
      throw err;
    }
  },
  async BULK_SKU(context, payload) {
    const options = getAxiosOpts(context.rootState.user.token);
    const {
      state: { company },
    } = context;
    const url = `api/skus_devices/:${company}/bulk_action`;

    try {
      const {
        data: { error },
        status,
        statusText,
      } = await axios.patch(url, payload, options);
      if (status === 200 && !error) {
        context.dispatch('LOAD_SKUS_DEVICES', context, true);
        context.dispatch('productionPeriod/LOAD_SKUS', {
          includeAllItems: true,
          ignoreCache: true,
        });
        context.dispatch('SHOW_SNACKBAR', {
          alertMessage: 'Product/Relation edited successfully.',
        });
      } else {
        context.dispatch('SHOW_SNACKBAR', {
          alertMessage: `Sorry an error occurred ${statusText}. Please try again.`,
          alertType: 'error',
        });
        throw new Error({ statusText, error });
      }
    } catch (err) {
      context.dispatch('SHOW_SNACKBAR', {
        alertMessage: `Sorry an error occurred ${err}. Please try again.`,
        alertType: 'error',
      });
      throw err;
    }
  },
};
