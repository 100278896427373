import Vue from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/vue';

import config from '@/config';
import routes from '@/config/routes';

import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

const requiresAuthenticationToken = async (to) => {
  // allow jwt to be passed in as querystring, email report will be using this
  // possible consideration is to limit it to the email report route ONLY or have allow list
  // remember that payload includes company that needs to match when run against /api/loginWithToken
  const queryJwt = to && to.query ? to.query[config.tokens.jwt.key] : null;
  let isAllowedJwtRoute = false;
  // only bother checking the route if we have a queryJwt passed
  if (queryJwt) {
    isAllowedJwtRoute = config.tokens.jwt.allowedRoutes.find((r) =>
      r.startsWith(to.path)
    );
  }

  const token =
    queryJwt && isAllowedJwtRoute
      ? queryJwt
      : window.localStorage.getItem(config.tokens.localStorage);

  if (token && to.path !== '/login') {
    if (!store.state.user.loggedIn) {
      try {
        await store.dispatch('LOGIN_TOKEN', token);
        // Prevent intercom update from failing
        store.dispatch('UPDATE_INTERCOM_SESSION_DATA').catch((e) => {
          Sentry.withScope((scope) => {
            scope.setLevel('debug');
            Sentry.captureException(e);
          });
        });
        return token;
      } catch {
        window.localStorage.removeItem(config.tokens.localStorage);
        throw new Error('Failed to log in');
      }
    } else {
      return token;
    }
  }

  throw new Error('Token not found');
};

const authValidations = async ({ meta, to, next }) => {
  const { requiresAuthentication, requiresAuthorizationLevel } = meta;
  const { loggedInUserInfo } = store.state.user;

  if (requiresAuthentication) {
    try {
      // Check authentication token
      await requiresAuthenticationToken(to);

      if (requiresAuthorizationLevel !== undefined) {
        // Check required authorization level
        if (
          loggedInUserInfo &&
          loggedInUserInfo.level > requiresAuthorizationLevel
        ) {
          next({ path: '/' });
        }
      }
    } catch (e) {
      // If authentication token not present => go to login page
      next({
        path: '/login',
        query: { redirectUrl: to.fullPath !== '/' ? to.fullPath : undefined },
      });
    }
  }

  // By default pass auth validations
  next();
};

router.beforeEach(async (to, _, next) => {
  // Cancel pending device requests when navigation starts to unblock navigationgs
  store.dispatch('cancelAllDeviceRequests');

  if (Object.keys(to.meta).length) {
    // Authentication and Authorization validations
    await authValidations({ meta: to.meta, to, next });
  } else {
    // No restrictions
    next();
  }
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
