import Vue from 'vue';
import {
  SET_STAFF,
  ADD_STAFF,
  EDIT_STAFF,
  DELETE_STAFF,
} from './mutation-types';

export default {
  [SET_STAFF](state, payload) {
    Vue.set(state, 'staff', payload);
  },
  [ADD_STAFF](state, payload) {
    Vue.set(state, 'staff', [...state.staff, payload]);
  },
  [EDIT_STAFF](state, payload) {
    const { staff } = state;
    const updatedStaff = staff.map((s) => {
      if (s.id === payload.id) {
        s = payload;
      }
      return s;
    });
    Vue.set(state, 'staff', updatedStaff);
  },
  [DELETE_STAFF](state, payload) {
    const { staff } = state;
    Vue.set(
      state,
      'staff',
      staff.filter((s) => s.id !== payload)
    );
  },
};
