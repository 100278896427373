import moment from 'moment';
import _ from 'lodash';
import { State as UnitsState, Unit } from '@/store/modules/units';
import { ChangelogInsert } from '@/models/Changelogs';

import { Device } from '@/store/state';
import { ProductionPeriod, Sku, ProducedValues } from './model';

const dataEntryCompleted = (period: ProductionPeriod): boolean => {
  const {
    production: { produced },
  } = period;
  // For now take as completed a production with produced and wasted filled in
  if (produced != null) return true;
  return false;
};

/**
 * Check if the period is in-progress
 * This is also implemented as an enpoint in dataProxy
 * /production/:companyid/in-progress
 * Keep this function here to store new production periods that could
 * be in-progress
 * @param period Production Period
 */
export const inProgressCondition = (
  period: ProductionPeriod
): ProductionPeriod | null => {
  const { from, to } = period.duration.production;
  const now = moment().valueOf();

  if (from <= now && to > now) {
    return period;
  }
  return null;
};

export const readyForReviewCondition = (period): ProductionPeriod | null => {
  const { from, to } = period.duration.production;
  const now = moment().valueOf();

  if (to < now && !dataEntryCompleted(period)) {
    return period;
  }
  return null;
};

export const completedCondition = (period): ProductionPeriod | null => {
  const { from, to } = period.duration.production;
  const now = moment().valueOf();

  // In the past and all values completed
  if (to < now) {
    return period;
  }

  return null;
};

export const getPeriodStatus = (period): string => {
  const isInProgress = inProgressCondition(period);
  const isReadyForReview = readyForReviewCondition(period);
  const isCompleted = completedCondition(period);

  if (isInProgress) return 'inProgress';
  if (isCompleted) return 'completed';
  if (isReadyForReview) return 'readyForReview';

  return 'others';
};

export const groupPeriodsByStatus = (periods) =>
  _.groupBy(periods, getPeriodStatus);

export const validUuid = (uuid) => {
  const uuidV4Regex =
    /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;
  return uuidV4Regex.test(uuid) ? uuid : null;
};

export const validUUIDList = (uuids) => !uuids.some((uuid) => !validUuid(uuid));

const isEscaped = (comment) => {
  if (!comment) return true;
  return comment !== unescape(comment);
};

export default {
  formatProdEntryObject(object, user, units: UnitsState) {
    let comment;
    if (object.comment !== null && object.comment !== undefined) {
      comment = !isEscaped(object.comment)
        ? escape(object.comment)
        : object.comment;
    } else {
      comment = null;
    }

    const confirmPercentage = (value) => {
      if (value === '') {
        return null;
      }
      return Number(value) >= 0 && Number(value) <= 100 ? value : null;
    };

    const from = moment(
      object.scheduled_run.split(' to ')[0],
      'YYYY-MM-DD HH:mm'
    ).valueOf();
    const to = moment(
      object.scheduled_run.split(' to ')[1],
      'YYYY-MM-DD HH:mm'
    ).valueOf();

    const plannedDowntime = Object.keys(object.planned_downtime).length
      ? object.planned_downtime
      : {
          from,
          duration: 0,
          unit: 'hours',
        };

    const validObject: ProductionPeriod = {
      device_id: object.machine.deviceid,
      sku: object.product ? object.product.id : null,
      duration: {
        scheduled: {
          to,
          from,
        },
        production: {
          to,
          from,
        },
        plannedDowntime,
        runtime: object.runtime || null,
        uptime: object.uptime || null,
      },
      production: {
        target: !_.isNil(object.target_prod) ? object.target_prod : null,
        target_speed: !_.isNil(object.target_prod_speed)
          ? object.target_prod_speed
          : null,
        unitid: object.prod.unitId,
        produced: object.prod.quantity,
        secondary: {
          produced: object.prod2.quantity,
          unitid: object.prod2.unitId,
          target: !_.isNil(object.target_prod2) ? object.target_prod2 : null,
          target_speed: !_.isNil(object.target_prod2_speed)
            ? object.target_prod2_speed
            : null,
        },
      },
      waste: {
        target: object.target_waste || null,
        wasted: object.waste || null,
        target_speed: object.target_waste_speed || null,
        target_percentage: confirmPercentage(object.target_waste_percentage),
        secondary: {
          unit: object.waste2Unit,
          wasted: object.waste2 || null,
          target: object.target_waste2 || null,
          target_speed: object.target_waste2_speed || null,
          target_percentage: confirmPercentage(object.target_waste2_percentage),
        },
      },
      energy: Number(object.energy) || null,
      oee: object.oee || null,
      tags: object.tags || null,
      people: {
        manager: object.manager || [],
        operator: object.operator || [],
        supervisor: object.supervisor || [],
        entered_by: user.loggedInUserInfo.username,
      },
      details: {
        comments: comment || null,
        batch: object.batch || null,
        createdByCsvUpload: object.createdByCsvUpload || false,
      },
      entryId: object.entryId || null,
      changelog: object.changelog || [],
    };

    return validObject;
  },
  dataEntryCompleted,
  inProgressCondition,
  readyForReviewCondition,
  completedCondition,
  getPeriodStatus,
  groupPeriodsByStatus,
};

export const validateNum = (num) =>
  isNaN(num) || num === null || num === '' ? null : Number(num);
export const validatePercent = (n) => {
  if (isNaN(n) || n === null || n === '' || Number(n) < 0 || Number(n) > 1) {
    return null;
  }
  return Number(n) * 100;
};

export const formatParsedCsvRow = (row, user, unitid, secondaryUnitid) => {
  let comment;
  if (
    row.comments &&
    row.comments.value !== null &&
    row.comments.value !== undefined
  ) {
    comment = !isEscaped(row.comments.value)
      ? escape(row.comments.value)
      : row.comments.value;
  } else {
    comment = null;
  }

  // Create a new changelog value for this entry
  const changelog = new ChangelogInsert({
    user: {
      username: user.loggedInUserInfo.username,
      companyid: user.loggedInUserInfo.companyid,
    },
    data: ['Entry created'],
  });

  const validObject = {
    device_id: row.machine.value.deviceid,
    sku: row.product ? row.product.value.id : null,
    duration: {
      scheduled: {
        to: row.endTime.error ? null : row.endTime.value,
        from: row.startTime.error ? null : row.startTime.value,
      },
      production: {
        to: row.endTime.error ? null : row.endTime.value,
        from: row.startTime.error ? null : row.startTime.value,
      },
      plannedDowntime: {
        from: row.startTime.error ? null : row.startTime.value,
        duration: Number(_.get(row, 'plannedDowntime.value', 0)) || 0,
        unit: 'hours',
      },
    },
    production: {
      target: validateNum(_.get(row, 'target_prod.value', null)),
      target_speed: validateNum(_.get(row, 'target_prod_speed.value', null)),
      unitid: unitid,
      produced: validateNum(_.get(row, 'productionQuantity.value', null)),
      secondary: {
        produced: validateNum(_.get(row, 'production2.value', null)),
        unitid: secondaryUnitid,
        target: validateNum(_.get(row, 'target_prod2.value', null)),
        target_speed: validateNum(_.get(row, 'target_prod2_speed.value', null)),
      },
    },
    waste: {
      target: validateNum(_.get(row, 'target_waste.value', null)),
      wasted: validateNum(_.get(row, 'wasteQuantity.value', null)),
      target_speed: validateNum(_.get(row, 'target_waste_speed.value', null)),
      target_percentage: validatePercent(
        _.get(row, 'target_waste_percentage.value', null)
      ),
      secondary: {
        unit: secondaryUnitid,
        wasted: validateNum(_.get(row, 'waste2.value', null)),
        target: validateNum(_.get(row, 'target_waste2.value', null)),
        target_speed: validateNum(
          _.get(row, 'target_waste2_speed.value', null)
        ),
        target_percentage: validatePercent(
          _.get(row, 'target_waste2_percentage.value', null)
        ),
      },
    },
    energy: null,
    oee: null,
    changelog: [changelog],
    tags: null,
    people: {
      manager: _.get(row, 'manager.value.id', null),
      operator: _.get(row, 'operator.value.id', null),
      supervisor: _.get(row, 'supervisor.value.id', null),
      entered_by: user.loggedInUserInfo.username,
    },
    details: {
      comments: comment,
      batch: _.get(row, 'batch.value', null),
    },
    entryId: null,
  };

  return validObject;
};

export const getCsvSaveSummary = (response, totalLength) => {
  const added = response.filter((e) => e.success !== false);
  const rejected = response.filter((e) => e.success === false);
  const duplicates = rejected.filter((e) => e.msg === 'duplicate');
  const failed = totalLength - added.length;
  return `
  ${added.length} ${added.length === 1 ? 'entry' : 'entries'} saved successfully.\n

  ${duplicates.length} duplicate ${duplicates.length === 1 ? 'entry' : 'entries'} found \n

  ${failed} ${failed === 1 ? 'entry' : 'entries'} failed to save
  `;
};

export const calculateProductionPeriodWaste = (period: ProductionPeriod) => {
  const {
    production: { produced },
    waste: { wasted },
  } = period;
  const waste = Number(wasted) / (Number(produced) + Number(wasted));
  return waste;
};

export const processProductionEntry = ({
  entry,
  availableMachines,
  getUnitById,
  skus,
}: {
  entry: ProductionPeriod;
  availableMachines: Device[];
  getUnitById: (unitId: number | null) => Unit;
  skus: Sku[];
}) => {
  const machine =
    availableMachines.filter((d) => d.deviceid === entry.device_id)[0] || null;

  const unit = getUnitById(entry.production.unitid);
  const unitName = unit ? unit.unitName : '';
  let secUnitName = '';
  if (entry.production.secondary) {
    const secUnit = getUnitById(entry.production.secondary.unitid);
    secUnitName = secUnit ? secUnit.unitName : '';
  }

  const secondaryTarget = entry.production.secondary
    ? entry.production.secondary.target
    : '';
  const secondaryTargetSpeed = entry.production.secondary
    ? entry.production.secondary.target_speed
    : '';
  const secondaryWaste =
    entry.waste.secondary && entry.waste.secondary.wasted
      ? entry.waste.secondary.wasted
      : '';
  const secondaryWasteUnit = secUnitName;
  const secondaryWasteTarget = entry.waste.secondary
    ? entry.waste.secondary.target
    : '';
  const secondaryWasteTargetSpeed = entry.waste.secondary
    ? entry.waste.secondary.target_speed
    : '';
  const secondaryWasteTargetPercentage = entry.waste.secondary
    ? entry.waste.secondary.target_percentage
    : '';

  const {
    duration: {
      plannedDowntime = {
        from: entry.duration.production.from,
        duration: 0,
        unit: 'hours',
      },
    },
    production: {
      secondary: secondaryProduction = {} as Partial<ProducedValues>,
    },
  } = entry;

  return {
    machine,
    scheduled_run: `${moment(entry.duration.production.from).format('YYYY-MM-DD HH:mm')} 
      to ${moment(entry.duration.production.to).format('YYYY-MM-DD HH:mm')}`,
    product: skus.filter((s) => s.id === Number(entry.sku))[0] || null,
    batch: entry.details.batch || null,
    prod: {
      quantity: entry.production.produced,
      unitId: entry.production.unitid,
    },
    prod2: {
      quantity: secondaryProduction.produced || null,
      unitId: secondaryProduction.unitid || null,
    },
    target_prod: entry.production.target || null,
    target_prod_speed: entry.production.target_speed || null,
    target_prod2: secondaryTarget,
    target_prod2_speed: secondaryTargetSpeed,
    waste: entry.waste.wasted || null,
    unitName,
    waste2: secondaryWaste,
    waste2Unit: secondaryWasteUnit,
    target_waste: entry.waste.target || null,
    target_waste_speed: entry.waste.target_speed || null,
    target_waste_percentage: entry.waste.target_percentage,
    target_waste2_percentage: secondaryWasteTargetPercentage,
    target_waste2: secondaryWasteTarget,
    target_waste2_speed: secondaryWasteTargetSpeed,
    energy: entry.energy,
    manager: entry.people.manager,
    operator: entry.people.operator,
    supervisor: entry.people.supervisor,
    comment: entry.details.comments || null,
    createdByCsvUpload: entry.details.createdByCsvUpload || false,

    tags: entry.tags
      ? Object.keys(entry.tags).length === 0
        ? null
        : entry.tags
      : null,
    entryId: entry.id,
    shift: (entry as any).shift as number,
    planned_downtime: plannedDowntime,
    changelog: entry.changelog,
    runtime: entry.duration.runtime || null,
    uptime: entry.duration.uptime || null,
  };
};
