import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';

import { company } from '../state';

export default {
  GET_LOGGED_INFO: ({ commit, state }) =>
    new Promise((res, rej) => {
      axios
        .get('/api/users', getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_USERS', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  VERIFY_MFA: async (
    { commit },
    { code, username, enabling = false, destination, channel, login }
  ) => {
    try {
      const { data } = await axios.post('/api/verifymfa', {
        code,
        username,
        company,
        enabling,
        destination,
        channel,
        login,
      });

      if (data.success) {
        if (login) {
          commit('LOGGED_IN', data);
        } else {
          commit('UPDATE_MFA_INFO', { enabled: enabling, channel });
        }
        return { success: true, message: data.message };
      }
      return {
        success: false,
        error: data.error,
        message: data.error,
        errorLevel: data.errorLevel,
      };
    } catch (error) {
      throw new Error(error);
    }
  },
  SEND_VERIFICATION_TOKEN: async (
    { dispatch, state },
    { username, channel, destination, resend }
  ) => {
    try {
      const { data, status } = await axios.post(
        'api/sendVerificationToken',
        {
          username,
          channel,
          destination,
          resend,
        },
        getAxiosOpts(state.user.token)
      );

      if (data.success && status === 200) {
        return { sent: true };
      }
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Error sending verification code, please try again.',
          alertType: 'error',
        },
        { root: true }
      );
      return { sent: false };
    } catch (err) {
      return err;
    }
  },
  LOGIN: ({ commit }, { username, password }) =>
    new Promise((res, rej) => {
      if (!username || !password || !company) {
        return res({ success: false, message: 'All fields are required' });
      }

      return axios
        .post('/api/login', {
          username,
          password,
          company,
        })
        .then((response) => {
          const {
            data: { mfa = false, success = false, error, message, ...user },
          } = response;

          if (mfa) {
            commit('MFA_USER', user);
            res({ success, mfa });
          }

          if (success) {
            commit('LOGGED_IN', user);
            res({ success });
          }

          res({ success, error, message });
        })
        .catch((err) => {
          rej(err);
        });
    }),
  LOGIN_TOKEN: ({ commit }, token) =>
    new Promise((res, rej) => {
      axios
        .post('/api/loginWithToken', { token, company })
        .then((response) => {
          if (response.data && !response.data.error) {
            commit('LOGGED_IN', response.data);
            res(response.data);
          } else {
            rej(response);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  LOGOUT: ({ commit }) => {
    commit('exportData/STOP');
    commit('EMPTY_SELECTED_DEVICES');
    commit('TURN_OFF_METRICS_FILTER');
    commit('TURN_OFF_DEVICES_FILTER');
    commit('TURN_OFF_ADVANCED_FILTER');
    commit('LOGOUT');
    localStorage.clear();
  },
  GET_USERS: ({ commit, state }) =>
    new Promise((res, rej) => {
      axios
        .get('/api/users', getAxiosOpts(state.user.token))
        .then((response) => {
          commit('SET_USERS', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  GET_COMPANY_USERS: ({ commit, state }, companyid) => {
    if (state.users.users.length) return;

    new Promise((res, rej) => {
      axios
        .get(
          `/api/users/company/${companyid || company}`,
          getAxiosOpts(state.user.token)
        )
        .then((response) => {
          commit('SET_USERS', response.data.response);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  CREATE_USER: ({ dispatch, commit, state }, data) =>
    new Promise((res, rej) => {
      axios
        .post('/api/users', { user: data.user }, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            if (responseData.indexOf('Error') !== -1) {
              if (data.showSnackbarError === true) {
                dispatch(
                  'SHOW_SNACKBAR',
                  {
                    alertMessage: 'User data was not created.',
                    alertType: 'error',
                  },
                  { root: true }
                );
              }
            } else {
              commit('SET_NEW_USER', data.user);
              dispatch(
                'SHOW_SNACKBAR',
                {
                  alertMessage: 'User created successfully.',
                },
                { root: true }
              );
            }
            if (data.cb) {
              data.cb(responseData);
            }
            return res();
          }
          return rej();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  EDIT_USER: ({ dispatch, commit, state }, data) =>
    new Promise((res, rej) => {
      axios
        .put('/api/users', { user: data.user }, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.status === 200) {
            const responseData = response.data;
            if (!responseData || responseData.indexOf('Error') !== -1) {
              if (data.showSnackbarError === true) {
                dispatch(
                  'SHOW_SNACKBAR',
                  {
                    alertMessage: 'User data was not updated.',
                    alertType: 'error',
                  },
                  { root: true }
                );
              }
            } else {
              commit('UPDATE_USER', { user: data.user, index: data.index });
              if (data.user.source !== 'devicesLists') {
                dispatch(
                  'SHOW_SNACKBAR',
                  {
                    alertMessage: 'User updated successfully.',
                  },
                  { root: true }
                );
              }
            }
            if (data.cb) {
              data.cb(responseData);
            }
            return res();
          }
          return rej();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  DEACTIVATE_USER: ({ dispatch, commit, state }, data) =>
    new Promise((res, rej) => {
      axios
        .put(
          '/api/users/delete',
          { user: data.user },
          getAxiosOpts(state.user.token)
        )
        .then(() => {
          commit('REMOVE_USER', data.user);
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'User deleted successfully.',
            },
            { root: true }
          );
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  FORGOT_USER: async (storage, payload) => {
    const { data } = await axios.post('/api/users/forgot', {
      email: payload,
      company,
    });

    if (data && !data.error) {
      return data;
    }

    throw new Error(data.error);
  },
  CHECK_TOKEN: async (ctx, payload) => {
    const { data } = await axios.post('/api/users/forgot/checkToken', {
      token: payload,
      company,
    });

    if (data && !data.error) {
      return data;
    }

    throw new Error(data.error);
  },
  RESET_USER: (storage, payload) =>
    new Promise((res, rej) => {
      const url = `/api/users/reset/${payload.token}`;
      axios
        .post(url, { password: payload.password })
        .then((response) => {
          const { data } = response;
          res(data);
        })
        .catch((err) => {
          rej(err.message);
        });
    }),
  RESET_PASSWORD: ({ state }, { password, username, companyid }) =>
    new Promise((res, rej) => {
      const url = '/api/users/standalone/reset';
      axios
        .post(
          url,
          {
            password,
            username,
            companyid,
          },
          getAxiosOpts(state.user.token)
        )
        .then((response) => {
          const { data } = response;
          res(data);
        })
        .catch((err) => {
          rej(err.message);
        });
    }),
  UPDATE_INTERCOM_SESSION_DATA: ({ state }) =>
    new Promise((res, rej) => {
      if (state.user.loggedInUserInfo.email) {
        axios
          .post(
            '/api/users/intercom/update',
            {
              email: state.user.loggedInUserInfo.email,
              companyid: state.user.loggedInUserInfo.companyid,
              username: state.user.loggedInUserInfo.username,
            },
            getAxiosOpts(state.user.token)
          )
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        rej(new Error('state.user.loggedInUserInfo.email not set'));
      }
    }),
  UPDATE_ISSUE_ALERT_SETTINGS: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      axios
        .post(
          '/api/settings/users/issues_alerts',
          { ...data },
          getAxiosOpts(state.user.token)
        )
        .then((response) => {
          if (response.status === 200) {
            commit('SET_ISSUE_ALERT_SETTINGS', response.data);
            res();
          } else {
            rej(response);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  TRACK_USER_EVENT: ({ state }, { event, metadata }) =>
    new Promise((res, rej) => {
      if (state.user.loggedInUserInfo.email) {
        axios
          .post(
            '/api/users/intercom/event',
            {
              email: state.user.loggedInUserInfo.email,
              companyid: state.user.loggedInUserInfo.companyid,
              username: state.user.loggedInUserInfo.username,
              event,
              created_at: new Date() / 1000,
              metadata,
            },
            getAxiosOpts(state.user.token)
          )
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        rej();
      }
    }),
};
