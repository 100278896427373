import Vue from 'vue';
import moment from 'moment';
import * as Sentry from '@sentry/vue';

import { getStateDefault } from '../state';

export default {
  MFA_USER: (state, data) => {
    Vue.set(state, 'usermfa', data);
  },
  LOGGED_IN: (state, data) => {
    Vue.set(state.user, 'token', data.token);
    Vue.set(state.user, 'loggedIn', true);
    const userInfo = data.userInfo || data.safiUserInfo;

    // Livesession: Identify user and custom data to session
    try {
      window.__ls('identify', {
        name: userInfo.username,
        email: null,
        params: {
          companyid: userInfo.companyid,
        },
      });
    } catch (err) {
      Sentry.withScope((scope) => {
        scope.setLevel('info');
        scope.setContext({ userInfo });
        Sentry.captureException(
          new Error('[Livesession Identify] failed', { cause: err })
        );
      });
    }

    Vue.set(state.user, 'loggedInUserInfo', userInfo);
    Vue.set(state.user, 'companyInfo', data.companyInfo);

    const userTimezone = userInfo.timezone;
    const companyTimezone = data.companyInfo
      ? data.companyInfo.timezone
      : undefined;
    const browserTimezone = userTimezone || companyTimezone;

    Vue.set(state, 'companyTimezone', companyTimezone);
    Vue.set(state, 'userTimezone', userTimezone);
    Vue.set(state, 'browserTimezone', browserTimezone);

    moment.tz.setDefault(browserTimezone);

    window.localStorage.setItem('safigen-token', data.token);
  },
  UPDATE_MFA_INFO: (state, data) => {
    const { loggedInUserInfo } = state.user;
    Vue.set(state.user, 'loggedInUserInfo', { ...loggedInUserInfo, mfa: data });
  },
  LOGOUT: (state) => {
    const s = getStateDefault();
    Object.keys(s).forEach((key) => Vue.set(state, key, s[key]));
    window.localStorage.removeItem('safigen-token');
  },
  SET_USERS: (state, users) => {
    const usersArray = [];
    if (users) {
      users.forEach((user) => {
        usersArray.push(user);
      });
    }
    Vue.set(state.users, 'users', usersArray);
  },
  SET_NEW_USER: (state, item) => {
    Vue.set(state.users, 'users', [...state.users.users, item]);
  },
  UPDATE_USER: (state, data) => {
    if (data.index >= 0) {
      const stateCopy = [...state.users.users];
      const index = stateCopy.findIndex(
        (u) =>
          u.username === data.user.username &&
          u.companyid === data.user.companyid
      );
      Object.assign(stateCopy[index], data.user);
      Vue.set(state.users, 'users', stateCopy);
    }
  },
  SET_ISSUE_ALERT_SETTINGS: (state, data) => {
    Vue.set(
      state.user.loggedInUserInfo.options,
      'issues_alerts',
      data.response.options.issues_alerts
    );
  },
  REMOVE_USER: (state, data) => {
    const stateCopy = [...state.users.users];
    const index = stateCopy.findIndex(
      (p) => p.username === data.originalUserName
    );
    stateCopy.splice(index, 1);
    Vue.set(state.users, 'users', stateCopy);
  },
  TEST_MESSAGE_SENT: (state, sentStatus) => {
    Vue.set(state.users, 'isTestMessageSent', sentStatus);
  },
  SET_THEME: (state, payload) => {
    Vue.set(state.user, 'isLightTheme', payload);
  },
};
