export interface DeviceState {
  id: string;
  companyId: string;
  deviceId: string;
  cluster: 8;
  description: string;
  modelVersion: string;
  tagsId: string;
}

export interface State {
  loading: boolean;
  data: {
    [key: string]: DeviceState;
  };
  lastUpdate: number;
}

export const initialState: State = {
  loading: false,
  data: {},
  lastUpdate: 0,
};

export default initialState;
