import Vue from 'vue';
import moment from 'moment';
import min from 'lodash/min';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';

import {
  SET_FEEDS,
  MERGE_FEEDS,
  SET_LOADING,
  SET_LOADING_DONE,
  APPEND_FEEDS,
  PREPEND_FEEDS,
  CLEAR_FEEDS,
  REMOVE_FEED,
  UPDATE_FEED,
} from './mutations-types';
import { State, Feed, initialState } from './model';

export const feedId = ({ timestamp, type, sourceId }: Feed) =>
  `${timestamp}.${type}.${sourceId}`;

export const findFeedGroup = (groupKeys, feed: Feed) => {
  // Find feed group
  const groups = sortBy(groupKeys);
  return groups.find((groupKey) => {
    if (groupKey >= feed.timestamp) return true;
    return false;
  });
};

export const findFeedPosition = (data: Array<Feed>, feed: Feed) =>
  data.findIndex((f) => feedId(f) === feedId(feed));

export const findFeed = (feeds: State['data'], feed: Feed) => {
  const group = findFeedGroup(Object.keys(feeds), feed);

  if (!group) return undefined;

  return { group, index: findFeedPosition(feeds[group], feed) };
};

export default {
  [SET_LOADING](state: State, payload) {
    Vue.set(state, 'loading', true);
  },
  [SET_LOADING_DONE](state: State, payload) {
    Vue.set(state, 'loading', false);
  },
  [SET_FEEDS](state: State, payload: State['data']) {
    Vue.set(state, 'data', payload);
    Vue.set(state, 'lastUpdate', moment().valueOf());
  },
  [MERGE_FEEDS](state: State, payload: State['data']) {
    Vue.set(state, 'data', { ...state.data, ...payload });
    Vue.set(state, 'lastUpdate', moment().valueOf());
  },
  [APPEND_FEEDS](state: State, payload: Array<Feed>) {
    const lastGroup = min(Object.keys(state.data));
    Vue.set(state.data, `${lastGroup}`, [...payload, ...state.data[lastGroup]]);
    Vue.set(state, 'lastUpdate', moment().valueOf());
  },
  [PREPEND_FEEDS](state: State, payload: Array<Feed>) {
    const firstGroup = max(Object.keys(state.data));
    Vue.set(state.data, `${firstGroup}`, [
      ...payload,
      ...state.data[firstGroup],
    ]);
    Vue.set(state, 'lastUpdate', moment().valueOf());
  },
  [CLEAR_FEEDS](state: State) {
    Vue.set(state, 'data', initialState.data);
  },
  [REMOVE_FEED](state: State, payload: Feed) {
    const feed = findFeed(state.data, payload);

    if (feed !== undefined) {
      const dataCopy = [...state.data[feed.group]];
      dataCopy.splice(feed.index, 1);
      Vue.set(state.data, `${feed.group}`, dataCopy);
    }
  },
  [UPDATE_FEED](state: State, payload: Feed) {
    const feed = findFeed(state.data, payload);

    if (feed !== undefined) {
      const dataCopy = [...state.data[feed.group]];

      dataCopy[feed.index] = payload;

      Vue.set(state.data, `${feed.group}`, dataCopy);
    }
  },
};
