export interface Scrap {
  id: number;
  deviceId: string;
  companyId: string;
  amount: number;
  unit: string;
  tags: Array<string>;
  fromTs: number;
  toTs: number;
  createdAt: number;
  updatedAt: number;
  changelog: Array<any>;
  deleted: boolean;
  comments: string;
}

export interface State {
  scraps: Array<Scrap>;
}

export const initialState: State = {
  scraps: [],
};

export default initialState;
