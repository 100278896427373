import Vue from 'vue';
import { findIndex } from 'lodash';

export default {
  SET_ALERTS: (state, alerts) => {
    const alertsArray = [];
    if (alerts) {
      alerts.forEach((alert) => {
        alertsArray.push(alert);
      });
    }
    Vue.set(state, 'alerts', alertsArray);
  },
  SET_NEW_CREATE_ALERT: (state, alert) => {
    const alertsArrayCopy = state.alerts;
    if (alert) {
      alertsArrayCopy.push(alert);
    }
    Vue.set(state, 'alerts', alertsArrayCopy);
  },
  SET_NEW_EDIT_ALERT: (state, alert) => {
    const alertIndex = state.alerts.findIndex((a) => a.id === alert.id);
    if (alertIndex >= 0) {
      Vue.set(state.alerts, alertIndex, alert);
    }
  },
  SET_NEW_DELETE_ALERT: (state, alert) => {
    const alertsArrayCopy = state.alerts;
    const alertIndex = state.alerts.findIndex((a) => a.id === alert.id);
    if (alert && alertIndex) {
      alertsArrayCopy.splice(alertIndex, 1);
      Vue.set(state, 'alerts', alertsArrayCopy);
    }
  },
  SET_NEW_CLEAR_ALERT: (state, alert) => {
    const alertsArrayCopy = state.alerts;
    const alertIndex = state.alerts.findIndex((a) => a.id === alert.id);
    if (alert && alertIndex) {
      alertsArrayCopy.splice(alertIndex, 1);
      Vue.set(state, 'alerts', alertsArrayCopy);
    }
  },
  SET_CURRENT_ALERTS: (state, alerts) => {
    const alertsArray = [];
    if (alerts) {
      alerts.forEach((alert) => {
        alertsArray.push(alert);
      });
    }
    Vue.set(state.alerts, 'alerts', alertsArray);
  },
  SET_NEW_ALERT: (state, item) => {
    const stateCopy = state.alerts.alerts;
    stateCopy.push({
      duration: item.thresholdDuration,
      ...item,
    });
    Vue.set(state.alerts, 'alerts', stateCopy);
  },
  UPDATE_ALERT_VALUE: (state, data) => {
    const stateCopy = state.alerts.alerts;
    Object.assign(stateCopy[data.index], data.alert, {
      duration: data.alert.thresholdDuration,
    });
    Vue.set(state.alerts, 'alerts', stateCopy);
  },
  REMOVE_ALERT: (state, index) => {
    const stateCopy = state.alerts.alerts;
    stateCopy.splice(index, 1);
    Vue.set(state.alerts, 'alerts', stateCopy);
  },
  SET_ALERTS_WIDGET_INFO: (state, { payload, range }) => {
    const { alertsCount, change, topAlerts } = payload;
    Vue.set(state.alertsWidget, range, {
      count: alertsCount,
      change,
    });
    Vue.set(state.alertsWidgetTop, range, topAlerts);
  },
  SET_ALERTS_WIDGET_LOADING: (state, isLoading) => {
    Vue.set(state, 'alertsWidgetLoading', isLoading);
  },
  BULK_EDIT: (state, payload) => {
    const { rules, ...rest } = payload;
    const alertsCopy = [...state.alerts];
    rules.forEach((updatedRule) => {
      const idx = findIndex(alertsCopy, { id: updatedRule });
      alertsCopy[idx] = { ...alertsCopy[idx], ...rest };
    });
    Vue.set(state, 'alerts', alertsCopy);
  },
};
