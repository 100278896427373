export const SET_CYCLES_SETTINGS_DATA = 'SET_CYCLES_SETTINGS_DATA';
export const SET_CYCLES_SETTINGS = 'SET_CYCLES_SETTINGS';
export const SET_CYCLES_PREVIEW_SETTINGS = 'SET_CYCLES_PREVIEW_SETTINGS';
export const SET_LOADING_STATE = 'SET_LOADING_STATE';
export const SET_CYCLES_QUERY = 'SET_CYCLES_QUERY';

export default {
  SET_CYCLES_SETTINGS_DATA,
  SET_CYCLES_SETTINGS,
  SET_CYCLES_PREVIEW_SETTINGS,
  SET_LOADING_STATE,
  SET_CYCLES_QUERY,
};
