import constants from './constants';

// Indicates if the app is running in the "test environment" infrastructure.
// This is distinct from the "test" mode used by test runners like Vitest.
const isTestEnv = import.meta.env.VITE_ENVIRONMENT === 'testenv';

const dataProxyUrl =
  import.meta.env.VITE_DATA_VAL_BASEURL || 'http://localhost:5000';

const emailUpstreamServer =
  import.meta.env.VITE_EMAIL_UPSTREAM_SERVER || dataProxyUrl;

export default {
  googleApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  commitSha: import.meta.env.VITE_COMMIT_SHA,

  // TODO: Refactor. The distinction between environment (`appEnvironment`)
  // and mode (`mode`) should be clearer and simpler.
  // `appEnvironment` is used for high-level deployment distinctions.
  // Examples:
  // - `production`: Live production environment
  // - `staging`: Staging environment for pre-production testing
  // - `testenv`: A special deployment for integration testing
  appEnvironment: import.meta.env.VITE_ENVIRONMENT,
  production: import.meta.env.VITE_ENVIRONMENT === 'production',
  staging: import.meta.env.VITE_ENVIRONMENT === 'staging',
  testenv: isTestEnv,

  // `mode` reflects the runtime context of the app:
  // - `development`: Running locally during active development
  // - `production`: Running in a deployed environment
  // - `test`: Running in a test runner (e.g., Vitest)
  //
  // This is primarily used to detect test runs and runtime distinctions,
  // and it should generally supersede `appEnvironment` for test-specific logic.
  mode: import.meta.env.MODE,

  defaultCompany: import.meta.env.VITE_DEFAULT_COMPANY,
  tokens: {
    jwt: {
      key: 'jwt',
      allowedRoutes: ['/trends', '/email'],
    },
    localStorage: 'safigen-token',
  },
  intercom: {
    INTERCOM_ID: import.meta.env.VITE_INTERCOM_ID,
    SECRET_KEY: import.meta.env.VITE_INTERCOM_SECRET_KEY,
  },
  analyticsEnabled: import.meta.env.VITE_ANALYTICS_ENABLED || false,
  intercomEnabled: import.meta.env.VITE_INTERCOM_ENABLED || false,
  isFetchedFromCache: import.meta.env.VITE_FETCH_HOME_CACHE,
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment:
      import.meta.env.VITE_SENTRY_ENV || import.meta.env.MODE || 'development',
  },
  dataProxyUrl,
  dataProxyUrls: {
    graphql: 'api/graphql',
    v2: {
      production: {
        url: ({ companyId }) => `api/production/${companyId}`,
        comments({ companyId, productionPeriodId }) {
          return `${this.url({ companyId })}/${productionPeriodId}/comments`;
        },
        updateOEEs({ companyId }) {
          return `${this.url({ companyId })}/update-oee`;
        },
        inProgressPeriods(params) {
          return `${this.url(params)}/in-progress`;
        },
        byId: ({ entryId }) => `api/production-entries/${entryId}`,
        throughputModelByPeriodId: ({ id }) =>
          `api/production/throughputmodel/${id}`,
        scheduleComplianceMetrics: ({ id }) =>
          `api/production-entries/schedule-compliance-metrics/${id}`,
      },
      uptime: {
        url: ({ uptimeBy }) => `api/uptime/uptime${uptimeBy}`,
      },
      multipleProductionPost: {
        url: ({ companyId }) => `api/production/${companyId}/multiple`,
      },
      productionEntry: {
        url: ({ companyId, entryId }) =>
          `api/production/${companyId}/${entryId}`,
      },
      comments: {
        url: ({ companyId }) => `api/comments/bycompany/${companyId}`,
      },
      productionStaff: {
        url: ({ companyId }) => `api/production_staff?companyid=${companyId}`,
      },
      tags: {
        url: () => 'api/tags',
      },
      emailReports: {
        url: ({ companyId = null, id = null } = {}) =>
          `api/email_reports${companyId ? `/${companyId}` : ''}${id ? `/${id}` : ''}`,
        resend({ companyId, id }) {
          return `${this.url({ companyId, id })}/resend`;
        },
      },
      notificationProfiles: {
        url: () => 'api/notification_profiles',
      },
      parsecsv: {
        url: ({ companyId }) =>
          `api/production/parsecsv?companyid=${companyId}`,
      },
      devices: {
        url: ({ companyId }) => `api/devices/${companyId}`,
        deviceLoadStates({ companyId, deviceId }) {
          return `${this.url({ companyId })}/${deviceId}/load-states`;
        },
        // single call with devices[]

        // {{dataProxy}}/api/v2/devices/thermopak/load-states?from=1606942800000&to=1607633999242&fromCache=true&devices[]=dev_thermopak_printers_afm15&devices[]=dev_thermopak_compressor_room_chiller2&groupBy=byShift,byDay
        devicesLoadStates({ companyId }) {
          return `${this.url({ companyId })}/load-states`;
        },
        getOEE: () => 'api/v2/devices/oee',
        getSKU({ companyId, deviceId }) {
          return `${this.url({ companyId })}/${deviceId}/sku`;
        },
      },
      device: {
        url: ({ deviceId }) => `/api/v2/device/${deviceId}`,
        cyclesStatistics({ deviceId }) {
          return `${this.url({ deviceId })}/cycles_statistics`;
        },
        secondLevelData({ deviceId }) {
          return `${this.url({ deviceId })}/second_level_data`;
        },
        cyclesSummary({ deviceId }) {
          return `${this.url({ deviceId })}/cycles_summary`;
        },
      },
      cyclesStatistics: {
        url: () => 'api/v2/cycles_statistics',
        settings: () => 'api/v2/cycles_statistics/settings',
      },
      devicesLists: {
        url: ({ companyId, id = null }) =>
          `/api/devices-lists/${companyId}${id ? `/${id}` : ''}`,
      },
      feeds: {
        url: ({ companyId }) => `api/${companyId}/feeds`,
      },
      posts: {
        url: ({ id, companyId } = {}) =>
          `api/posts/${companyId}${id ? `/${id}` : ''}`,
      },
      issuesMessages: {
        url: ({ companyId }) => `api/issuesMessages/${companyId}`,
      },
      issuesAndRules: {
        url: ({ companyId }) => `api/issuesandrules/${companyId}`,
      },
      metricStats: {
        url: ({ companyId, from, to }) =>
          `api/metrics/stats/${companyId}/${from}/${to}`,
      },
      dashboard: {
        get: ({
          companyId,
          averages,
          excludedDays,
          excludedDevicesListIds,
          targetShiftPosition,
          viewId,
          from,
          to,
        }) => {
          const queryParams = [];
          // convert to array
          excludedDays.forEach((d) => {
            queryParams.push(`excludedDays[]=${d}`);
          });

          excludedDevicesListIds.forEach((id) => {
            queryParams.push(`excludedDevicesListIds[]=${id}`);
          });

          averages.forEach((avg) => {
            queryParams.push(`averages[]=${avg}`);
          });
          if (targetShiftPosition !== undefined) {
            queryParams.push(`targetShiftPosition=${targetShiftPosition}`);
          }
          if (from && to) {
            queryParams.push(`from=${from}`);
            queryParams.push(`to=${to}`);
          }
          queryParams.push(`viewId=${viewId}`);
          return `api/dashboard/${companyId}?${queryParams.join('&')}`;
        },
        post: ({ companyId, from, to }) =>
          `api/dashboard/segments?companyId=${companyId}&from=${from}&to=${to}`,
      },
      dashboardViews: {
        url: () => 'api/dashboard-views',
      },
      deviceStates: {
        url: ({ id = '' }) => `api/device-states/${id}`,
      },
      deviceSchedule: {
        url: () => 'api/device-schedule',
      },
      deviceLabelledStates: {
        url: () => 'api/device-labelled-states',
      },
      maintenance: {
        url: ({ companyId }) => `api/device-maintenance-info/${companyId}`,
        resetUrl: () => 'api/device-maintenance-info/reset-runtime-count',
      },
      scrap: {
        url: ({ companyId }) => `api/scrap/${companyId}`,
      },
    },
    v1: {
      shifts: {
        url: ({ companyId }) => `api/production/shifts/${companyId}`,
      },
      units: {
        url: () => 'api/companies/:companyId/produnits',
      },
      issues: {
        url: ({ from, to }) =>
          `/api/issue_details?occurredAt=gt:${from}&occurredAt=lt:${to}`,
      },
    },
  },
  constants,
  emailUpstreamServer,
};
