import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';
// import helper from '../../helpers/prepareData';

export default {
  GET_PRODUCTION_PERIOD_STATS: (
    { commit, state },
    { prevFrom, prevTo, currFrom, currTo, device, range }
  ) =>
    new Promise((res, rej) => {
      const authOptions = getAxiosOpts(state.user.token);
      const url = state.isFetchedFromCache
        ? `api/home/${state.company}`
        : 'api/production/periods/stats';
      const params = state.isFetchedFromCache
        ? {
            range: range.toLocaleLowerCase(),
            widget: 'prodperiod',
          }
        : {
            companyid: state.company,
            prevFrom,
            prevTo,
            currFrom,
            currTo,
            device,
          };
      axios
        .get(url, {
          params,
          ...authOptions,
        })
        .then((response) => {
          const { stats, prevRange, currRange } = response.data;

          const payload = [
            ...(range !== 'week' ? prevRange : []),
            ...currRange,
          ];

          commit('SET_PRODUCTION_PERIOD_STATS', { stats, range });
          commit('productionPeriod/SET_PROD_PERIOD_DATA', { payload, range });
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
};
