import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/state.ts';
import getAxiosOpts from '@/helpers/axios.js';
import { FilterTypes } from '@/store/modules/filters';
import config from '@/config';
import { prepareQuery } from '@/helpers/filters';

import { ChangelogInsert, ChangelogUpdate } from '@/models/Changelogs';

import {
  SET_PRODUCTION_PERIODS,
  ADD_PRODUCTION_PERIODS,
  CLEAR_PRODUCTION_PERIODS,
  SET_UPTIME,
  SET_PARSED_CSV,
  SET_CSV_SAVING_STATUS,
  SET_CSV_SAVED,
  SET_CSV_SAVE_MESSAGE,
  UPDATING_OEES,
  SET_UNSAVED_ENTRIES_STATUS,
  SET_CSV_PARSE_STATUS,
  SET_IN_PROGRESS_PRODUCTION_PERIODS,
  LOADING_RESOURCE,
  LOADING_RESOURCE_DONE,
  UPDATE_PRODUCTION_ENTRY,
  SAVE_THROUGHPUT_MODEL,
  CLEAN_PRODUCTION_THROUGHPUT,
  UPDATING_OEES_DONE,
  UPDATE_EDIT_SCRAP,
  UPDATE_PRODUCTION_PERIOD_CREATE_UPDATE_MODAL,
} from './mutations-types';

import productionHelper, { formatParsedCsvRow } from './helpers';

import { ProductionPeriod, State } from './model';

const DEFAULT_OEE = {
  oee: 0,
  quality: 0,
  performance: 0,
  availability: 0,
};

// We need this until we update all the jsonb values
const staffParser = (people) => {
  if (typeof people === 'string') return [people];
  if (!Array.isArray(people)) return [];
  return people;
};

const mapPeriods = (periods, rootGetters) =>
  periods.map((pp: ProductionPeriod) => {
    let unit = rootGetters['units/getUnitById'](pp.production?.unitid);
    const { usesAdditionalSensor, ratelessUnit } = rootGetters[
      'getAdditionalSensorMetricData'
    ](pp.device_id);
    if (usesAdditionalSensor && ratelessUnit) {
      unit = {
        id: -1,
        unitName: ratelessUnit,
      };
    }

    return {
      ...pp,
      duration: {
        ...pp.duration,
        production: {
          from: Number(pp.duration.production.from),
          to: Number(pp.duration.production.to),
        },
      },
      oee: pp.oee || { ...DEFAULT_OEE },
      unit,
      // This should not be here, but as uptime returns null sometimes we need it.
      uptime: pp.uptime != null ? pp.uptime : undefined,
      // As the energy is returning as string we need to parse it to work with numbers
      energy: Number(pp.energy),
      people: {
        manager: staffParser(pp.people.manager),
        supervisor: staffParser(pp.people.supervisor),
        operator: staffParser(pp.people.operator),
      },
    };
  });

const orderPeriods = (periods) =>
  periods.sort((a, b) => {
    const {
      duration: { production: productionA },
    } = a;
    const {
      duration: { production: productionB },
    } = b;
    return productionB.from - productionA.from;
  });

const resolveRelations = (data, rootGetters) =>
  orderPeriods(mapPeriods(data, rootGetters));

export default {
  async getProduction({ commit, rootState: { user, company }, rootGetters }) {
    const url = config.dataProxyUrls.v2.production.url({ companyId: company });
    const remoteFilters = rootGetters['filters/getFiltersByType'](
      FilterTypes.REMOTE
    );

    try {
      const { data } = await axios.get(url, {
        params: prepareQuery(remoteFilters),
        ...getAxiosOpts(user.token),
      });
      if (data && data.response) {
        commit(
          SET_PRODUCTION_PERIODS,
          resolveRelations(data.response, rootGetters)
        );
      } else {
        commit(CLEAR_PRODUCTION_PERIODS);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async getProductionEntry({ commit, rootState, rootGetters }, { entryId }) {
    // get production entry from dataprox
    const url = config.dataProxyUrls.v2.production.byId({ entryId });

    try {
      const { data } = await axios.get(url);
      // update entry in the store
      commit(
        UPDATE_PRODUCTION_ENTRY,
        resolveRelations([data.response], rootGetters)[0]
      );
      return data.response;
    } catch (error) {
      console.error('Error getting production entry', error);
      return null;
    }
  },
  SAVE_PRODUCTION_ENTRIES({ commit, rootState, rootGetters }, payload) {
    const valid = payload.map((p) => {
      const entry = productionHelper.formatProdEntryObject(
        p,
        rootState.user,
        rootState.units
      );

      // Create a new changelog value for this entry
      const changelog = new ChangelogInsert({
        user: {
          username: rootState.user.loggedInUserInfo.username,
          companyid: rootState.user.loggedInUserInfo.companyid,
        },
        data: ['Entry created'],
      });

      // Add that new changelog to the array of changelogs in the entry
      changelog.appendChangelogToEntry(entry);

      return entry;
    });

    const url = config.dataProxyUrls.v2.multipleProductionPost.url({
      companyId: rootState.company,
    });

    return new Promise((res, rej) => {
      axios
        .post(url, valid, getAxiosOpts(rootState.user.token))
        .then(({ data, status }) => {
          if (data.error) {
            throw new Error(data.message);
          }
          const p = resolveRelations(
            data.response.filter((e) => e.success !== false),
            rootGetters
          );
          commit(ADD_PRODUCTION_PERIODS, p);
          res({ response: data, status: status });
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  EDIT_PRODUCTION_ENTRIES({ commit, getters, rootState, dispatch }, payload) {
    const valid = payload.map((p) => {
      const entry = productionHelper.formatProdEntryObject(
        p,
        rootState.user,
        rootState.units
      );

      // Add original changelog to the entry
      const originalEntry = getters.getProductionById(entry.entryId) || {};
      entry.changelog = originalEntry.changelog;
      // Create a new changelog value for this update
      const changelog = new ChangelogUpdate({
        user: {
          username: rootState.user.loggedInUserInfo.username,
          companyid: rootState.user.loggedInUserInfo.companyid,
        },
        data: ['Entry updated'],
      });

      // Add old data to the changelog created
      changelog.addData({ ...originalEntry });
      // Add that new changelog to the array of changelogs in the entry
      changelog.appendChangelogToEntry(entry);

      return entry;
    });

    const url = config.dataProxyUrls.v2.multipleProductionPost.url({
      companyId: rootState.company,
    });
    return new Promise((res, rej) => {
      axios
        .put(url, valid, getAxiosOpts(rootState.user.token))
        .then((response) => {
          dispatch('getComments', valid[0].entryId);
          commit(CLEAN_PRODUCTION_THROUGHPUT);
          res({ response: response.data, status: response.status });
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  async UpdateProductionEntry(
    { commit, rootState, rootGetters, dispatch },
    period
  ) {
    const url = config.dataProxyUrls.v2.productionEntry.url({
      companyId: rootState.company,
      entryId: period.id,
    });
    return new Promise((res, rej) => {
      axios
        .put(url, period, getAxiosOpts(rootState.user.token))
        .then((response) => {
          res({ response: response.data, status: response.status });
          commit(UPDATE_PRODUCTION_ENTRY, mapPeriods([period], rootGetters)[0]);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  async getUptime({ commit, rootState: { user } }, { period, type }) {
    const url = config.dataProxyUrls.v2.uptime.url({
      uptimeBy: 'min',
    });

    const params = {
      deviceId: period.device_id,
      from: period.duration.production.from,
      to: period.duration.production.to,
    };

    try {
      const { data } = await axios.get(url, {
        ...getAxiosOpts(user.token),
        params,
      });

      if (data && data.response) {
        return data.response;
      }
      return {};
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async DELETE_ENTRIES({ commit, rootState }, data) {
    const url = config.dataProxyUrls.v2.multipleProductionPost.url({
      companyId: rootState.company,
    });

    return new Promise((res, rej) => {
      axios
        .delete(url, {
          data,
          ...getAxiosOpts(rootState.user.token),
        })
        .then((response) => {
          res({ response: response.data, status: response.status });
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  async getComments({ dispatch }, { periodId }) {
    return dispatch(
      'comments/getComments',
      {
        ftable: 'production_entries',
        ftableid: periodId,
      },
      { root: true }
    );
  },
  async addComment({ dispatch }, { periodId, comment }) {
    return dispatch(
      'comments/addComment',
      {
        ftable: 'production_entries',
        ftableid: periodId,
        comment,
      },
      { root: true }
    );
  },
  async parseCsv({ commit, rootState: { user, company } }, data) {
    const url = config.dataProxyUrls.v2.parsecsv.url({ companyId: company });
    return new Promise((res, rej) => {
      axios
        .post(url, data, getAxiosOpts(user.token))
        .then((response) => {
          commit(SET_CSV_SAVED, false);
          commit(SET_PARSED_CSV, response.data.response);
          res(response.data.response);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  RESET_PARSED_DATA({ commit }) {
    commit(SET_PARSED_CSV, []);
    commit(SET_CSV_PARSE_STATUS, false);
  },
  SAVE_CSV_DATA({ commit, rootState, rootGetters }, payload) {
    commit(SET_CSV_SAVING_STATUS, true);
    const unitid = _.get(
      rootState.companySettings,
      'production.production.primary.unit',
      rootState.units[1].id
    );
    const secondaryUnitid = _.get(
      rootState.companySettings,
      'production.production.secondary.unit',
      rootState.units[1].id
    );
    const validPayload = payload.map((p) =>
      formatParsedCsvRow(p, rootState.user, unitid, secondaryUnitid)
    );

    // Remove entries that do not have required data
    const filtered = validPayload.filter(
      (f) =>
        f.device_id &&
        // Production qty no longer required
        // f.production.produced &&
        f.duration.production.from &&
        f.duration.production.to
    );
    const url = config.dataProxyUrls.v2.multipleProductionPost.url({
      companyId: rootState.company,
    });
    return new Promise((res, rej) => {
      if (filtered.length < 1) {
        commit(SET_CSV_SAVING_STATUS, false);
        commit(SET_CSV_SAVED, true);
        commit(SET_CSV_SAVE_MESSAGE, 'No valid rows to save.');
        rej(new Error('No valid rows to save.'));
      } else {
        res(filtered);
      }
    });
  },
  SET_CSV_SAVING_STATUS({ commit }, payload) {
    commit(SET_CSV_SAVING_STATUS, payload);
  },
  SET_CSV_SAVED_STATUS({ commit }, payload) {
    commit(SET_CSV_SAVED, payload);
  },
  SET_CSV_SAVE_MESSAGE({ commit }, payload) {
    commit(SET_CSV_SAVE_MESSAGE, payload.msg);
  },
  UPDATE_FROM_CSV_RESPONSE({ commit, rootGetters }, payload) {
    if (!payload.periods.length) return;
    const p = resolveRelations(payload.periods, rootGetters);
    commit(ADD_PRODUCTION_PERIODS, p);
  },
  async updateOEEs(
    { commit, rootState: { user, company }, dispatch },
    payload
  ) {
    const url = config.dataProxyUrls.v2.production.updateOEEs({
      companyId: company,
    });
    // Trigger loading action
    commit(UPDATING_OEES);
    // Call endpoint to update
    try {
      const { data, isAxiosError } = await axios.post(url, payload, {
        ...getAxiosOpts(user.token),
      });
      console.log(!isAxiosError ? data.response : data.error);
      dispatch('getProduction');
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: !isAxiosError
            ? 'Entries updated successfully.'
            : 'Sorry an error occurred. Please try again.',
          alertType: !isAxiosError ? 'success' : 'error',
        },
        { root: true }
      );
    } catch (e) {
      console.log('ERROR Updating OEEs', e);
    }
    // Trigger ready action
    commit(UPDATING_OEES_DONE);
  },
  setUnSavedEntriesState({ commit }, payload) {
    commit(SET_UNSAVED_ENTRIES_STATUS, payload);
  },
  async getInProgressPeriods(context: ActionContext<State, RootState>) {
    const {
      commit,
      state: {
        periods: {
          all,
          inProgress: { loading },
        },
      },
      rootState: { user, company },
      rootGetters,
    } = context;

    const url = config.dataProxyUrls.v2.production.inProgressPeriods({
      companyId: company,
    });

    // Prevent triggering another call if there is one in progress
    if (loading) return Promise.resolve();

    try {
      commit(LOADING_RESOURCE, 'inProgress');
      const { data } = await axios.get(url, {
        params: {
          now: moment().valueOf(),
        },
        ...getAxiosOpts(user.token),
      });

      if (data && data.response) {
        const periods = orderPeriods(mapPeriods(data.response, rootGetters));
        commit(SET_IN_PROGRESS_PRODUCTION_PERIODS, periods);

        // Re-order if there was periods already loaded in the store
        if (all.length) {
          commit(SET_PRODUCTION_PERIODS, all);
        }
        return Promise.resolve(periods);
      }
      throw new Error('Error getting in progress entries');
    } catch (e) {
      console.error(e);
      // Neve fails
      return Promise.resolve([]);
    } finally {
      commit(LOADING_RESOURCE_DONE, 'inProgress');
    }
  },
  cleanProduction: ({ commit }) => commit(CLEAR_PRODUCTION_PERIODS),
  async getThroughputModel(
    { commit, state, rootState: { user } },
    { id, reload = false }
  ) {
    const url = config.dataProxyUrls.v2.production.throughputModelByPeriodId({
      id,
    });
    if (state.throughputModels[id] && !reload)
      return state.throughputModels[id];

    try {
      const { data } = await axios.get(url, getAxiosOpts(user.token));
      commit(SAVE_THROUGHPUT_MODEL, { ...data.response, id });
      return data.response;
    } catch (err) {
      console.error('Error getting production throughput model', err);
      return null;
    }
  },
  async getScheduleComplianceMetrics({ rootState: { user } }, { id }) {
    const url = config.dataProxyUrls.v2.production.scheduleComplianceMetrics({
      id,
    });

    try {
      const { data } = await axios.get(url, getAxiosOpts(user.token));
      return data.response;
    } catch (err) {
      console.error(
        'Error getting production schedule compliance metrics',
        err
      );
      return null;
    }
  },
  editScrap({ commit }, scrap) {
    commit(UPDATE_EDIT_SCRAP, scrap);
  },
  updateProductionPeriodCreateUpdateModal({ commit }, payload) {
    commit(UPDATE_PRODUCTION_PERIOD_CREATE_UPDATE_MODAL, payload);
  },
};
