export default {
  COMMENTS_MAX_LENGTH: import.meta.env.VITE_COMMENTS_MAX_LENGTH || 500,
  production: {
    inProgressRefreshInterval:
      import.meta.env.VITE_IN_PROGRESS_REFRESH_INTERVAL || 60000,
  },
  dashboard: {
    liveModeRefreshInterval:
      import.meta.env.VITE_DASHBOARD_LIVEMODE_REFRESH_TIME_SECS || 60000,
  },
  loadStatesRefreshInterval:
    import.meta.env.VITE_LOAD_STATES_REFRESH_INTERVAL || 60000,
  loadStatesCacheTime: import.meta.env.VITE_LOAD_STATES_CACHE_TIME || 30000,
  cyclesSummaryPollingInterval:
    import.meta.env.VITE_CYCLES_SUMMARY_POLLING_INTERVAL || 2000,
  cyclesSummaryPollingAttempts:
    import.meta.env.VITE_CYCLES_SUMMARY_POLLING_ATTEMPTS || 90,
  issuesLimit: import.meta.env.VITE_ISSUES_LIMIT || 6000,
  trendsLimit: import.meta.env.VITE_TRENDS_LIMIT || 14000,
};
