import Vue from 'vue';
import _ from 'lodash';

export default {
  SET_PREVIOUS_BEST: (state, devicePreviousBest) => {
    const previousBest = state.previousBest.slice();
    const position = previousBest.findIndex(
      (p) => p.deviceid === devicePreviousBest.deviceid
    );
    if (position > -1) {
      previousBest[position] = _.merge(
        previousBest[position],
        devicePreviousBest
      );
    } else {
      // Create new issue in store
      previousBest.push(devicePreviousBest);
    }
    Vue.set(state, 'previousBest', previousBest);
  },
};
