import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';

export default {
  namespaced: true,
  state: {
    list: [],
    created: false,
    intervalId: null,
  },
  getters: {
    list: (state) => state.list,
    unread: (state) =>
      state.list.filter((item) => item.isRead === false && item.status === 2)
        .length,
  },
  mutations: {
    SET_LIST(ctx, payload) {
      ctx.list = payload;
      ctx.created = true;
    },
    STOP: (ctx) => {
      clearInterval(ctx.intervalId);
    },
  },
  actions: {
    INIT: async (ctx) => {
      await ctx.dispatch('GET_LIST');
      ctx.state.intervalId = setInterval(() => {
        ctx.dispatch('REFRESH');
      }, 30000);
    },
    REFRESH: async (ctx) => {
      ctx.state.created = false;
      return ctx.dispatch('GET_LIST');
    },
    GET_LIST: async ({ rootState, state, commit }) => {
      if (state.created === true) {
        return Promise.resolve(true);
      }
      const { company } = rootState;
      const { username } = rootState.user.loggedInUserInfo;
      const options = getAxiosOpts(rootState.user.token);
      try {
        const url = `/api/exportdata/${company}/${username}`;
        const { data } = await axios.get(url, options);
        if (data.error) {
          return Promise.reject(data.error);
        }
        commit('SET_LIST', data.data);
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
    SAVE: async ({ rootState }, payload) => {
      const { company } = rootState;
      const { username } = rootState.user.loggedInUserInfo;
      const options = getAxiosOpts(rootState.user.token);
      try {
        const url = `/api/exportdata/${company}/${username}`;
        const { data } = await axios.post(url, payload, options);
        if (data.error) {
          return Promise.reject(data.error);
        }
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
    SET_READ: async ({ rootState, dispatch }, payload) => {
      const { company } = rootState;
      const { username } = rootState.user.loggedInUserInfo;
      const options = getAxiosOpts(rootState.user.token);
      try {
        const url = `/api/exportdata/${company}/${username}`;
        const { data } = await axios.put(url, { id: payload }, options);
        if (data.error) {
          return Promise.reject(data.error);
        }
        dispatch('REFRESH');
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
  },
};
