import { Filter, FilterTypes } from '@/store/modules/filters';
import { applyFiltersToData } from '@/helpers/filters';
import { LineRule, LineRuleParams, State } from './model';

export default {
  getLineRule: (state: State) => (params: LineRuleParams) => {
    const { lineRuleId, lineRuleVersion } = params;

    const lRule = state.lineRules.find((mdr) => mdr.id === lineRuleId);

    if (
      typeof lineRuleVersion !== 'undefined' &&
      lRule?.version !== lineRuleVersion
    ) {
      // version mismatch, need to get from server
      return null;
    }

    return lRule;
  },
  getFilteredLineRules: (state: State, getters, rootState, rootGetters) => {
    let filteredData: Array<LineRule> = state.lineRules || [];

    const deviceFilter: Filter = rootGetters['filters/getFilters']('device');
    if (deviceFilter) {
      filteredData = applyFiltersToData<LineRule>([deviceFilter], filteredData);
    }

    const localFilters: Array<Filter> = rootGetters['filters/getFiltersByType'](
      FilterTypes.LOCAL,
      'alert'
    );
    if (localFilters.length) {
      filteredData = applyFiltersToData<LineRule>(localFilters, filteredData);
    }

    return filteredData;
  },
};
