import _ from 'lodash';
import { State, Type } from './model';
import { goBackByTime } from './helpers';

export default {
  getFeeds: (state: State) => (types: Array<Type>) => {
    // No filter applied
    if (!types) {
      return state.data;
    }

    // @TODO: Filter by type
    return state.data;

    // return state.data.filter(feed => types.includes(feed.type)) || [];
  },
  getStatus: (state: State) => ({
    loading: state.loading,
  }),
};
