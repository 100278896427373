import Device from '@/models/Device';

export interface LineRule {
  actions: any;
  alertScope: 'lineAlert';
  alertType: string;
  companyId: string;
  conditions: {
    conditions?: Record<any, any>[];
    ruleOperator?: string;
  };
  conditionsText?: string; // harmonize with device rule for table header conditions
  createdAt: number;
  createdBy: string;
  deleted: boolean;
  description: string;
  devices?: Array<Device>;
  deviceGroupId?: string;
  deviceIds: Array<string>;
  deviceNicknames?: string;
  deviceThresholdsVersion?: number;
  dnd: any;
  enabled: boolean;
  id: number;
  lineConditions?: Array<object>;
  message?: string; // harmonize with device rule for table header message
  notifications: object;
  recipientsList?: string;
  thresholds: object;
  thresholdDuration?: string;
  updatedAt?: number;
  updatedBy?: string;
  version: number;
}

export type LineRuleParams = {
  lineRuleId: number;
  lineRuleVersion?: number;
};

export interface State {
  lineRules: Array<LineRule>;
  lineRulesHistory: Array<LineRule>;
}

export const initialState: State = {
  lineRules: [],
  lineRulesHistory: [],
};
