import Vue from 'vue';
import _ from 'lodash';
import mappers from '@/helpers/mappers.js';
import moment from 'moment';

export default {
  SET_ISSUES: (state, issues) => {
    const updatedIssues = issues.map((issue) => ({
      ...issue,
      // used by passedIssue watcher on issue details to reload on refresh data
      reloadIssue: true,
    }));
    Vue.set(state, 'issues', updatedIssues);
    // set for issuesByDevice
    const { issuesByDevice } = state;
    const deviceIssues = updatedIssues.reduce((acc, curr) => {
      const deviceId = curr.deviceId || curr.deviceid;
      if (!acc[deviceId]) acc[deviceId] = [];
      acc[deviceId].push(curr);
      return acc;
    }, {});
    Vue.set(state, 'issuesByDevice', { ...issuesByDevice, ...deviceIssues });
  },
  SET_ISSUE_TYPE: (state, type) => {
    Vue.set(state, 'selectedIssueType', type.toLowerCase());
  },
  SET_ISSUE_DETAILS: (state, issue) => {
    const issueDetails = state.issues.slice();
    const {
      started_at: startedAt,
      stopped_at: stoppedAt,
      updated_at: updatedAt,
    } = issue;
    const parsedIssue = issue.startedAt
      ? { ...issue }
      : {
          ...issue,
          startedAt,
          stoppedAt,
          updatedAt,
        };
    const issueD = issueDetails.findIndex(
      (issueDet) => issueDet.id === parsedIssue.id
    );
    if (issueD > -1) {
      issueDetails[issueD] = _.assignIn(issueDetails[issueD], parsedIssue);
    } else {
      // Create new issue in store
      issueDetails.push(parsedIssue);
    }
    Vue.set(state, 'issues', _.orderBy(issueDetails, 'startedAt', ['desc']));

    // set for issuesByDevice
    const { deviceId } = parsedIssue;
    const { issuesByDevice } = state;
    const existingIssues = issuesByDevice[deviceId];
    if (existingIssues && existingIssues.length > 0) {
      const index = existingIssues.findIndex(
        (issueDet) => issueDet.id === parsedIssue.id
      );

      if (index > -1) {
        existingIssues[index] = _.assignIn(existingIssues[index], parsedIssue);
      } else {
        existingIssues.push(parsedIssue);
      }

      Vue.set(state.issuesByDevice, deviceId, existingIssues);
    } else {
      Vue.set(state.issuesByDevice, deviceId, [parsedIssue]);
    }
    Vue.set(state.updateDeviceState, deviceId, moment().valueOf());
  },
  SET_MESSAGE_SNIPPETS: (state, payload) => {
    Vue.set(state, 'messageSnippets', payload);
  },
  SET_ISSUES_STATUS: (state) => {
    Vue.set(state, 'status', mappers.defaultIssuesStatuses);
  },
  SET_ISSUES_TYPES: (state) => {
    Vue.set(state, 'issuesTypes', [...mappers.defaultIssueTypes]);
  },
  SET_ISSUES_AND_RULES: (state, payload) => {
    Vue.set(state, 'issuesWithRules', payload);
  },
  ADD_ISSUE_FROM_GRAPH: (state) => {
    Vue.set(state, 'addingIssueNavigation', false);
    Vue.set(state, 'addingIssueGraph', true);
  },
  ADD_ISSUE_FROM_NAVIGATION: (state) => {
    Vue.set(state, 'addingIssueGraph', false);
    Vue.set(state, 'addingIssueNavigation', true);
  },
  CLEAN_ISSUES: (state) => {
    Vue.set(state, 'issues', []);
  },
  MULTIPLE_UPDATE_ISSUES_DETAILS: (state, { issues, column }) => {
    const issuesCopy = state.issues.slice();
    issues.forEach((issue) => {
      const index = issuesCopy.findIndex((i) => i.id === issue.id);
      Vue.set(state.updateDeviceState, issue.deviceid, moment().valueOf());
      if (column === 'deleted') {
        issuesCopy.splice(index, 1);
      } else {
        const duration = issue.stoppedAt
          ? (Number(issue.stoppedAt) - Number(issue.startedAt)) / 1000 / 60
          : null;
        issuesCopy[index] = _.assignIn(issuesCopy[index], {
          ...issue,
          duration,
        });
      }
    });
    Vue.set(state, 'issues', issuesCopy);
  },
  SET_LOADING: (state, loading) => {
    Vue.set(state, 'loading', loading);
  },
};
