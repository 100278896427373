import Vue from 'vue';
import { Shift } from '.';
import { SET_SHIFTS } from './mutations-types';

export default {
  [SET_SHIFTS](state: Array<Shift>, payload: Array<Shift>) {
    payload.forEach((shift) => {
      Vue.set(state, shift.id, shift);
    });
  },
};
