import { sortBy, uniq, find, difference } from 'lodash';
import Device from '@/models/Device';
import { LineIssueDetail } from './model';

/*
  Gets the First Fault Alerting (FFA) devices
  and an array of status codes used for additonal
  user context regarding FFA (ie. FFA info only going back one hour)
*/
export const getFFADevices = (
  allDevices: Array<Device>,
  lineIssueDetail: LineIssueDetail
): { ffaDevices?: Array<Device>; ffaStatusCodes?: Array<string> } => {
  const { inAlertDevicesData } = lineIssueDetail;

  const rows = Object.values(inAlertDevicesData || {});

  if (rows.length === 0) return {};

  const sortedRows = sortBy(rows, 'fromTs');
  const earliestFromTs = sortedRows?.[0]?.fromTs;
  const earliestRows = sortedRows.filter((r) => r.fromTs === earliestFromTs);

  const ffaDeviceIds = uniq(earliestRows.map((r) => r.deviceId));
  const ffaStatusCodes = uniq(earliestRows.map((r) => r.statusCode)).filter(
    (r) => !['SUCCESS', undefined].includes(r)
  );

  const ffaDevices = allDevices.filter((dev) =>
    ffaDeviceIds.includes(dev.deviceid)
  );

  return {
    ffaDevices,
    ffaStatusCodes,
  };
};

export const addMetadata = (
  allDevices: Array<Device>,
  lineIssueDetail: LineIssueDetail
) => {
  const { deviceIds = [] } = lineIssueDetail;

  const devices = allDevices.filter((dev) => deviceIds.includes(dev.deviceid));
  const deviceNicknames = devices.map(({ nickname }) => nickname).join(', ');
  const { ffaDevices = [], ffaStatusCodes = [] } =
    getFFADevices(allDevices, lineIssueDetail) || {};
  const ffaDeviceNicknames = ffaDevices
    .map(({ nickname }) => nickname)
    .join(', ');
  const affectedDevices = difference(devices, ffaDevices) || [];
  const affectedDeviceNicknames = affectedDevices
    .map(({ nickname }) => nickname)
    .join(', ');

  return {
    ...lineIssueDetail,
    devices,
    deviceNicknames,
    ffaDevices,
    ffaDeviceNicknames,
    ffaStatusCodes,
    affectedDevices,
    affectedDeviceNicknames,
  };
};

// This function assumes that ffaDevices is already part of the issue. Issues in the vuex
// store already have the metadata added and have the ffaDevices.
export const isFirstFaultDevice = (issue: LineIssueDetail, deviceId: string) =>
  !!find(issue.ffaDevices, { deviceid: deviceId });
