import { find } from 'lodash';

const COLORS = {
  NODATA: {
    color: '#3D525C',
    status: 'No data',
    bgColor: '#F0F3F5',
    barColor: '#3D525C',
  },
  HEALTHY: {
    color: '#237804',
    bgColor: '#E6FBE5',
    barColor: '#52C41A',
    status: 'Healthy',
  },
  SOON: {
    color: '#874D00',
    bgColor: '#FEF8D5',
    barColor: '#FFC53D',
    status: 'Due soon',
  },
  OVER: {
    color: '#A8071A',
    bgColor: '#FFF1F0',
    barColor: '#FF4D4F',
    status: 'Overdue',
  },
};

const getStatus = (percentage) => {
  if (!percentage && percentage !== 0) return COLORS.NODATA;
  if (percentage < 70) {
    return COLORS.HEALTHY;
  } else if (percentage > 100) {
    return COLORS.OVER;
  }
  return COLORS.SOON;
};

export default {
  getMaintenanceData: (state, getters, rootState, rootGetters) => {
    const { devices } = rootGetters;
    const data = state.data.map(({ deviceId, runtimeHours, ...rest }) => {
      const { settings, nickname } = rootGetters.getDeviceById(deviceId);
      const maintenanceHours = settings?.maintenanceHours || 2000;
      const healthBar =
        runtimeHours !== null && (runtimeHours / maintenanceHours) * 100;
      const info = getStatus(healthBar);
      return {
        ...rest,
        runtimeHours,
        runtimeTarget: maintenanceHours,
        healthBar,
        nickname,
        deviceId,
        ...info,
      };
    });
    if (!data || !data.length || !devices) return [];
    return devices.map(
      ({ deviceid }) => find(data, { deviceId: deviceid }) || {}
    );
  },
};
