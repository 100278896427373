import Device from '@/models/Device';

export interface LineIssueDetail {
  alertScope: 'lineAlert';
  alertType: 'device_data' | 'anomaly' | 'hybrid'; // copied from lineAlertType to use for filter
  assigned: {
    email: string;
    username: string;
    phonenumber: string;
  }[];
  changelog: {
    data: unknown[];
    user: string;
    action: string;
    timestamp: number;
  }[];
  comments: { action: string }; // action is the message
  createdAt: number; // unix timestamp in ms
  createdBy: string;
  deleted?: boolean;
  devices?: Device[];
  deviceIds: string[]; // all device ids from Line rule
  deviceNicknames?: string;
  ffaDevices?: Device[];
  ffaDeviceNicknames?: string;
  ffaStatusCodes?: string[];
  affectedDevices?: Device[];
  affectedDeviceNicknames?: string;
  id: string; // uuid
  inAlertDeviceIds: string[]; // only alerting device ids
  inAlertDevicesData: {
    deviceId: string;
    fromTs: number;
    status?: string;
    toTs?: number | null;
  }[];
  issueType: 'alert';
  lineAlertType?: 'device_data' | 'anomaly' | 'hybrid'; // actual database field
  lineRuleId: number;
  lineRuleVersion: number;
  notified: string[];
  startedAt: number; // unix timestamp in ms
  status: 'open' | 'closed' | 'acknowledged';
  stoppedAt?: number | null; // unix timestamp in ms, null = ongoing
  tags: {
    tagname: string;
    [key: string]: unknown;
  }[];
  updatedAt?: number; // unix timestamp in ms
  updatedBy?: string;
  version: number;
}

export interface State {
  lineIssueDetails: LineIssueDetail[];
  lineIssueDetailsByDevice: { [key: string]: LineIssueDetail[] };
}

export const initialState: State = {
  lineIssueDetails: [],
  lineIssueDetailsByDevice: {},
};

export default initialState;

export type GetLineIssueDetailsPayload = {
  to?: number;
  from?: number;
  deviceIds?: string[];
};

export type LineIssueDetailPayload = {
  allDevices: Device[];
  lineIssueDetail: LineIssueDetail;
};

export type LineIssueDetailsPayload = {
  allDevices: Device[];
  lineIssueDetails: LineIssueDetail[];
  deviceIds?: string[];
};

export type BulkUpdateParams = {
  issues: unknown[];
  column: string;
};
