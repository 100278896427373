import axios from 'axios';
import config from '@/config';
import getAxiosOpts from '@/helpers/axios.js';

import { SET_NOTIF_PROFILE } from './mutation-types';

const url = config.dataProxyUrls.v2.notificationProfiles.url();

export default {
  getNotifProfile: ({ commit, rootState: { user, notifprofiles } }, data) => {
    if (Object.keys(notifprofiles).length > 0) return;
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get(url, {
          params: {
            ...data,
          },
          ...getAxiosOpts(user.token),
        });
        if (response.status === 200) {
          commit(SET_NOTIF_PROFILE, response.data.response[0]);
        } else {
          rej(response);
        }
      } catch (err) {
        rej(err);
      }
    });
  },
};
