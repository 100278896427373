import axios, { AxiosResponse, AxiosPromise, CancelTokenStatic } from 'axios';
import moment from 'moment';
import { merge } from 'lodash';
import getAxiosOpts from '@/helpers/axios.js';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/state.ts';
import config from '@/config';
import { isDeviceGroup } from '@/helpers/validations';
import {
  SET_DEVICE_LOAD_STATE,
  LOADING_DEVICE_LOAD_STATE,
  LOADING_DEVICE_LOAD_STATE_DONE,
} from './mutations-types';
import { State, LoadState, DeviceStops } from './model';

type ServiceResponse<T = Record<string, LoadState>> = AxiosResponse<{
  response: T;
}>;

const CACHE_TIME = config.constants.loadStatesCacheTime;

const cancelToken: CancelTokenStatic = axios.CancelToken;
let cancelSource = cancelToken.source();

const refreshToken = () => {
  cancelSource = cancelToken.source();
};

const cancelAllRequests = () => {
  cancelSource.cancel('cancelled');
};

export default {
  async getDeviceLoadStates(context: ActionContext<State, RootState>, payload) {
    const { commit, rootState, rootGetters, getters } = context;

    const { from, to, metricId, fromCache } = payload;

    let { deviceId } = payload;

    const {
      company: companyId,
      user: { token },
    } = rootState;

    // get default device if its is device group
    if (isDeviceGroup(deviceId)) {
      deviceId = rootGetters.getGroupDefaultDevice(deviceId);
    }

    const loadStates = getters.deviceLoadStates(deviceId, from, to);

    // If loading then return, some other action is already waiting for data
    if (loadStates.loading) return Promise.resolve();

    // If lastUpdate is defined then chech the cache time before triggering the request.
    if (loadStates.lastUpdate) {
      const timeDiff = moment().diff(loadStates.lastUpdate);

      if (timeDiff < CACHE_TIME) {
        return Promise.resolve();
      }
    }

    const url = config.dataProxyUrls.v2.devices.deviceLoadStates({
      companyId,
      deviceId,
    });

    commit(LOADING_DEVICE_LOAD_STATE, { deviceId, from, to });

    try {
      const { status, data }: ServiceResponse = await axios.get(url, {
        params: {
          metricId,
          from,
          to,
          fromCache,
        },
        ...getAxiosOpts(token),
      });

      if (status === 200) {
        commit(SET_DEVICE_LOAD_STATE, {
          deviceId,
          from,
          to,
          data: data.response ? data.response[deviceId] : [],
        });
        commit(LOADING_DEVICE_LOAD_STATE_DONE, { deviceId, from, to });
        return Promise.resolve(data.response);
      }
      commit(LOADING_DEVICE_LOAD_STATE_DONE, { deviceId, from, to });
      return Promise.reject();
    } catch (e) {
      commit(LOADING_DEVICE_LOAD_STATE_DONE, { deviceId, from, to });
      return Promise.reject();
    }
  },
  cancelLoadStatesSingleRequests(
    context: ActionContext<State, RootState>,
    payload
  ) {
    // Cancel all previous calls
    cancelAllRequests();
  },
  // end point for a single call to the server passing in an array of devices and getting
  // their states grouped by day or shift, load is already aggregated by day/shift
  // skipping store activity for now
  // individualDeviceRequest = make individual calls for DB performance reasons
  async getDevicesLoadStatesSingleRequest(
    context: ActionContext<State, RootState>,
    payload
  ) {
    // Cancel all previous calls
    cancelAllRequests();
    refreshToken();

    const { rootState } = context;

    const {
      from,
      to,
      fromCache = true,
      // don't go to the DB at all, return nulls if cache doesn't have the value
      useCacheOnly = true,
      devices,
      groupBy,
      units,
      individualDeviceRequest,
      excludeWeekends = false,
      // server will provide -1 day view by default
      shiftIds = [],
    } = payload;

    const {
      company: companyId,
      user: { token },
    } = rootState;

    const url = config.dataProxyUrls.v2.devices.devicesLoadStates({
      companyId,
    });

    // use this as a template object for each API call, changing the devices list
    const paramsTemplate: any = {
      from,
      to,
      fromCache,
      useCacheOnly,
      groupBy,
      units,
      excludeWeekends,
      shiftIds,
    };

    // each device gets one query for better db performance, tradeoff = client processing, api cache
    if (individualDeviceRequest) {
      const result = {};
      const promises = devices.map((deviceId) => {
        const params = { ...paramsTemplate };
        // return axios promise
        return axios
          .put(
            url,
            {
              // API expect devices in an array format
              devices: [deviceId],
            },
            {
              params,
              cancelToken: cancelSource.token,
              ...getAxiosOpts(token),
            }
          )
          .then(({ status, data }) => {
            // AxiosResponse comes back as {config, data, headers, request, status, statusText}
            if (status === 200 && data && data.response) {
              // keep merging the response data into the ongoing result object
              merge(result, data.response);
            }
            // TODO: alei 2020dec15 - what do we do for the error case? currently just not including
            //  it in the result
          })
          .catch((e) => Promise.reject(e));
      });
      // wait for all the promises/axios responses to finish
      await Promise.all(promises);

      // return the result that should look just like the single request for all devices
      return Promise.resolve(result);

      // else is returning items inside try, but eslint is complaining
    } else {
      // single request for all devices
      try {
        const params = { ...paramsTemplate };
        const { status, data }: ServiceResponse = await axios.put(
          url,
          {
            devices,
          },
          {
            params,
            cancelToken: cancelSource.token,
            ...getAxiosOpts(token),
          }
        );

        // errors are also coming back as 200 from the local server even if real endpoint is 5xx
        if (status === 200) {
          return Promise.resolve(data.response);
        }

        return Promise.reject();
      } catch (e) {
        return Promise.reject();
      }
    }
  },
  async getDevicesLoadStates(
    context: ActionContext<State, RootState>,
    payload
  ) {
    const { commit, dispatch, rootState, rootGetters, getters } = context;

    const { company: companyId, devices, user: token } = rootState;

    // ONE QUERY PER DEVICE
    const data = {};

    const promises = devices.map(({ deviceid: deviceId }) =>
      dispatch('getDeviceLoadStates', {
        ...payload,
        deviceId,
      }).then((d) => {
        data[deviceId] = d;
      })
    );

    await Promise.all(promises);

    return data;

    // Query all devices at once
    /* const url = config.dataProxyUrls.v2.devices.devicesLoadStates({ companyId });

    const { status, data }: ServiceResponse = await axios.get(
      url,
      {
        params: {
          ...payload,
        },
        ...getAxiosOpts(token),
      },
    );

    return data.response; */
  },
  async getDeviceStopsInPeriod(
    context: ActionContext<State, RootState>,
    payload: {
      device: string;
      from: number;
      to: number;
    }
  ) {
    const { rootState } = context;
    const { device, from, to } = payload;

    const { token } = rootState.user;

    try {
      const { status, data }: ServiceResponse<DeviceStops> = await axios.get(
        '/api/device-stops',
        {
          params: {
            device,
            from,
            to,
          },
          ...getAxiosOpts(token),
        }
      );

      if (status !== 200) {
        return Promise.reject();
      }

      return Promise.resolve(data.response);
    } catch (e) {
      return Promise.reject();
    }
  },
};
