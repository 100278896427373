export const EVENTS = {
  click: 'click',
  shiftView: 'Shift View',
  changeViewGrid: 'Change View - grid',
  changeViewTable: 'Change View - table',
  download: 'Download',
  newIssueChart: 'ISSUES - add new on chart',
  showIssuesChartGrid: 'ISSUES - Show on chart grid',
  showIssuesChartTable: 'ISSUES - Show on chart table',
  goToIssueChartGrid: 'ISSUES - Go to on chart grid',
  goToIssueChartTable: 'ISSUES - Go to on chart table',
  addIssue: 'ISSUES - add',
  newIssue: 'ISSUES - new',
  newIssueChartGrid: 'ISSUES - new on chart grid',
  newIssueChartTable: 'ISSUES - new on chart table',
  createIssue: 'ISSUES - created',
  updateIssue: 'ISSUES - updated',
  showIssues: 'ISSUES - show',
  goToIssue: 'ISSUES - go to',
  hideIssues: 'ISSUES - hide',
  statusIssues: 'ISSUES - status',
  changeParams: 'CHANGE PARAMS',
  changeFilter: 'CHANGE FILTER',
  noTagFilter: 'No tag filter',
  printChart: 'Print Chart',
  zoomChart: 'Zoom Chart',
  mobileTaggingAdd: 'Mobile Tagging - add',
  mobileTaggingRemove: 'Mobile Tagging - remove',
  taggingCreate: 'Tagging - Type in',
  taggingAdd: 'Tagging - Check mark on box',
  taggingRemove: 'Tagging - remove',
  deviceListSorting: 'Device list sorting',
  navMenuClick: 'Navigation Menu Click',
  liveViewClick: 'Scoreboard - Live View Click',
  viewClick: 'Scoreboard - View Click',
  viewSettingsClick: 'Scoreboard - View Settings Click',
  metricFilter: 'Scoreboard - Metric Filter',
  drilldownButtonClick: 'Scoreboard - Drilldown Button Click',
  viewSettingsFromSettingsPage: 'Scoreboard - View Settings From Settings Page',
  sideKickIssueTagClick: 'Sidekick - Update Issue Tag Click',
  sideKickTagTagClick: 'Sidekick - Update Tag Tag Click',
  sideKickIssueMultipleTagsClick: 'Sidekick - Update Issue Multiple Tags Click',
  sideKickIssueUpdateStartTime: 'Sidekick - Update Issue Start Time',
  sideKickIssueUpdateEndTime: 'Sidekick - Update Issue End Time',
  productionPeriodGraphTagClicked: 'Production - Period Graph Tag Clicked',
  fullScreenClick: 'Scoreboard - Full Screen Click',
};

export const CATEGORIES = {
  filters: 'Filters',
  navigation: 'Navigation',
  crud: 'CRUD',
  drillDown: 'Drill Down',
  settings: 'Settings',
  tagging: 'Tagging',
  issues: 'Issues',
};
