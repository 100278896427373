import { getQuery } from './helpers/query';

declare global {
  interface Window {
    qa: {
      commitSha: string;
      printQueryParamsTable: () => Record<string, unknown>;
    };
  }
}

window.qa = Object.create(null);

window.qa.commitSha = import.meta.env.VITE_COMMIT_SHA;

window.qa.printQueryParamsTable = () => {
  const currentQueries = { ...getQuery() };
  const queryTable = Object.keys(currentQueries).reduce(
    (acc, queryKey) => ({
      ...acc,
      [queryKey]: Array.isArray(currentQueries[queryKey])
        ? currentQueries[queryKey].join(', ')
        : currentQueries[queryKey],
    }),
    {}
  );

  console.table(queryTable);

  return currentQueries;
};
