import axios from 'axios';
import io from 'socket.io-client';
import moment from 'moment';
import _ from 'lodash';

import getAxiosOpts from '@/helpers/axios.js';
import { socketBaseURL } from '../../config/urls';
import config from '../../config';

export default {
  CLEAR_ENERGY_DATA: ({ commit }) => {
    commit('CLEAR_ENERGY_DATA');
  },
  FETCH_CACHED_ENERGY: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const authOptions = getAxiosOpts(state.user.token);
      const { range, fromDate, toDate } = data;
      const url = `api/home/${state.company}`;
      const params = {
        range,
        widget: 'energy',
      };
      commit('ENERGY_WIDGET_DATA_ACQUIRED_ALL_DEVICES', {
        payload: null,
        range,
      });
      axios
        .get(url, {
          params,
          ...authOptions,
        })
        .then((response) => {
          const { chart, stats } = response.data;
          const { response: chartResp } = chart;
          const { response: statsResp } = stats;
          chartResp.push([
            {
              extras: { fromDate, toDate },
            },
          ]);
          commit('ENERGY_WIDGET_DATA_ACQUIRED_ALL_DEVICES', {
            payload: chartResp,
            range,
          });
          commit('SET_ENERGY_STATS', { payload: statsResp, range });
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_TOTAL_ENERGY_WIDGET_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      const { range } = data;
      commit('ENERGY_WIDGET_DATA_ACQUIRED_ALL_DEVICES', {
        payload: null,
        range,
      });
      const url = `/api/devices/${data.companyid}/energyforall?from=${data.fromDate}&to=${data.toDate}${data.shiftId != null ? `&shiftId=${data.shiftId}` : ''}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data.response) {
            response.data.response.push([
              {
                extras: {
                  fromDate: data.fromDate,
                  toDate: data.toDate,
                },
              },
            ]);
          }
          commit('ENERGY_WIDGET_DATA_ACQUIRED_ALL_DEVICES', {
            payload: response.data.response,
            range,
          });
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_TOTAL_ENERGY_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      commit('CLEAR_ENERGY_DATA');
      const { useStore = true } = data || {};
      const url = `/api/devices/${data.companyid}/energyforall?from=${data.fromDate}&to=${data.toDate}${data.shiftId != null ? `&shiftId=${data.shiftId}` : ''}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data.response) {
            response.data.response.push([
              {
                extras: {
                  fromDate: data.fromDate,
                  toDate: data.toDate,
                },
              },
            ]);
          }

          if (useStore)
            commit('ENERGY_DATA_ACQUIRED_ALL_DEVICES', response.data.response);
          res(response.data.response);
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_TOTAL_ENERGY_SHIFT_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      commit('CLEAR_ENERGY_DATA');
      if (data.device) {
        const url = `/api/devices/${data.companyid || null}/${data.device || null}/energyshifts?shiftid=${data.shiftId}&from=${data.fromDate}&to=${data.toDate}&shiftstarttime=${data.starttime}&shiftduration=${data.duration}`;
        axios
          .get(url, getAxiosOpts(state.user.token))
          .then((response) => {
            commit('ENERGY_DATA_ACQUIRED_ALL_DEVICES', response.data.response);
            res();
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        // Do the main connection with socket.io
        let allEngyArr = [];
        let progressCount = 0;
        const vueState = state;
        const progressTotal = moment
          .duration(moment(data.toDate).diff(moment(data.fromDate)))
          .asDays();
        const socket = io(`${config.dataProxyUrl}/sockets/energyallshifts`, {
          query: `token=${state.user.token}`,
          transports: ['websocket', 'polling'],
          reconnectionAttempts: 5,
        });
        socket.emit('getenergyshiftdata', {
          data: { ...data, user: state.user.loggedInUserInfo },
        });
        socket.on('energydata', (engydata) => {
          if (engydata) {
            allEngyArr.push(...engydata);
            progressCount += 1;
            vueState.progressEnergy = (progressCount / progressTotal) * 100;
          }
        });
        // Commit once we receive all data
        socket.on('engydone', () => {
          if (allEngyArr.length > 0) {
            allEngyArr = _(allEngyArr)
              .groupBy('deviceId')
              .map((objs, key) => ({
                deviceId: key,
                start: Math.min(..._.map(objs, (o) => o.start)),
                end: Math.max(..._.map(objs, (o) => o.end)),
                loadtype: objs.find((o) => o.deviceId === key).loadtype,
                energy:
                  _.sumBy(objs, (o) => Number(Number(o.value).toFixed(2))) ||
                  _.sumBy(objs, (o) => Number(Number(o.energy).toFixed(2))) ||
                  0,
                days: [
                  ..._.orderBy(
                    objs.map((o) => ({
                      deviceId: o.deviceId,
                      last: o.end,
                      start: o.start,
                      trueval: o.trueval
                        ? o.trueval
                        : o.days
                          ? o.days[0].trueval
                          : false,
                      value: Number(o.value) || Number(o.energy) || 0,
                    })),
                    'start',
                    'asc'
                  ),
                ],
              }))
              .value();
            allEngyArr.push([
              {
                extras: {
                  fromDate: data.fromDate,
                  toDate: data.toDate,
                  starttime: data.starttime,
                  duration: data.duration,
                },
              },
            ]);
            commit('ENERGY_DATA_ACQUIRED_ALL_DEVICES', allEngyArr);
          }
          socket.close();
          vueState.progressEnergy = 0;
          res();
        });
        // Capture any errors
        socket.on('error', (err) => {
          if (
            err.type === 'UnauthorizedError' ||
            err.code === 'invalid_token'
          ) {
            socket.close();
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          } else {
            socket.close();
            vueState.progressEnergy = 0;
            rej(err);
          }
        });
        // Capture invalid token
        socket.on('unauthorized', (error) => {
          if (
            error.data.type === 'UnauthorizedError' ||
            error.data.code === 'invalid_token'
          ) {
            socket.close();
            // Clear any cached data...
            localStorage.clear();
            commit('LOGOUT');
          }
        });
      }
    }),
  ACQUIRE_ENERGY_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      commit('CLEAR_ENERGY_DATA');
      const url = `/api/devices/${data.device}/energy?from=${data.fromDate}&to=${data.toDate}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data) {
            response.data.response.extras = {
              fromDate: data.fromDate,
              toDate: data.toDate,
            };
            commit('ENERGY_DATA_ACQUIRED', response.data.response);
          }
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_ENERGY_SHIFT_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      commit('CLEAR_ENERGY_DATA');
      const url = `/api/devices/${data.companyid || null}/${data.device || null}/energyshifts?shiftid=${data.shiftId}&from=${data.fromDate}&to=${data.toDate}&shiftstarttime=${data.starttime}&shiftduration=${data.duration}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data.response[0]) {
            response.data.response[0].extras = {
              fromDate: data.fromDate,
              toDate: data.toDate,
              starttime: data.starttime,
              duration: data.duration,
            };
          }
          commit('ENERGY_DATA_ACQUIRED', response.data.response[0]);
          res();
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_DAY_ENERGY_DATA: ({ commit, state }, data) =>
    new Promise((res, rej) => {
      commit('CLEAR_ENERGY_DATA');
      const url = `/api/devices/${data.device}/energy/time?from=${data.from}&to=${data.to}`;
      axios
        .get(url, getAxiosOpts(state.user.token))
        .then((response) => {
          if (response.data.response) {
            commit('ENERGY_DAY_DATA_ACQUIRED', response.data.response);
            res();
          } else {
            rej();
          }
        })
        .catch((err) => {
          rej(err);
        });
    }),
  ACQUIRE_LIVE_ENERGY_DATA: ({ commit, state }, channel) => {
    // Do the main connection with socket.io
    const { token } = state.user;
    const socket = io(`${socketBaseURL}/eNS`);
    socket.emit('join', {
      channel,
      token,
    });
    // When data received commit to RAW_LIVE_DATA_ACQUIRED with da data.
    socket.on('data', (data) => {
      commit('ENERGY_DATA_ACQUIRED', data);
    });
  },
  GET_ENERGY_STATS: async ({ commit, state }, { from, to, range }) => {
    const { company } = state;
    const url = `/api/energy/stats/${company}?from=${from}&to=${to}`;
    try {
      const response = await axios.get(url, getAxiosOpts(state.user.token));
      commit('SET_ENERGY_STATS', { payload: response.data.response, range });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.message);
    }
  },
};
