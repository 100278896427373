import axios from 'axios';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/state';
import config from '@/config';
import { SET_STATES } from './mutations-types';
import { initialState, State } from './model';

export default {
  async GET_STATES(context: ActionContext<State, RootState>, payload) {
    if (context.state.data.length) {
      return context.getters.getDeviceStates;
    }

    /* Your action content here */
    try {
      const { status, statusText, data } = await axios.get(
        config.dataProxyUrls.v2.deviceStates.url(),
        {
          params: {
            deviceId: payload.deviceId,
            companyId: context.rootState.company,
          },
        }
      );

      if (status === 200) {
        context.commit(SET_STATES, data.response);
        return data.response;
      }

      throw new Error(`[GET_STATES]: ${status} - ${statusText}`);
    } catch (error) {
      console.log('ERROR:', error);
      return [];
    }
  },
  async GET_STATE(context: ActionContext<State, RootState>, payload) {
    const { id } = payload;
    /* Your action content here */
    try {
      const { status, statusText, data } = await axios.get(
        config.dataProxyUrls.v2.deviceStates.url({ id }),
        {}
      );

      if (status === 200) {
        context.commit(SET_STATES, data.response);
        return data.response[0];
      }

      throw new Error(`[GET_STATES]: ${status} - ${statusText}`);
    } catch (error) {
      console.log('ERROR:', error);
      return null;
    }
  },
  async UPDATE_STATE(context: ActionContext<State, RootState>, payload) {
    const { id, ...rest } = payload;
    try {
      const { status, statusText, data } = await axios.patch(
        config.dataProxyUrls.v2.deviceStates.url({ id }),
        rest
      );

      if (status === 200 && data.response) {
        context.commit(SET_STATES, data.response);
        return data.response[0];
      }
      throw new Error(`[UPDATE_STATE]: ${status} - ${statusText}`);
    } catch (error) {
      console.log(error);
      return null;
    }
  },
};
