
<v-app>
  <v-snackbar
    v-if="loggedIn || alert.forceShow"
    v-model="alertVisible"
    :timeout="alert.actions.length || alert.forceKeep ? -1 : 10000"
    :top="true"
    :color="alert.alertType"
    :class="alert.class || ''"
  >
    <v-icon v-if="icons[alertType]">{{ icons[alertType] }}</v-icon>
    <!-- FIXME: `alert.alertMessage` is set in over 100 places in the app at
         time of writing. It appears that it is set as a simple string
         (without HTML markup) but needs vetting before this can be fixed.
    -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="alert.alertMessage" />
    <v-btn
      v-for="({ action, caption }, idx) in alert.actions"
      :key="`action-${idx}`"
      text
      @click="action"
    >
      {{ caption }}
    </v-btn>
  </v-snackbar>
  <navigation-layout
    v-if="showNavBar() && loggedIn && !routeWithoutNabvar"
    :routes="routes"
    defaultIssueType="Manual"
    class="nav-layout"
  >
    <router-view v-if="companyDataReady" />
  </navigation-layout>
  <router-view v-else />
  <loader
    v-if="loggedIn && !companyDataReady && !routeWithoutNabvar"
    message="Loading..."
    :height="'100vh'"
    :style="{ zIndex: 2 }"
  />
  <analytics v-if="analyticsEnabled" />
  <intercom v-if="intercomEnabled && !routeWithoutNabvar" />
</v-app>
