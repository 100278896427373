import Vue from 'vue';
import moment from 'moment';
import {
  SET_DEVICE_LOAD_STATE,
  LOADING_DEVICE_LOAD_STATE,
  LOADING_DEVICE_LOAD_STATE_DONE,
} from './mutations-types';
import { State } from './model';

const defaultState = {
  loading: false,
  data: [],
};

export const storedKey = (deviceId, from, to) => `${from}_${to}`;

const setDeviceDefaultState = (state, deviceId, from, to) => {
  Vue.set(state, `${deviceId}`, {
    [storedKey(deviceId, from, to)]: defaultState,
  });
};

const setLoadingState =
  (loading) =>
  (state: State, { deviceId, from, to }) => {
    if (!state[deviceId]) {
      setDeviceDefaultState(state, deviceId, from, to);
    }

    const oldState = state[deviceId][storedKey(deviceId, from, to)];
    Vue.set(state[deviceId], storedKey(deviceId, from, to), {
      ...oldState,
      loading,
    });
  };

export default {
  [LOADING_DEVICE_LOAD_STATE]: setLoadingState(true),
  [LOADING_DEVICE_LOAD_STATE_DONE]: setLoadingState(false),
  [SET_DEVICE_LOAD_STATE](state: State, { deviceId, from, to, data }) {
    if (!state[deviceId]) {
      setDeviceDefaultState(state, deviceId, from, to);
    }

    const oldState = state[deviceId][storedKey(deviceId, from, to)];

    Vue.set(state[deviceId], storedKey(deviceId, from, to), {
      ...oldState,
      data,
      lastUpdate: moment().valueOf(),
    });
  },
};
