import Thresholds from './thresholds';

export const DEFAULT_SAFI_CSV_MAPPING = {
  batch: 2,
  comments: 16,
  endTime: 4,
  machine: 0,
  manager: 21,
  operator: 20,
  plannedDowntime: 6,
  product: 1,
  productionQuantity: 7,
  startTime: 3,
  target_prod: 8,
  target_waste_percentage: 10,
  wasteQuantity: 9,
};

export const DEFAULT_SUMMARY_CONFIG = {
  enabledWidgets: [
    { id: 'totalPower-widget' },
    { id: 'production-widget' },
    { id: 'uptime-widget' },
    { id: 'alerts-widget' },
    { id: 'energy-widget' },
  ],
};

export const DEFAULT_DASHBOARD_CONFIG = {
  // general tab
  metric: 'runtime',
  excludedDays: [],
  thresholds: {
    danger: 10,
    warning: 60,
  },
  plannedThreshold: 50,
  // devices and list tab
  displayMode: 'devicesLists',
  excludedDevicesListIds: [],
};

const thresholdColors = {
  bad: '#fe6847',
  medium: '#f1c232',
  good: '#f9ff99',
  veryGood: '#0dc39f',
};

export interface Setting {
  [key: string]: any;
}

export interface CompanySettings {
  productionPeriodsThresholds: {
    [paramenter: string]: Thresholds;
  };
}

export interface SettingPayload {
  name: string;
  value: Setting;
}

export interface State {
  company?: Setting;
  user?: Setting;
}

export const initialState: State = {
  company: {
    productionPeriodsThresholds: {
      oee: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Typical', thresholdColors.medium, 40, 60),
        new Thresholds('WorldClass', thresholdColors.good, 60, 85),
        new Thresholds('Perfect', thresholdColors.veryGood, 85, null),
      ],
      time: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Med', thresholdColors.good, 40, 80),
        new Thresholds('High', thresholdColors.veryGood, 80, null),
      ],
      production: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Med', thresholdColors.good, 40, 80),
        new Thresholds('High', thresholdColors.veryGood, 80, null),
      ],
      waste: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Med', thresholdColors.good, 40, 80),
        new Thresholds('High', thresholdColors.veryGood, 80, null),
      ],
      energy: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Med', thresholdColors.good, 40, 80),
        new Thresholds('High', thresholdColors.veryGood, 80, null),
      ],
      uptime: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Med', thresholdColors.good, 40, 80),
        new Thresholds('High', thresholdColors.veryGood, 80, null),
      ],
      runtime: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Med', thresholdColors.good, 40, 80),
        new Thresholds('High', thresholdColors.veryGood, 80, null),
      ],
      cycles: [
        new Thresholds('Low', thresholdColors.bad, 0, 40),
        new Thresholds('Med', thresholdColors.good, 40, 80),
        new Thresholds('High', thresholdColors.veryGood, 80, null),
      ],
    },
    csvMapping: DEFAULT_SAFI_CSV_MAPPING,
    summary: DEFAULT_SUMMARY_CONFIG,
    dashboard: DEFAULT_DASHBOARD_CONFIG,
  },
};
