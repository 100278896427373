'use strict';

import mapElementFactory from './mapElementFactory.js';

const props = {
  draggable: {
    type: Boolean,
  },
  editable: {
    type: Boolean,
  },
  options: {
    twoWay: false,
    type: Object,
  },
  path: {
    type: Array,
    twoWay: true,
  },
};

const events = [
  'click',
  'dblclick',
  'drag',
  'dragend',
  'dragstart',
  'mousedown',
  'mousemove',
  'mouseout',
  'mouseover',
  'mouseup',
  'rightclick',
];

export default mapElementFactory({
  mappedProps: props,
  props: {
    deepWatch: {
      type: Boolean,
      default: false,
    },
  },
  events: events,

  name: 'polyline',
  ctr() {
    return google.maps.Polyline;
  },

  afterCreate() {
    const _this = this;

    let clearEvents = function () {};

    this.$watch(
      'path',
      function (path) {
        if (path) {
          clearEvents();

          _this.$polylineObject.setPath(path);

          const mvcPath = _this.$polylineObject.getPath();
          const eventListeners = [];

          const updatePaths = function () {
            _this.$emit('path_changed', _this.$polylineObject.getPath());
          };

          eventListeners.push([
            mvcPath,
            mvcPath.addListener('insert_at', updatePaths),
          ]);
          eventListeners.push([
            mvcPath,
            mvcPath.addListener('remove_at', updatePaths),
          ]);
          eventListeners.push([
            mvcPath,
            mvcPath.addListener('set_at', updatePaths),
          ]);

          clearEvents = function () {
            eventListeners.map(function ([obj, listenerHandle]) {
              google.maps.event.removeListener(listenerHandle);
            });
          };
        }
      },
      {
        deep: this.deepWatch,
        immediate: true,
      }
    );
  },
});
