import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export interface Unit {
  id: number;
  unitName: string;
}

export interface State {
  [key: string]: Unit;
}

export const initialState = {};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
