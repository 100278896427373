/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import moment from 'moment';
import config from '@/config';
import getAxiosOpts from '@/helpers/axios.js';
import {
  SET_COMMENTS,
  SET_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
} from './mutation-types';
import { Comment } from './';

const parseCommentData = (commentToParse: Comment) => {
  const {
    id,
    comment,
    createdat,
    feature_table,
    feature_table_id,
    companyid,
    userid,
    changelog,
    replyto,
  } = commentToParse;

  return {
    id,
    comment,
    createdat,
    ftable: feature_table,
    ftableid: feature_table_id,
    companyid,
    user: userid,
    changelog,
    replyto,
  };
};

export default {
  getComments: ({ commit, rootState: { user, company } }, data) =>
    new Promise(async (res, rej) => {
      try {
        const url = config.dataProxyUrls.v2.comments.url({
          companyId: company,
        });
        const response = await axios.get(url, {
          params: { ...data },
          ...getAxiosOpts(user.token),
        });
        if (response.status === 200) {
          commit(SET_COMMENTS, response.data.response);
          res();
        } else {
          rej(response);
        }
      } catch (err) {
        rej(err);
      }
    }),
  addComment: ({ commit, rootState: { user, company } }, data) =>
    new Promise(async (res, rej) => {
      const { username } = user.loggedInUserInfo;
      const extraData = {
        ...parseCommentData(data),
        companyid: company,
        user: username,
        createdat: moment.utc().valueOf(),
        changelog: [
          {
            data: [],
            timestamp: moment.utc().valueOf(),
            action: 'Posted comment',
            user: username,
          },
        ],
      };

      try {
        const response = await axios.post(
          '/api/comments',
          extraData,
          getAxiosOpts(user.token)
        );
        if (response.status === 200) {
          commit(SET_COMMENT, response.data.response);
          res(response.data.response);
        } else {
          rej(response.data.error);
        }
      } catch (err) {
        rej(err);
      }
    }),
  copyComment: ({ commit, rootState: { user, company } }, data) =>
    new Promise(async (res, rej) => {
      const { username } = user.loggedInUserInfo;
      const extraData = {
        ...parseCommentData(data),
        companyid: company,
        changelog: [
          {
            data: [],
            timestamp: moment.utc().valueOf(),
            action: 'Copied comment',
            user: username,
          },
        ],
        comment: unescape(data.comment),
      };

      try {
        const response = await axios.post(
          '/api/comments',
          extraData,
          getAxiosOpts(user.token)
        );
        if (response.status === 200) {
          commit(SET_COMMENT, response.data.response);
          res(response.data.response);
        } else {
          rej(response.data.error);
        }
      } catch (err) {
        rej(err);
      }
    }),
  updateComment: ({ commit, rootState: { user } }, data) =>
    new Promise(async (res, rej) => {
      const { username } = user.loggedInUserInfo;

      data.changelog.push({
        data: [],
        timestamp: moment.utc().valueOf(),
        action: 'Updated comment',
        user: username,
      });

      try {
        const response = await axios.put(
          `/api/comments/${data.id}`,
          parseCommentData(data),
          getAxiosOpts(user.token)
        );
        if (response.status === 200) {
          commit(UPDATE_COMMENT, response.data.response);
          res(response.data.response);
        } else {
          rej(response);
        }
      } catch (err) {
        rej(err);
      }
    }),
  deleteComment: ({ commit, rootState: { user } }, data) =>
    new Promise(async (res, rej) => {
      try {
        const response = await axios.put(
          `/api/comments/softdelete/${data.id}`,
          parseCommentData(data),
          getAxiosOpts(user.token)
        );
        if (response.status === 200) {
          commit(DELETE_COMMENT, { ...data, ...response.data.response });
          res();
        } else {
          res(response);
        }
      } catch (err) {
        rej(err);
      }
    }),
};
