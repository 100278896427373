import axios from 'axios';
import getAxiosOpts from '@/helpers/axios.js';

export default {
  namespaced: true,
  state: {
    list: [],
    availableUnits: [],
    selectedUnit: '',
  },
  getters: {
    list: (state) => state.list,
    availableUnits: (state) => state.availableUnits.map((a) => a.unitName),
    selectedUnit: (state) => state.selectedUnit,
  },
  mutations: {
    SET_LIST(ctx, payload) {
      ctx.list = payload;
    },
    SET_UNITS(ctx, payload) {
      ctx.availableUnits = [];
      ctx.availableUnits = payload.availableUnits;
    },
    SET_COMPANY_PROD_UNIT(ctx, payload) {
      ctx.selectedUnit = payload.selectedUnit;
    },
  },
  actions: {
    GET_LIST: async ({ rootState, commit }) => {
      const { company } = rootState;
      try {
        const url = `/api/companies/${company}/bills`;
        const options = getAxiosOpts(rootState.user.token);
        const { data } = await axios.get(url, options);
        if (data.units) {
          commit('SET_UNITS', {
            availableUnits: data.units,
          });
        }
        if (data.companyprodunit && data.companyprodunit.length > 0) {
          commit('SET_COMPANY_PROD_UNIT', {
            selectedUnit: data.companyprodunit.map((a) => a.unitName)[0],
          });
        }
        if (data.error) {
          return Promise.reject(data.error);
        }
        commit('SET_LIST', data.data);
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
    SAVE: async ({ rootState }, payload) => {
      const { company } = rootState;
      const url = `/api/bill/${company}`;
      const options = getAxiosOpts(rootState.user.token);
      try {
        const { data } = await axios.post(url, payload, options);
        if (data.success === false) {
          return Promise.resolve(data);
        }
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error.message);
      }
    },
    DELETE: async ({ rootState }, payload) => {
      const { company } = rootState;
      const options = getAxiosOpts(rootState.user.token);
      try {
        const url = `/api/bill/${company}/${payload.month}/${payload.year}/${payload.device}`;
        const { data } = await axios.delete(url, options);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.resolve(error.message);
      }
    },
    SAVE_PROD_UNIT: async ({ rootState }, payload) => {
      const { company } = rootState;
      const { unitid } = payload;
      const options = getAxiosOpts(rootState.user.token);
      try {
        const url = `/api/companies/${company}/produnit`;
        const { data } = await axios.post(url, { unitid }, options);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    GET_PROD_UNIT: async ({ state, rootState, commit }) => {
      if (state.selectedUnit) return Promise.resolve();
      const { company } = rootState;
      const options = getAxiosOpts(rootState.user.token);
      try {
        const url = `/api/companies/${company}/produnit`;
        const { data } = await axios.get(url, options);
        if (data.companyprodunit && data.companyprodunit.length > 0) {
          commit('SET_COMPANY_PROD_UNIT', {
            selectedUnit: data.companyprodunit.map((a) => a.unitName)[0],
          });
        }
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
