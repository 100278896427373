
<div v-if="userInfo">
  <v-navigation-drawer
    class="justify-start sideBar pt-1"
    v-model="drawer"
    :mini-variant.sync="mini"
    app
    :permanent="!mobile"
    hide-overlay
    :temporary="mobile"
    :width="mobile ? '100%' : '150'"
  >
    <div class="d-flex flex-column align-center">
      <v-btn
        v-if="!mobile"
        text
        @click.stop="mini = !mini"
        class="sidebar-edge-toggle"
      >
        <span hidden>Toggle sidebar</span>
      </v-btn>
      <v-list dense style="width: 100%">
        <v-btn v-if="mobile" icon @click.stop="collapse" style="float: right">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-list-item
          link
          to="/"
          color="primary"
          active-class="selected-item"
          class="d-md-flex d-none"
          @click.stop=""
        >
          <v-list-item-title>
            <svg class="SA-logo logo-full" height="40" width="118">
              <use href="#logo"></use>
            </svg>
            <svg class="SA-logo logo-symbol" height="40" width="118">
              <use href="#logo-symbol"></use>
            </svg>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="d-md-none menu-label">
          <v-list-item-title> Menu </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="text-capitalize text-left company-name">
            {{ companyname }}
          </v-list-item-title>
        </v-list-item>
        <template v-for="item in items">
          <!-- Item with children -->
          <v-tooltip
            top
            v-if="item.children"
            :key="`tooltip-${item.navItem.text}`"
          >
            <template v-slot:activator="{ on }">
              <v-list-group
                v-on="mini ? on : null"
                v-model="item.active"
                :key="`vlg-${item.navItem.text}`"
                link
                class="menuButton"
              >
                <template #prependIcon>
                  <svg class="sidebar-icon" aria-hidden="true">
                    <use :href="`#${item.navItem.icon}`"></use>
                  </svg>
                </template>
                <template v-slot:activator>
                  <v-list-item-title>
                    {{ item.navItem.text }}
                  </v-list-item-title>
                </template>
                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="`c-${i}`"
                  :to="child.navItem.to"
                  link
                  class="sidebar-child-link"
                >
                  <v-list-item-title class="listGroupItem">
                    {{ child.navItem.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </template>
            <span>{{ item.navItem.text }}</span>
          </v-tooltip>
          <!-- End Item with children -->
          <!-- Item without children -->
          <v-tooltip top v-else :key="`tooltip-${item.navItem.text}`">
            <template v-slot:activator="{ on }">
              <v-list-item
                v-on="mini ? on : null"
                :key="`vlt-${item.navItem.text}`"
                :to="item.navItem.to"
                color="primary"
                active-class="selected-item"
                link
                @click.native="trackClick(item.navItem.text)"
                class="menuButton"
              >
                <v-list-item-icon class="my-0 mr-2">
                  <svg class="sidebar-icon" aria-hidden="true">
                    <use :href="`#${item.navItem.icon}`"></use>
                  </svg>
                </v-list-item-icon>
                <v-list-item-title>{{ item.navItem.text }}</v-list-item-title>
                <v-list-item-action v-if="item.newFeature">
                  <v-badge left color="#502DD5">
                    <span slot="badge" style="padding-top: 3px">*</span>
                  </v-badge>
                </v-list-item-action>
              </v-list-item>
            </template>
            <span>{{ item.navItem.text }}</span>
          </v-tooltip>
          <!-- End Item without children -->
        </template>
      </v-list>
    </div>
    <template v-slot:append>
      <v-list dense>
        <v-tooltip top v-if="!mobile">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="mini ? on : null" @click.stop="collapse">
              <v-list-item-icon class="mr-2">
                <svg class="sidebar-icon" aria-hidden="true">
                  <use
                    :href="
                      mini ? '#sidebar-expand-icon' : '#sidebar-collapse-icon'
                    "
                  ></use>
                </svg>
              </v-list-item-icon>
              <v-list-item-title>Collapse</v-list-item-title>
            </v-list-item>
          </template>
          <span>Expand</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-list-item
              v-on="on"
              target="_blank"
              href="https://support.guidewheel.app"
            >
              <v-list-item-icon class="mr-2">
                <svg class="sidebar-icon" aria-hidden="true">
                  <use href="#help"></use>
                </svg>
              </v-list-item-icon>
              <v-list-item-title>Help center</v-list-item-title>
            </v-list-item>
          </template>
          <span>Go to help center</span>
        </v-tooltip>
        <v-menu
          top
          open-on-hover
          offset-x
          :nudge-left="mini ? '10' : '30'"
          nudge-top="8"
          min-width="0"
        >
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on">
              <v-list-item-icon class="mr-2">
                <svg class="sidebar-icon" aria-hidden="true">
                  <use href="#user-icon"></use>
                </svg>
              </v-list-item-icon>
              <v-list-item-title class="d-flex align-center">
                <span class="username" :title="userInfo.username">
                  {{ userInfo.username }}
                </span>
                <v-icon
                  v-if="!mobile"
                  class="ml-auto"
                  style="float: right"
                  small
                >
                  mdi-dots-vertical
                </v-icon>
                <v-btn
                  class="ml-4"
                  text
                  v-if="mobile"
                  small
                  color="primary"
                  @click="logout"
                >
                  <svg class="user-menu-icon mr-1" aria-hidden="true">
                    <use href="#logout"></use>
                  </svg>
                  Logout
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </template>
          <div class="user-menu">
            <v-btn v-if="!mobile" small text color="primary" @click="logout">
              <svg class="user-menu-icon mr-1" aria-hidden="true">
                <use href="#logout"></use>
              </svg>
              Logout
            </v-btn>
          </div>
        </v-menu>
      </v-list>
    </template>
  </v-navigation-drawer>
  <v-app-bar
    app
    fixed
    clipped-left
    dense
    v-if="mobile && showAppBar"
    class="mobile-menu"
  >
    <v-app-bar-nav-icon @click.stop="collapse"></v-app-bar-nav-icon>
    <svg class="SA-logo" height="36" width="140">
      <use href="#logo"></use>
    </svg>
    <v-spacer />
  </v-app-bar>
  <v-main>
    <div v-if="showCollapseIcon" class="collapseIcon">
      <div @click.stop="collapse">
        <v-icon>mdi-menu</v-icon>
      </div>
    </div>
    <NotificationBar />
    <slot />
  </v-main>
</div>
