export interface DevicesList {
  id: Number;
  companyId: String;
  name: String;
  devices: String[];

  shiftsFilter?: any[];
  scheduledShifts?: any[];

  // Array of tag IDs
  tags?: String[];

  // Timezone
  tz: String;

  // e.g. [1, 2, 3, 4, 5]
  operatingDays?: Number[];

  // e.g.["08:00", "22:00"]
  operatingTimes?: String[];

  type?: 'list' | 'plant';
  location?: { lat: number; lng: number } | null;
  timePreference?: any;

  users?: Array<{ username: string } & Record<string, any>>;

  // Percentage runtime target (0–100), optional
  target?: number | null;
}

export interface State {
  loading: boolean;
  data: Array<DevicesList>;
  lastUpdate: number;
}

export const initialState: State = {
  loading: false,
  data: [],
  lastUpdate: 0,
};

export default initialState;
