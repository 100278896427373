import Vue from 'vue';

export default {
  SET_AVAILABLE_DEVICE_GROUPS: (state, devicegroups) => {
    const deviceGroupArray = [];
    if (devicegroups) {
      devicegroups.forEach((device) => {
        deviceGroupArray.push(device);
      });
    }
    Vue.set(state, 'availableDeviceGroups', deviceGroupArray);
  },
  SET_DEVICE_GROUPS: (state, devicegroups) => {
    const deviceGroupArray = [];
    if (devicegroups) {
      devicegroups.forEach((device) => {
        deviceGroupArray.push(device);
      });
    }
    Vue.set(state, 'deviceGroups', deviceGroupArray);
    Vue.set(state, 'allDeviceGroups', deviceGroupArray);
  },
  SET_NEW_DEVICE_GROUP: (state, { deviceGroup, groupid }) => {
    const stateCopy = [...state.allDeviceGroups];
    stateCopy.push({
      ...deviceGroup,
      groupid,
    });
    Vue.set(state, 'allDeviceGroups', stateCopy);
    Vue.set(state, 'deviceGroups', stateCopy);
    Vue.set(state, 'availableDeviceGroups', [
      ...state.availableDeviceGroups,
      { ...deviceGroup, groupid },
    ]);
  },
  UPDATE_DEVICE_GROUP: (state, { oldGroup, newGroup }) => {
    if (oldGroup) {
      const stateCopy = [...state.allDeviceGroups];
      const index = stateCopy.findIndex(
        (dg) => dg.groupid === newGroup.groupid
      );
      Object.assign(stateCopy[index], newGroup);
      Vue.set(state.allDeviceGroups, 'deviceGroups', stateCopy);
    }
  },
  REMOVE_DEVICE_GROUP: (state, index) => {
    const stateCopy = state.allDeviceGroups;
    stateCopy.splice(index, 1);
    Vue.set(state.allDeviceGroups, 'deviceGroups', stateCopy);
  },
};
