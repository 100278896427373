import Vuex from 'vuex';
import { VueStoreState } from '@/store';
import { Device } from '@/store/state';
import { Alert } from '@/store/modules/alerts/model';
import { LineRule } from '@/store/modules/lineRules/model';

import { formatCondition } from '@/helpers/prepareData';

export const MAX_CHART_DEVICES = 5;
export const MAX_LINE_CONDITIONS_LIMIT = 10;

export const ALERT_TYPE_SUBTYPE = {
  anomaly: {
    alertType: 'anomaly',
    alertSubtype: 'default',
  },
  customDeviceData: {
    alertType: 'device_data',
    alertSubtype: 'custom',
  },
  deviceData: {
    alertType: 'device_data',
    alertSubtype: 'easy_alert',
  },
};

export const LINE_ALERT_AVAILABLE_AGGREGATES = [
  {
    text: 'Max',
    value: 'max',
  },
  {
    text: 'Average',
    value: 'avg',
  },
  {
    text: 'Min',
    value: 'min',
  },
];

export const AVAILABLE_LINE_RULE_OPERATORS = [
  {
    conjunction: 'or',
    text: 'any',
    value: 'ANY',
  },
  {
    conjunction: 'and',
    text: 'all',
    value: 'ALL',
  },
];

export const LINE_ALERT_SUBTYPES = {
  STATIC: 'static',
  DYNAMIC: 'dynamic',
};

export const LINE_ALERT_DEFAULT_METRIC = 'Psum';

const DEFAULT_CONDITION = {
  attribute: 'psum',
  entity: 'devicemetric',
};

type AvailableCondition = {
  key: string;
  text: string;
  custom: boolean;
  threshold?: string;
  description?: string;
  tooltip?: string;
};

type LineCondition = {
  aggregate?: string;
  condition: AvailableCondition;
  condOperator?: string;
  deviceId: string;
  dynamicName?: string;
  metric?: string;
  subtype: 'dynamic' | 'static';
  type: 'device_data' | 'anomaly' | 'hybrid';
  value?: number;
};

export const getPredefinedCondition = (condition, deviceThresholds, stat) => {
  const predefinedCondition = {
    anomaly: [
      {
        operator: 'eq',
        value: '1',
        attribute: 'anomaly',
      },
    ],
    below_idle_Psum: [
      {
        ...DEFAULT_CONDITION,
        operator: 'lt',
        value: deviceThresholds.idle_Psum,
        stat,
      },
    ],
    idle_Psum: [
      {
        ...DEFAULT_CONDITION,
        operator: 'lt',
        value: deviceThresholds.idle_Psum,
        stat,
      },
      {
        ...DEFAULT_CONDITION,
        operator: 'gt',
        value: deviceThresholds.offline_Psum,
        stat,
      },
    ],
    offline_Psum: [
      {
        ...DEFAULT_CONDITION,
        operator: 'lt',
        value: deviceThresholds.offline_Psum,
        stat,
      },
    ],
    ratedload: [
      {
        ...DEFAULT_CONDITION,
        operator: 'gt',
        value: deviceThresholds.ratedload,
        stat,
      },
    ],
  };
  return {
    AND: predefinedCondition[condition],
    OR: [],
  };
};

export const getDeviceNicknameFromId = (
  deviceId: string,
  allDevices: Array<Device> = []
): string => {
  return allDevices.find((d) => d.deviceid === deviceId)?.nickname || '';
};

export const isAlertAnomaly = (rule: Alert | LineRule): boolean => {
  const { alertScope, alertType } = rule;

  if (alertScope === 'lineAlert') return isLineAlertAnomaly(rule);

  return alertType === 'anomaly';
};

const isLineAlertAnomaly = (lineRule: LineRule): boolean => {
  const {
    conditions: { conditions: ruleConditions },
  } = lineRule;

  const hasAnomalyConditions = ruleConditions.some(
    (condition) => condition.type === 'anomaly'
  );

  return hasAnomalyConditions;
};

export const lineRuleOperatorConjunction = (lineRule: LineRule): string => {
  const { ruleOperator } = lineRule.conditions || {};
  const { conjunction } =
    AVAILABLE_LINE_RULE_OPERATORS.find((o) => o.value === ruleOperator) || {};

  return conjunction || '';
};

export const statForDeviceId = (
  deviceId: string,
  store: Vuex.Store<VueStoreState>
): { value: string; text: string } => {
  const stat = store.getters.getStat(deviceId);
  const mapStat = {
    avgvalue: '',
    maxvalue: '(max)',
    minvalue: '(min)',
  };

  return {
    value: stat,
    text: mapStat[stat],
  };
};

export const availablePredefinedConditionsForDeviceIdAndRule = (
  deviceId: string | null,
  rule: Alert | LineRule,
  devicesHaveAnomaly: boolean = false,
  store: Vuex.Store<VueStoreState> | null
): Array<AvailableCondition> => {
  if (isAlertAnomaly(rule) && devicesHaveAnomaly) {
    return [
      {
        key: 'DEFAULT',
        text: 'Default',
        custom: false,
        threshold: 'anomaly',
        description: 'anomaly has been detected',
        tooltip: 'anomaly has been detected',
      },
    ];
  }

  const statText =
    deviceId && store ? statForDeviceId(deviceId, store)?.text : '';

  const availablePredefinedConditions = [
    {
      key: 'DOWN',
      text: `Down ${statText}`,
      custom: false,
      threshold: 'below_idle_Psum',
      description: 'is down',
      tooltip: 'Below Idle threshold',
    },
    {
      key: 'IDLE',
      text: `Idle ${statText}`,
      custom: false,
      threshold: 'idle_Psum',
      description: 'is idling',
      tooltip: 'Between Idle and Offline thresholds',
    },
    {
      key: 'OFFLINE',
      text: `Offline ${statText}`,
      custom: false,
      threshold: 'offline_Psum',
      description: 'is offline',
      tooltip: 'Below Offline threshold',
    },
    {
      key: 'OVERLOADED',
      text: `Overloaded ${statText}`,
      custom: false,
      threshold: 'ratedload',
      description: 'is overloaded',
      tooltip: 'Above Rated load threshold',
    },
    {
      key: 'CUSTOM',
      text: 'Custom',
      custom: true,
      description: 'has custom condition',
    },
  ];

  return availablePredefinedConditions;
};

export const lineRuleConditions = (
  lineRule: LineRule,
  availablePredefinedConditions: Array<AvailableCondition>
): Array<LineCondition> => {
  const lineConditions = lineRule?.conditions?.conditions?.map((cond) => {
    const condition =
      cond.subtype === LINE_ALERT_SUBTYPES.STATIC
        ? availablePredefinedConditions.find((apc) => apc.key === 'CUSTOM')
        : availablePredefinedConditions.find(
            (apc) => apc.key === cond.dynamicName
          );

    return {
      ...cond,
      condition,
    };
  });

  return lineConditions || [];
};

type DeviceRuleCustomConditionDefaultMessageParams = {
  attribute: string;
  attributeMultiple: string;
  availableMetricsToLowerCase: Array<{
    metric: string;
    [key: string]: unknown;
  }>;
  conditionToggle: string;
  range: string;
  operator: string;
  operatorMultiple: string;
  value: string;
  valueMultiple: string;
};

export const deviceRuleCustomConditionDefaultMessage = ({
  attribute,
  attributeMultiple,
  availableMetricsToLowerCase,
  conditionToggle,
  operator,
  operatorMultiple,
  value,
  valueMultiple,
}: DeviceRuleCustomConditionDefaultMessageParams): string => {
  const isValidFirstCondition = attribute && operator && value;
  const isValidSecondCondition =
    attributeMultiple && operatorMultiple && valueMultiple;

  const metric = availableMetricsToLowerCase.find(
    (m) => m.metric === attribute?.toLowerCase()
  );
  const unit = metric?.unit || '';
  const metricName = metric?.name || attribute;

  if (!isValidFirstCondition) return 'has custom condition';

  let customConditionText = `has ${metricName} ${formatCondition(operator)} ${value} ${unit}`;

  if (conditionToggle && isValidSecondCondition) {
    const secondMetric = availableMetricsToLowerCase.find(
      (m) => m.metric === attributeMultiple?.toLowerCase()
    );
    const secondUnit = secondMetric?.unit || '';
    const secondMetricName = secondMetric?.name || attribute;

    customConditionText += ` ${conditionToggle.toLowerCase()} has ${secondMetricName} ${formatCondition(operatorMultiple)} ${valueMultiple} ${secondUnit}`;
  }

  return customConditionText;
};

export const lineRuleConditionsText = (
  lineRule: LineRule,
  availablePredefinedConditions: Array<AvailableCondition>,
  allDevices: Array<Device>,
  metricsGroupedByDevices: object,
  useSimpleText = true
): string => {
  const validLineConditions =
    lineRule?.lineConditions ||
    lineRuleConditions(lineRule, availablePredefinedConditions);

  const lineDescription = validLineConditions.map((condition) => {
    const {
      condition: {
        custom = false,
        description = '',
        key: conditionKey = 'IDLE',
        threshold = 'idle_Psum',
      } = {},
      condOperator,
      deviceId,
      metric: conditionMetric,
      value,
    } = condition;

    const device = allDevices.find((device) => device.deviceid === deviceId);
    const { thresholds = [{}], ratedload } = device || {};
    const firstThreshold = thresholds[0];
    const idlePsum = firstThreshold?.['idle_Psum'];
    const offlinePsum = firstThreshold?.['offline_Psum'];

    let thresholdText;
    switch (conditionKey) {
      case 'OFFLINE': // below offline threshold
        thresholdText = `has Active Power < ${offlinePsum} kW`;
        break;
      case 'DOWN': // below idle threshold
        thresholdText = `has Active Power < ${idlePsum} kW`;
        break;
      case 'IDLE': // between idle and offline thresholds
        thresholdText = `has Active Power < ${idlePsum} kW and Active Power > ${offlinePsum} kW`;
        break;
      case 'OVERLOADED': // above rated load threshold
        thresholdText = `has Active Power > ${ratedload} kW`;
        break;
    }

    const deviceMetric = metricsGroupedByDevices?.[deviceId]?.find(
      ({ metric }) => metric === conditionMetric
    );
    const areMissingCustomConditionFields =
      deviceMetric == null || condOperator == null || value == null;
    const customConditionText = areMissingCustomConditionFields
      ? 'has custom condition'
      : `has ${deviceMetric.name} ${formatCondition(condOperator)} ${value} ${deviceMetric.unit || ''}`;
    const easyConditionText = useSimpleText ? description : thresholdText;
    const conditionText = custom ? customConditionText : easyConditionText;

    return `${getDeviceNicknameFromId(deviceId, allDevices)} ${conditionText}`;
  });

  const lastLineDescription = lineDescription.slice(-1);

  // add and/or before last device
  if (validLineConditions.length > 1) {
    lineDescription.splice(
      -1,
      1,
      `${lineRuleOperatorConjunction(lineRule)} ${lastLineDescription}`
    );
  }

  // Add comma before last rule if more than two in list
  const conjunctionStr = lineDescription.length > 2 ? ', ' : ' ';
  const lineDefaultDescription = lineDescription.join(conjunctionStr);

  return lineDefaultDescription;
};

export const deviceHasThreshold = (
  device: Device,
  threshold: string
): boolean => {
  let selectedThreshold = threshold;

  if (threshold === 'below_idle_Psum') {
    // below_idle_Psum is the key used by the "down" condition and
    // idle_Psum is the device threshold key and value we actually need to check for
    selectedThreshold = 'idle_Psum';
  }

  const { thresholds = [], ratedload } = device || {};
  const deviceThreshold = { ...thresholds[0], ratedload };

  const hasValue = deviceThreshold[selectedThreshold] != null;

  return hasValue;
};

export const devicesMissingThreshold = (
  devices: Array<Device>,
  threshold: string
): Array<Device> => {
  return devices.filter((device) => !deviceHasThreshold(device, threshold));
};

export const minDuration = (value) =>
  (value && value >= 1) || 'should be greater than 0';

export const disabledDevices = (lineRule: LineRule): Array<Device> => {
  return (lineRule.devices || []).filter((device) => device.status === 'D');
};
