import moment from 'moment';

export enum ViewTypes {
  DEVICES_LISTS = 'DEVICES_LISTS',
  DEVICES = 'DEVICES',
  SEGMENTS = 'SEGMENTS',
}

export const VIEW_TYPES_LABEL = {
  [ViewTypes.DEVICES]: 'Devices',
  [ViewTypes.DEVICES_LISTS]: 'Devices lists',
};

export enum ViewMetrics {
  RUNTIME = 'RUNTIME',
  CYCLES = 'CYCLES',
  OEE = 'OEE',
  DEFAULT = 'DEFAULT',
}

export const DEFAULTVIEWMETRICS = [
  ViewMetrics.RUNTIME,
  ViewMetrics.CYCLES,
  ViewMetrics.OEE,
];

export enum CyclesMetrics {
  COUNT = 'CYCLESCOUNT',
  AVG = 'AVGCYCLETIME',
  CYLESMINUTE = 'CYLESPERMINUTE',
  TOTALUNITS = 'TOTALUNITS',
}

export enum DynamicMetricsAccumulatedOptions {
  AVG = 'avg',
  ACCUMULATED = 'accumulated',
  PERFORMANCE = 'performance',
}

export const VIEW_METRICS_LABEL = {
  [ViewMetrics.RUNTIME]: 'Runtime',
  [ViewMetrics.CYCLES]: 'Cycle time',
  [ViewMetrics.OEE]: 'OEE',
  [ViewMetrics.DEFAULT]: 'DEFAULT',
};

export const ViewMetricsSettingMap = {
  [ViewMetrics.RUNTIME]: 'runtime',
  [ViewMetrics.CYCLES]: 'cycleTime',
  [ViewMetrics.OEE]: 'oee',
  [ViewMetrics.DEFAULT]: 'DEFAULT',
};

export enum ViewIntervals {
  'IN_PROGRESS' = 'inProgress',
  '5_MIN' = '5min',
  '30_MIN' = '30min',
  '1_HR' = '1hr',
  '6_HRS' = '6hrs',
  '12_HRS' = '12hrs',
  '24_HRS' = '24hrs',
  '48_HRS' = '48hrs',
  '72_HRS' = '72hrs',
  '7_DAYS' = '7days',
}

export const ViewIntervalsPerMetric = {
  [ViewMetrics.RUNTIME]: [
    ViewIntervals['5_MIN'],
    ViewIntervals['30_MIN'],
    ViewIntervals['1_HR'],
    ViewIntervals['6_HRS'],
    ViewIntervals['24_HRS'],
  ],
  [ViewMetrics.CYCLES]: [
    ViewIntervals['5_MIN'],
    ViewIntervals['30_MIN'],
    ViewIntervals['1_HR'],
  ],
  [ViewMetrics.OEE]: [
    ViewIntervals.IN_PROGRESS,
    ViewIntervals['12_HRS'],
    ViewIntervals['24_HRS'],
    // ViewIntervals['48_HRS'],
    ViewIntervals['72_HRS'],
    // ViewIntervals['7_DAYS'],
  ],
  [ViewMetrics.DEFAULT]: [
    ViewIntervals['5_MIN'],
    ViewIntervals['30_MIN'],
    ViewIntervals['1_HR'],
    ViewIntervals['6_HRS'],
    ViewIntervals['24_HRS'],
  ],
};

export const VIEW_INTERVALS_LABELS = {
  [ViewIntervals.IN_PROGRESS]: 'In progress',
  [ViewIntervals['5_MIN']]: 'Last 5 min',
  [ViewIntervals['30_MIN']]: 'Last 30 min',
  [ViewIntervals['1_HR']]: 'Last hour',
  [ViewIntervals['6_HRS']]: 'Last 6 hrs',
  [ViewIntervals['12_HRS']]: 'Last 12 hrs',
  [ViewIntervals['24_HRS']]: 'Last 24 hrs',
  [ViewIntervals['48_HRS']]: 'Last 48 hrs',
  [ViewIntervals['72_HRS']]: 'Last 72 hrs',
  [ViewIntervals['7_DAYS']]: 'Last 7 days',
};

export const INTERVAL_MOMENT_UNITS = {
  HRS: 'hours',
  HR: 'hour',
  MIN: 'minutes',
};

const intervalKeyByValue = (value) => {
  const found = Object.entries(ViewIntervals).find(
    ([key, val]) => val === value
  );
  if (found) return found[0];
  return null;
};

export const intervalValue = (interval) => {
  if (interval === ViewIntervals.IN_PROGRESS) return { inProgress: true };
  const key = intervalKeyByValue(interval);
  if (!key) throw new Error(`Not valid interval ${interval}`);

  const [value, unit] = key.split('_');
  const to = moment.utc().startOf('minute').subtract(2, 'minute').valueOf();
  return {
    from: moment(to).subtract(value, INTERVAL_MOMENT_UNITS[unit]).valueOf(),
    to,
  };
};

export interface RuntimeSettings {
  thresholds: {
    danger: number;
    warning: number;
  };
  defaultInterval: ViewIntervals;
  excludedDays: number[];
}

export interface CyclesSettings {
  metric: string;
  thresholds: {
    good: number;
    warning: number;
  };
  defaultInterval: ViewIntervals;
}

export interface OEESettings {
  values: string[];
  defaultInterval: ViewIntervals;
  thresholds: {
    danger: number;
    warning: number;
  };
}

export interface DEFAULTSettings {
  thresholds: {
    good: number;
    warning: number;
  };
  defaultInterval: ViewIntervals;
}

export const DEFAULT_SETTINGS: DEFAULTSettings = {
  thresholds: {
    good: 10,
    warning: 60,
  },
  defaultInterval: ViewIntervals['1_HR'],
};

export interface ViewSettings {
  runtime: RuntimeSettings;
  cycleTime: CyclesSettings;
  oee: OEESettings;
  enabledMetrics: ViewMetrics[];
  autoRefresh: boolean;
  plannedThreshold: number;
}
export interface View {
  id?: string;
  companyId?: string;
  name: string;
  metric: ViewMetrics;
  type: ViewTypes;
  devices: any[];
  devicesLists: any[];
  settings: ViewSettings;
  segments: any[];
}

export interface GetViewsPayload {
  setLoading?: Boolean;
}

export interface State {
  data: {
    views: View[];
    loadingViews: Boolean;
  };
}

export enum LiveModeAppearanceType {
  PERFORMANCE = 'PERFORMANCE',
  CURRENT_STATUS = 'CURRENT_STATUS',
}

export const DEFAULT_VIEW: View = {
  name: '',
  metric: ViewMetrics.RUNTIME,
  type: ViewTypes.DEVICES_LISTS,
  devices: [],
  devicesLists: [],
  settings: {
    enabledMetrics: [ViewMetrics.RUNTIME],
    autoRefresh: false,
    runtime: {
      excludedDays: [],
      defaultInterval: ViewIntervals['1_HR'],
      thresholds: {
        danger: 10,
        warning: 60,
      },
    },
    cycleTime: {
      metric: CyclesMetrics.AVG,
      thresholds: {
        good: 10,
        warning: 60,
      },
      defaultInterval: ViewIntervals['5_MIN'],
    },
    oee: {
      values: ['availability', 'performance', 'quality'],
      defaultInterval: ViewIntervals['24_HRS'],
      thresholds: {
        danger: 10,
        warning: 60,
      },
    },
    plannedThreshold: 50,
  },
  segments: [],
};

export const initialState: State = {
  data: { views: [], loadingViews: false },
};

export default initialState;
