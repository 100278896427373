import Vue from 'vue';

export default {
  SET_METRICS_GROUPED_BY_DEVICES: (state, metrics) => {
    Vue.set(state, 'metricsGroupedByDevices', metrics);
  },
  SET_AVAILABLE_METRICS: (state, metrics) => {
    Vue.set(state, 'available_metrics', metrics);
  },
  SET_DEVICE_AVAILABLE_METRICS: (state, { data, deviceId }) => {
    const newState = { ...state.deviceAvailableMetrics, [deviceId]: data };
    Vue.set(state, 'deviceAvailableMetrics', newState);
  },
};
