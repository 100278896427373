export interface Issue {
  id: string;
  deviceId: string;
  deviceid: string;
  status: 'open' | 'closed' | 'acknowledged';
  entryId: string;
  startedAt: number;
  stoppedAt?: number | null;
  tags: Array<string>;
  comments: { action: string };
  assigned: Array<{
    email: string;
    username: string;
    phonenumber: string;
  }>;
  issuetype: 'manual' | 'alert' | 'diagnostics' | 'production';
  alertScope: 'deviceAlert' | 'lineAlert';
  alertType: 'device_data' | 'anomaly';
  changelog: Array<{
    data: [];
    user: string;
    action: string;
    timestamp: number;
  }>;
  notified: Array<string>;
  createdAt: string;
  updatedAt: string;
}

export type FormattedIssue = Omit<
  Issue,
  'deviceid' | 'issuetype' | 'createdAt' | 'tags'
> & {
  createdAt?: string;
  issueType: 'manual' | 'alert' | 'diagnostics' | 'production';
  deviceNicknames?: string;
  tags: Array<{
    tagname: string;
    [key: string]: unknown;
  }>;
};

export interface State {
  issues: Array<Issue>;
  selectedIssueType: string;
  issuesByDevice: { [key: string]: Issue[] };
  messageSnippets: Array<string>;
  addingIssueGraph: boolean;
  addingIssueNavigation: boolean;
  loading: boolean;
  updateDeviceState: { [key: string]: Number };
}

export const initialState: State = {
  issues: [],
  issuesByDevice: {},
  selectedIssueType: 'alert',
  messageSnippets: [],
  addingIssueGraph: false,
  addingIssueNavigation: false,
  loading: false,
  updateDeviceState: {},
};

export default initialState;
