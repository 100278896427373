import { isArray, isObject, isString, isNumber } from 'util';
import moment from 'moment';
import { State, Filter } from '@/store/modules/filters';

export const readableDate = (value) => moment(value).format('D MMM YYYY');
export const readableDateTime = (value) =>
  moment(value).format('D MMM YYYY HH:mm');
export const readableDateTimeLog = (value) =>
  moment(value).format('MMM Do YYYY, HH:mm');
export const formatDate = (value) => moment(value).format('YYYY-MM-DD');
export const onlyTime = (value) => moment(value).format('HH:mm');
export const formatDateRange = ({ from, to }) => {
  if (formatDate(from) === formatDate(to)) {
    return `${moment(from).format('D MMM')}<br/>${onlyTime(from)}-${onlyTime(to)}`;
  }
  return `${moment(from).format('D MMM HH:mm')}<br/>${moment(to).format('D MMM HH:mm')}`;
};

export const formatValue = (value, decimals = 0) =>
  value !== null && value !== undefined
    ? value.toLocaleString('en', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      })
    : null;

export const prepareQuery = (filters: State['items'] = {}) =>
  Object.values(filters).reduce((acc, filter) => {
    if (filter.prepareQuery) {
      return filter.prepareQuery(filter.value, acc);
    }
    if (!filter.value) {
      acc[filter.id] = undefined;
      return acc;
    }

    if (isString(filter.value) || isNumber(filter.value)) {
      acc[filter.id] = filter.value;
      return acc;
    }

    if (isArray(filter.value)) {
      const filterValue = (filter.value as Array<any>).map((value) => {
        if (isObject(value)) {
          return value[filter.objectKey];
        }
        return value;
      });
      if (filterValue) {
        acc[filter.id] = filterValue;
      }
      return acc;
    }

    if (isObject(filter.value)) {
      acc[filter.id] = filter.value[filter.objectKey] || undefined;
      return acc;
    }

    return acc;
  }, {});

export function applyFiltersToData<T extends any>(
  filters: Array<Filter>,
  data: Array<T>
): Array<T> {
  if (!data.length) return [];

  return data.filter((row) =>
    filters.reduce((acc: boolean, filter: Filter) => {
      if (
        filter.condition &&
        filter.value &&
        !filter.condition(filter.value, row)
      ) {
        acc = false;
      }

      return acc;
    }, true)
  );
}
