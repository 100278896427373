import {
  SET_FILTERS,
  SET_FILTER,
  SET_INITIAL_FILTERS,
  ADD_FILTER,
  DEL_FILTER,
  CLEAR_FILTER,
  CLEAR_FILTERS,
  SET_STATE_FILTER,
  UPDATE_FILTER,
} from './mutations-types';

export default {
  setStateFilter({ commit }, payload) {
    commit(SET_STATE_FILTER, payload);
  },
  setFilters({ commit }, payload) {
    commit(SET_FILTERS, payload);
  },
  setFilter({ commit }, payload) {
    commit(SET_FILTER, payload);
  },
  addFilter({ commit }, payload) {
    commit(ADD_FILTER, payload);
  },
  delFilter({ commit }, index) {
    commit(DEL_FILTER, index);
  },
  setInitialFilters({ commit }, payload) {
    commit(SET_INITIAL_FILTERS, payload);
  },
  clearFilter({ commit }, payload) {
    commit(CLEAR_FILTER, payload);
  },
  clearFilters({ commit }, payload) {
    commit(CLEAR_FILTERS, payload);
  },
  updateFilter({ commit }, payload) {
    commit(UPDATE_FILTER, payload);
  },
};
