import Vue from 'vue';
import _ from 'lodash';
import mappers from '@/helpers/mappers';

import { State, Tag } from './';
import { SET_TAGS, SET_TAG, UPDATE_TAG, DELETE_TAG } from './mutation-types';

export default {
  [SET_TAGS]: (state: State, tagData: Array<Tag>) => {
    if (tagData && Array.isArray(tagData) && tagData.length > 0) {
      const groupedTags = _.groupBy(tagData, 'tagtype');
      Object.keys(groupedTags).forEach((gt) => {
        Vue.set(state, gt, groupedTags[gt].sort(mappers.tagNameSort));
      });
    }
  },
  [SET_TAG]: (state: State, tag: Tag) => {
    Vue.set(
      state,
      tag.tagtype,
      [...state[tag.tagtype], tag].sort(mappers.tagNameSort)
    );
  },
  [UPDATE_TAG]: (state: State, tag: Tag) => {
    const { tagtype } = tag;
    const tagIdx = state[tag.tagtype].findIndex((tt) => tt.id === tag.id);
    const copy = [...state[tagtype]];
    copy[tagIdx] = tag;
    if (tagIdx > -1) {
      Vue.set(state, tagtype, copy.sort(mappers.tagNameSort));
    }
  },
  [DELETE_TAG]: (state: State, data) => {
    const { tagtype } = data;
    const tagIdx = state[tagtype].findIndex((tt) => tt.id === data.id);
    const copy = [...state[tagtype]];
    copy.splice(tagIdx, 1);
    if (tagIdx > -1) {
      Vue.set(state, tagtype, copy.sort(mappers.tagNameSort));
    }
  },
};
