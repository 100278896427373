import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export enum FilterTypes {
  VIEW = 'VIEW',
  TABLE = 'TABLE',
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE',
  BOTH = 'BOTH',
}

export interface Filter {
  id: string;
  label?: string;
  type?: FilterTypes;
  value?: Array<string> | Array<object> | string | object | number;
  objectKey: string;
  ready?: boolean;
  prepareQuery?: (value: any, acc: any) => boolean;
  condition: (value: any, data: any) => boolean;
  resources?: Array<string>;
  version?: number;
  canBeCleared?: boolean;
}

export interface FilterState {
  id: string;
  value: string;
  date: number;
}

export interface FilterComponent {
  filterType: string;
  data?: any;
}

export interface State {
  filterChangedType?: string;
  filterChanged?: string;
  items: {
    [key: string]: Filter;
  };
  clearFilters?: number;
  appliedFilterList?: Array<FilterComponent>;
  states: {
    [key: string]: FilterState;
  };
  updateFilter: {
    [key: string]: object;
  };
}

export const initialState: State = {
  items: {},
  appliedFilterList: [],
  states: {},
  updateFilter: {},
};

export default {
  namespaced: true,
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
