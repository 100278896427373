export interface State {
  show: boolean;
  component?: any;
  props?: any;
}

export const initialState: State = {
  show: false,
};

export default initialState;
