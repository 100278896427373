import { keyBy, orderBy, get, find } from 'lodash';
import { State, ViewTypes } from './model';

export default {
  getViews(state: State, getters, rootState, rootGetters) {
    // Important to keep relations updated for instance if the user deletes
    // a device list the database will handle it with CASCADE but the frontend
    // does not know about that change until we refresh the page
    const devicesListsLookup = keyBy(
      rootGetters['devicesLists/devicesLists'],
      'id'
    );
    const devices = rootGetters.allEnabledDevicesWithoutGhosts;

    return orderBy(
      state.data.views.map((view) => ({
        ...view,
        devicesLists: view.devicesLists.filter(
          (dlId) => devicesListsLookup[dlId]
        ),
        devices: devices
          .filter(({ deviceid }) => view.devices.includes(deviceid))
          .map(({ deviceid }) => deviceid),
      })),
      [(view) => view.name.toLowerCase()],
      ['asc']
    );
  },
  getViewsById(state: State, getters) {
    return keyBy(getters.getViews, 'id');
  },
  getViewById: (state: State, getters) => (viewId: string) =>
    getters.getViewsById[viewId],
  loadingViews: (state: State) => state.data.loadingViews,
  getSegmentName:
    (state: State, getters, rootState, rootGetters) => (segment) => {
      const { is, id } = segment;
      if (is === ViewTypes.SEGMENTS) return id;

      const ObjectsLists =
        is === ViewTypes.DEVICES
          ? rootGetters.devices
          : rootGetters['devicesLists/allowedLists'];

      const mapObj = {
        [ViewTypes.DEVICES]: { name: 'nickname', id: 'deviceid' },
        [ViewTypes.DEVICES_LISTS]: { name: 'name', id: 'id' },
      };

      return get(
        find(ObjectsLists, { [mapObj[is].id]: id }),
        mapObj[is].name,
        'no-name'
      );
    },
};
