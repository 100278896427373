import Vue from 'vue';
import moment from 'moment';

const allDevicesEnergyAcquired = (state, energyData, timezone) => {
  const energy = { days: [] };
  let energyAllDevices = [];
  let energyTotal = 'N/A';

  if (energyData) {
    const { fromDate, toDate, starttime, duration } =
      energyData[energyData.length - 1][0].extras;
    energyData.pop();
    const energyArray = [];
    let energyObject = state.energyAllDevices;
    let { totalEnergy } = state.totalEnergy;
    const totalEnergySource = {};
    const totalEnergyUse = {};
    // First we populate source and use stacks

    for (const engyItem of energyData) {
      energyObject.days = [];
      energyObject.energy = parseFloat(Number(engyItem.energy)).toFixed(2);
      energyObject.start = moment(Number(fromDate)).format('ddd DD MMM');
      energyObject.end = moment(Number(toDate)).format('ddd DD MMM');
      energyObject.device = engyItem.deviceId;

      energyObject.stackname =
        engyItem.loadtype === 'mains_machine'
          ? 'sourceanduse'
          : engyItem.loadtype.split('_')[0] === 'mains'
            ? 'source'
            : 'use';

      // Need to loop by time to create zeros for the graph
      // and avoid skewing data
      const fromM = moment(fromDate).tz(timezone);
      const toM = moment(toDate).tz(timezone);
      while (fromM.valueOf() <= toM.valueOf()) {
        const energyFound = starttime
          ? engyItem.days.find(
              (ed) =>
                Number(ed.start) ===
                fromM.startOf('day').add(Number(starttime), 'minutes').valueOf()
            )
          : engyItem.days.find(
              (ed) => Number(ed.start) === fromM.startOf('day').valueOf()
            );
        if (energyFound) {
          const obj = {};
          obj.ts = moment(Number(energyFound.start))
            .tz(timezone)
            .format('ddd DD MMM YYYY');
          obj.value = parseFloat(Number(energyFound.value)).toFixed(2);
          obj.startT = moment(Number(energyFound.start))
            .tz(timezone)
            .format('ddd DD MMM HH:mm:ss');
          obj.endT = moment(Number(energyFound.last || energyFound.end))
            .tz(timezone)
            .format('ddd DD MMM HH:mm:ss');
          energyObject.days.push(obj);
          if (energyObject.stackname === 'sourceanduse') {
            totalEnergySource[obj.ts] = {
              value: totalEnergySource[obj.ts]
                ? Number(
                    parseFloat(
                      Number(totalEnergySource[obj.ts].value) +
                        Number(obj.value)
                    ).toFixed(2)
                  )
                : 0 + Number(obj.value).toFixed(2),
              startT: moment(Number(energyFound.start))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
              endT: moment(Number(energyFound.last || energyFound.end))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
            };
            totalEnergyUse[obj.ts] = {
              value: totalEnergyUse[obj.ts]
                ? Number(
                    parseFloat(
                      Number(totalEnergyUse[obj.ts].value) + Number(obj.value)
                    ).toFixed(2)
                  )
                : 0 + Number(obj.value).toFixed(2),
              startT: moment(Number(energyFound.start))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
              endT: moment(Number(energyFound.last || energyFound.end))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
            };
          } else if (energyObject.stackname === 'source') {
            totalEnergySource[obj.ts] = {
              value: totalEnergySource[obj.ts]
                ? Number(
                    parseFloat(
                      Number(totalEnergySource[obj.ts].value) +
                        Number(obj.value)
                    ).toFixed(2)
                  )
                : 0 + Number(obj.value).toFixed(2),
              startT: moment(Number(energyFound.start))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
              endT: moment(Number(energyFound.last || energyFound.end))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
            };
          } else {
            totalEnergyUse[obj.ts] = {
              value: totalEnergyUse[obj.ts]
                ? Number(
                    parseFloat(
                      Number(totalEnergyUse[obj.ts].value) + Number(obj.value)
                    ).toFixed(2)
                  )
                : 0 + Number(obj.value).toFixed(2),
              startT: moment(Number(energyFound.start))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
              endT: moment(Number(energyFound.last || energyFound.end))
                .tz(timezone)
                .format('ddd DD MMM HH:mm:ss'),
            };
          }
          // Add zeros if no value for the time period found
        } else {
          const ts = starttime
            ? fromM
                .startOf('day')
                .add(starttime, 'minutes')
                .format('ddd DD MMM YYYY')
            : fromM.startOf('day').format('ddd DD MMM YYYY');
          const obj = {};
          obj.ts = ts;
          obj.value = 0;
          obj.startT = starttime
            ? fromM
                .startOf('day')
                .add(starttime, 'minutes')
                .format('ddd DD MMM HH:mm:ss')
            : fromM.startOf('day').format('ddd DD MMM HH:mm:ss');
          obj.endT = starttime
            ? fromM
                .startOf('day')
                .add(Number(starttime + duration), 'minutes')
                .format('ddd DD MMM HH:mm:ss')
            : fromM.endOf('day').format('ddd DD MMM HH:mm:ss');
          energyObject.days.push(obj);
          if (energyObject.stackname === 'sourceanduse') {
            totalEnergySource[ts] = {
              value: totalEnergySource[ts]
                ? Number(parseFloat(Number(totalEnergySource[ts].value)))
                : 0,
              startT: starttime
                ? fromM
                    .startOf('day')
                    .add(starttime, 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.startOf('day').format('ddd DD MMM HH:mm:ss'),
              endT: starttime
                ? fromM
                    .startOf('day')
                    .add(Number(starttime + duration), 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.endOf('day').format('ddd DD MMM HH:mm:ss'),
            };
            totalEnergyUse[ts] = {
              value: totalEnergyUse[ts]
                ? Number(parseFloat(Number(totalEnergyUse[ts].value)))
                : 0,
              startT: starttime
                ? fromM
                    .startOf('day')
                    .add(starttime, 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.startOf('day').format('ddd DD MMM HH:mm:ss'),
              endT: starttime
                ? fromM
                    .startOf('day')
                    .add(Number(starttime + duration), 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.endOf('day').format('ddd DD MMM HH:mm:ss'),
            };
          } else if (energyObject.stackname === 'source') {
            totalEnergySource[ts] = {
              value: totalEnergySource[ts]
                ? Number(parseFloat(Number(totalEnergySource[ts].value)))
                : 0,
              startT: starttime
                ? fromM
                    .startOf('day')
                    .add(starttime, 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.startOf('day').format('ddd DD MMM HH:mm:ss'),
              endT: starttime
                ? fromM
                    .startOf('day')
                    .add(Number(starttime + duration), 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.endOf('day').format('ddd DD MMM HH:mm:ss'),
            };
          } else {
            totalEnergyUse[ts] = {
              value: totalEnergyUse[ts]
                ? Number(parseFloat(Number(totalEnergyUse[ts].value)))
                : 0,
              startT: starttime
                ? fromM
                    .startOf('day')
                    .add(starttime, 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.startOf('day').format('ddd DD MMM HH:mm:ss'),
              endT: starttime
                ? fromM
                    .startOf('day')
                    .add(Number(starttime + duration), 'minutes')
                    .format('ddd DD MMM HH:mm:ss')
                : fromM.endOf('day').format('ddd DD MMM HH:mm:ss'),
            };
          }
        }
        if (duration && duration >= 1440) {
          fromM.add(duration, 'minutes');
        } else {
          fromM.add(1, 'day');
        }
      }
      energyArray.push(energyObject);
      energyObject = {};
    }
    // Now we create a 'device' called others in the 'use' stack
    if (
      Object.keys(totalEnergySource).length > 0 &&
      Object.keys(totalEnergyUse).length > 0
    ) {
      const otherEnergy = Object.keys(totalEnergySource).map((key) => {
        let engyDiff = totalEnergySource[key].value - totalEnergyUse[key].value;
        if (Number(engyDiff) && Math.sign(engyDiff) === -1) {
          engyDiff = 0;
        } else {
          engyDiff = Number(engyDiff) || 0;
        }
        return {
          ts: key,
          value: Number(parseFloat(engyDiff).toFixed(2)),
          startT: totalEnergySource[key].startT || totalEnergyUse[key].startT,
          endT: totalEnergySource[key].endT || totalEnergyUse[key].endT,
        };
      });
      energyObject = {};
      energyObject.days = otherEnergy;
      energyObject.device = 'Others';
      energyObject.energy = otherEnergy
        .map((oe) => oe.value)
        .reduce((prev, next) => Number(prev) + Number(next));
      energyObject.stackname = 'use';
      energyObject.color = '#f45a5b'; // Default colour for others stack
      energyArray.push(energyObject);

      // Finally sum up total energy for all the devices
      totalEnergy =
        energyArray
          .map((ea) => ea.energy)
          .reduce((prev, next) => Number(prev) + Number(next)) / 2;
      // Divide by two because both bars have same total.
      // Energy consumed is only the sum of one of the two bars
    } else {
      // Finally sum up total energy for all the devices
      totalEnergy = energyArray
        .map((ea) => ea.energy)
        .reduce((prev, next) => Number(prev) + Number(next));
    }
    energyAllDevices = energyArray;
    energyTotal = Number(totalEnergy).toLocaleString();
  }

  return {
    energy,
    energyAllDevices,
    energyTotal,
  };
};

export default {
  ENERGY_DATA_ACQUIRED: (state, energyData) => {
    const { fromDate, toDate, starttime, duration } = energyData.extras;
    const energyObject = state.energy;
    if (energyData) {
      energyObject.days = [];
      if (!energyObject.energy) {
        energyObject.energy = Number(
          parseFloat(Number(energyData.energy)).toFixed(2)
        ).toLocaleString();
        energyObject.start = moment(Number(energyData.start)).format(
          'ddd DD MMM'
        );
        energyObject.end = moment(Number(energyData.end)).format('ddd DD MMM');
      }

      // Need to loop by time to create zeros for the graph
      // and avoid skewing data
      const fromM = moment(fromDate).tz(state.companyTimezone);
      const toM = moment(toDate).tz(state.companyTimezone);
      while (fromM.valueOf() <= toM.valueOf()) {
        const energyFound = starttime
          ? energyData.days.find(
              (ed) =>
                Number(ed.start) ===
                fromM.startOf('day').add(Number(starttime), 'minutes').valueOf()
            )
          : energyData.days.find(
              (ed) => Number(ed.start) === fromM.startOf('day').valueOf()
            );
        if (energyFound) {
          const obj = {};
          obj.ts = moment(Number(energyFound.start)).format('ddd DD MMM YYYY');
          obj.value = parseFloat(Number(energyFound.value)).toFixed(2);
          obj.startT = moment(Number(energyFound.start)).format(
            'ddd DD MMM HH:mm:ss'
          );
          obj.endT = moment(Number(energyFound.last)).format(
            'ddd DD MMM HH:mm:ss'
          );
          if (energyData.days.length === 1 && energyObject.days.length > 0) {
            Vue.set(energyObject.days, energyObject.days.length - 1, obj);
          } else {
            energyObject.days.push(obj);
          }
        } else {
          const obj = {};
          obj.ts = starttime
            ? fromM.add(starttime, 'minutes').format('ddd DD MMM YYYY')
            : fromM.startOf('day').format('ddd DD MMM YYYY');
          obj.value = 0;
          obj.startT = starttime
            ? fromM.add(starttime, 'minutes').format('ddd DD MMM HH:mm:ss')
            : fromM.startOf('day').format('ddd DD MMM HH:mm:ss');
          obj.endT = starttime
            ? fromM
                .add(Number(starttime + duration), 'minutes')
                .format('ddd DD MMM HH:mm:ss')
            : fromM.endOf('day').format('ddd DD MMM HH:mm:ss');
          if (energyData.days.length === 1 && energyObject.days.length > 0) {
            Vue.set(energyObject.days, energyObject.days.length - 1, obj);
          } else {
            energyObject.days.push(obj);
          }
        }
        if (duration && duration >= 1440) {
          fromM.add(duration, 'minutes');
        } else {
          fromM.add(1, 'day');
        }
      }
    }
    Vue.set(state, 'energy', energyObject);
  },
  ENERGY_DAY_DATA_ACQUIRED: (state, energyData) => {
    const energyObject = state.energy;
    if (!energyObject.energy) {
      energyObject.energy = Number(
        parseFloat(Number(energyData.energy)).toFixed(2)
      ).toLocaleString();
      energyObject.start = moment(Number(energyData.start)).format('HH:mm');
      energyObject.end = moment(Number(energyData.end)).format('HH:mm');
    }

    energyData.hours.forEach((hour) => {
      const obj = {};
      obj.ts = moment(Number(hour.start)).format('HH:mm');
      obj.value = parseFloat(Number(hour.value)).toFixed(2);
      energyObject.days.push(obj);
    });

    Vue.set(state, 'energy', energyObject);
  },
  ENERGY_WIDGET_DATA_ACQUIRED_ALL_DEVICES: (state, { payload, range }) => {
    const energyObject = allDevicesEnergyAcquired(
      JSON.parse(JSON.stringify(state)),
      JSON.parse(JSON.stringify(payload)),
      state.browserTimezone
    );
    if (!payload) {
      Vue.set(state.energyWidget.energy, range, energyObject.energy);
    }
    Vue.set(
      state.energyWidget.energyAllDevices,
      range,
      energyObject.energyAllDevices
    );
    Vue.set(state.energyWidget.totalEnergy, range, energyObject.energyTotal);
  },
  ENERGY_DATA_ACQUIRED_ALL_DEVICES: (state, energyData) => {
    let parsedState;
    let parsedEnergyData;
    try {
      parsedState = JSON.parse(JSON.stringify(state));
      parsedEnergyData = JSON.parse(JSON.stringify(energyData));
    } catch {
      parsedState = null;
      parsedEnergyData = null;
    }

    if (!parsedState || !parsedEnergyData) {
      return;
    }

    const energyObject = allDevicesEnergyAcquired(
      parsedState,
      parsedEnergyData,
      state.companyTimezone
    );
    if (!energyData) {
      Vue.set(state, 'energy', energyObject.energy);
    }
    Vue.set(state, 'energyAllDevices', energyObject.energyAllDevices);
    Vue.set(state, 'totalEnergy', energyObject.energyTotal);
  },
  CLEAR_ENERGY_DATA: (state) => {
    Vue.set(state, 'energy', { days: [] });
    Vue.set(state, 'energyAllDevices', []);
    Vue.set(state, 'totalEnergy', '0');
  },
  SET_ENERGY_STATS: (state, { payload, range }) => {
    Vue.set(state.energyStats, range, payload);
  },
};
