'use strict';

export { getPropsValues, bindProps };

import WatchPrimitiveProperties from '../utils/WatchPrimitiveProperties';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getPropsValues(vueInst, props) {
  return Object.keys(props).reduce(function (acc, prop) {
    if (vueInst[prop] !== undefined) {
      acc[prop] = vueInst[prop];
    }
    return acc;
  }, {});
}

/**
 * Binds the properties defined in props to the google maps instance.
 * If the prop is an Object type, and we wish to track the properties
 * of the object (e.g. the lat and lng of a LatLng), then we do a deep
 * watch. For deep watch, we also prevent the _changed event from being
 * emitted if the data source was external.
 */
function bindProps(vueInst, googleMapsInst, props) {
  const _loop = function (attribute) {
    const { twoWay, type, trackProperties, noBind } = props[attribute];

    if (noBind) return 'continue';

    const setMethodName = 'set' + capitalizeFirstLetter(attribute);
    const getMethodName = 'get' + capitalizeFirstLetter(attribute);
    const eventName = attribute.toLowerCase() + '_changed';
    const initialValue = vueInst[attribute];

    if (typeof googleMapsInst[setMethodName] === 'undefined') {
      throw new Error(
        `${setMethodName} is not a method of (the Maps object corresponding to) ${vueInst.$options._componentTag}`
      );
    }

    if (type !== Object || !trackProperties) {
      vueInst.$watch(
        attribute,
        function () {
          const attributeValue = vueInst[attribute];
          googleMapsInst[setMethodName](attributeValue);
        },
        {
          immediate: typeof initialValue !== 'undefined',
          deep: type === Object,
        }
      );
    } else {
      WatchPrimitiveProperties(
        vueInst,
        trackProperties.map((prop) => `${attribute}.${prop}`),
        () => googleMapsInst[setMethodName](vueInst[attribute]),
        vueInst[attribute] !== undefined
      );
    }

    if (
      twoWay &&
      (vueInst.$gmapOptions.autobindAllEvents || vueInst.$listeners[eventName])
    ) {
      googleMapsInst.addListener(eventName, () => {
        vueInst.$emit(eventName, googleMapsInst[getMethodName]());
      });
    }
  };

  for (const attribute in props) {
    const _ret = _loop(attribute);
    if (_ret === 'continue') continue;
  }
}
