import Vue from 'vue';
import { Mutation } from 'vuex';

import { State } from './model';
import { SET_ANOMALY_METRICS } from './mutations-types';

const setAnomalyMetrics: Mutation<State> = (state, payload) => {
  const anomalyData = payload || {};
  Vue.set(state, 'anomalyData', anomalyData);
};

export default {
  [SET_ANOMALY_METRICS]: setAnomalyMetrics,
};
