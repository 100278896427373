import { initialState, State } from './model';

export default {
  getCycleCountSettings(state: State, getters, rootState, rootGetters) {
    /* Your getter function here */
    return state.data.settings;
  },
  getPreviewSettings(state: State, getters, rootState, rootGetters) {
    return state.previewSettings;
  },
  getLoadingState(state: State, getters) {
    return state.loading;
  },
};
