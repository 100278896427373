import Vue from 'vue';
import { isEmpty, assignIn } from 'lodash';
import {
  LineIssueDetail,
  LineIssueDetailPayload,
  LineIssueDetailsPayload,
  State,
} from './model';
import { addMetadata } from './helpers';

export default {
  SET_LINE_ISSUE_DETAIL: (state: State, payload: LineIssueDetailPayload) => {
    const { allDevices = [], lineIssueDetail } = payload;

    if (isEmpty(lineIssueDetail))
      throw new Error('Line issue detail failed to retrieve.');

    // To request a line-issue here it assumes that there are already lines-issues in the vuex
    // store. I think this dependency is not correct as I don't see the reason why I can't get a
    // single issue regardless of whether the issue is in the store or not.

    const index =
      state.lineIssueDetails?.length > 0
        ? state.lineIssueDetails.findIndex(
            (lineID) => lineID.id === lineIssueDetail.id
          )
        : 0;

    if (index >= 0) {
      if (lineIssueDetail?.deleted) {
        const stateCopy = state.lineIssueDetails;
        stateCopy.splice(index, 1);
        Vue.set(state, 'lineIssueDetails', stateCopy);
      } else {
        Vue.set(
          state.lineIssueDetails,
          index,
          addMetadata(allDevices, lineIssueDetail)
        );
      }
    }

    // Update entries for this issue in lineIssueDetailsByDevice
    const { lineIssueDetailsByDevice } = state;
    const deviceIds = lineIssueDetail.deviceIds || [];
    deviceIds.forEach((deviceId) => {
      const deviceLineIssues = lineIssueDetailsByDevice[deviceId] || [];
      const issueIndex = deviceLineIssues.findIndex(
        (issue) => issue.id === lineIssueDetail.id
      );
      if (issueIndex > -1) {
        deviceLineIssues[issueIndex] = assignIn(
          deviceLineIssues[issueIndex],
          lineIssueDetail
        );
      } else {
        deviceLineIssues.push(lineIssueDetail);
      }
      Vue.set(state.lineIssueDetailsByDevice, deviceId, deviceLineIssues);
    });
  },
  /**
   * Set the line issue details in the store state:
   * - Replace `lineIssueDetails` state with the new line issue details
   * - Extend `lineIssueDetailsByDevice` state, only updating entries for
   *   devices that were filtered for in the request
   */
  SET_LINE_ISSUE_DETAILS: (state: State, payload: LineIssueDetailsPayload) => {
    const { allDevices = [], lineIssueDetails = [], deviceIds = [] } = payload;

    const allLineIssueDetails: Array<LineIssueDetail> = lineIssueDetails.map(
      (lineID) => addMetadata(allDevices, lineID)
    );

    // Replace the existing line issue details with the new ones
    Vue.set(state, 'lineIssueDetails', allLineIssueDetails);

    // If the payload includes a filter for specific deviceIds, update the
    // `lineIssueDetailsByDevice` map only for those devices. This ensures that
    // details for devices not included in the filter remain unchanged, which
    // is important for line issues that involve multiple devices.
    if (deviceIds.length > 0) {
      const deviceLineIssues = {};
      // Reset the collection of line issues for each deviceId filtered for
      // This will ensure that stale entries are removed (e.g. if there are no
      // line issues for a given deviceId in allLineIssueDetails)
      deviceIds.forEach((deviceId) => {
        deviceLineIssues[deviceId] = [];
      });
      const { lineIssueDetailsByDevice } = state;

      allLineIssueDetails.forEach((issue) => {
        const issueDeviceIds = issue.deviceIds || [];
        issueDeviceIds.forEach((deviceId) => {
          // Only proceed if the deviceId was one of the requested deviceIds
          if (!deviceIds.includes(deviceId)) return;
          deviceLineIssues[deviceId]?.push(issue);
        });
      });

      Vue.set(state, 'lineIssueDetailsByDevice', {
        ...lineIssueDetailsByDevice,
        ...deviceLineIssues,
      });
    }
  },
};
