export const SET_TAGS = 'SET_TAGS';
export const SET_TAG = 'SET_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';

export default {
  SET_TAGS,
  SET_TAG,
  UPDATE_TAG,
  DELETE_TAG,
};
