import es from 'vuetify/es5/locale/es';
import constants from '@/config/constants';

export default {
  ...es,
  FROM: 'desde',
  TO: 'hasta',
  USERNAME_MISSPELLED: `Error en nombre de usuario:
  Los espacios en nombre de usuario no estan permitidos.`,
  ENERGY: 'Energía',
  ENERGY_CONSUMED: 'Energía consumida',
  PRODUCTION: 'Producción',
  EDIT: 'Editar',
  DELETE: 'Borrar',
  WRITE_A_COMMENT: 'Escribe un comentario...',
  COMMENT_TOO_LONG: `Comentario muy largo, el máximo es ${constants.COMMENTS_MAX_LENGTH} caracteres`,
  REQUIRED_FIELD: 'Campo requerido',
};
