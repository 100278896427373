import _ from 'lodash';
import { State, Tag } from './';
import { DevicesList } from '../devicesLists/model';

export enum TagTypes {
  'ML' = 'ml',
  'PRODUCTION' = 'production_entries',
  'ISSUES' = 'issue_details',
  'DEAFULT' = 'default',
  'ALL' = 'all',
}

export enum TagPlanned {
  'NEITHER' = 0,
  'PLANNED' = 1,
  'UNPLANNED' = 2,
}

const tagsByFeature = (tags: State, feature: TagTypes): Tag[] =>
  tags[feature] || [];

type TagsFilteredByDeviceListAttrs = {
  feature: string;
  featureId: string;
  addDefaultTags: boolean;
  selectedTags: string[];
  deviceLists?: DevicesList[];
};

type TagsForDeviceAttrs = {
  feature: string;
  selectedTags: string[];
  deviceTags: string[];
  featureId: string;
  addDefaultTags: boolean;
};

export default {
  getTagsForDevice:
    (state: State, getters, rootState, rootGetters) =>
    (opts: TagsForDeviceAttrs): Tag[] => {
      const {
        deviceTags,
        selectedTags,
        feature,
        featureId = '',
        addDefaultTags = true,
      } = opts;

      if (!deviceTags.length) {
        return getters.getTagsFilteredByDeviceList(opts);
      }

      const tags: Tag[] = [];
      const allTags = getters.getTags(feature, featureId, addDefaultTags) || [];

      allTags.forEach((t) => {
        const isInDeviceList = deviceTags.includes(t.id);
        const isSelected = (selectedTags || []).includes(t.id);
        if (isInDeviceList || isSelected) {
          tags.push(t);
        }
      });

      return tags.length ? tags : allTags;
    },
  getTagsFilteredByDeviceLists:
    (state: State, getters, rootState, rootGetters) =>
    (opts: TagsFilteredByDeviceListAttrs): Tag[] => {
      const { deviceLists } = opts;
      const deviceListTags = _.flatten(_.map(deviceLists, 'tags')) || [];
      const hasFilter = deviceListTags.length;
      const tags: Tag[] = [];
      const allTags =
        getters.getTags(opts.feature, opts.featureId, opts.addDefaultTags) ||
        [];
      allTags.forEach((t) => {
        const isInDeviceList = !hasFilter || deviceListTags.includes(t.id);
        const isSelected = (opts.selectedTags || []).includes(t.id);
        if (isInDeviceList || isSelected) {
          t.isInDeviceList = isInDeviceList;
          tags.push(t);
        }
      });
      return tags.length ? tags : allTags;
    },
  getTagsFilteredByDeviceList:
    (state: State, getters, rootState, rootGetters) =>
    (opts: TagsFilteredByDeviceListAttrs): Tag[] => {
      const deviceList = rootGetters['devicesLists/currentDeviceList'];
      const deviceListTags = deviceList?.tags || [];
      const hasFilter = deviceListTags.length;
      const tags: Tag[] = [];
      const allTags =
        getters.getTags(opts.feature, opts.featureId, opts.addDefaultTags) ||
        [];
      allTags.forEach((t) => {
        const isInDeviceList = !hasFilter || deviceListTags.includes(t.id);
        const isSelected = (opts.selectedTags || []).includes(t.id);
        if (isInDeviceList || isSelected) {
          tags.push(t);
        }
      });
      return tags.length ? tags : allTags;
    },
  getTags:
    (state: State) =>
    (
      feature: string,
      featureId: string = '',
      addDefaultTags: boolean = true
    ): Tag[] => {
      if (!feature) return [];

      if (feature === TagTypes.ALL) {
        const allEntries = [
          ...state.production_entries,
          ...state.issue_details,
          ...state.ml,
          ...state.default,
        ];
        return allEntries;
      }

      const defaultTags = addDefaultTags ? state.default : [];

      // return the specific feature group
      if (feature.split(',').length === 1) {
        if (featureId === '') {
          return [...tagsByFeature(state, feature as TagTypes), ...defaultTags];
        }

        return state[feature][featureId] || [];
      }

      // If feature is a csv of tags => find all the feature tags
      // featureId is not important
      const temp = feature.split(',');
      const featureTags: Tag[] = temp.reduce((acc: any, f: string) => {
        acc = [...acc, ...tagsByFeature(state, f as TagTypes)];
        return acc;
      }, []);

      return [...featureTags, ...defaultTags];
    },
  getProductionTags(state: State, getters, rootState, rootGetters) {
    const productionPeriods = rootGetters['production/getProduction'];
    const data: String[] = [];
    productionPeriods.forEach((period) => {
      if (period.tags) {
        period.tags.forEach((tags) => data.push(tags));
      }
    });
    return _.uniqBy(data);
  },
  getNoTagFilterOption: () => [
    {
      value: null,
      text: 'No tag',
      data: null,
      tagcolor: '#000000',
      tagtype: 'default',
      tagplanned: 0,
      id: 'notagfilterid',
    },
  ],
  getTagPlannedOptions: () => [
    {
      id: 'sort-01-planned',
      value: 1,
      text: 'Planned',
    },
    {
      id: 'sort-02-unplanned',
      value: 2,
      text: 'Unplanned',
    },
    {
      id: 'sort-99-neither',
      value: 0,
      text: 'Neither',
    },
  ],
};
