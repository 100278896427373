import { Action } from 'vuex';

import { RootState } from '@/store/state';

import { State } from './model';
import { ENABLE_URL_SHORTER, DISABLE_URL_SHORTER } from './mutations-types';

const enableUrlShorter: Action<State, RootState> = ({ commit }) => {
  commit(ENABLE_URL_SHORTER);
};

const disableUrlShorter: Action<State, RootState> = ({ commit }) => {
  commit(DISABLE_URL_SHORTER);
};

export default {
  enableUrlShorter,
  disableUrlShorter,
};
