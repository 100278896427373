'use strict';

// Import necessary modules with ES syntax
import lazyValue from './utils/lazyValue';
import { loadGmapApi as loadGmapApiManager } from './manager';
import Marker from './components/marker';
import Polyline from './components/polyline';
import Polygon from './components/polygon';
import Circle from './components/circle';
import Rectangle from './components/rectangle';
import InfoWindow from './components/infoWindow.vue';
import Map from './components/map.vue';
import StreetViewPanorama from './components/streetViewPanorama.vue';
import PlaceInput from './components/placeInput.vue';
import Autocomplete from './components/autocomplete.vue';
import MapElementMixin from './components/mapElementMixin';
import MapElementFactory from './components/mapElementFactory';
import MountableMixin from './utils/mountableMixin';

const Cluster = undefined; // Retain as undefined per original

let GmapApi = null;

// Export all modules as named exports
export {
  loadGmapApiManager as loadGmapApi,
  Marker,
  Polyline,
  Polygon,
  Circle,
  Cluster,
  Rectangle,
  InfoWindow,
  Map,
  PlaceInput,
  MapElementMixin,
  MapElementFactory,
  Autocomplete,
  MountableMixin,
  StreetViewPanorama,
};

// `install` function as an export
export function install(Vue, options) {
  // Set defaults for options
  options = Object.assign(
    {
      installComponents: true,
      autobindAllEvents: false,
    },
    options
  );

  // Initialize the global `GmapApi` for reactive `google` access
  GmapApi = new Vue({ data: { gmapApi: null } });
  const defaultResizeBus = new Vue();
  const gmapApiPromiseLazy = makeGmapApiPromiseLazy(options);

  Vue.mixin({
    created() {
      this.$gmapDefaultResizeBus = defaultResizeBus;
      this.$gmapOptions = options;
      this.$gmapApiPromiseLazy = gmapApiPromiseLazy;
    },
  });
  Vue.$gmapDefaultResizeBus = defaultResizeBus;
  Vue.$gmapApiPromiseLazy = gmapApiPromiseLazy;

  // Register components if `installComponents` is true
  if (options.installComponents) {
    Vue.component('GmapMap', Map);
    Vue.component('GmapMarker', Marker);
    Vue.component('GmapInfoWindow', InfoWindow);
    Vue.component('GmapPolyline', Polyline);
    Vue.component('GmapPolygon', Polygon);
    Vue.component('GmapCircle', Circle);
    Vue.component('GmapRectangle', Rectangle);
    Vue.component('GmapAutocomplete', Autocomplete);
    Vue.component('GmapPlaceInput', PlaceInput);
    Vue.component('GmapStreetViewPanorama', StreetViewPanorama);
  }
}

// Helper function to load the API as a Promise
function makeGmapApiPromiseLazy(options) {
  function onApiLoaded() {
    GmapApi.gmapApi = {};
    return window.google;
  }

  if (options.load) {
    return lazyValue(() => {
      if (typeof window === 'undefined') {
        return new Promise(() => {}).then(onApiLoaded);
      } else {
        return new Promise((resolve, reject) => {
          try {
            window['vueGoogleMapsInit'] = resolve;
            loadGmapApiManager(options.load, options.loadCn);
          } catch (err) {
            reject(err);
          }
        }).then(onApiLoaded);
      }
    });
  } else {
    const promise = new Promise((resolve) => {
      if (typeof window === 'undefined') {
        return;
      }
      window['vueGoogleMapsInit'] = resolve;
    }).then(onApiLoaded);

    return lazyValue(() => promise);
  }
}

// Export `gmapApi` as a named export
export function gmapApi() {
  return GmapApi.gmapApi && window.google;
}
