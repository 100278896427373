import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import Vue from 'vue';

/**
 * Custom type guard for Axios Errors.
 *
 * TODO: Upgrade Axios and get better types
 */
const isAxiosError = (err: any): err is AxiosError =>
  err && err.isAxiosError === true;

/**
 * Filter out certain non-actionable errors before sending to Sentry
 *
 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-before-send
 */
export const beforeSend = (
  event: Sentry.ErrorEvent,
  hint: Sentry.EventHint
) => {
  const error = hint.originalException;

  // Ignore Axios network errors without a response (offline, DNS issues)
  if (isAxiosError(error) && !error.response) {
    return null;
  }

  const ignorableErrorMessages = [
    'Network Error', // Axios network failures, e.g. offline
    'Failed to fetch', // Fetch API errors, usually network loss
    'Request aborted', // User navigation interrupts
    'AbortError', // Explicitly aborted requests
  ];

  if (
    error instanceof Error &&
    ignorableErrorMessages.some((msg) => error.message.includes(msg))
  ) {
    return null;
  }

  return event; // Capture all other errors
};

interface SentryOptions {
  dsn: string;
  environment: string;
  transport?: any; // Optional transport (Test seam)
}

export const initSentry = (sentryConfig: Partial<SentryOptions> = {}) => {
  const { dsn, transport, environment } = sentryConfig ?? {};
  if (dsn) {
    Sentry.init({
      Vue,
      dsn,
      environment,
      transport,
      beforeSend,
    });
    Sentry.getCurrentScope().setUser({ username: 'guest' });
  }
};
