import router from '../router';
import { objectFromEntries } from './object';
import {
  getDecompressedQueryString,
  isUrlShorterEnabled,
  getCompressedQueryString,
  isQueryStringAboveCompressionThreshold,
} from './urlShorter';

let currentQueries = {};

const compressedQueryKey = 'c';

const getPlainTextURLSearchParams = () => {
  const plainTextURLSearchParams = new URLSearchParams();

  Object.keys(currentQueries).forEach((currentQueryItemKey) => {
    const currentQueryItemValue = currentQueries[currentQueryItemKey];
    if (typeof currentQueryItemValue === 'undefined') {
      return;
    }
    const values = Array.isArray(currentQueryItemValue)
      ? currentQueryItemValue
      : [currentQueryItemValue];
    values.forEach((valueItem) => {
      plainTextURLSearchParams.append(currentQueryItemKey, valueItem);
    });
  });

  return plainTextURLSearchParams;
};

const getCompressedCurrentQueries = () => {
  const plainTextURLSearchParams = getPlainTextURLSearchParams();
  const compressedQuery = getCompressedQueryString(
    plainTextURLSearchParams.toString()
  );

  return {
    [compressedQueryKey]: compressedQuery,
  };
};

const shouldCompressQueryString = () => {
  const plainTextURLSearchParams = getPlainTextURLSearchParams();
  return (
    isUrlShorterEnabled() &&
    isQueryStringAboveCompressionThreshold(plainTextURLSearchParams.toString())
  );
};

export const initQuery = () => {
  currentQueries = {
    ...router.app?.$route.query,
  };

  delete currentQueries[compressedQueryKey];

  const compressedString = router.app?.$route.query[compressedQueryKey];
  const plainTextQuery = getDecompressedQueryString(compressedString);

  if (!plainTextQuery) return;

  const urlSearchParams = new URLSearchParams(plainTextQuery);
  const compressedQueries = objectFromEntries(urlSearchParams.entries());

  currentQueries = {
    ...currentQueries,
    ...compressedQueries,
  };
};

export const removeAllQuery = () => {
  currentQueries = {};

  return new Promise((resolve) => {
    router.push(
      {
        query: {
          ...currentQueries,
        },
      },
      resolve,
      resolve
    );
  });
};

export const addQuery = (name, value) => {
  currentQueries[name] = value;

  if (!shouldCompressQueryString()) {
    router.push({
      query: {
        ...currentQueries,
      },
    });
  } else {
    router.push({
      query: {
        ...getCompressedCurrentQueries(),
      },
    });
  }
};

export const removeQuery = (name) => {
  delete currentQueries[name];

  if (!shouldCompressQueryString()) {
    router.push({
      query: {
        ...currentQueries,
      },
    });
  } else {
    router.push({
      query: {
        ...getCompressedCurrentQueries(),
      },
    });
  }
};

export const removeQueries = (names) => {
  names.forEach((name) => {
    delete currentQueries[name];
  });

  if (!shouldCompressQueryString()) {
    router.push({
      query: {
        ...currentQueries,
      },
    });
  } else {
    router.push({
      query: {
        ...getCompressedCurrentQueries(),
      },
    });
  }
};

export const resetQuery = () => {
  currentQueries = {};
};

export const getQuery = () => currentQueries;
