import _ from 'lodash';
import moment from 'moment-timezone';
import mappers from '@/helpers/mappers';
import { isNil } from 'lodash';
import DeviceMetric from '../models/DeviceMetric';

export const formatMTTR = (issue) => {
  let mttr;
  const closedIssue = issue.changelog.findIndex((ch) =>
    ch.action.includes('status to: closed')
  );
  const issueCreatedAt = issue.changelog.find((ch) =>
    ch.action.includes('first created')
  );
  if (issue.status === 'closed') {
    if (closedIssue >= -1 && issue.changelog[closedIssue]) {
      mttr = moment(Number(issue.changelog[closedIssue].timestamp)).diff(
        moment(Number(issue.startedAt)),
        'minutes'
      );
    } else if (issueCreatedAt) {
      mttr = moment(Number(issueCreatedAt.timestamp)).diff(
        moment(Number(issue.startedAt)),
        'minutes'
      );
    }
  } else {
    mttr = moment.utc().diff(moment(Number(issue.startedAt)), 'minutes');
  }
  if (typeof mttr === 'number') {
    return {
      html: `${moment.duration(mttr, 'minutes').humanize()}`,
      shade: mappers.defaultMTTRShade(mttr),
    };
  }
  return {
    html: 'N/A',
    shade: '#ffffff',
  };
};

export function formatCondition(condition) {
  if (condition === 'gt') {
    return '>';
  }
  if (condition === 'eq') {
    return '=';
  }
  if (condition === 'lt') {
    return '<';
  }
  return null;
}

export function getThresholdDurationAndUnit(milliseconds) {
  const ms = Number(milliseconds);
  let thresholdDuration = ms;
  let timeUnit = 'milliseconds';

  // each unit in milliseconds
  const sec = 1000;
  const min = 60 * sec;
  const hour = 60 * min;
  const day = 24 * hour;

  if (ms % day === 0) {
    thresholdDuration = ms / day;
    timeUnit = 'days';
  } else if (ms % hour === 0) {
    thresholdDuration = ms / hour;
    timeUnit = 'hours';
  } else if (ms % min === 0) {
    thresholdDuration = ms / min;
    timeUnit = 'minutes';
  } else if (ms % sec === 0) {
    thresholdDuration = ms / sec;
    timeUnit = 'seconds';
  }

  return {
    thresholdDuration,
    timeUnit,
  };
}

export default {
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return null;
  },

  prepareAlertToSend(data, device) {
    const preparedData = {};
    const {
      metricid,
      metricNameShown,
      action,
      recipient,
      condition,
      thresholdDuration,
      timeUnit,
      id,
    } = data;
    const threshDurValue = Number(thresholdDuration);

    preparedData.metricid = metricid;
    preparedData.metricNameShown = metricNameShown;
    preparedData.deviceid = device;
    preparedData.action = action;
    preparedData.recipient = recipient;
    preparedData.id = id;

    switch (condition) {
      case '>':
        preparedData.condition = 'gt';
        break;
      case '=':
        preparedData.condition = 'eq';
        break;
      case '<':
        preparedData.condition = 'lt';
        break;
      default:
        preparedData.condition = '';
        break;
    }

    if (data.value !== null) {
      preparedData.value = Number(data.value);
    } else {
      preparedData.value = '';
    }

    switch (
      timeUnit // get duration in milliseconds
    ) {
      case 'seconds':
        preparedData.thresholdDuration = threshDurValue * 1000;
        break;
      case 'minutes':
        preparedData.thresholdDuration = threshDurValue * 60 * 1000;
        break;
      case 'hours':
        preparedData.thresholdDuration = threshDurValue * 3600 * 1000;
        break;
      case 'days':
        preparedData.thresholdDuration = threshDurValue * 3600 * 1000 * 24;
        break;
      default:
        preparedData.thresholdDuration = 0;
        break;
    }

    return preparedData;
  },

  formatReceivedAlert(receivedAlerts, metrics) {
    const newArray = [];
    receivedAlerts.forEach((elem) => {
      const { thresholdDuration, timeUnit } = getThresholdDurationAndUnit(
        elem.duration || elem.thresholdDuration
      );
      const formattedObject = {};
      formattedObject.metricid = elem.metricid;
      formattedObject.metricNameShown = elem.metricNameShown;
      formattedObject.deviceid = elem.deviceid;
      formattedObject.value = elem.value;
      formattedObject.unit = metrics[elem.metricNameShown]
        ? metrics[elem.metricNameShown].unit
        : '';
      formattedObject.action = elem.action;
      formattedObject.active = elem.active;
      formattedObject.condition = formatCondition(elem.condition);
      formattedObject.date = elem.creationDate;
      formattedObject.level = elem.level;
      formattedObject.recipient = this.capitalizeFirstLetter(elem.recipient);
      formattedObject.thresholdDuration = thresholdDuration;
      formattedObject.timeUnit = timeUnit;
      formattedObject.id = elem.id;
      newArray.push(formattedObject);
    });
    return newArray;
  },

  formatReceivednotifs(receivedNotifs, timezone) {
    const newArray = [];
    receivedNotifs.forEach((elem) => {
      const { thresholdDuration, timeUnit } = getThresholdDurationAndUnit(
        elem.duration
      );
      const { creationdate } = elem;

      elem.creationdate = moment(new Date(creationdate))
        .tz(timezone)
        .format('ddd MMM DD YYYY hh:mm:ss A');
      const formattedObject = {
        ...elem,
        thresholdDuration,
        timeUnit,
        is: formatCondition(elem.condition),
      };
      newArray.push(formattedObject);
    });
    return newArray;
  },

  formatReceivedUser(receivedUsers, companies, loggedInUser, company, level) {
    const newArray = [];
    for (let i = 0; i < receivedUsers.length; i += 1) {
      const foundcompany = companies.find(
        (cp) => cp.companyid === receivedUsers[i].companyid
      );
      const companyname = foundcompany
        ? foundcompany.name
        : receivedUsers[i].companyid;
      const formattedObject = {};
      formattedObject.username = this.capitalizeFirstLetter(
        receivedUsers[i].username
      );
      formattedObject.originalUserName = receivedUsers[i].username;
      formattedObject.phone = receivedUsers[i].phone;
      formattedObject.companyid = this.capitalizeFirstLetter(
        receivedUsers[i].companyid
      );
      formattedObject.companyname = this.capitalizeFirstLetter(companyname);
      formattedObject.level = receivedUsers[i].level;
      formattedObject.email = receivedUsers[i].email;
      formattedObject.options = receivedUsers[i].options;
      formattedObject.permissions = receivedUsers[i].permissions;
      formattedObject.mfa = receivedUsers[i].mfa;
      formattedObject.notification_preference =
        receivedUsers[i].notification_preference;
      if (
        level === 0 ||
        ((formattedObject.username.toLowerCase() ===
          loggedInUser.toLowerCase() ||
          level <= formattedObject.level) &&
          formattedObject.companyid.toLowerCase() === company.toLowerCase())
      ) {
        newArray.push(formattedObject);
      }
    }
    return newArray;
  },

  prepareUserToSend(data) {
    const preparedData = {};
    preparedData.username = data.username.toLowerCase().trim();
    preparedData.password = data.password;
    preparedData.companyid =
      typeof data.company === 'string'
        ? data.company
        : data.company.companyid.toLowerCase();
    preparedData.level =
      typeof data.role === 'object' ? data.role.level : data.level;
    preparedData.phone = data.phone;
    preparedData.email = data.email;
    preparedData.options = data.options;
    preparedData.permissions = data.permissions;
    preparedData.mfa = data.mfa;
    preparedData.landing_page = data.landing_page;
    preparedData.notification_preference = data.notification_preference;
    return preparedData;
  },

  prepareDeviceToSend(data, thresholdsParameter) {
    const preparedDevice = {};
    preparedDevice.deviceid = data.deviceid.toLowerCase();
    preparedDevice.companyid = data.companyid.toLowerCase();
    preparedDevice.dashboardmetric = data.dashboardmetric;
    preparedDevice.nickname = data.nickname;
    preparedDevice.type = data.type.toLowerCase();
    preparedDevice.meterid = data.meterid;
    preparedDevice.loadtype = data.loadtype;
    preparedDevice.normalspeed = data.normalspeed;
    preparedDevice.wastespeed = data.wastespeed;
    preparedDevice.targetei = data.targetei;
    preparedDevice.ratedload = data.ratedload;
    preparedDevice.target = data.target;
    preparedDevice.settings = data.settings;
    preparedDevice.tags = data.tags;
    preparedDevice.details = data.details;
    const thresholdsEx = { Psum: {} };
    // Remove all empty data and change to numeric type.
    if (data.type.toLowerCase() !== 'ghost') {
      Object.keys(data.thresholds[0]).forEach((key) => {
        if (data.thresholds[0][key].length === 0) {
          data.thresholds[0][key] = '';
        } else {
          data.thresholds[0][key] = Number(data.thresholds[0][key]);
          const splitedKey = key.split('_')[0];
          thresholdsEx.Psum[splitedKey] = {
            value: Number(data.thresholds[0][key]),
            parameter: thresholdsParameter,
          };
        }
      });
      preparedDevice.thresholds = data.thresholds;
    }
    if (data.ratedload) {
      thresholdsEx.Psum.ratedload = {
        value: Number(data.ratedload),
        parameter: thresholdsParameter,
      };
    }
    preparedDevice.thresholdsEx = thresholdsEx;
    return preparedDevice;
  },

  findMetricId(metricName, metricsArray) {
    const metricFound = metricsArray.filter((item) => item.name === metricName);
    if (metricFound.length > 0) {
      return metricFound[0].metric;
    }
    return null;
  },

  dataAcquiredByDevice(arrayReceived, sample) {
    const arrayCopy = arrayReceived;
    // eslint-disable-next-line
    if (arrayCopy.hasOwnProperty(sample.deviceId)) {
      if (!arrayCopy[sample.deviceId].values) {
        const obj = arrayCopy[sample.deviceId];
        obj.values = [];
        arrayCopy[sample.deviceId] = obj;
      } else {
        if (
          arrayCopy[sample.deviceId].values.filter(
            (item) => item.timestamp === sample.timestamp
          ).length === 0
        ) {
          const array = arrayCopy[sample.deviceId].values;
          if (array.length > 3) {
            const prevValue = array[array.length - 1];
            if (
              Number(prevValue.timestamp) + 600000 <
              Number(sample.timestamp)
            ) {
              let baseCount = 0;
              while (baseCount < 5) {
                array.push(
                  new DeviceMetric(
                    0,
                    sample.metrics[0].metricId,
                    sample.deviceId,
                    0,
                    0,
                    false,
                    false,
                    0
                  )
                );
                baseCount += 1;
              }
            }
          }

          arrayCopy[sample.deviceId].values.push(
            new DeviceMetric(
              sample.timestamp,
              sample.metrics[0].metricId,
              sample.deviceId,
              sample.metrics[0].recValue,
              sample.metrics[0].calcValue,
              sample.metrics[0].excTh,
              sample.metrics[0].excThLimit,
              sample.metrics[0].deviation
            )
          );
        }
      }
    } else {
      const obj = { values: [] };
      obj.values.push(
        new DeviceMetric(
          sample.timestamp,
          sample.metrics[0].metricId,
          sample.deviceId,
          sample.metrics[0].recValue,
          sample.metrics[0].calcValue,
          sample.metrics[0].excTh,
          sample.metrics[0].excThLimit,
          sample.metrics[0].deviation
        )
      );
      arrayCopy[sample.deviceId] = obj;
    }
  },

  sortBy(criteria) {
    return function compareFunction(a, b) {
      if (a[criteria] < b[criteria]) {
        return -1;
      }
      if (a[criteria] > b[criteria]) {
        return 1;
      }
      return 0;
    };
  },

  formatAlert(rule, device, metricsMap) {
    const processedAlert = {
      id: rule.id,
      device: device.nickname,
      deviceNicknames: device.nickname, // harmonize with line rules for table header device(s)
      deviceid: device.deviceid,
      entryId: rule.device_rule_id,
      thresholds: [],
      recipients: [],
      action: rule.description,
      thresholdDuration: '',
      status: 0,
      extradata: {},
      alertType: rule.alertType,
      alertSubtype: rule.alertSubtype,
      alertScope: rule.alertScope,
      conditionsText: [], // harmonize with line rules for table header conditions
      message: rule.description, // harmonize with line rules for table header message
    };

    // Thresholds
    Object.keys(rule.thresholds).forEach((operator) => {
      rule.thresholds[operator].forEach((threshold) => {
        threshold.attribute = threshold.attribute.toLowerCase();
        const metricName = metricsMap[threshold.attribute.toLowerCase()]
          ? metricsMap[threshold.attribute.toLowerCase()].name
          : threshold.attribute.toLowerCase();
        const metricUnit = metricsMap[threshold.attribute.toLowerCase()]
          ? metricsMap[threshold.attribute.toLowerCase()].unit
          : '';

        if (isNil(threshold.value) || isNil(threshold.operator)) {
          return;
        }

        processedAlert.thresholds.push({
          field: metricName,
          condition: formatCondition(threshold.operator),
          value: `${threshold.value} ${metricUnit || ''}`,
          operator,
        });
      });
    });
    processedAlert.conditionsText = processedAlert.thresholds;

    // Recipients
    processedAlert.recipients =
      rule.actions.filter(
        (a) =>
          a.kind === 'send_sms_notification' ||
          a.kind === 'send_email_notification'
      ).length > 0
        ? rule.actions.filter(
            (a) =>
              a.kind === 'send_sms_notification' ||
              a.kind === 'send_email_notification'
          )[0].recipients
        : null;

    // Threshold Duration
    const { thresholdDuration, timeUnit } = getThresholdDurationAndUnit(
      Number(rule.state.AND[0].value) * 1000
    );
    processedAlert.thresholdDuration = `${thresholdDuration} ${timeUnit}`;
    // Status
    processedAlert.status = rule.status;
    // Extra data
    processedAlert.extradata = rule.data;
    processedAlert.escalation = rule.escalation;

    return processedAlert;
  },

  formatNotifications(notification, device, metricsMap, timezone) {
    const processedAlert = this.formatAlert(notification, device, metricsMap);
    const creationdate = moment(new Date(Number(notification.sent_at)))
      .tz(timezone)
      .format('DD MMM hh:mm A');
    const creationts = moment.utc(Number(notification.sent_at)).valueOf();
    const processedNotification = {
      ...processedAlert,
      creationdate,
      creationts,
    };
    return processedNotification;
  },

  formatIssue(processedNotif, issueInfo) {
    processedNotif.issueID = issueInfo.id;
    processedNotif.issueStatus = issueInfo.status;
    processedNotif.issueType = issueInfo.issuetype;
    processedNotif.issueAssignedCount = issueInfo.assigned.length;
    processedNotif.issueAssignees = issueInfo.assigned;
    processedNotif.issueTags = issueInfo.tags;
    processedNotif.issueChangelog =
      issueInfo.changelog && issueInfo.changelog.length > 0
        ? _.orderBy(issueInfo.changelog, 'timestamp', ['desc'])
        : issueInfo.changelog;
    processedNotif.issueOccurredAt = Number(issueInfo.occurredAt);
    // Determine MTTR
    processedNotif.mttr = 'N/A';
    const closedIssue = processedNotif.issueChangelog
      ? processedNotif.issueChangelog.findIndex((ic) =>
          ic.action.includes('status to: closed')
        )
      : -1;
    const issueCreatedAt = processedNotif.issueChangelog
      ? processedNotif.issueChangelog.find((ic) =>
          ic.action.includes('first created')
        )
      : null;
    if (
      processedNotif.issueStatus &&
      processedNotif.issueStatus === 'closed' &&
      closedIssue >= -1 &&
      processedNotif.issueChangelog[closedIssue]
    ) {
      processedNotif.mttr = moment(
        Number(processedNotif.issueChangelog[closedIssue].timestamp)
      ).diff(moment(Number(processedNotif.creationts)), 'minutes');
    } else if (
      processedNotif.issueStatus &&
      processedNotif.issueStatus === 'closed' &&
      issueCreatedAt
    ) {
      processedNotif.mttr = moment(Number(issueCreatedAt.timestamp)).diff(
        moment(processedNotif.issueOccurredAt),
        'minutes'
      );
    } else {
      processedNotif.mttr = moment
        .utc()
        .diff(moment(Number(processedNotif.creationts)), 'minutes');
    }
  },

  timeunitToSecondsConstant(timeunit) {
    switch (timeunit) {
      case 'minutes':
        return 60;
      case 'hours':
        return 3600;
      case 'days':
        return 86400;
      default:
        return 1;
    }
  },

  /**
   * @param {Number} range in minutes
   */
  getAvailableHours(range) {
    const hours = [];
    let startHour = '00:00';
    if (this.settings && this.settings.startOfDay) {
      startHour = moment
        .utc(this.settings.startOfDay, 'HH:mm')
        .tz(this.$store.state.companyTimezone)
        .format('HH:mm');
    }

    let hour = moment(startHour, 'HH:mm').add(1, 'hours').format('HH:mm');
    do {
      hours.push(hour);
      hour = moment(hour, 'HH:mm').add(range, 'minutes').format('HH:mm');
    } while (hour !== '00:00' && hour !== '00:30');

    return hours;
  },

  generateGetQueryParams(obj) {
    const keys = Object.keys(obj);
    const keysLen = keys.length;
    let url = '?';
    for (let i = 0; i < keysLen - 1; i += 1) {
      const currKey = keys[i];
      const currVal = obj[currKey];
      url += `${currKey}=${currVal}&`;
    }
    const currKey = keys[keysLen - 1];
    const currVal = obj[currKey];
    url += `${currKey}=${currVal}`;
    return url;
  },
};
