export const requiredRule = (value) =>
  (value === 0 ? true : !!value) || 'Required field';

export const minSelectRule = (value) =>
  (value && value.length > 0) || 'Must select at least one';

export const minRule = (value) =>
  (value && value.length >= 4) || 'This field must have more than 4 characters';

export const emailRule = (value) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || 'Invalid e-mail.';
};

export const integerRule = (value) =>
  Number.isInteger(Number(value)) || 'Only integer number';

export const floatOrIntegerRule = (value) => {
  const pattern = /\d+(\.\d+)?/;
  const stringValue = `${value}`;
  const matchResult = stringValue.match(pattern);
  if (!matchResult) {
    return 'Invalid number';
  }
  return matchResult[0] === stringValue || 'Invalid number';
};

export const decimalPointsRule = (decimal) => (value) =>
  !value ||
  isNaN(value) ||
  Math.floor(value) === Number(value) ||
  value.toString().split('.')[1].length <= decimal ||
  `Limited to only ${decimal} decimal places.`;

export const onlyOneDecimalRule = (value) =>
  !value ||
  isNaN(value) ||
  Math.floor(value) === Number(value) ||
  value.toString().split('.')[1].length <= 1 ||
  'Limited to only 1 decimal place (e.g. 12.3).';

export const floatOrIntegerNonRequiredRule = (value) => {
  if (!value) {
    return true;
  }
  return floatOrIntegerRule(value);
};

export const noSpacesRule = (value) => {
  const pattern = /\s/;
  return !pattern.test(value) || 'Do not insert spaces';
};

export const isDeviceGroup = (deviceId) => {
  const devicePrefix = deviceId.split('_')[0];
  if (devicePrefix === 'group') {
    return true;
  }
  return false;
};

export const capitalize = (text) =>
  `${text.split('_')[1].charAt(0).toUpperCase()}${text.split('_')[1].slice(1)}`;

export const uppercaseFirstLetter = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const time24HrFormat = (value) => {
  const pattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return pattern.test(value) || 'Invalid time';
};

export const dateTimeFormat = (value) => {
  // regex for 'YYYY-MM-DD HH:mm'
  const pattern =
    /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]$/;
  return pattern.test(value) || 'Invalid date and time format';
};

export const maxLengthRule =
  (maxLength = 30, errorMsg = "You've hit the character limit.") =>
  (value) =>
    value.length <= maxLength || errorMsg;

export const percentage = (value) =>
  (value && value <= 100 && value >= 0) ||
  'The number must be between 0 and 100';

export const percentageOptional = (value) => {
  if (!value) {
    return true;
  }
  return percentage(value);
};

export const hasJsonStructure = (str) => {
  if (typeof str !== 'string') return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch {
    return false;
  }
};

export const positiveRule = (value) =>
  Number(value) >= 0 || 'Only positive number';

export const passwordRule = (value) =>
  (value && value.length >= 12) ||
  'Use 12 or more characters for your password';

export const greaterThanZeroRule = (value) =>
  value === '' ||
  value === null ||
  value === undefined ||
  Number(value) > 0 ||
  'Number must be greater than 0';

export const stringLengthLessThanRule = (maxLen) => (value) =>
  !value ||
  value.length <= maxLen ||
  `This field must be less than ${maxLen} characters`;

export const minValueRule = (min) => (value) => value >= min || `Min is ${min}`;
export const maxValueRule = (max) => (value) => value <= max || `Max is ${max}`;
