export const getFilterState = (page: string): object => {
  const filtersString = window.localStorage.getItem('filters');
  if (!filtersString) return {};
  const filters = JSON.parse(filtersString);
  const { [page]: filtersPage = {} } = filters;
  return filtersPage;
};

export const setFilterState = (page: string, newFilters: object) => {
  const filtersString = window.localStorage.getItem('filters');
  let filters = {};
  if (filtersString) filters = JSON.parse(filtersString);
  filters[page] = { ...filters[page], ...newFilters };
  window.localStorage.setItem('filters', JSON.stringify(filters));
};

export const removeFilterFromState = (page: string, key: string) => {
  const filtersString = window.localStorage.getItem('filters');
  let filters = {};
  if (filtersString) filters = JSON.parse(filtersString);
  delete filters[page][key];
  filters[page] = { ...filters[page] };
  window.localStorage.setItem('filters', JSON.stringify(filters));
};

export const removeAllFiltersFromState = (page: string) => {
  const filtersString = window.localStorage.getItem('filters');
  let filters = {};
  if (filtersString) filters = JSON.parse(filtersString);
  delete filters[page];
  filters = { ...filters };
  window.localStorage.setItem('filters', JSON.stringify(filters));
};

// delete specific keys from each filter's page
export const removeFiltersPageKeysFromState = (keys: string[]) => {
  if (keys) {
    const filtersString = window.localStorage.getItem('filters');
    let filters = {};
    if (filtersString) filters = JSON.parse(filtersString);
    // loop through all the top-level pages and target device and shift keys to clear them out
    Object.keys(filters).forEach((page: string) => {
      if (filters[page]) {
        keys.forEach((key) => delete filters[page][key]);
      }
    });
    filters = { ...filters };
    window.localStorage.setItem('filters', JSON.stringify(filters));
  }
};

export const updateFilterForEachPage = (
  filterKey: string,
  newValue: any = {}
) => {
  if (filterKey) {
    const filtersString = window.localStorage.getItem('filters');
    let filters = {};
    if (filtersString) filters = JSON.parse(filtersString);
    // loop through all the top-level pages and target device and shift keys to clear them out
    Object.keys(filters).forEach((page: string) => {
      if (filters[page] && filters[page][filterKey]) {
        filters[page][filterKey] = {
          ...filters[page][filterKey],
          ...newValue,
        };
      }
    });
    filters = { ...filters };
    window.localStorage.setItem('filters', JSON.stringify(filters));
  }
};

// needed when a user changes devices list
export const removeDeviceAndShiftFiltersFromState = () => {
  // trends page = shifts, devices, Alerts = shift, device
  removeFiltersPageKeysFromState(['device', 'devices', 'shift', 'shifts']);
};
