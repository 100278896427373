import Vue from 'vue';
import { remove } from 'lodash';

export default {
  SET_COMPANY: (state, payload) => {
    const company = payload || {};
    Vue.set(state, 'companyData', company);
  },
  SET_USER_COMPANY: (state, payload) => {
    const company = payload || {};
    Vue.set(state.user, 'companyData', company);
  },
  SET_PROD_UNITS: (state, units) => {
    if (units) {
      Vue.set(state, 'prodUnits', units);
    }
  },
  SET_SETTINGS: (state, settings) => {
    const newSettings = settings || {};
    if (newSettings) {
      Vue.set(state, 'companySettings', newSettings);
    }
  },
  SET_REFRESH_HOME: (state, { widgetId, refresh }) => {
    if (widgetId !== null) {
      Vue.set(state.refreshWidget, widgetId, refresh);
    } else {
      Vue.set(state, 'shouldRefreshHome', refresh);
    }
  },
  SET_PROD_SETTING: (state, value) => {
    const text = value === true ? 'No shift' : 'Shift';
    Vue.set(state.companySettings, 'defaultProdSetting', text);
  },
  SET_SKUS_DEVICES(ctx, payload) {
    Vue.set(ctx, 'skusDevices', payload);
  },
  ADD_SKU_DEVICE: (state, payload) => {
    const newState = [...state.skusDevices, payload];
    Vue.set(state, 'skusDevices', newState);
  },
  EDIT_SKU_DEVICE: (state, payload) => {
    const {
      id,
      sku: { id: skuID },
    } = payload;
    const idx = id
      ? state.skusDevices.findIndex((skuDev) => skuDev.id === id)
      : state.skusDevices.findIndex((skuDev) => skuDev.sku_id === skuID);
    if (idx > -1) {
      const newState = [...state.skusDevices];
      newState.splice(idx, 1, payload);
      Vue.set(state, 'skusDevices', newState);
    }
  },
  REMOVE_SKU_DEVICE: (state, id) => {
    const idx = state.skusDevices.findIndex((skuDev) => skuDev.id === id);
    if (idx > -1) {
      const newState = [...state.skusDevices];
      newState.splice(idx, 1);
      Vue.set(state, 'skusDevices', newState);
    }
  },

  REMOVE_SKU: (state, id) => {
    const newState = [...state.skusDevices];
    remove(newState, { sku_id: id });
    Vue.set(state, 'skusDevices', newState);
  },
};
