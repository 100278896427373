import Vue from 'vue';
import {
  DELETE_VIEWS,
  LOAD_VIEWS,
  ADD_VIEW,
  UPDATE_VIEW,
  LOADING_VIEW,
} from './mutations-types';
import { initialState, State, View } from './model';

export default {
  [LOAD_VIEWS](state: State, payload) {
    /* Your mutation code here */
    /* Don't forget to use Vue.set to update the state */
    Vue.set(state.data, 'views', payload);
  },
  [DELETE_VIEWS](state: State, ids) {
    Vue.set(
      state.data,
      'views',
      state.data.views.filter((v) => !ids.includes(v.id))
    );
  },
  [ADD_VIEW](state: State, view: View) {
    Vue.set(state.data, 'views', [...state.data.views, view]);
  },
  [LOADING_VIEW](state: State, loadingViews) {
    Vue.set(state.data, 'loadingViews', loadingViews);
  },
  [UPDATE_VIEW](state: State, view: View) {
    Vue.set(
      state.data,
      'views',
      state.data.views.map((v) => {
        if (v.id === view.id) return view;
        return v;
      })
    );
  },
};
