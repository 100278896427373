import { User } from '@/store/modules/users';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
// This should be a type cross to all events
export interface Changelog {
  data: Array<any>;
  user: User;
  action: string;
  timestamp: number;
}

export interface Comment {
  id: string;
  feature_table: string;
  feature_table_id: string;
  companyid: string;
  userid: string;
  comment?: string;
  createdat: number;
  replyto?: string;
  changelog?: Array<Changelog>;
}

export interface State {
  [key: string]: { [key: string]: Array<Comment> };
}

const initialState = {
  issue_details: {},
  production_entries: {},
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
