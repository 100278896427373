import axios from 'axios';
import config from '@/config';
import getAxiosOpts from '@/helpers/axios.js';
import { SET_TAGS, SET_TAG, UPDATE_TAG, DELETE_TAG } from './mutation-types';

const url = config.dataProxyUrls.v2.tags.url();

export default {
  getTags: ({ commit, state, rootState: { user, company }, getters }, data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      try {
        const response = await axios.get(`/${url}`, {
          params: {
            ...data,
            companyid: company,
          },
          ...getAxiosOpts(user.token),
        });
        if (response.status === 200) {
          commit(SET_TAGS, response.data.response);
          res(getters.getTags('all'));
        } else {
          rej(response);
        }
      } catch (err) {
        rej(err);
      }
    });
  },
  addTag: ({ dispatch, commit, rootState: { user, company } }, data) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (res, rej) => {
      try {
        const response = await axios.post(
          url,
          {
            companyid: company,
            ...data,
          },
          getAxiosOpts(user.token)
        );
        if (response.status === 200) {
          commit(SET_TAG, response.data.response);
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Tag created successfully.',
            },
            { root: true }
          );
          res(response.data.response);
        } else {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Sorry an error occurred . Please try again.',
              alertType: 'error',
            },
            { root: true }
          );
          rej(response);
        }
      } catch (err) {
        rej(err);
      }
    }),
  updateTag: ({ dispatch, commit, rootState: { user, company } }, data) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (res, rej) => {
      try {
        const response = await axios.put(
          url,
          {
            companyid: company,
            ...data,
          },
          getAxiosOpts(user.token)
        );
        if (response.status === 200) {
          commit(UPDATE_TAG, response.data.response);
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Tag updated successfully.',
            },
            { root: true }
          );
          res(response.data.response);
        } else {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Sorry an error occurred . Please try again.',
              alertType: 'error',
            },
            { root: true }
          );
          rej(response);
        }
      } catch (err) {
        rej(err);
      }
    }),
  deleteTag: ({ dispatch, commit, rootState: { user } }, data) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (res, rej) => {
      try {
        const response = await axios.delete(url, {
          params: {
            ...data,
          },
          ...getAxiosOpts(user.token),
        });
        if (response.status === 200) {
          commit(DELETE_TAG, response.data.response);
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Tag deleted successfully.',
            },
            { root: true }
          );
          res(true);
        } else {
          dispatch(
            'SHOW_SNACKBAR',
            {
              alertMessage: 'Sorry an error occurred . Please try again.',
              alertType: 'error',
            },
            { root: true }
          );
          rej(response);
        }
      } catch (err) {
        rej(err);
      }
    }),
};
