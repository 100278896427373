import moment from 'moment';

// Below are used by uptime feature
const metricMap = {
  'Active Power': {
    id: 'Psum',
    unit: 'kW',
  },
  'Apparent Power': {
    id: 'Ssum',
    unit: 'kVA',
  },
  'Power Factor': {
    id: 'PFavg',
    unit: 'PF',
  },
};

const uptimeTypes = [
  {
    id: 'percentage',
    name: 'Percentage',
  },
  {
    id: 'hrs',
    name: 'Hours',
  },
  {
    id: 'min',
    name: 'Minutes',
  },
];

const supportedMetrics = [
  {
    id: 'kW',
    name: 'Active Power',
  },
  {
    id: 'kVA',
    name: 'Apparent Power',
  },
  {
    id: 'PF',
    name: 'Power Factor',
  },
];

const peakTypes = {
  peakHigh: 'Max',
  peakLow: 'Min',
  avgVal: 'Averages',
  loadFactor: 'Load factor',
};
// Above are used by uptime feature

const axmMetricsMap = {
  Psum_kW: 'Psum',
  Ssum_kVA: 'Ssum',
  Iavg_A: 'Iavg',
  EP_TOTAL_kWh: 'engy',
  ES_kVAh: 'apparentEngy',
  Freq_Hz: 'F',
  PF: 'PFavg',
  VIavg_V: 'Vllavg',
  Vnavg_V: 'Vlnavg',
  V12: 'Vll1',
  V23: 'Vll2',
  V31: 'Vll3',
  V1: 'Vln1',
  V2: 'Vln2',
  V3: 'Vln3',
};

const mapMetric = function mapMetric(metric, from, to) {
  const mappedMetrics = Object.keys(axmMetricsMap);
  if (to - from > 691200000) {
    return metric;
  }
  if (mappedMetrics.includes(metric)) {
    return axmMetricsMap[metric];
  }
  return metric;
};

// Below used by alerts/issues feature
const defaultTags = [
  {
    id: 'MechanicalTag',
    tagname: 'Mechanical',
    color: '#6ec6ff',
  },
  {
    id: 'ElectricalTag',
    tagname: 'Electrical',
    color: '#ffff72',
  },
  {
    id: 'PlannedTag',
    tagname: 'Planned',
    color: '#52c7b8',
  },
  {
    id: 'NoOperatorTag',
    tagname: 'No operator',
    color: '#ffc947',
  },
  {
    id: 'NoMaterialTag',
    tagname: 'No material',
    color: '#9a67ea',
  },
  {
    id: 'OtherTag',
    tagname: 'Other',
    color: '#ff6090',
  },
];

const defaultStatuses = {
  open: {
    status: 'Open',
    color: '#ff7961',
    icon: 'issues-icon',
  },
  acknowledged: {
    status: 'Acknowledged',
    color: '#F2994A',
    icon: 'seen',
  },
  closed: {
    status: 'Closed',
    color: '#27AE60',
    icon: 'done-circle',
  },
};

export const mfaOptions = {
  sms: {
    text: 'SMS',
    value: 'sms',
    destType: 'phone',
  },
  whatsapp: {
    text: 'WhatsApp',
    value: 'whatsapp',
    destType: 'phone',
  },
  call: {
    text: 'Phone Calls',
    value: 'call',
    destType: 'phone',
  },
  email: {
    text: 'Email',
    value: 'email',
    destType: 'email',
  },
};

const defaultMTTRShade = function defaultMTTRShade(durationMin) {
  if (durationMin <= 120) {
    return '#27AE60';
  } else if (durationMin <= 1440) {
    return '#F2994A';
  }
  return '#ff7961';
};

const defaultIssueTypes = ['Alert', 'Diagnostics', 'Manual', 'Production'];
const defaultIssuesStatuses = ['open', 'acknowledged', 'closed'];

const issueChanges = function issueChanges(
  user,
  changetype,
  changevalue,
  iscreated = false,
  actionEffect = ''
) {
  const value = Array.isArray(changevalue)
    ? changevalue.join(', ')
    : changevalue;
  return {
    data: { type: changetype, value },
    user,
    timestamp: moment().valueOf(),
    action: iscreated
      ? 'Issue first created'
      : `Changed ${changetype} to: ${changetype === 'started time' || changetype === 'stopped time' ? '' : `${value}.`}
      ${actionEffect && actionEffect}`,
  };
};

const issueIcons = {
  issue:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuNTkzIDMuNTcybC00Ljg2IDguMzI0YS41MzYuNTM2IDAgMDAwIC41MTkuNDk4LjQ5OCAwIDAwLjE3OC4xODUuNDYuNDYgMCAwMC4yMy4wNjdoOS43MThhLjQ2LjQ2IDAgMDAuMjMtLjA2Ny40OTguNDk4IDAgMDAuMTc4LS4xODUuNTM2LjUzNiAwIDAwMC0uNTE5TDguNDA4IDMuNTczdi0uMDAxYS40OTYuNDk2IDAgMDAtLjE3OC0uMTc2LjQ2LjQ2IDAgMDAtLjQ2IDAgLjQ5Ni40OTYgMCAwMC0uMTc3LjE3NnoiIGZpbGw9IiNmZmYiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuMTAzIDIuMjQxYTEuNzkzIDEuNzkzIDAgMDExLjc5NCAwYy4yNzMuMTU3LjQ5OS4zODMuNjU5LjY1NGwuMDAyLjAwMyA0Ljg2NCA4LjMzMi4wMDUuMDA5YTEuODY3IDEuODY3IDAgMDEtLjY0OSAyLjUwMiAxLjc5NCAxLjc5NCAwIDAxLS45MDYuMjU5SDMuMTI4YTEuNzkzIDEuNzkzIDAgMDEtLjkwNy0uMjU5IDEuODMxIDEuODMxIDAgMDEtLjY1My0uNjc5IDEuODcgMS44NyAwIDAxLjAwNS0xLjgyM2wuMDA1LS4wMSA0Ljg2NC04LjMzMS41NzYuMzM2LS41NzQtLjM0Yy4xNi0uMjcuMzg2LS40OTYuNjU5LS42NTN6bS40OSAxLjMzMWwtNC44NiA4LjMyNGEuNTM2LjUzNiAwIDAwMCAuNTE5LjQ5OC40OTggMCAwMC4xNzguMTg1LjQ2LjQ2IDAgMDAuMjMuMDY3aDkuNzE4YS40Ni40NiAwIDAwLjIzLS4wNjcuNDk4LjQ5OCAwIDAwLjE3OC0uMTg1LjUzNi41MzYgMCAwMDAtLjUxOUw4LjQwOCAzLjU3M3YtLjAwMWEuNDk2LjQ5NiAwIDAwLS4xNzgtLjE3Ni40Ni40NiAwIDAwLS40NiAwIC40OTYuNDk2IDAgMDAtLjE3Ny4xNzZ6IiBmaWxsPSIjRjUyMjJEIi8+PHBhdGggZD0iTTcuNTc2IDkuMTE2Yy4wMzEuMTk0LjIzNC4zMi40MzEuMzIuMjEgMCAuNDEtLjEyNS40NC0uMzMzbC4zMjgtMi4yMTJ2LS4xMDNhLjc4NS43ODUgMCAwMC0uNzgtLjc4NC44MTYuODE2IDAgMDAtLjU5LjI3Ljc5Ni43OTYgMCAwMC0uMTkxLjYxN2wuMzYyIDIuMjI1ek04IDkuOTk1YS42Ny42NyAwIDAwLS42NjcuNjY2LjY3LjY3IDAgMDAuNjY3LjY2Ny42Ny42NyAwIDAwLjY2Ny0uNjY3QS42NTcuNjU3IDAgMDA4IDkuOTk1eiIgZmlsbD0iI0Y1MjIyRCIvPjwvc3ZnPg==',
  sms: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNS4zMzMgOGguMDA3LS4wMDd6TTggOGguMDA3SDh6bTIuNjY3IDBoLjAwNi0uMDA2ek0xNCA4YzAgMi45NDUtMi42ODcgNS4zMzMtNiA1LjMzM2E2LjU3NSA2LjU3NSAwIDAxLTIuODM3LS42MzJMMiAxMy4zMzNsLjkzLTIuNDhBNC44OSA0Ljg5IDAgMDEyIDhjMC0yLjk0NSAyLjY4Ny01LjMzMyA2LTUuMzMzUzE0IDUuMDU1IDE0IDh6IiBmaWxsPSIjZmZmIi8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDMuMzMzQzQuOTc5IDMuMzMzIDIuNjY3IDUuNDk1IDIuNjY3IDhjMCAuOS4yOTIgMS43NDYuODA2IDIuNDY2LjEyOS4xOC4xNi40MTQuMDgxLjYyMWwtLjUwOSAxLjM1NyAxLjk4OC0uMzk3YS42NjcuNjY3IDAgMDEuNDE2LjA1MSA1LjkxIDUuOTEgMCAwMDIuNTQ5LjU2OUg4YzMuMDIxIDAgNS4zMzMtMi4xNjIgNS4zMzMtNC42NjdTMTEuMDIxIDMuMzMzIDggMy4zMzN6TTEuMzMzIDhjMC0zLjM4NSAzLjA2MS02IDYuNjY3LTYgMy42MDUgMCA2LjY2NyAyLjYxNSA2LjY2NyA2UzExLjYwNyAxNCA4IDE0SDhoLjAwMmE3LjI0MSA3LjI0MSAwIDAxLTIuOTE5LS42MDRsLTIuOTUyLjU5MWEuNjY3LjY2NyAwIDAxLS43NTUtLjg4OGwuODEtMi4xNkE1LjUyNCA1LjUyNCAwIDAxMS4zMzMgOHoiIGZpbGw9IiM1MTZEN0IiLz48cGF0aCBkPSJNOC4wNDkgOC42ODFjLS4xOTcgMC0uNC0uMTI1LS40MzItLjMybC0uMzYyLTIuMjI1YS43OTYuNzk2IDAgMDEuMTkyLS42MTYuODE2LjgxNiAwIDAxLjU4OS0uMjdjLjQyMiAwIC43OC4zNDcuNzguNzg0di4xMDJMOC40OSA4LjM1Yy0uMDMuMjA3LS4yMy4zMzItLjQ0LjMzMnpNNy4zNzUgOS45MDdhLjY3LjY3IDAgMDEuNjY3LS42NjdjLjM3NiAwIC42NjYuMjkuNjY2LjY2N2EuNjcuNjcgMCAwMS0uNjY2LjY2Ny42Ny42NyAwIDAxLS42NjctLjY2N3oiIGZpbGw9IiM1MTZEN0IiLz48L3N2Zz4=',
};

export const issueStatusIcon = {
  open: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABzElEQVRIS+2UzUtUYRTGf8+dZhMERqgotGplyzatgkRCEnXRHxB+DNXGPijMGVoEwYyiSwn6oDECN4YgSotsGUQ7oUVBi5bVokJ0Fdf3xB3N7lzfmTs4uBB614fnd87znvOIA346YH3+A1IdTrXICrl2LBxFnAEF/xTNYfqIsg9VfPy1FqkuwPLDrcADYABo8YhsYKwSZO6o+PS7D1ITUBGX7mN2CWgDYt3vShniB85WCI5M+CBeQB3x98AacBo4t4NxiJ8YyyiTT0L8gImhu0hjQEd153oBbhHRi+lazJJtCDxSsXwvblWtCRaAPuBota96grl50CDiVsLz38Bblco96YDC8CJG7z4A71Qqn28CwDzSEtgFjJxngqYBH4BPwCmI7qLqRRY1Chh6ieniHouMz8i+IJ3E6GriD0ai/b/q2f9ZAj2v3IaRjwEM+AWaU+nZ7Qb+INeOCycJ1I9xAnZTdwHpFc66EZd3hCLxdeA1yt5MxkbtS44yyG3NoMq6Ho9B4g3+FV8ltOuanvuWjIv6WVS40oGFU2AR5JgnazaBN4R2wyce1aen6fhIJxk3BjqbyCOHsUaQnd53mqaGfQMFqRM0oFG35PAD/gCG5aoZDMYocgAAAABJRU5ErkJggg==',
  acknowledged:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABqklEQVRIS+WVP0xTURSHv997DVHjQITBRUwIBkYWJ2IiKU0gMVEfWCYmFiY3JwesDl0MGwyMxomCAyzEtNSY6OJENEz+YaZ0ICRWat49pk2DBkwfD3iD8az3nvPdc37nnCsSNiUcn/8QYIZYHbqsu+/2T1Le2CWypeF+fO8hcosKyptRkFgAW073IisAPZjymijNnRvAlkZ68N06MABUkD1SUH5xZkCz5iuZPhS+BG5Co/PsG3iTGi99iA2w8u0UFS7wo8OoVet0dV5Dbh6UAfxWwC/IppHtHQJ+qgbdn5UthH9Cj2lgy5kbKJzE9B3Ct8gbBJ4BVyNe+xXPpXX/zXZ7wKv0HczWgF2MXPOymAW6o8qBNKag1NDp0I5ncBaAY0gPNt4nAxBbkBpW8HonGQB6THjlubKFehKAHXxvRPeKH4/qFK2BJ8PsSRuRG+cLeN6sglL1JIBRrLkOqqA8chVMT4Hrf+8iq+A0w6dbReVyLhrQWGYpTbXmYIPaxS0uHYxj9KIj/4fJYW4T5xeVLf4eunZtGtnrMS/E2qYxY7dm9DReMXz+/Qx+AfIFrhkG7iHcAAAAAElFTkSuQmCC',
  closed:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACeklEQVRIS+WUT2hUVxTGf999k8EqGhWtY2vU7kTwT2k2FlSQLKSF7oa6dGVaQYQG0Sjx5RlqVUpdCSoqCCqWEEFBhOJCycKFpS7SXaHQiFZRVGTUOJL7yUsbCHGcFxcuSu/2nvv9zvnuOUe856P3rM//CWBUpRrGLe2nPyJcZHGhRenvabk8/dWcernlk2CviPZ84F4CQ7Ge/LXwyd3Hne3HX70N9HaA0Q/Du2bXQ/mLCF0yn04WMdwM4uC0aSO/7Jx3qNaoosaAcXGVN0dIBa1NrHgisyt+oLPZh1ltclxDQG5LaHU1miNF4oY7grkQtrrNlzJlcSKkIeD7P3cvqJdKlwWfNcn8Eeg8xEHQfuD+jPLzjh2VH581Bxj13u5dK+I103hODI+Bcwk+bWtDFF0B5sWo9mxp9ltTQNXVZPnwsm1B+inCQ+FboJVA5d+Hjww/K4YzUlxvsU1QyZMx/jZb3He0KSB1WtIwPZJ7DEOWumXWIG/BlHJbAvG80TrDN4KPxyu13Z0t6TswpQokHQYeWpxM7AFbHVHMTPCVXDxCZ4BFE2203Zkt6Tte+Af7hns+tzSYB+Y2IU4l+OyoQhKiv2wkLrDkVXvb+oYKu2j/393zX9ZbLkqsyR+OQeACUBN8PdGWcTHDoEphY+9Hvc8LAcd+3dJyb0HlK5sTwOwxiKlJjOZzMbm7/ukqbaKNq1OagzyD9I90FuW4SdLBHNJsHixvb6U28F3b4RdTmuSxIKP0QTpDI+qAuAdof2MXmRsE7Wvl6fVG4nl88TZ1GnjAdF6wWCGutqkI7iaJb42qdNsLPTLZlsI/KNrx73JfWMG7iDWK/e8DXgMhMvwZ6GVfNQAAAABJRU5ErkJggg==',
};

// Get the SVG from https://materialdesignicons.com
// Convert with https://yoksel.github.io/url-encoder/
export const generalIcons = {
  'help-circle':
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z' /%3E%3C/svg%3E",
  'clipboard-tick-outline':
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M7,7H17V5H19V19H5V5H7V7M7.5,13.5L9,12L11,14L15.5,9.5L17,11L11,17L7.5,13.5Z' /%3E%3C/svg%3E",
};

const availableTagTypes = {
  issue_details: 'Issues',
  ml: 'ML',
  production_entries: 'Production',
};

const availableTagPlanned = {
  0: 'Neither',
  1: 'Planned',
  2: 'Unplanned',
};

const tagNameSort = function tagNameSort(a, b) {
  if (a.tagname && b.tagname) {
    // for case-insensitive
    const tagA = a.tagname.toLowerCase();
    const tagB = b.tagname.toLowerCase();
    return tagA.localeCompare(tagB, undefined, { numeric: true });
  }
  return 0;
};

const formatNumberLocaleString = (number) =>
  (number && number.toLocaleString('en')) || number;

// Above used by alerts/issues feature
export default {
  metricMap,
  uptimeTypes,
  supportedMetrics,
  peakTypes,
  axmMetricsMap,
  mapMetric,
  defaultTags,
  defaultStatuses,
  issueChanges,
  defaultIssueTypes,
  issueStatusIcon,
  defaultMTTRShade,
  availableTagTypes,
  availableTagPlanned,
  defaultIssuesStatuses,
  issueIcons,
  tagNameSort,
  generalIcons,
  formatNumberLocaleString,
};
