import axios from 'axios';

import getAxiosOpts from '@/helpers/axios.js';
import { company } from '../state';

export default {
  async GET_AVAILABLE_DEVICE_GROUPS({ commit, state }) {
    if (state.availableDeviceGroups.length) return Promise.resolve();
    const response = await axios.get(
      `/api/devicegroups?companyid=${company}`,
      getAxiosOpts(state.user.token)
    );
    commit('SET_AVAILABLE_DEVICE_GROUPS', response.data.response);
  },
  async GET_ALL_DEVICE_GROUPS({ commit, state }, data = {}) {
    if (state.deviceGroups.length) return Promise.resolve();
    const response = await axios.get(
      `/api/devicegroups?companyid=${company}${data.settings ? '&settings=true' : ''}`,
      getAxiosOpts(state.user.token)
    );
    commit('SET_DEVICE_GROUPS', response.data.response);
  },
  async CREATE_DEVICE_GROUP({ dispatch, commit, state }, deviceGroup) {
    const { data } = await axios.post(
      '/api/devicegroups',
      {
        ...deviceGroup,
      },
      getAxiosOpts(state.user.token)
    );
    if (
      (typeof data === 'string' || data instanceof String) &&
      data.toLowerCase().includes('error')
    ) {
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: data,
          alertType: 'error',
        },
        { root: true }
      );
      return data;
    } else {
      const { groupid } = data;
      commit('SET_NEW_DEVICE_GROUP', {
        deviceGroup,
        groupid,
      });
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Device group created successfully.',
        },
        { root: true }
      );
    }
  },
  async EDIT_DEVICE_GROUP({ dispatch, commit, state }, { oldGroup, newGroup }) {
    const { data } = await axios.put(
      '/api/devicegroups',
      {
        oldGroup,
        newGroup,
      },
      getAxiosOpts(state.user.token)
    );
    if (
      (typeof data === 'string' || data instanceof String) &&
      data.toLowerCase().includes('error')
    ) {
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Sorry an error occurred. Please try again.',
          alertType: 'error',
        },
        { root: true }
      );
      return data;
    } else {
      commit('UPDATE_DEVICE_GROUP', {
        oldGroup,
        newGroup,
      });
      dispatch(
        'SHOW_SNACKBAR',
        {
          alertMessage: 'Device group updated successfully.',
        },
        { root: true }
      );
    }
  },
  async SOFT_DELETE_DEVICE_GROUP({ commit, state }, { group, index }) {
    const response = await axios.put(
      '/api/devicegroups',
      {
        oldGroup: group,
        newGroup: {
          ...group,
          status: 'D',
        },
      },
      getAxiosOpts(state.user.token)
    );
    if (!response.data.toLowerCase().includes('error')) {
      commit('REMOVE_DEVICE_GROUP', index);
    } else {
      return response.data;
    }
  },
};
