import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import { Uptime } from '@/store/modules/uptime';
import {
  initialState,
  State,
  ProductionPeriod,
  ParsedCsvRow,
  ProductionPeriodCreateUpdateModalState,
} from './model';
import {
  SET_PRODUCTION_PERIODS,
  ADD_PRODUCTION_PERIODS,
  CLEAR_PRODUCTION_PERIODS,
  SET_UPTIME,
  SET_PARSED_CSV,
  SET_CSV_SAVING_STATUS,
  SET_CSV_SAVED,
  SET_CSV_SAVE_MESSAGE,
  SET_CSV_PARSE_STATUS,
  UPDATING_OEES_DONE,
  UPDATING_OEES,
  SET_UNSAVED_ENTRIES_STATUS,
  SET_IN_PROGRESS_PRODUCTION_PERIODS,
  LOADING_RESOURCE,
  LOADING_RESOURCE_DONE,
  UPDATE_PRODUCTION_ENTRY,
  SET_CSV_MAPPING,
  SAVE_THROUGHPUT_MODEL,
  CLEAN_PRODUCTION_THROUGHPUT,
  UPDATE_EDIT_SCRAP,
  UPDATE_PRODUCTION_PERIOD_CREATE_UPDATE_MODAL,
} from './mutations-types';
import { groupPeriodsByStatus, getPeriodStatus } from './helpers';

export interface SetUptimeMutationPayload {
  period: ProductionPeriod;
  uptime: Uptime;
  type?: string;
}

export default {
  [SET_PRODUCTION_PERIODS](state: State, payload: Array<ProductionPeriod>) {
    const groupedPeriods = groupPeriodsByStatus(payload);

    const inProgressPeriods = {
      ...state.periods.inProgress,
      data: groupedPeriods.inProgress || state.periods.inProgress.data,
    };

    inProgressPeriods.data = inProgressPeriods.data.map((p) => ({
      ...p,
      uptime: undefined,
    }));

    Vue.set(state, 'error', false);

    const newStatePeriods = {
      ...initialState.periods,
      ...groupedPeriods,
      inProgress: inProgressPeriods,
      all: payload,
    };

    Vue.set(state, 'periods', { ...newStatePeriods });
  },
  [SET_IN_PROGRESS_PRODUCTION_PERIODS](
    state: State,
    periods: Array<ProductionPeriod>
  ) {
    Vue.set(state.periods.inProgress, 'data', [...periods]);
    Vue.set(state.periods.inProgress, 'lastUpdate', moment().valueOf());
  },
  [ADD_PRODUCTION_PERIODS](state: State, payload: Array<ProductionPeriod>) {
    Vue.set(state, 'error', false);
    payload.forEach((period) => {
      const periodStatus = getPeriodStatus(period);
      if (periodStatus === 'inProgress') {
        Vue.set(state.periods.inProgress, 'data', [
          ...state.periods[periodStatus].data,
          period,
        ]);
      } else {
        Vue.set(state.periods, periodStatus, [
          ...state.periods[periodStatus],
          period,
        ]);
        Vue.set(state.periods, 'all', [...state.periods.all, period]);
      }
    });
  },
  [CLEAR_PRODUCTION_PERIODS](state: State) {
    state = initialState;
  },
  [SET_UPTIME](
    state: State,
    { period, uptime, type }: SetUptimeMutationPayload
  ) {
    const periodType = type || getPeriodStatus(period);

    if (periodType !== 'inProgress') {
      const index = state.periods[periodType].findIndex(
        (p) => p.id === period.id
      );
      if (index > -1) {
        Vue.set(state.periods[periodType], index, {
          ...state.periods[periodType][index],
          uptime,
        });
      }
    } else {
      const index = state.periods.inProgress.data.findIndex(
        (p) => p.id === period.id
      );
      if (index > -1) {
        Vue.set(state.periods.inProgress.data, index, {
          ...state.periods.inProgress.data[index],
          uptime,
        });
      }
    }
  },
  [SET_PARSED_CSV](state: State, payload: Array<ParsedCsvRow>) {
    Vue.set(state, 'csvParsed', true);
    Vue.set(state, 'parsedCsv', payload);
  },
  [SET_CSV_PARSE_STATUS](state: State, payload: Boolean) {
    Vue.set(state, 'csvParsed', payload);
  },
  [SET_CSV_SAVING_STATUS](state: State, payload: Boolean) {
    Vue.set(state, 'savingCsvData', payload);
  },
  [SET_CSV_SAVED](state: State, payload: Boolean) {
    Vue.set(state, 'parsedCsvDataSaved', payload);
  },
  [SET_CSV_SAVE_MESSAGE](state: State, message: String) {
    Vue.set(state, 'csvSaveMessage', message);
  },
  [SET_CSV_MAPPING](state: State, payload) {
    Vue.set(state, 'csvMapping', payload);
  },
  [UPDATING_OEES](state: State) {
    Vue.set(state, 'updatingOEEs', true);
  },
  [UPDATING_OEES_DONE](state: State) {
    Vue.set(state, 'updatingOEEs', false);
  },
  [SET_UNSAVED_ENTRIES_STATUS](state: State, payload: boolean) {
    Vue.set(state, 'unSavedEntries', payload);
  },
  [LOADING_RESOURCE](state: State, resource) {
    Vue.set(state.periods[resource], 'loading', true);
  },
  [LOADING_RESOURCE_DONE](state: State, resource) {
    Vue.set(state.periods[resource], 'loading', false);
  },
  [UPDATE_PRODUCTION_ENTRY](state: State, resource) {
    const periodsCopy = [...state.periods.inProgress.data];
    const allPeriodsCopy = [...state.periods.all];
    const allIndex = allPeriodsCopy.findIndex(
      (period) => period.id === resource.id
    );
    const index = periodsCopy.findIndex((period) => period.id === resource.id);
    periodsCopy[index] = resource;
    allPeriodsCopy[allIndex] = resource;
    Vue.set(state.periods, 'all', allPeriodsCopy);
    Vue.set(state.periods.inProgress, 'data', periodsCopy);

    // Check if in completed array
    const completedIndex = state.periods.completed.findIndex(
      (p) => p.id === resource.id
    );
    if (completedIndex > -1) {
      Vue.set(state.periods.completed, completedIndex, resource);
    }
  },
  [SAVE_THROUGHPUT_MODEL](state: State, payload) {
    const { id } = payload;
    const { throughputModels } = state;
    throughputModels[id] = payload;
    Vue.set(state, 'throughputModels', { ...throughputModels });
  },
  [CLEAN_PRODUCTION_THROUGHPUT](state: State) {
    Vue.set(state, 'throughputModels', {});
  },
  [UPDATE_EDIT_SCRAP](state: State, scrap) {
    Vue.set(state, 'editingScrap', scrap);
  },
  [UPDATE_PRODUCTION_PERIOD_CREATE_UPDATE_MODAL](
    state: State,
    payload: ProductionPeriodCreateUpdateModalState
  ) {
    Vue.set(state, 'productionPeriodCreateUpdateModal', payload);
  },
};
