import Vue from 'vue';
import _ from 'lodash';
import { State, Comment } from './index';
import {
  SET_COMMENTS,
  SET_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
} from './mutation-types';

export default {
  [SET_COMMENTS]: (state: State, commentData: Array<Comment>) => {
    if (commentData && Array.isArray(commentData) && commentData.length > 0) {
      const groupedByTable = _.groupBy(
        _.orderBy(commentData, ['createdat'], ['desc']),
        'feature_table'
      );
      Object.keys(groupedByTable).forEach((key) => {
        Vue.set(state, key, {
          ...state[key],
          ..._.groupBy(groupedByTable[key], 'feature_table_id'),
        });
      });
    }
  },
  [SET_COMMENT]: (state: State, comment: Comment) => {
    let featureTable = state[comment.feature_table];
    if (!featureTable) {
      featureTable = {};
      Vue.set(state, comment.feature_table, featureTable);
    }

    let allComments = featureTable[comment.feature_table_id];
    if (!allComments) {
      allComments = [];
      Vue.set(featureTable, comment.feature_table_id, allComments);
    }

    // Changelog came back as string so convert back to array

    comment.changelog =
      typeof comment.changelog === 'string'
        ? JSON.parse(comment.changelog)
        : comment.changelog;
    allComments.unshift(comment);
  },
  [UPDATE_COMMENT]: (state: State, comment: Comment) => {
    const comments = state[comment.feature_table][comment.feature_table_id];
    const commentIdx = comments.findIndex((ac) => ac.id === comment.id);
    if (commentIdx > -1) {
      comments[commentIdx].comment = comment.comment;
      comments[commentIdx].changelog = comment.changelog;
    }
    Vue.set(state[comment.feature_table], comment.feature_table_id, comments);
  },
  [DELETE_COMMENT]: (state: State, comment: Comment) => {
    const comments = state[comment.feature_table][comment.feature_table_id];
    const commentIdx = comments.findIndex((ac) => ac.id === comment.id);
    if (commentIdx > -1) {
      comments[commentIdx].comment = undefined;
      comments[commentIdx].changelog =
        typeof comment.changelog === 'string'
          ? JSON.parse(comment.changelog)
          : comment.changelog;
    }
    Vue.set(state[comment.feature_table], comment.feature_table_id, comments);
  },
};
