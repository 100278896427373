import Vue from 'vue';
import { SET_STATES } from './mutations-types';
import { initialState, State, DeviceState } from './model';

export default {
  [SET_STATES](state: State, payload: DeviceState[] = []) {
    /* Your mutation code here */
    /* Don't forget to use Vue.set to update the state */
    Vue.set(
      state,
      'data',
      Object.assign(
        state.data,
        payload.reduce((acc: any, { id, ...rest }) => {
          acc[id] = { id, ...rest };
          return acc;
        }, {})
      )
    );
  },
};
